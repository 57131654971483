import { AgChartOptions } from "ag-charts-community";
import { agCharts } from "ag-grid-enterprise";
import { useMemo } from "react";

const markerFormatter = (params) => {
  const { highlighted, size } = params;
  return {
    size: highlighted ? size * 1.5 : size,
    fill: highlighted ? "red" : "gold",
    fillOpacity: highlighted ? 0.8 : 0.4,
    strokeWidth: 0,
  };
};

const tooltip = {
  renderer: ({ datum }) => {
    const formattedDate = new Date(datum.release_date).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
      },
    );

    return {
      title: `<span style="font-size: 16px; color: black">${datum.name}</span>`,
      content: `
                  <b>Release Date:</b> ${formattedDate} <br />
                  <b>Votes:</b> ${datum.imdb_vote.toLocaleString()}<br/>
                  <b>Rating:</b> ${datum.imdb_rating}<br/>
              `,
    };
  },
  position: {
    type: "pointer",
    xOffset: 100,
    yOffset: -30,
  },
};

const pantheonTheme = {
  baseTheme: "ag-vivid-dark",
  overrides: {
    common: {
      background: { fill: "#0F1B2A" },
    },
  },
};

export const useChartConfig = (data) => {
  const options = useMemo(
    () =>
      ({
        theme: pantheonTheme,
        animation: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
        title: {
          text: `IMDb Ratings and Votes Analysis - ${data?.length} Titles`,
        },
        subtitle: {
          text: "Assessing the Impact of Release Dates on Audience Ratings and Votes; Star Size Indicates Vote Count",
        },
        series: [
          {
            type: "bubble",
            data: data,
            xKey: "release_date",
            xName: "Release Date",
            sizeKey: "imdb_vote",
            sizeName: "Votes",
            yKey: "imdb_rating",
            yName: "Rating",
            labelKey: "name",
            marker: {
              shape: "star",
              formatter: markerFormatter,
            },
            tooltip,
          },
        ],
        axes: [
          {
            position: "bottom",
            type: "time",
            keys: ["release_date"],
            label: {
              autoRotate: true,
              minSpacing: 10,
              avoidCollisions: true,
              format: "%b %Y",
            },
            tick: {
              interval: agCharts.time.year,
            },
            gridLine: {
              enabled: false,
            },
            line: {
              enabled: false,
            },
            title: {
              text: "Release Dates →",
            },
          },
          {
            position: "left",
            type: "number",
            keys: ["imdb_rating"],
            line: {
              width: 1,
            },
            gridLine: {
              enabled: false,
            },
            title: {
              text: "IMDb Votes →",
            },
          },
        ],
      } as AgChartOptions),
    [data],
  );

  return { options };
};
