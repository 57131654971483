import React, {useState , useEffect} from 'react';
import { 
    PropertyFilter,
    FormField,
    DateInput,
    Calendar 
} from "@cloudscape-design/components";
import axiosInstance from '../../../utils/axios';

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const Filtering = ({ filteringQuery, handlePropertyFilteringChange }) => {
    const [filteringOptions, setFilteringOptions] = useState([]);

    useEffect(() => {
        axiosInstance.get('/padb/unique/v2?field=providers,genre')
            .then((response) => {
                const data = response.data;
                const newFilteringOptions = [];

                Object.keys(data).forEach(key => {
                    data[key].data.forEach(value => {
                        newFilteringOptions.push({ propertyKey: key, value });
                    });
                });

                // Add static values for Theatrical
                newFilteringOptions.push({ propertyKey: "theatrical", value: "Yes" });
                newFilteringOptions.push({ propertyKey: "theatrical", value: "No" });
                newFilteringOptions.push({ propertyKey: "exclusive", value: "Yes" });
                newFilteringOptions.push({ propertyKey: "exclusive", value: "No" });

                setFilteringOptions(newFilteringOptions);
            });
    }, []);

    return (
        <PropertyFilter
            i18nStrings={{
                filteringAriaLabel: "your choice",
                dismissAriaLabel: "Dismiss",
                clearAriaLabel: "Clear",

                filteringPlaceholder: "Filter by text, property or value",
                groupValuesText: "Values",
                groupPropertiesText: "Properties",
                operatorsText: "Operators",

                operationAndText: "and",
                operationOrText: "or",

                operatorLessText: "Less than",
                operatorLessOrEqualText: "Less than or equal",
                operatorGreaterText: "Greater than",
                operatorGreaterOrEqualText: "Greater than or equal",
                operatorContainsText: "Contains",
                operatorDoesNotContainText: "Does not contain",
                operatorEqualsText: "Equals",
                operatorDoesNotEqualText: "Does not equal",

                editTokenHeader: "Edit filter",
                propertyText: "Property",
                operatorText: "Operator",
                valueText: "Value",
                cancelActionText: "Cancel",
                applyActionText: "Apply",
                allPropertiesLabel: "All properties",

                tokenLimitShowMore: "Show more",
                tokenLimitShowFewer: "Show fewer",
                clearFiltersText: "Clear filters",
                removeTokenButtonAriaLabel: (token) =>
                    `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            filteringProperties={[
                {
                    propertyLabel: "Name",
                    key: "name",
                    groupValuesLabel: "Name",
                    operators: [":", "="],
                },
                {
                    propertyLabel: "Genre",
                    key: "genre",
                    groupValuesLabel: "Genre",
                    operators: ["!=", "="],
                },
                
                {
                    propertyLabel: "Release Date",
                    key: "release_date",
                    groupValuesLabel: "Release Date",
                    operators: ["=", ">", "<", "<=",">="].map((operator) => ({
                        operator,
                        form: ({ value, onChange }) => (
                            <div className="date-form">
                                <FormField>
                                    <DateInput
                                        value={value ?? ""}
                                        onChange={(event) => onChange(event.detail.value)}
                                        placeholder="YYYY/MM/DD"
                                    />
                                </FormField>
                                <Calendar
                                    value={value ?? ""}
                                    onChange={(event) => onChange(event.detail.value)}
                                    locale="en-GB"
                                />
                            </div>
                        ),
                        match: "date",
                    })),

                },
                
                {
                    propertyLabel: "Theatrical",
                    key: "theatrical",
                    groupValuesLabel: "Theatrical",
                    operators: ["="],
                   
                },
                {
                    propertyLabel: "Watch Provider",
                    key: "providers",
                    groupValuesLabel: "Watch Provider",
                    operators: ["!=", "="],
                    
                },
                {
                    propertyLabel: "Exclusive",
                    key: "exclusive",
                    groupValuesLabel: "Exclusive",
                    operators: ["="],
                    
                }
            ].sort((a, b) =>
                a.propertyLabel.localeCompare(b.propertyLabel)
            )}
            onChange={handlePropertyFilteringChange}
            query={filteringQuery}
            filteringOptions={filteringOptions}
        />
    );
}

export default Filtering;