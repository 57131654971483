import { useState } from "react";

import { TopNavigation } from "@cloudscape-design/components";
import { useAuthContext } from "../../auth/useAuthContext";
import CommonSearch from "../../components/CommonSearch";
import { ServicesMenu } from "./ServicesMenu/ServicesMenu";
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
];

export default function Header() {
  const { logout, user } = useAuthContext();
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      {/* <ServicesMenu /> */}
      <TopNavigation
        identity={{
          href: "/dashboards/wizard",
          logo: {
            src: require("../../assets/images/icons/logo.png"),
            alt: "Pantheon",
          },
        }}
        search={<CommonSearch />}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu",
        }}
        utilities={[
          // {
          //   type: "button",
          //   iconName: "notification",
          //   title: "Notifications",
          //   ariaLabel: "Notifications (unread)",
          //   badge: true,
          //   disableUtilityCollapse: false,
          // },
          {
            type: "menu-dropdown",
            iconName: "settings",
            ariaLabel: "Settings",
            title: "Settings",
            items: [
              {
                id: "account-mgmt",
                text: "Account Management",
                items: [
                  { id: "users", text: "Users", href: "/users" },
                  { id: "roles", text: "Roles", href: "/roles" },
                  {
                    id: "permissions",
                    text: "Permissions",
                    href: "/permissions",
                  },
                  { id: "teams", text: "Teams", href: "/teams" },
                ],
              },
              {
                text: "IP Management",
                items: [
                  { id: "ip", text: "NBCU IP's", href: "/ipmanagement" },
                  {
                    id: "pantheon",
                    text: "Pantheon IP's",
                    href: "/ipmanagement/pantheon",
                  },
                  {
                    id: "data",
                    text: "Data Mapping",
                    href: "/datamapping/idmanagement",
                  },
                ],
              },
              {
                text: "Weightage Settings",
                items: [
                  {
                    id: "tracked",
                    text: "Tracked Titles",
                    href: "/settings/nbcu-weightage/ranking",
                  },
                ],
              },
            ],
          },
          {
            type: "menu-dropdown",
            text: user.name,
            description: user.email,
            iconName: "user-profile",
            onItemClick: (e) => {
              if (e.detail.id === "signout") {
                logout();
              }
            },
            items: [
              { id: "profile", text: "Profile" },
              {
                text: "Support",
                items: [
                  {
                    text: "Documentation",
                    id: "docs",
                    href: "https://guide.searchpantheon.com",
                    external: true,
                  },
                  {
                    text: "Job Monitoring",
                    id: "jb",
                    href: "/platformjobstate",
                  },
                  { text: "Audit Events", id: "audit", href: "/audit" },
                  { id: "link", text: "Issue Tracker", href: "/issues" },
                ],
              },
              { id: "signout", text: "Sign out" },
            ],
          },
        ]}
      />
    </>
  );
}
