import { ContentLayout, Header } from "@cloudscape-design/components";
import React from "react";
import { navItems } from "../../layouts/common/menu/side-menu";
import { Layout } from "./Layout";
import { IMDbRatingChart } from "./components/IMDbRatingChart";

export const IMDbDashboardPage = () => {
    const breadcrumbs = [{ text: "Platform" }, { text: "IMDb" }];
    return (
        <Layout
            title="IMDb"
            breadcrumbs={breadcrumbs}
            navItems={navItems}
            content={
                <ContentLayout
                    header={
                        <Header
                            variant="h2"
                            description="IMDb Ratings & Votes for Movies,TV Shows and Games"
                        >
                            IMDb 
                        </Header>
                    }>
                    <IMDbRatingChart />
                </ContentLayout>
            }
            contentType="table"
        />
    );
};
