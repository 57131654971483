import { Link } from "@cloudscape-design/components";
import React from "react";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import shortenInteger from "../../../../../utils/shortenInteger";

export const columnDefs = [
  {
    field: "title",
    headerName: "Title",
    cellRenderer: (params) => {
      return (
        <Link href={`/mediacatalog/youtube/videos/${params.data.videoId}`}>
          {params.value}
        </Link>
      );
    },
    sortable: false,
    flex: 2,
  },
  {
    field: "ip",
    headerName: "IP",
    flex: 1,
    cellRenderer: (params) => {
      return (
        <Link href={`/item/${params.data.ip_id}`}>
          {params.value}
        </Link>
      );
    }
  },
  {
    field: "publishedAt",
    headerName: "Published At",
    minWidth: 120,
    maxWidth: 120,
    cellRenderer: (params) => {
      const date = params.value * 1000;
      return dateFormatter(date);
    },
  },
  {
    field: "views",
    headerName: "Views",
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: (params) => {
      return shortenInteger(params.value);
    },
  },
  {
    field: "likes",
    headerName: "Likes",
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: (params) => {
      return shortenInteger(params.value);
    },
  },
  {
    field: "comments",
    headerName: "Comments",
    minWidth: 120,
    maxWidth: 120,
    cellRenderer: (params) => {
      return shortenInteger(params.value);
    },
  },
];
