import {
  Container,
  FormField,
  Grid,
  Icon,
  Select,
  Link,
  SpaceBetween,
  Spinner,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axiosInstance from "../../../utils/axios";
import { getQueryParams } from "../../../utils/queryUtils";
import TableLegend from "../components/TableLegend";
import renderTrend from "../../../utils/trendIcon";
import shortenInteger from "../../../utils/shortenInteger";

const categories = [
  { label: "Movies", value: "Movies" },
  { label: "Series", value: "Television" },
];

function RTTrendsChartsPage() {
  const gridRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [RTTC, setRTTC] = useState({
    data: [],
    status: "idle",
  });
  const [error, setError] = useState(null);

  const category = getQueryParams("category");
  const option =
    category?.toLocaleLowerCase() === "movies"
      ? { label: "Movies", value: "Movies" }
      : {
          label: "Series",
          value: "Television",
        };

  const [selectedOption, setSelectedOption] = useState(option);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption( 'includeHiddenColumnsInAdvancedFilter', true );
    }
  }, []);

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "daily_rank_change",
        headerName: "Daily Rank Change",
        filter: "agNumberColumnFilter",
        hide: true      
      },
      {
        field: "daily_rank",
        headerName: "Rank",
        maxWidth: 120,
        cellDataType: "number",
        minWidth: 120,
        cellRenderer: (params) => renderTrend(params, "daily_rank", "daily_rank_change", "daily_trend"),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        cellRenderer: (params) => {
          const releaseYear = new Date(params.data.release_date).getFullYear();
          const ipId = params.data.ip_id;
          const destination = ipId
            ? `/item/${ipId}`
            : undefined;
          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }
          return (
            <div className="flex gap-2 mt-2">
              <Icon name={params.data.tracked != null ? "status-positive" : "status-negative"} variant={iconVariant} />
              <Link color="inverted" variant="secondary" href={destination} onClick={(e) => !ipId && e.preventDefault()} > {params.value}</Link><span className="text-xs text-slate-500 mt-1">({releaseYear})</span>
            </div>
          );
        },
      },
      {
        field: "daily_votes",
        headerName: "Daily Votes",
        minWidth: 100,
      },
      {
        field: "rank",
        headerName: "All Time Rank",
        cellDataType: "number",
        minWidth: 120,
        cellRenderer: (params) => renderTrend(params, "rank", "rank_change", "trend"),
        valueGetter: (params) => parseInt(params.data.rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "fan_votes",
        headerName: "Audience Votes",
        minWidth: 100,
        cellRenderer: (params) => shortenInteger(params.value),
      },
      {
        field: "fan_rating",
        headerName: "Audience Rating",
        minWidth: 100,
      },
      {
        field: "weekly_votes",
        headerName: "Weekly Votes",
        minWidth: 100,
        cellDataType: "number",
      },
      {
        field: "weekly_rating",
        headerName: "Weekly Rating Change",
        minWidth: 100,
        cellDataType: "number",
        cellRenderer: (params) => {
          return params.value === 0 ? 0 : params.value.toFixed(0);
        }
      },
      {
        field: "sparkline",
        headerName: "7-day Trend",
        cellRenderer: "agSparklineCellRenderer",
        filter: false,
      },
    ];
    return allColumns;
  }, []);

  const fetchRTTCData = async (vertical = "Television") => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        url: `/ranking/daily/rottentomatoes?vertical=${vertical}`,
        method: "GET",
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.data.map((item) => {
        return {
          ...item,
        };
      });
      const latestUpdatedAt = moment(response.data.timestamp * 1000).format(
        "MMM DD, Y",
      );
      setRTTC({ data, status: "loaded", latestUpdatedAt });
    } catch (error) {
      setError(error.message);
      setRTTC((prevState) => ({ ...prevState, status: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRTTCData(selectedOption.value);
  }, [selectedOption.value]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  const gridOptions = {
    getContextMenuItems: (params) => {
      if (params.column.colId === "title") {
        return null;
      }
      return params.defaultItems;
    },
  };

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Container>
        <Grid
          gridDefinition={[{ colspan: 8 }, { colspan: 2 }, { colspan: 2 }]}
        >
          <FormField description="Advanced Filtering">
            <div id="advancedFilterParent"></div>
          </FormField>
          <FormField description="Filter titles from the table">
            <TextFilter
              filteringText={filterText}
              filteringPlaceholder="Search title"
              filteringAriaLabel="Filter title"
              onChange={({ detail }) =>
                setFilterText(detail.filteringText)
              }
            />
          </FormField>
          <FormField description="Categories">
            <Select
              selectedOption={selectedOption}
              onChange={({ detail }) =>
                setSelectedOption(detail.selectedOption)
              }
              options={categories}
            />
          </FormField>
        </Grid>
      </Container>

      {isLoading ? (
        <div className="flex flex-row justify-center items-center">
          <Spinner size="large" />
        </div>
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <div style={{ height: "70vh" }} className="ag-theme-quartz-dark">
            <AgGridReact
              ref={gridRef}
              quickFilterText={filterText}
              rowData={RTTC.data}
              paginationPageSize={100}
              pagination={true}
              defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              enableAdvancedFilter={true}
              tooltipShowDelay={100}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </div>
          <TableLegend />
        </SpaceBetween>
      )}
    </SpaceBetween>
  );
}

export default RTTrendsChartsPage;
