import { formattedBoxOfficeRegions } from "../../../config-global";

const createBoxOfficeUrl = ({
  occasion = "",
  region = "",
  startDate = "",
  endDate = "",
}) => {
  return `boxoffice/grosstimeseries?occasion=${occasion}&region=${region}&startDate=${startDate}&endDate=${endDate}`;
};

function filterRegionsByOccassion(occasion) {
  if (!occasion) {
    return [];
  }
  if (occasion === "daily") {
    return formattedBoxOfficeRegions[0].options.filter(
      (d) => d.value === "XDOM"
    );
  }
  if (occasion === "weekly") {
    return formattedBoxOfficeRegions[0].options.filter(
      (d) => d.value === "XDOM" || d.value === "XWW"
    );
  }
  if (occasion === "weekend") {
    return formattedBoxOfficeRegions;
  }
  return [];
}

const extractGreatestEndDate = ({ payload }) => {
  let greatestEndDate = "";
  if (payload?.length <= 0) return 0;
  payload?.map((item) => {
    item.grossTimeseries.map((series) => {
      if (
        !greatestEndDate ||
        new Date(series.endDate) > new Date(greatestEndDate)
      ) {
        greatestEndDate = series.endDate;
      }
    });
  });

  return greatestEndDate;
};

export { createBoxOfficeUrl, filterRegionsByOccassion, extractGreatestEndDate };
