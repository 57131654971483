import React from "react";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";

const DateRangeSelector = ({ defaults, onChange, isDateEnabled }) => {
    return (
        <RangeDateSelector
            defaults={defaults}
            onChange={onChange}
            isDateEnabled={isDateEnabled}
        />
    );
};

export default DateRangeSelector;
