import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchPiracyGenericMoviesSummary = createAsyncThunk(
  "piracyGenericMoviesSummary/fetchPiracyGenericMoviesSummary",

  async ({ gte, lte, option, size, vertical, country }) => {
    const response = await axiosInstance.request({
      url: `/piracy/generic/us/summary`,
      method: `GET`,
      params: {
        gte,
        lte,
        option,
        size,
        vertical: vertical.value,
        country: country,
      },
    });
    return response;
  }
);

const piracyGenericMoviesSummarySlice = createSlice({
  name: "piracyGenericMoviesSummary",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPiracyGenericMoviesSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPiracyGenericMoviesSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchPiracyGenericMoviesSummary.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const piracyGenericMoviesSummaryReducer =
  piracyGenericMoviesSummarySlice.reducer;

export default piracyGenericMoviesSummaryReducer;

export const piracyGenericMoviesSummaryActions =
  piracyGenericMoviesSummarySlice.actions;
