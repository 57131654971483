import { useEffect, useState } from "react";
import { useGetBOData } from "./useGetBoByStudio";

interface BOData {
    domestic: string;
    international: string;
    productionBudget: string;
    distributor: string;
}

interface ProcessedData {
    distributor: string;
    domestic: number;
    international: number;
    productionBudget: number;
    count: number;
    worldwide?: number;
    domesticMarketShare?: number;
    internationalMarketShare?: number;
    profitMargin?: number;
}

interface BODataProcessingResult {
    isLoading: boolean;
    processedData: ProcessedData[] | null;
    error: { name: string; message: string } | null;
}

export const useBODataProcessing = (): BODataProcessingResult => {
    const { isLoading, data, error } = useGetBOData();
    const [processedData, setProcessedData] = useState<ProcessedData[] | null>(null);

    useEffect(() => {
        if (data && data.length > 0) {
            const cleanedData: ProcessedData[] = data.map(item => ({
                distributor: item.distributor.trim(),
                domestic: parseInt(item.domestic.replace(/,/g, ''), 10),
                international: parseInt(item.international.replace(/,/g, ''), 10),
                productionBudget: Number.isNaN(parseInt(item.productionBudget.replace(/,/g, ''), 10))
                    ? 0
                    : parseInt(item.productionBudget.replace(/,/g, ''), 10),
                count: 0,
                worldwide: 0,
                domesticMarketShare: 0,
                internationalMarketShare: 0,
                profitMargin: 0
            }));

            const groupedData = cleanedData.reduce<Record<string, ProcessedData>>((acc, item) => {
                const distributor = item.distributor;
                if (!acc[distributor]) {
                    acc[distributor] = {
                        distributor,
                        domestic: 0,
                        international: 0,
                        productionBudget: 0,
                        count: 0,
                        worldwide: 0,
                        domesticMarketShare: 0,
                        internationalMarketShare: 0,
                        profitMargin: 0
                    };
                }
                acc[distributor].domestic += item.domestic;
                acc[distributor].international += item.international;
                acc[distributor].productionBudget += item.productionBudget;
                acc[distributor].count += 1;
                return acc;
            }, {});

            const sortedData = Object.values(groupedData).sort(
                (a, b) => b.domestic + b.international - a.domestic - a.international
            );

            const totalDomestic = sortedData.reduce((acc, studio) => acc + studio.domestic, 0);
            const totalInternational = sortedData.reduce((acc, studio) => acc + studio.international, 0);

            sortedData.forEach(studio => {
                studio.worldwide = studio.domestic + studio.international;
                studio.domesticMarketShare = (studio.domestic / totalDomestic) * 100;
                studio.internationalMarketShare = (studio.international / totalInternational) * 100;
                studio.profitMargin = ((studio.domestic + studio.international - studio.productionBudget) / studio.productionBudget) * 100;
            });

            setProcessedData(sortedData);
        }
    }, [data]);

    const formattedError = error && error instanceof Error ? { name: error.name, message: error.message } : null;

    return { isLoading, processedData, error: formattedError };
};
