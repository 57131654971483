import React from 'react';
import { Grid, Container, Box, Link, SpaceBetween } from "@cloudscape-design/components";

function IndexPageGrid({ items, gridDefinition }) {
    return (
        <div>
            <Grid gridDefinition={gridDefinition}>
                {items.map((item, index) => (
                    <Container
                        variant="stacked"
                        fitHeight
                        key={index}
                        media={{
                            content: (
                                <Link href={item.link}>
                                    <img src={item.image} alt={item.name} />
                                </Link>
                            ),
                            width: 100,
                            position: "side"
                        }}
                    >
                        <SpaceBetween direction="vertical" size="xxs">
                            <Box variant="h2">
                                <Link variant="primary" fontSize="heading-m" href={item.link}>
                                    {item.name}
                                </Link>
                            </Box>
                            {item.description}
                        </SpaceBetween>
                    </Container>
                ))}
            </Grid>
        </div>
    );
}

export default IndexPageGrid;