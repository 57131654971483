import { isSomeOrElse } from "../../../../utils/sugarUtils";

type PlatformPayloadItem = {
  weightage: number;
  isChecked: boolean;
};

export type PayloadRequest = {
  wiki: PlatformPayloadItem;
  youtubeViews: PlatformPayloadItem;
  p2p: PlatformPayloadItem;
  twitch: PlatformPayloadItem;
  imdb: PlatformPayloadItem;
  rottenTomatoes: PlatformPayloadItem;
  youtubeLikes: PlatformPayloadItem;
  youtubeComments: PlatformPayloadItem;
};

export const mapFormValueToRequest = (data): PayloadRequest => {
  return {
    wiki: {
      weightage: Number(data.wikipedia.weightage),
      isChecked: data.wikipedia.isChecked,
    },
    youtubeViews: {
      weightage: Number(data.youtubeViews.weightage),
      isChecked: data.youtubeViews.isChecked,
    },
    p2p: {
      weightage: Number(data.p2p.weightage),
      isChecked: data.p2p.isChecked,
    },
    twitch: {
      weightage: Number(data.twitch.weightage),
      isChecked: data.twitch.isChecked,
    },
    imdb: {
      weightage: Number(data.imdb.weightage),
      isChecked: data.imdb.isChecked,
    },
    rottenTomatoes: {
      weightage: Number(data.rottenTomatoes.weightage),
      isChecked: data.rottenTomatoes.isChecked,
    },
    youtubeLikes: {
      weightage: Number(data.youtubeLikes.weightage),
      isChecked: data.youtubeLikes.isChecked,
    },
    youtubeComments: {
      weightage: Number(data.youtubeComments.weightage),
      isChecked: data.youtubeComments.isChecked,
    },
  };
};
