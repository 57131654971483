import {
  Container,
  FormField,
  Header,
  Select,
  SpaceBetween,
  TextFilter
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useEffect,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { AdvancedFilters } from "../../../components/filters/AdvancedFilters";
import { FiltersDropdown } from "../../../components/filters/FiltersDropdown";
import { getQueryParams } from "../../../utils/queryUtils";
import { isNone, isSomeOrElse } from "../../../utils/sugarUtils";
import TableLegend from "../components/TableLegend";
import { useGetIMDbTrendsData } from "./api/hooks/useGetIMDbTrendsData";
import { useGridConfig } from "./hooks/useGridConfig";
import moment from "moment";

const categories = [
  { label: "Movies", value: "Movies" },
  { label: "Series", value: "Television" },
  { label: "Games", value: "Gaming" },
];

function IMDbTrendsChartsPage() {
  const gridRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [IMDbTC, setIMDbTC] = useState({
    data: [],
    status: "idle",
  });
  const [filter, setFilter] = useState(null);
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const { defaultColDef, columnDefs, onGridReady, gridOptions } = useGridConfig();

  const category = getQueryParams("category");
  const option =
    category?.toLocaleLowerCase() === "movies"
      ? { label: "Movies", value: "Movies" }
      : {
        label: "Series",
        value: "Television",
      };

  const [selectedOption, setSelectedOption] = useState(option);

  const {
    data: imdbTrendsData,
    isLoading,
    error,
  } = useGetIMDbTrendsData(selectedOption.value);

  useEffect(() => {
    if (isNone(imdbTrendsData)) return;

    let data = imdbTrendsData.data?.data?.map((item) => {
      return {
        ...item,
      };
    });

    setIMDbTC({ data, status: "loaded" });
  }, [imdbTrendsData]);

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const getExistingIMDbPreferences = (currentFilters) => ({
    ...globalPref,
    dashboards: {
      ...globalPref?.dashboards,
      viewersPulse: {
        ...globalPref?.dashboards?.viewersPulse,
        imdb: {
          filters: { ...currentFilters },
        },
      },
    },
  });

  const getPreferencesAfterDelete = (name) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.imdb?.filters,
        {},
      ),
    };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingIMDbPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.imdb?.filters,
        {},
      ),
    };
    currentFilters[name] = { ...filter };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingIMDbPreferences(currentFilters);
  };

  if (error) {
    return <div>Error: {error?.message}</div>;
  }

  if (isLoading) {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  const handleClearFilter = () =>
    gridRef.current.api.setAdvancedFilterModel(null);

  const handleFilterItemClick = (id, filters) => {
    const filter = filters[id];

    gridRef.current.api.setAdvancedFilterModel(filter);
    setSelectedFilterName(id);
  };

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <FiltersDropdown
                  handleFilterItemClick={handleFilterItemClick}
                  path="dashboards.viewersPulse.imdb.filters"
                />
                <AdvancedFilters
                  handleClearFilter={handleClearFilter}
                  selectedFilterName={selectedFilterName}
                  filter={filter}
                  getPreferencesAfterDelete={getPreferencesAfterDelete}
                  getPreferencesAfterSaveOrUpdate={
                    getPreferencesAfterSaveOrUpdate
                  }
                />
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="horizontal" size="xs">
              <FormField description="Filter titles from the table">
                <TextFilter
                  filteringText={filterText}
                  filteringPlaceholder="Search title"
                  filteringAriaLabel="Filter title"
                  onChange={({ detail }) =>
                    setFilterText(detail.filteringText)
                  }
                />
              </FormField>
              <FormField description="Categories">
                <Select
                  selectedOption={selectedOption}
                  onChange={({ detail }) =>
                    setSelectedOption(detail.selectedOption)
                  }
                  options={categories}
                />
              </FormField>
            </SpaceBetween>
          </Header>
        }
      >
        <div id="advancedFilterParent"></div>
      </Container>
      <div style={{ height: "70vh" }} className="ag-theme-quartz-dark">
        <AgGridReact
          ref={gridRef}
          quickFilterText={filterText}
          rowData={IMDbTC.data}
          paginationPageSize={100}
          pagination={true}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          loadingOverlayComponent={CustomLoadingOverlay}
          noRowsOverlayComponent={CustomLoadingOverlay}
          suppressDragLeaveHidesColumns={true}
          enableAdvancedFilter={true}
          tooltipShowDelay={100}
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          onFilterChanged={(params) => {
            const f = params?.api?.getAdvancedFilterModel();
            setFilter(f);
          }}
        />
      </div>
      <TableLegend />
    </SpaceBetween>
  );
}

export default IMDbTrendsChartsPage;