import { dispatch } from "../../../../../app/store";
import { fetchTarSeriesWikiViewGenrePlatformMarketshare, tarSeriesWikiViewGenrePlatformMarketshareResposeAction } from "../slices/tar-series-wiki-view-genre-platform-marketshare-slice";


export async function getTarSeriesWikiViewGenrePlatformMarketshareResponse({
  gte,
  lte,
  interval,
  excludeOTT,
}) {
  await dispatch(
    fetchTarSeriesWikiViewGenrePlatformMarketshare({ gte, lte, interval, excludeOTT })
  );
}

export function clearTarSeriesWikiViewGenrePlatformMarketshareResponse() {
  dispatch(tarSeriesWikiViewGenrePlatformMarketshareResposeAction.clearData());
}
