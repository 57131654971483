import React, { useEffect, useState } from "react";
import {
  Header,
  SplitPanel,
} from "@cloudscape-design/components";

const SplitPanelContainer = ({ selectedItems, contents }) => {
  const [splitPanelSize, setSplitPanelSize] = useState(300);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  const onSplitPanelToggle = ({ detail: { open } }) => {
    setSplitPanelOpen(open);
    if (!open) {
      setHasManuallyClosedOnce(true);
    }
  };

  useEffect(() => {
    if (selectedItems.length && !hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  }, [selectedItems.length, hasManuallyClosedOnce]);

  return (
    <SplitPanel
      header={
        <Header>
          <span style={{ textTransform: "capitalize" }}>Compare Titles</span>
        </Header>
      }
      i18nStrings={{
        preferencesTitle: "Split panel preferences",
        preferencesPositionLabel: "Split panel position",
        preferencesPositionDescription:
          "Choose the default split panel position for the service.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesConfirm: "Confirm",
        preferencesCancel: "Cancel",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
      closeBehavior="collapse"
      defaultPosition="bottom"
      size={splitPanelSize}
      onResize={onSplitPanelResize}
      onToggle={onSplitPanelToggle}
    >
      {contents}
    </SplitPanel>
  );
};

export default SplitPanelContainer;
