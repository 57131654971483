import { dispatch } from "../../../../../app/store";
import {
  fetchTarSeriesWikiViewGenrePlatform,
  tarSeriesWikiViewGenrePlatformResposeAction,
} from "../slices/tar-series-wiki-view-genre-platform-slice";

export async function getTarSeriesWikiViewGenrePlatformResponse({
  gte,
  lte,
  interval,
  excludeOTT,
}) {
  await dispatch(
    fetchTarSeriesWikiViewGenrePlatform({ gte, lte, interval, excludeOTT })
  );
}

export function clearTarSeriesWikiViewGenrePlatformResponse() {
  dispatch(tarSeriesWikiViewGenrePlatformResposeAction.clearData());
}
