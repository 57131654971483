import { Box, Link } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../../../utils/axios";
import renderTrend from "../../../../../utils/trendIcon";

export const widgetTwo = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "IMDb Movie Trends",
    description: "Daily IMDb Movie user engagement stats",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
      {widgetTwo.data.title}
    </Box>
  );
}

function AccountAttributesFooter() {
  return (
    <Box>
      <Link
        fontSize="inherit"
        href="/dashboards/imdbtrends?category=movies"
        variant="secondary"
      >
        View All
      </Link>
    </Box>
  );
}

function AccountAttributesContent() {
  const [IMDbTC, setIMDbTC] = useState({ data: [], status: "idle" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchIMDbTCData = async (vertical = "Movies") => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        url: `/ranking/daily/imdb?vertical=${vertical}&size=10`,
        method: "GET",
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.data.map((item) => {
        return {
          ...item,
        };
      });
      setIMDbTC({ data, status: "loaded" });
    } catch (error) {
      setError(error.message);
      setIMDbTC((prevState) => ({ ...prevState, status: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Rank ",
        maxWidth: 80,
        cellDataType: "number",
        minWidth: 80,
        sortable: true,
        cellRenderer: (params) => renderTrend(params, "daily_rank", "daily_rank_change", "daily_trend"),
        valueGetter: (params) => parseInt(params.data.daily_rank),
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span> {params.data.ip}</span>
            </Link>
          );
        },
      },
      {
        field: "sparkline",
        headerName: "Trend",
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: 'area',
            marker: {
              size: 3,
              shape: 'circle',
              fill: 'white',
            },
            fill: '#0972d3',
            highlightStyle: {
              fill: '#033160',
            },
          },
        },
      },
    ];
    return allColumns;
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  useEffect(() => {
    fetchIMDbTCData();
  }, []);

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className="ag-theme-quartz-dark compact" style={{ height: '99.5%' }}>
        <AgGridReact
          rowData={IMDbTC.data}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
