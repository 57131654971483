import { dispatch } from "../../../../app/store";
import axiosInstance from "../../../../utils/axios";
import { fetchGamesData, fetchFilteredGamesData } from "./games_slice";

export async function getGamesData(from, size, range) {
  await dispatch(fetchGamesData({ from, size, range }));
}

export async function getFilteredGamesData(from, size, range, body) {
  await dispatch(fetchFilteredGamesData({ from, size, range, body }));
}

