import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../../utils/axios';

export const fetchSeries = createAsyncThunk(
    'seriesNonOtt/fetchSeries',
    async ({ sort, include, exclude, includeCondition }, { rejectWithValue }) => {
        try {
            const url = `/tar/non_ott?vertical=series`;
            const requestBody = {
                sort,
                include,
                exclude,
                include_condition: includeCondition
            };
            const response = await axiosInstance.request({
                url: url,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: requestBody
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    seriesNonOtt: [],
    loading: false,
    error: null,
};

const seriesSlice = createSlice({
    name: 'seriesNonOtt',
    initialState,
    reducers: {
        clearSeries: (state) => {
            state.seriesNonOtt = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSeries.fulfilled, (state, action) => {
                state.loading = false;
                state.seriesNonOtt = action.payload;
            })
            .addCase(fetchSeries.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong';
            });
    },
});

export const { reducer: seriesNonOttReducer } = seriesSlice;