import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getItemCompareData } from "../request";

export const useGetItemCompareData = ({
  ipId,
  imdbId,
}: {
  ipId: string;
  imdbId: string;
}) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_ITEM_COMPARE_DATA, ipId, imdbId],
    () => getItemCompareData(ipId, imdbId),
  );
  return {
    data: isSomeOrElse(data?.data, {}),
    isLoading,
    error,
  };
};
