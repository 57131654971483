const lineTheme = {
    baseTheme: "ag-default-dark",
    overrides: {
        line: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        bar: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        common: {
            background: { fill: "#0F1B2A" },
        },
    },
};

const gridline = {
    style: [
        {
            stroke: "#192e47",
            lineDash: [10, 10],
        },
    ],
};

export const baseOptions = {
    autoSize: true,
    theme: lineTheme,
    legend: {
        enabled: false,
    },
    zoom: {
        enabled: true,
    },
    axes: [
        {
            type: "category",
            position: "bottom",
            label: {
                formatter: function (params) {
                    const date = new Date(params.value * 1000);
                    const dateFormatter = new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                    });
                    return dateFormatter.format(date);
                },
                minSpacing: 20,
                autoRotate: false,
            },
            crossLines: [
                {
                    type: "line",
                    stroke: "rgb(100 116 139)",
                    strokeWidth: .5,
                    label: {
                        text: "Release Date",
                        position: "top",
                        fontSize: 12,
                        fontFamily: "Open Sans",
                        color: "rgb(100 116 139)",
                    },
                },
            ],
        },
        {
            type: "number",
            position: "left",
            gridLine: gridline,
            label: {
                fontFamily: '"Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif',
                formatter: (params) =>
                    Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                    }).format(params.value),
            },
            min: 0,
            tick: {
                minSpacing: 10,
            },
            keys: ["wikipedia_pageviews","google_trends"],
        },
    ],
    padding: {
        right: 0,
    },
};

export const trackedWikiTrendsChartOption = {
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    series: [
        {
            type: "bar",
            xKey: "timestamp",
            xName: "Date",
            yKey: "views_user",
            yName: "Total",
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "Total Views",
                        content: `${params.datum.timestamp} : ${params.datum.views_user.toLocaleString()}`,
                    };
                },
            }
        }
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            label: {
                fontFamily: "Open Sans",
                minSpacing: 20,
                autoRotate: false,
            },
            crossLines: [
                {
                    type: "line",
                    value: null,
                    stroke: "rgb(100 116 139)",
                    label: {
                        text: "Release Date",
                        position: "top",
                        fontSize: 12,
                        fontFamily: "Open Sans",
                        color: "rgb(100 116 139)",
                    },
                },
            ],
        },
        {
            type: "number",
            position: "left",
            gridLine: gridline,
            label: {
                formatter: (params) =>
                    Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                    }).format(params.value),
            },
            min: 0,
            tick: {
                minSpacing: 10,
            },
            keys: ["views_user"],
        },

    ],
    padding: {
        right: 0,
    },
};

export const timelineTheme = {
    cardBgColor: "#0F1B2A",
    cardDetailsBackGround: "#000716",
    cardDetailsColor: "#ffffff",
    cardMediaBgColor: "#2f2f2f",
    cardSubtitleColor: "#94a3b8",
    cardTitleColor: "#539fe5",
    detailsColor: "#94a3b8",
    iconBackgroundColor: "#539fe5",
    nestedCardBgColor: "#333333",
    nestedCardDetailsBackGround: "#333333",
    nestedCardDetailsColor: "#94a3b8",
    nestedCardSubtitleColor: "#94a3b8",
    nestedCardTitleColor: "#94a3b8",
    primary: "#539fe5",
    secondary: "#ffffff",
    titleColor: "#539fe5",
    titleColorActive: "#0F1B2A",
};

export const timelineClasses = {
    card: "timeline-card",
    cardMedia: "timeline-card-media",
    cardSubTitle: "timeline-card-subtitle",
    cardText: "timeline-card-text",
    cardTitle: "timeline-card-title",
    controls: "timeline-controls",
    title: "timeline-title",
};


export const baseTwitchChartOptions = {
    autoSize: true,
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    legend: {
        enabled: true,
        position: "right",
    },
    series: [
        {
            type: "area",
            xKey: "time",
            yKey: "peak_viewers",
            yName: "Peak Viewers",
            marker: { enabled: false },
            fill: "#5090dc",
            stroke: "#2d588a",
            strokeWidth: 2,
            tooltip: {
                renderer: (params) => {
                    const date = new Date(params.datum.time * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return {
                        title: "Peak Twitch Viewers",
                        content: `${dateFormatter.format(date)}: ${Math.round(params.datum.peak_viewers).toLocaleString()}`,
                    };
                }
            }
        },
        {
            type: "area",
            xKey: "time",
            yKey: "average_viewers",
            yName: "Average Viewers",
            marker: { enabled: false },
            fill: "#5090dc",
            stroke: "#2d588a",
            strokeWidth: 2,
            tooltip: {
                renderer: (params) => {
                    const date = new Date(params.datum.time * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return {
                        title: "Average Twitch Viewers",
                        content: `${dateFormatter.format(date)}: ${Math.round(params.datum.average_viewers).toLocaleString()}`,
                    };
                }
            }
        },
        {
            type: "line",
            xKey: "time",
            yKey: "peak_streamers",
            yName: "Peak Streamers",
            marker: { enabled: false },
            stroke: "#ffa03a",
            tooltip: {
                renderer: (params) => {
                    const date = new Date(params.datum.time * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return {
                        title: "Peak Twitch Streamers",
                        content: `${dateFormatter.format(date)}: ${Math.round(params.datum.peak_streamers).toLocaleString()}`,
                    };
                }
            }
        },
        {
            type: "line",
            xKey: "time",
            yKey: "average_streamers",
            yName: "Average Streamers",
            marker: { enabled: false },
            stroke: "#ffa03a",
            tooltip: {
                renderer: (params) => {
                    const date = new Date(params.datum.time * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return {
                        title: "Average Twitch Streamers",
                        content: `${dateFormatter.format(date)}: ${Math.round(params.datum.average_streamers).toLocaleString()}`,
                    };
                }
            }
        },
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            label: {
                formatter: (params) => {
                    const date = new Date(params.value * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return dateFormatter.format(date);
                },
                avoidCollisions: true,
                minSpacing: 20,
                autoRotate: true,
            },
            crossLines: [
                {
                    type: "line",
                    value: null,
                    stroke: "rgb(100 116 139)",
                    label: {
                        text: 'Release Date',
                        position: 'top',
                        fontSize: 12,
                        fontFamily: "Open Sans",
                        color: "rgb(100 116 139)",
                    },
                }
            ],
        },
        {
            type: "number",
            position: "left",
            gridLine: { style: [ { stroke: "#192e47", lineDash: [10, 10] } ] },
            label: {
                formatter: (params) => Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                }).format(params.value),
            },
            tick: {
                minSpacing: 20
            },
            min: 0,
            keys: ["average_viewers", "peak_viewers"],
            title: {
                text: 'Viewers',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },
        {
            type: "number",
            position: "right",
            gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
            label: {
                formatter: (params) => Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                }).format(params.value),
            },
            tick: {
                minSpacing: 20
            },
            min: 0,
            keys: ["average_streamers", "peak_streamers"],
            title: {
                text: 'Streamers',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },
    ],
    padding: {
        right: 0
    },
    data: [],
};

export const baseSteamChartOptions = {
    autoSize: true,
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    legend: {
        enabled: true,
        position: "right",
    },
    series: [
        {
            type: "line",
            xKey: "time",
            yKey: "ccu",
            yName: "Concurrent Users",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    const date = new Date(params.datum.time * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return {
                        title: "Steam Concurrent Users",
                        content: `${dateFormatter.format(date)}: ${Math.round(params.datum.ccu).toLocaleString()}`
                    };
                }
            }
        },
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            label: {
                formatter: (params) => {
                    const date = new Date(params.value * 1000);
                    const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                    return dateFormatter.format(date);
                },
                avoidCollisions: true,
                minSpacing: 20,
                autoRotate: true,
            },
            crossLines: [
                {
                    type: "line",
                    value: null,
                    stroke: "rgb(100 116 139)",
                    label: {
                        text: 'Release Date',
                        position: 'top',
                        fontSize: 12,
                        fontFamily: "Open Sans",
                        color: "rgb(100 116 139)",
                    },
                }
            ],
        },
        {
            type: "number",
            position: "left",
            gridLine: { style: [ { stroke: "#192e47", lineDash: [10, 10] } ] },
            label: {
                formatter: (params) => Intl.NumberFormat('en-US', {
                    notation: "compact",
                    maximumFractionDigits: 1
                }).format(params.value),
            },
            tick: {
                minSpacing: 20
            },
            min: 0,
            keys: ["ccu"],
        },
    ],
    padding: {
        right: 0
    },
    data: [],
};

export const trackedFandomTrendsChartOption = {
    autoSize: true,
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    legend: {
        enabled: true,
        position: "right"
    },
    series: [
        {
            type: "line",
            xKey: "timestamp",
            yKey: "usersGrowth",
            yName: "Users",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "User Growth in %",
                        content: `${params.datum.timestamp} : ${params.datum.usersGrowth.toFixed(2)}%`,
                    };
                },
            }
        },
        {
            type: "line",
            xKey: "timestamp",
            yKey: "pagesGrowth",
            yName: "Pages",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "Pages Growth in %",
                        content: `${params.datum.timestamp} : ${params.datum.pagesGrowth.toFixed(2)}%`,
                    };
                },
            }
        },
        {
            type: "line",
            xKey: "timestamp",
            yKey: "articlesGrowth",
            yName: "Articles",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "Articles Growth in %",
                        content: `${params.datum.timestamp} : ${params.datum.articlesGrowth.toFixed(2)}%`,
                    };
                },
            }
        }
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            crosshair: { enabled: true },
            label: {
                fontFamily: "Open Sans",
                minSpacing: 20,
                autoRotate: true,
            },
        },
        {
            type: "number",
            position: "left",
            title: {
                text: 'Pages & Articles Growth %',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
            crosshair: { enabled: true },
            gridLine: gridline,
            label: {
                formatter: (params) => `${params.value}%`,
            },
            min: 0,
            tick: {
                minSpacing: 10,
                maxSpacing: 100,
            },
            keys: ["pagesGrowth", "articlesGrowth"],
        },
        {
            type: "number",
            position: "right",
            crosshair: { enabled: true },
            gridLine: gridline,
            label: {
                formatter: (params) => `${params.value}%`,
            },
            min: 0,
            tick: {
                minSpacing: 10,
                maxSpacing: 100,
            },
            keys: ["usersGrowth"],
            title: {
                text: 'User Growth %',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },

    ],
    padding: {
        right: 0,
    },
};

export const trackedRedditTrendsChartOption = {
    autoSize: true,
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    legend: {
        enabled: true,
        position: "right"
    },
    series: [
        {
            type: "line",
            xKey: "timestamp",
            yKey: "subscriberGrowth",
            yName: "Subscribers",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "New Subscribers",
                        content: `${params.datum.timestamp} : ${params.datum.subscriberGrowth}`,
                    };
                },
            }
        },
        {
            type: "line",
            xKey: "timestamp",
            yKey: "comments",
            yName: "Comments",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "New Comments",
                        content: `${params.datum.timestamp} : ${params.datum.comments}`,
                    };
                },
            }
        },
        {
            type: "line",
            xKey: "timestamp",
            yKey: "activeUsers",
            yName: "Active Users",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "Active Users",
                        content: `${params.datum.timestamp} : ${parseInt(params.datum.activeUsers)}`,
                    };
                },
            }
        }
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            crosshair: { enabled: true },
            label: {
                format: "%d %b %Y",
                minSpacing: 20,
                autoRotate: true,
            },
        },
        {
            type: "number",
            position: "left",
            gridLine: gridline,
            crosshair: { enabled: true },
            label: {
                formatter: (params) =>
                    Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                    }).format(params.value),
            },
            min: 0,
            tick: {
                minSpacing: 10,
            },
            keys: ["subscriberGrowth", "activeUsers"],
            title: {
                text: 'Active Users & Subscribers',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },
        {
            type: "number",
            position: "right",
            gridLine: gridline,
            crosshair: { enabled: true },
            label: {
                formatter: (params) =>
                    Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                    }).format(params.value),
            },
            min: 0,
            tick: {
                minSpacing: 10,
            },
            keys: ["comments"],
            title: {
                text: 'New Comments',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },
    ],
    padding: {
        right: 0,
    },
};


export const trackedInstagramTrendsChartOption = {
    autoSize: true,
    theme: lineTheme,
    zoom: {
        enabled: true,
    },
    legend: {
        enabled: true,
        position: "right"
    },
    series: [
        {
            type: "line",
            xKey: "timestamp",
            yKey: "newPosts",
            yName: "Posts",
            marker: { enabled: false },
            tooltip: {
                renderer: (params) => {
                    return {
                        title: "New Posts",
                        content: `${params.datum.timestamp} : ${params.datum.newPosts}`,
                    };
                },
            }
        },
    ],
    axes: [
        {
            type: "category",
            position: "bottom",
            crosshair: { enabled: true },
            label: {
                format: "%d %b %Y",
                minSpacing: 20,
                autoRotate: true,
            },
        },
        {
            type: "number",
            position: "left",
            gridLine: gridline,
            crosshair: { enabled: true },
            label: {
                formatter: (params) =>
                    Intl.NumberFormat("en-US", {
                        notation: "compact",
                        maximumFractionDigits: 1,
                    }).format(params.value),
            },
            min: 0,
            tick: {
                minSpacing: 10,
            },
            keys: ["newPosts"],
            title: {
                text: 'New Posts',
                fontSize: 10,
                color: "rgb(100 116 139)",
            },
        },
    ],
    padding: {
        right: 0,
    },
};