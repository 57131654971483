import React, { useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Header,
  SideNavigation,
  Box,
  Icon,
  Table,
  Link,
  Flashbar,
  Pagination,
} from "@cloudscape-design/components";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import { getFilteredGamesData } from "./redux/games_action";
import Preferences from "../components/Preferences";
import Filters from "../components/Filters";
import { addMessageToFlash, cleanAllFlashMessage } from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };
const breadcrumbs = [{text: "Media Catalog"},{ text: "Explore", href: "/explore" }, { text: "Games" }];

const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

const TableColumns = [
  {
    id: "poster",
    header: "Poster",
    width: 120,
    cell: (e) => {
      return (
        <a href={`${window.location.origin}/item/${e.ip_id}`}>
          <img
            src={
              "https://images.igdb.com/igdb/image/upload/t_cover_big/" +
              e.image_url +
              ".png"
            }
            alt={e.name}
            className="rounded"
            loading="lazy"
          />
        </a>
      );
    },
  },
  {
    id: "name",
    header: "Title & Summary",
    width: 700,
    minWidth: 300,
    cell: (e) => {
      const summary = e.summary || "N/A";
      const truncatedSummary =
        summary.length > 250 ? summary.substring(0, 250) + "..." : summary;

      return (
        <div>
          <Link
            href={`${window.location.origin}/item/${e.ip_id}`}
            fontSize="heading-m"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <span className="font-bold">{e.ip}</span>
          </Link>
          <br />
          <span className="text-slate-400">{truncatedSummary}</span>
        </div>
      );
    },

    sortingField: "name",
  },
  // {
  //   id: "status",
  //   header: "Status",
  //   cell: (e) => {
  //     return <span>{e.status}</span>;
  //   },
  //   sortingField: "status",
  // },
  {
    id: "release_date",
    header: "Release Date",
    cell: (e) => {
      return (
        <span>
          {e.release_date
            ? formatDate(new Date(Date.parse(e.release_date)))
            : "N/A"}
        </span>
      );
    },
    sortingField: "release_date",
  },
  {
    id: "tracked",
    header: "Tracked",
    minWidth: 130,
    maxWidth: 130,
    cell: (e) =>
      e.tracked ? (
        <div className="text-green-500">
          <Icon name="status-positive" variant="success" />
          {e.ip_list?.length > 0 ? e.ip_list.join(", ") : "Tracked"}
        </div>
      ) : (
        <div className="text-slate-400">
          <Icon name="status-stopped" variant="subtle" /> Not Tracked
        </div>
      ),
    sortingField: "tracked",
  },
];

const idMap = new Map([
  ["name", "ip.keyword"],
  // ["status", "status"],
  ["tracked", "tracked"],
  ["release_date", "release_date"],
]);

export default function ExploreGames() {
  const { user } = useAuthContext();
  const { data, total, status, error } = useSelector(
    (state) => state.gamesData
  );
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: ["name", "poster", "release_date", "tracked"],
  });
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          explore: {
            games: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          })
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.response?.data?.message || "Failed to save preference ",
          })
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.explore?.games?.preferences) {
          setPreferences(d.explore?.games?.preferences);
        }
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  const formatFilter = (token, operator) => {
    if (!token.propertyKey) {
      return {
        match_phrase: {
          ip: `*${token.value}*`,
        },
      };
    }

    switch (token.propertyKey) {
      case "name":
        return operator === "contain"
          ? {
              query_string: {
                default_field: "ip",
                query: `*${token.value}*`,
              },
            }
          : {
              match: {
                ip: {
                  query: token.value,
                  auto_generate_synonyms_phrase_query: false,
                },
              },
            };

      case "tracked":
        return {
          query_string: {
            default_field: "tracked",
            query: token.value,
          },
        };
    }
  };

  useEffect(() => {
    const from = (currentPage - 1) * preferences.pageSize;

    const sort = sortingColumn
      ? [
          {
            [`${idMap.get(sortingColumn.id)}`]: sortingDescending
              ? "desc"
              : "asc",
          },
        ]
      : [];
    const include = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "=") {
          return formatFilter(t);
        }
        if (t.operator === ":") {
          return formatFilter(t, "contain");
        }
        return null;
      })
      .filter((e) => e);
    const exclude = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "!=") {
          return formatFilter(t);
        }
        return null;
      })
      .filter((e) => e);
    const body = {
      sort,
      include,
      exclude,
      include_condition: filteringQuery.operation,
    };
    const range = () => {
      if (
        !filteringQuery.tokens.map((e) => e.propertyKey)
        // .includes("release_date")
      ) {
        return null;
      }

      // return filteringQuery.tokens
      //   .map((t) => {
      //     if (t.propertyKey === "release_date") {
      //       switch (t.operator) {
      //         case ">=":
      //           return `&gte=${t?.value}`;

      //         case "<=":
      //           return `&lte=${t?.value}`;

      //         case ">":
      //           return `&gt=${t?.value}`;

      //         case "<":
      //           return `&lt=${t?.value}`;

      //         default:
      //           return null;
      //       }
      //     }

      //     return null;
      //   })
      //   .filter((e) => e);
    };

    getFilteredGamesData(from, preferences.pageSize, range(), body);
  }, [
    currentPage,
    preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
  ]);

  useEffect(() => {
    getPref();
    return ()=>cleanAllFlashMessage()
  }, []);

  useEffect(() => {
    if (status === "failed" && error) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        })
      );
    }
  }, [status, error]);

  return (
    <>
      <Helmet>
        <title>Explore Games</title>
      </Helmet>

      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
            <Table
              visibleColumns={preferences.visibleContent}
              columnDefinitions={TableColumns}
              sortingColumn={sortingColumn}
              sortingDescending={sortingDescending}
              onSortingChange={(event) => {
                setSortingDescending(event.detail.isDescending);
                setSortingColumn(event.detail.sortingColumn);
              }}
              loading={status === "loading"}
              items={data}
              loadingText="Loading resources"
              wrapLines
              stickyHeader
              resizableColumns={true}
              variant="full-page"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No items</b>
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    No items to display.
                  </Box>
                </Box>
              }
              header={
                <>
                 <CustomFlashBar />
                  <Header
                    variant="h3"
                    counter={<span>({total.toLocaleString()})</span>}>
                    Games
                  </Header>
                </>
              }
              preferences={
                <Preferences
                  preferences={preferences}
                  handleChange={handlePreferencesChange}
                />
              }
              pagination={
                <Pagination
                  currentPageIndex={currentPage}
                  pagesCount={Math.ceil(
                    (total > 10000 ? 10000 : total) / preferences.pageSize
                  )}
                  onChange={(page) => {
                    setCurrentPage(page.detail.currentPageIndex);
                  }}
                />
              }
              filter={
                <Filters
                  data={data}
                  preferences={preferences}
                  query={filteringQuery}
                  handleChange={(detail) => {
                    setFilteringQuery(detail);
                    setCurrentPage(1);
                  }}
                />
              }
            />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}
