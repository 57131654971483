import { dispatch } from "../../../../app/store";
import axiosInstance from "../../../../utils/axios";
import { ipSearchListActions } from "../slices/ip-search-list-slice";
import { wikiRawDataActions } from "../slices/raw-data-list-slice";
import { getGeneralTrendsData } from "./general-wikipedia-trends-actions";
import { getWikiLeaderBoardData } from "./wikipedia-leader-board-actions";

export async function getIpSearchList() {
  dispatch(ipSearchListActions.loading());
  try {
    const response = await axiosInstance.request({
      url: `/ipmanagement/searchbar`,
      method: `GET`,
    });
    dispatch(ipSearchListActions.insertData(response.data));
  } catch (e) {
    dispatch(ipSearchListActions.insertError(e.message));
  }
}

export async function getWikiRawData({ interval, lte, gte }) {
  dispatch(wikiRawDataActions.loading());
  try {
    const response = await axiosInstance.request({
      url: `wikipedia/rawdata`,
      method: `GET`,
      params: { interval, lte, gte },
    });

    dispatch(wikiRawDataActions.insertData(response.data));
  } catch (e) {
    dispatch(wikiRawDataActions.insertError(e.message));
  }
}

export { getGeneralTrendsData, getWikiLeaderBoardData };
