import _ from "lodash";
const todaysDate = new Date().toISOString().split("T")[0];

const createConditions = (filter) => {
  if (!filter) return { includes: [], excludes: [] };
  const includes = [
  //   {
  //   range: {
  //     release_date: {
  //       lte: todaysDate,
  //     },
  //   },
  // }
];
  const excludes = [];

  const processCondition = (condition) => {
    switch (condition.type) {
      case "contains": {
        includes.push({
          match: {
            [condition?.colId]: {
              query: `${condition?.filter}`,
              auto_generate_synonyms_phrase_query:
                condition?.colId === "ip" ? true : false,
            },
          },
        });
        break;
      }
      // write a case for 'true' or 'false' here
      case "true":
        includes.push({
          match: {
            [condition.colId]: true,
          },
        });
        break;
      case "false":
        includes.push({
          match: {
            [condition.colId]: false,
          },
        });
        break;
      case "equals":
        includes.push({
          match_phrase: {
            [condition.colId]: condition.filter,
          },
        });
        break;
      case "notEqual":
        excludes.push({
          match: {
            [condition.colId]: condition.filter,
          },
        });
        break;
      case "blank":
        excludes.push({
          exists: {
            field: condition.colId,
          },
        });
        break;
      case "notBlank":
        includes.push({
          exists: {
            field: condition.colId,
          },
        });
        break;
      case "lessThan":
        includes.push({
          range: {
            [condition.colId]: {
              lt: condition.filter,
            },
          },
        });
        break;
      case "greaterThan":
        includes.push({
          range: {
            [condition.colId]: {
              gt: condition.filter,
            },
          },
        });
        break;
      case "lessThanOrEqual":
        includes.push({
          range: {
            [condition.colId]: {
              lte: condition.filter,
            },
          },
        });
        break;
      case "greaterThanOrEqual":
        includes.push({
          range: {
            [condition.colId]: {
              gte: condition.filter,
            },
          },
        });
        break;
      case "between":
        includes.push({
          range: {
            [condition.colId]: {
              gte: condition.filter[0],
              lte: condition.filter[1],
            },
          },
        });
        break;
      case "notBetween":
        excludes.push({
          range: {
            [condition.colId]: {
              gte: condition.filter[0],
              lte: condition.filter[1],
            },
          },
        });
        break;
    }
  };

  if (filter?.filterType === "join" && Array.isArray(filter.conditions)) {
    filter.conditions.forEach(processCondition);
  } else {
    processCondition(filter);
  }

  return { includes, excludes };
};

function isJSON(str) {
  return _.attempt(JSON.parse.bind(null, str)) instanceof Error ? false : true;
}

export { createConditions, isJSON };
