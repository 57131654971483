import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import WeightIcon from "../../../assets/images/icons/weight.svg";
import { navItems } from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import { RawData } from "./RawData";
import {
  PlatformWeightageDrawer,
  defaultWeightageValues,
} from "./components/PlatformWeightageDrawer";

function GRRawDataIndexPage() {
  const section = "Global Ranking";
  const title = "Raw Data";
  const breadcrumbs = [
    { text: "Dashboard" },
    { text: section, href: "/dashboards/globalranking" },
    { text: title },
  ];

  const [weightageValues, setWeightageValues] = useState(
    defaultWeightageValues,
  );

  const weightageDrawer = {
    id: "weightageDrawer",
    content: (
      <PlatformWeightageDrawer
        weightageValues={weightageValues}
        setWeightageValues={setWeightageValues}
      />
    ),
    trigger: {
      iconName: "Weightage",
      iconSvg: <img src={WeightIcon} alt="Weight" />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
    onResize: (size) => {},
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        drawers={[weightageDrawer]}
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  description="Global Ranking is done on a weekly basis and is based on various metrics like user activity, searches, and votes."
                  variant="h2"
                >
                  {title}
                </Header>
                <CustomFlashBar />
              </>
            }
          >
            <RawData
              weightageValues={weightageValues}
              setWeightageValues={setWeightageValues}
            />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default GRRawDataIndexPage;
