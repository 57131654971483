
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchGoogleRealtimeSavedResponse = createAsyncThunk(
    "googleRealtimeSavedRespose/fetchGoogleRealtimeSavedResponse",
    async () => {
        const response = await axiosInstance.request({
            url: `/preferences/googlerealtime/search`,
            method: `GET`,
        });
        return response;
    }
);

const googleRealtimeSavedResposeSlice = createSlice({
    name: "googleRealtimeSavedRespose",
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGoogleRealtimeSavedResponse.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGoogleRealtimeSavedResponse.fulfilled, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.data = action.payload.data;
            })
            .addCase(fetchGoogleRealtimeSavedResponse.rejected, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.hasError = true;
                state.message = action.error.message;
            });
    },

});

const GoogleRealTimeSearchSavedResponseReducer = googleRealtimeSavedResposeSlice.reducer;

export default GoogleRealTimeSearchSavedResponseReducer;

export const googleRealtimeSavedResposeAction = googleRealtimeSavedResposeSlice.actions;

