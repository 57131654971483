import React, { useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Header,
  SideNavigation,
  Box,
  Table,
  Flashbar,
  Pagination,
} from "@cloudscape-design/components";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { useTarPaginationCallback } from "./useTarPaginationCallback";
import { TableColumns } from "./TableColumns";
import { PreferencesComponent } from "./PreferencesComponent";
import Filtering from "./FilteringComponent";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import createFlashMessage from "../../../utils/createFlashMessage";
import CustomFlashBar from "../../common/CustomFlashBar";
const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };
const TitleAvailibility = () => {
  const { user } = useAuthContext();
  const breadcrumbs = [{ text: "Media Catalog" },{ text: "Title Availability" }, { text: "Movies" }];
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [sortingColumn, setSortingColumn] = useState(TableColumns[1]);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: [
      "title",
      "genre",
      "poster_path",
      "release_date",
      "providers",
      "theatrical",
    ],
  });

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage();
  }, []);

  const { pageSize } = preferences;
  const params = {
    pagination: {
      currentPageIndex,
      pageSize,
    },
    filtering: {
      filteringTokens: filteringQuery.tokens,
      filteringOperation: filteringQuery.operation,
    },
    sorting: {
      sortingColumn,
      sortingDescending: sortingDescending,
    },
  };
  const {
    items,
    loading,
    pagesCount,
    apiCount,
    flashbarItems,
    currentPageIndex: serverPageIndex,
  } = useTarPaginationCallback(params);

  const handleSortingChange = (event) => {
    setSortingDescending(event.detail.isDescending);
    setSortingColumn(event.detail.sortingColumn);
  };

  const handlePropertyFilteringChange = ({ detail }) =>
    setFilteringQuery(detail);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          tar: {
            movie: {
              titleavailibility: {
                preferences: pref,
              },
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          })
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message:
              e.response?.data?.message || "Failed to save data to the API.",
          })
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.tar?.movie?.titleavailibility?.preferences) {
          setPreferences(d.tar?.movie?.titleavailibility?.preferences);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>

      <AppLayout
        stickyNotifications
        toolsHide
        headerSelector="#header"
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        content={
            <Table
              onSortingChange={handleSortingChange}
              sortingColumn={sortingColumn}
              sortingDescending={sortingDescending}
              visibleColumns={preferences.visibleContent}
              loading={loading}
              columnDefinitions={TableColumns}
              filter={
                <Filtering
                  filteringQuery={filteringQuery}
                  handlePropertyFilteringChange={handlePropertyFilteringChange}
                />
              }
              items={items}
              loadingText="Loading resources"
              wrapLines
              stickyHeader
              variant="full-page"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No items</b>
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    No items to display.
                  </Box>
                </Box>
              }
              header={
                <>
                  <CustomFlashBar />
                  <Header
                    variant="h3"
                    description="This page displays the availability of Movie titles across all providers."
                    counter={`(${apiCount})`}
                  >
                    Movies 
                  </Header>
                </>
              }
              pagination={
                <Pagination
                  currentPageIndex={serverPageIndex}
                  pagesCount={Math.min(pagesCount, 200)}
                  disabled={loading}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) =>
                      `Page ${pageNumber} of all pages`,
                  }}
                  onChange={(event) =>
                    setCurrentPageIndex(event.detail.currentPageIndex)
                  }
                />
              }
              preferences={
                <PreferencesComponent
                  initialPreferences={preferences}
                  onPreferencesChange={handlePreferencesChange}
                />
              }
            />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TitleAvailibility;
