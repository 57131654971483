import { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import _ from "lodash";

const conditionMaker = (condition, isExclude = false) => {
  switch (condition.operator) {
    case "=":
    case "!=":
      if (condition.propertyKey === "name") {
        return {
          match: { [`${condition.propertyKey}.keyword`]: condition.value },
        };
      }

      if (condition.propertyKey === "exclusive") {
        if (condition.value === "Yes") {
          return { script: { script: "doc['providers'].length > 1" } };
        }
      }
      return { match: { [condition.propertyKey]: condition.value } };
    case ":":
      return {
        query_string: {
          default_field: condition.propertyKey,
          query: `*${condition.value}*`,
        },
      };

    case "<":
      return { range: { [condition.propertyKey]: { lt: condition.value } } };

    case ">":
      return { range: { [condition.propertyKey]: { gt: condition.value } } };
    case ">=":
      return { range: { [condition.propertyKey]: { gte: condition.value } } };
    case "<=":
      return { range: { [condition.propertyKey]: { lte: condition.value } } };
  }
};

export function useTarPaginationCallback(params = {}) {
  const [apiCount, setApiCount] = useState(0);
  const { pageSize, currentPageIndex: clientPageIndex } =
    params.pagination || {};
  const { sortingDescending, sortingColumn } = params.sorting || {};
  const { filteringText, filteringTokens, filteringOperation } =
    params.filtering || {};
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(clientPageIndex);
  const [pagesCount, setPagesCount] = useState(0);
  const [flashbarItems, setFlashbarItems] = useState([]);

  useEffect(() => {
    setCurrentPageIndex(clientPageIndex);
  }, [clientPageIndex]);

  useEffect(() => {
    setLoading(true);
    const params = {
      filteringText,
      filteringTokens,
      filteringOperation,
      pageSize,
      currentPageIndex,
      sortingDescending,
      ...(sortingColumn
        ? {
            sortingColumn: sortingColumn.sortingField,
          }
        : {}),
    };

    let urlParams = null;
    let sort = [];

    if (params?.sortingColumn) {
       sort = params.sortingColumn
        ? [
            {
              [`${params?.sortingColumn}`]: sortingDescending ? "desc" : "asc",
            },
          ]
        : [];
    }
    if (filteringTokens && filteringTokens.length > 0) {
      let includeCondition = [];
      let excludeCondition = [];

      filteringTokens.forEach((condition) => {
        if (condition.propertyKey === "theatrical") {
          if (condition.operator === "=" && condition.value === "Yes") {
            includeCondition.push(conditionMaker(condition));
          } else if (condition.operator === "=" && condition.value === "No") {
            excludeCondition.push(conditionMaker(condition, true));
          }
        } else if (condition.propertyKey === "exclusive") {
          // Check if a watch provider filter is selected
          const hasWatchProviderFilter = filteringTokens.some(
            (token) => token.propertyKey === "providers"
          );

          if (hasWatchProviderFilter) {
            if (condition.operator === "=" && condition.value === "Yes") {
              excludeCondition.push(conditionMaker(condition));
            } // If value is "No", we don't add any condition
          } else if (!hasWatchProviderFilter) {
            setFlashbarItems([
              {
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashbarItems([]),
                content:
                  "A watch provider filter must be selected when using the 'exclusive' filter.",
                id: "error_1",
              },
            ]);
          }
        } else {
          if (condition.operator === "=" || condition.operator === ":") {
            includeCondition.push(conditionMaker(condition));
          } else if (condition.operator === "!=") {
            excludeCondition.push(conditionMaker(condition, true));
          }
        }
        if (condition.operator === "<" || condition.operator === ">") {
          includeCondition.push(conditionMaker(condition));
        }
          if (condition.operator === "<=" || condition.operator === ">=") {
          includeCondition.push(conditionMaker(condition));
        }
      });

      urlParams = {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
        url: `/padb/list/v2?size=${params.pageSize}&after=${
          params.pageSize * (params.currentPageIndex - 1)
        }&vertical=television`,
        data: JSON.stringify({
          include: includeCondition,
          exclude: excludeCondition,
          condition: params.filteringOperation || "and",
          sort: sort,
        }),
      };
    } else {
   
      urlParams = {
        method: "POST",
        url: `/padb/list/v2?size=${params.pageSize}&after=${
          params.pageSize * (params.currentPageIndex - 1)
        }&vertical=television`,
      };
      if(sort.length >0){
        urlParams['data']={sort};
      }
    }
    axiosInstance
      .request(urlParams)
      .then((result) => {
        setApiCount(result.data.count);

        setPagesCount(Math.ceil(result.data.count / params.pageSize));
        /*  setItems(
          sortingDescending
            ? _.sortBy(result.data.data, params.sortingColumn)
                .reverse()
                .map((item, index) => ({
                  ...item,
                  id: item.imdb_id,
                  title: item.name,
                }))
            : _.sortBy(result.data.data, params.sortingColumn).map(
                (item, index) => ({
                  ...item,
                  id: item.imdb_id,
                  title: item.name,
                })
              )
        ); */
        setItems(result?.data?.data);
        setLoading(false);

        setCurrentPageIndex(currentPageIndex);
        if (totalCount === 0) {
          setTotalCount(result.data.count);
        }
      })
      .catch((e) => setLoading(false));
  }, [
    pageSize,
    sortingDescending,
    sortingColumn,
    currentPageIndex,
    filteringText,
    filteringTokens,
    filteringOperation,
  ]);

  return {
    items,
    loading,
    totalCount,
    pagesCount,
    currentPageIndex,
    apiCount,
    flashbarItems,
    sorting: params.sorting,
  };
}
