import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import {
  AppLayout,
  BreadcrumbGroup,
  SideNavigation,
  Header,
  Table,
  Link,
  Box,
  SpaceBetween,
  StatusIndicator,
  Pagination,
  PropertyFilter,
  Popover,
  ColumnLayout,
} from "@cloudscape-design/components";
import { navItems } from "../../layouts/common/menu/side-menu";
import axiosInstance from "../../utils/axios";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import moment from "moment";
import { CommonPreference } from "../../components/CommonPreference";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useAuthContext } from "../../auth/useAuthContext";
import { useSelector } from "react-redux";
import { getAuditPreferences } from "../common/redux/global-pref-selector";
import { saveGlobalPersonalPref } from "../common/redux/global-pref-action";

const breadcrumbs = [{ text: "Settings" }, { text: "Audit Events" }];

export const roleVisibleContentPreference = [
  {
    id: "timestamp",
    label: "Timestamp",
    visible: true,
  },
  {
    id: "event_type",
    label: "Event",
    visible: true,
  },
  {
    id: "name",
    label: "Title",
    visible: true,
  },
  {
    id: "id",
    label: "ID",
    visible: true,
  },
  { id: "username", label: "Username", visible: true },
  { id: "user_role", label: "User role", visible: true },
];

function AuditHistory() {
  const { user } = useAuthContext();
  const [preferences, setPreferences] = React.useState({
    pageSize: 25,
    wrapLines: false,
    visibleContent: ["name", "username"],
  });
  const defaultDateRange = {
    type: "relative",
    unit: "day",
    amount: "7",
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().toDate(),
  };

  const [auditLogs, setAuditLogs] = useState([]);
  const [auditLogsLoading, setAuditLogsLoading] = useState(false);

  const auditPrefs = useSelector(getAuditPreferences);


  useEffect(() => {
    if (auditPrefs) {
      setPreferences(auditPrefs);
    }
  }, [auditPrefs]);

  const [filterQuery, setFilterQuery] = useState({
    tokens: [],
    operation: "and",
  });
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const getAuditLogs = (params) => {
    setAuditLogsLoading(true);

    axiosInstance
      .request({
        method: "GET",
        url: "/audit",
        params: params,
      })
      .then((response) => {
        setAuditLogs(response.data);
        setAuditLogsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setAuditLogsLoading(false);
      });
  };

  const handleSetPropertyFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  const filtersToQueryParams = useCallback(() => {
    const gte = Math.round(dateRange.startDate?.getTime() / 1000);
    const lte = Math.round(dateRange.endDate?.getTime() / 1000);

    if (!gte || !lte) return null;

    const params = {
      gte: gte,
      lte: lte,
      size: 10000,
    };

    return params;
  }, [dateRange]);

  useEffect(() => {
    const params = filtersToQueryParams();
    if (params) getAuditLogs(params);
  }, [filtersToQueryParams]);

  const { items, paginationProps, collectionProps, propertyFilterProps } =
    useCollection(auditLogs, {
      pagination: { pageSize: preferences.pageSize },
      propertyFiltering: {
        filteringProperties: [
          {
            key: "event_type",
            operators: ["=", "!="],
            propertyLabel: "Event type",
            groupValuesLabel: "Event type values",
          },
          {
            key: "username",
            operators: ["=", "!="],
            propertyLabel: "Username",
            groupValuesLabel: "Username values",
          },
          {
            key: "user_role",
            operators: ["=", "!="],
            propertyLabel: "User role",
            groupValuesLabel: "User role values",
          },
          {
            key: "ip",
            operators: ["=", "!="],
            propertyLabel: "Item name",
            groupValuesLabel: "Item name values",
          },
          {
            key: "ip_id",
            operators: ["=", "!="],
            propertyLabel: "Item ID",
            groupValuesLabel: "Item ID values",
          },
          {
            key: "vertical",
            operators: ["=", "!="],
            propertyLabel: "Vertical",
            groupValuesLabel: "Vertical",
          },
        ],
      },
    });

  return (
    <>
      <Helmet>
        <title>Audit Events</title>
      </Helmet>
      <AppLayout
        toolsHide
        contentType="form"
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <Table
            {...collectionProps}
            columnDefinitions={[
              {
                id: "timestamp",
                header: "Timestamp",
                cell: (item) =>
                  new Date(item.timestamp * 1000).toLocaleString("en-US", {}),
                minWidth: 200,
                maxWidth: 250,
              },
              {
                id: "event_type",
                header: "Event",
                cell: (item) => item.event_type,
              },
              {
                id: "name",
                header: "Title",
                cell: (item) => (
                  <Link href={`/item/${item.ip_id}`} external>
                    {item.ip}
                  </Link>
                ),
              },
              {
                id: "id",
                header: "ID",
                cell: (item) => <div>{item.ip_id}</div>,
              },
              {
                id: "username",
                header: "Username",
                cell: (item) => item.username,
              },
              {
                id: "user_role",
                header: "User role",
                cell: (item) => item.user_role,
              },
              {
                id: "changes",
                header: "Changes",
                minWidth: 100,
                maxWidth: 100,
                cell: (item) => {
                  const { event_details } = item;
                  const currentState = event_details?.current_state
                    ? JSON.stringify(event_details.current_state, null, 2)
                    : "N/A";
                  const previousState = event_details?.previous_state
                    ? JSON.stringify(event_details.previous_state, null, 2)
                    : "N/A";

                  return (
                    <Popover
                      fixedWidth
                      size="large"
                      content={
                        <ColumnLayout columns={2} variant="text-grid">
                          <SpaceBetween size="l">
                            <strong>Current State:</strong>
                            <pre
                              style={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                padding: "1rem",
                                backgroundColor: "black",
                                borderRadius: "0.25rem",
                                overflowX: "auto",
                              }}
                            >
                              {currentState}
                            </pre>
                          </SpaceBetween>
                          <SpaceBetween size="l">
                            <strong>Previous State:</strong>
                            <pre
                              style={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                padding: "1rem",
                                backgroundColor: "black",
                                borderRadius: "0.25rem",
                                overflowX: "auto",
                              }}
                            >
                              {previousState}
                            </pre>
                          </SpaceBetween>
                        </ColumnLayout>
                      }
                    >
                      <StatusIndicator type="info">Info</StatusIndicator>
                    </Popover>
                  );
                },
              },
            ]}
            stickyColumns={preferences.stickyColumns}
            columnDisplay={preferences.contentDisplay}
            items={items}
            loadingText="Loading resources"
            loading={auditLogsLoading}
            contentDensity={preferences.contentDensity}
            sortingDescending
            stickyHeader
            variant="full-page"
            header={<Header>Audit Events</Header>}
            empty={
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <b>No resources</b>
              </Box>
            }
            filter={
              <div className="flex space-x-2">
                <div className="grow">
                  <PropertyFilter
                    {...propertyFilterProps}
                    i18nStrings={{
                      applyActionText: "Apply",
                      clearFiltersText: "Clear filters",
                      groupPropertiesText: "Properties",
                      filteringAriaLabel: "Filter logs",
                      filteringPlaceholder: "Filter logs",
                      operationAndText: "and",
                      operationOrText: "or",
                      operatorContainsText: "contains",
                      operatorDoesNotContainText: "does not contain",
                      operatorDoesNotEqualText: "does not equal",
                      operatorEqualsText: "equals",
                      operatorGreaterOrEqualText: "greater than or equal to",
                      operatorGreaterText: "greater than",
                      operatorLessOrEqualText: "less than or equal to",
                      operatorLessText: "less than",
                      operatorStartsWithText: "starts with",
                    }}
                  />
                </div>

                <RangeDateSelector
                  onChange={(e) => setDateRange(e)}
                  defaults={dateRange}
                />
              </div>
            }
            wrapLines={preferences.wrapLines}
            stripedRows={preferences.stripedRows}
            pagination={
              <Pagination
                {...paginationProps}
                ariaLabels={{
                  nextPageLabel: "Next page",
                  previousPageLabel: "Previous page",
                  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                }}
              />
            }
            preferences={
              <CommonPreference
                preferences={preferences}
                onConfirm={({ detail }) =>
                  saveGlobalPersonalPref({
                    username: user?.username,
                    data: {
                      audit: {
                        preferences: detail,
                      },
                    },
                  })
                }
                contentDisplayOptions={roleVisibleContentPreference}
              />
            }
          />
        }
      />
    </>
  );
}

export default AuditHistory;
