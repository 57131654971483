import axiosInstance from "../../../../utils/axios";

export const getChannelTrends = (channelId: string) =>
  axiosInstance.get(`/youtube/v1/channels/${channelId}/trends`);

export type GetChannelVideosProps = {
  channelId: string;
  from: number;
  sort: Record<string, string>;
};

export const getChannelVideos = ({
  channelId,
  from,
  sort = { publishedAt: "desc" },
}: GetChannelVideosProps) =>
  axiosInstance.request({
    url: `/youtube/v1/channels/${channelId}/videos?size=6&from=${from}`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: {
      sort: [sort],
      include: [],
      exclude: [],
      condition: "and",
    },
  });


export type GetTopVideosProps = {
  channelId: string;
  from: number;
  sort: Record<string, string>;
};

export const getTopVideos = ({
  channelId,
  sort = { views: "desc" },
}: GetChannelVideosProps) =>
  axiosInstance.request({
    url: `/youtube/v1/channels/${channelId}/videos?size=10&from=0`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: {
      sort: [sort],
      include: [],
      exclude: [],
      condition: "and",
    },
  });

export type GetRecentVideosProps = {
  channelId: string;
  from: number;
  sort: Record<string, string>;
};

export const getRecentVideos = ({
  channelId,
  sort = { publishedAt: "desc" },
}: GetChannelVideosProps) =>
  axiosInstance.request({
    url: `/youtube/v1/channels/${channelId}/videos?size=10&from=0`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: {
      sort: [sort],
      include: [],
      exclude: [],
      condition: "and",
    },
  });