import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import FormProvider from "../../../../components/forms/FormProvider";
import { isNone } from "../../../../utils/sugarUtils";
import { useCreateMovie } from "../api/hooks/useCreateMovie";
import { checkIdType } from "../utils/checkIdType";
import { MovieCard } from "./MovieCard";

type AddMovieModalprops = { visible: boolean; onDismiss: () => void };

export const AddMovieModal: FC<AddMovieModalprops> = ({
  visible,
  onDismiss,
}) => {
  const [showFlashbar, setShowFlashbar] = useState(false);
  const [showPoster, setShowPoster] = useState(false);
  const [items, setItems] = useState([]);

  const resolver: Resolver<{ id: string }> = async (values) => {
    return {
      errors: {
        ...(!values.id
          ? { id: { type: "required", message: "Id is required" } }
          : isNone(checkIdType(values.id))
          ? { id: { type: "required", message: "Enter a valid Id format" } }
          : {}),
      },
      values,
    };
  };

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver,
  });

  const { control, formState, watch, reset, handleSubmit } = methods;

  const onSubmit = () => {
    try {
      createMovie(watch().id);
    } catch (e) {}
  };

  const closeModal = () => {
    setShowPoster(false);
    setShowFlashbar(false);
    setItems([]);
    reset();
    onDismiss();
  };

  const getItems = (message: string = "Movie creation failed") => [
    {
      type: "error",
      content: message,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1",
    },
  ];

  const getCallback = (message: string) => {
    const items = getItems(message);
    setItems(items);
    setShowFlashbar(true);

    setTimeout(() => {
      setShowFlashbar(false);
    }, 5000);
  };

  const onSuccess = () => setShowPoster(true);
  const onError = (message: string) => getCallback(message);

  const {
    data: movieData,
    mutate: createMovie,
    isLoading,
  } = useCreateMovie({
    onSuccess,
    onError,
  });

  return (
    <Modal onDismiss={closeModal} visible={visible} header="Add Movie">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          {showPoster ? (
            <>
              <MovieCard data={movieData} />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </SpaceBetween>
              </Box>
            </>
          ) : (
            <>
              {showFlashbar && <Flashbar items={items} />}
              <Controller
                name={"id"}
                control={control}
                render={({ field }) => {
                  return (
                    <FormField
                      stretch={true}
                      errorText={formState.errors?.id?.message ?? ""}
                    >
                      <Input
                        {...field}
                        value={field.value}
                        placeholder="Add Imdb/Tmdb Id"
                        onChange={(e) => field.onChange(e.detail.value)}
                      />
                    </FormField>
                  );
                }}
              />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" formAction="none" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    formAction="submit"
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </SpaceBetween>
              </Box>
            </>
          )}
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
