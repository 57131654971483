const ErrorLayout = () => {
    return (
      <div>
        <h1>Oops! Something went wrong.</h1>
        <p>We're working on fixing this. Please try again later.</p>
        <button onClick={() => {}}>Retry</button>
        <a href="/contact-support">Contact Support</a>
        {/* Show/Hide Details button to toggle error details */}
      </div>
    );
  };
  
  export { ErrorLayout }