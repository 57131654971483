import React, { useCallback, useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-material.css";
import ReadMore from "../../components/ReadMore";
import {
  SpaceBetween,
  Box,
  Container,
  PieChart,
  ButtonDropdown,
  Header,
  Button,
} from "@cloudscape-design/components";
import _ from "lodash";
import { FranchiseEditModal } from "./modals/EditModal";
import { FranchiseDeleteConfirmation } from "./modals/DeleteConfirmation";
import { MergeModal } from "./modals/MergeModal";
import { AddModel } from "./modals/AddModal";
import { MediaSection } from "./components/MediaSelection";
import { DeleteModal } from "./modals/DeleteModal";

export default function ItemFranchise({
  itemData,
  onDelete,
  onAddItems,
  onMergeItems,
  onFranchiseEditSubmit,
  onFranchiseDeleteSubmit,
  onFranchiseUpdateCoverImage,
  ...props
}) {
  const [isTracked, setIsTracked] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showFcDeleteModal, setShowFcDeleteModal] = useState(false);

  const onAddInit = () => setShowCreateModal(true);
  const onAddDiscard = () => setShowCreateModal(false);
  const onMergeInit = () => setShowMergeModal(true);
  const onMergeDiscard = () => setShowMergeModal(false);
  const onEditInit = () => setShowEditModal(true);
  const onEditDiscard = () => setShowEditModal(false);
  const onDeleteFcInit = () => setShowFcDeleteModal(true);
  const onDeleteFcDiscard = () => setShowFcDeleteModal(false);

  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onDeleteConfirm = () => {
    onDelete({
      id: itemData?.data?.ip_id,
      name: itemData?.data?.name,
      removed: selectedCards,
      added: [],
    });
    setSelectedCards([]);
    onDeleteDiscard();
  };
  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const videoGames = itemData?.data?.["Video Game"] || [];
  const movies = itemData?.data?.["Film"] || [];
  const series = itemData?.data?.["Television Series"] || [];
  const noImage = require("../../assets/images/icons/no-image_342.jpg");

  const addOrRemoveCards = (checked, id) => {
    if (!_.includes(selectedCards, id)) {
      setSelectedCards([...selectedCards, id]);
    } else if (_.includes(selectedCards, id)) {
      setSelectedCards(_.filter(selectedCards, (item) => item !== id));
    }
  };

  const sortByReleaseDate = (a, b) => {
    const dateA = new Date(a.release_date);
    const dateB = new Date(b.release_date);
    return dateA - dateB;
  };

  const sortedVideoGames = [...videoGames].sort(sortByReleaseDate);
  const sortedMovies = [...movies].sort(sortByReleaseDate);
  const sortedSeries = [...series].sort(sortByReleaseDate);
  const total_count =
    itemData.data.game_count +
    itemData.data.series_count +
    itemData.data.film_count;
  const data = [
    {
      title: "Movies",
      value: itemData.data.film_count,
      percentage: (itemData.data.film_count / total_count) * 100,
    },
    {
      title: "Series",
      value: itemData.data.series_count,
      percentage: (itemData.data.series_count / total_count) * 100,
    },
    {
      title: "Games",
      value: itemData.data.game_count,
      percentage: (itemData.data.game_count / total_count) * 100,
    },
  ];

  return (
    <>
      {!itemData.loading && itemData.data && itemData.data.type ? (
        <Container
          variant="borderless"
          header={
            <Header
              actions={
                <ButtonDropdown
                  expandToViewport
                  onItemClick={({ detail }) => {
                    if (detail.id === "de" && selectedCards.length > 0) {
                      onDeleteInit();
                    }
                    if (detail.id === "ad") {
                      onAddInit();
                    }
                    if (detail.id === "mg") {
                      onMergeInit();
                    }
                    if (detail.id === "ed") {
                      onEditInit();
                    }
                    if (detail.id === "df") {
                      onDeleteFcInit();
                    }
                    if (detail.id === "uc") {
                      onFranchiseUpdateCoverImage(itemData?.data?.ip_id);
                    }
                  }}
                  items={[
                    { text: "Associate Titles", id: "ad" },
                    { text: "Disassociate Title", id: "de" },
                    { text: "Edit Franchise", id: "ed" },
                    { text: "Merge Franchise", id: "mg" },
                    { text: "Delete Franchise", id: "df" },
                    {
                      text: "Update Cover Image",
                      id: "uc",
                      disabled: itemData?.data?.total_ips <= 0,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              }
            />
          }
        >
          <div className="space-y-6">
            <div className="flex space-x-6">
              <img
                src={`https://images.searchpantheon.com/collections/${itemData.data.ip_id}_684.jpg`}
                alt="..."
                className="w-48 xl:w-64 drop-shadow-lg h-min rounded-md"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noImage;
                }}
                loading="lazy"
              />

              <div className="flex-1 flex-col">
                <div className="text-3xl font-bold">{itemData.data.name}</div>
                <div className="text-slate-400">
                  {itemData.data.type.name} •
                  {new Date(itemData.data.first_date).getFullYear()} -
                  {new Date(itemData.data.last_date).getFullYear()} (
                  {new Date(itemData.data.last_date).getFullYear() -
                    new Date(itemData.data.first_date).getFullYear()}
                  years)
                </div>
                {itemData.data.summary && (
                  <>
                    <div className="text-lg xl:text-xl font-bold mt-6">
                      Overview
                    </div>
                    <ReadMore className="text-xs xl:text-sm">
                      {itemData.data.summary}
                    </ReadMore>
                  </>
                )}
              </div>
              <div style={{ width: "400px" }}>
                <PieChart
                  data={data}
                  segmentDescription={(datum, sum) =>
                    `${datum.value} , ${((datum.value / sum) * 100).toFixed(
                      0
                    )}%`
                  }
                  fitHeight
                  hideFilter
                  size="medium"
                  variant="pie"
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No data available</b>
                      <Box variant="p" color="inherit">
                        There is no data available
                      </Box>
                    </Box>
                  }
                  noMatch={
                    <Box textAlign="center" color="inherit">
                      <b>No matching data</b>
                      <Box variant="p" color="inherit">
                        There is no matching data to display
                      </Box>
                      <Button>Clear filter</Button>
                    </Box>
                  }
                />
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="w-full">
                <SpaceBetween direction="vertical" size="l">
                  {itemData?.data?.["Video Game"]?.length > 0 && (
                    <MediaSection
                      type="Games"
                      items={sortedVideoGames}
                      selectedCards={selectedCards}
                      addOrRemoveCards={addOrRemoveCards}
                    />
                  )}
                  {itemData?.data?.["Film"]?.length > 0 && (
                    <MediaSection
                      type="Movies"
                      items={sortedMovies}
                      selectedCards={selectedCards}
                      addOrRemoveCards={addOrRemoveCards}
                    />
                  )}
                  {itemData?.data?.["Television Series"]?.length > 0 && (
                    <MediaSection
                      type="Series"
                      items={sortedSeries}
                      selectedCards={selectedCards}
                      addOrRemoveCards={addOrRemoveCards}
                    />
                  )}
                </SpaceBetween>
              </div>
            </div>
          </div>
          <DeleteModal
            visible={showDeleteModal}
            onDiscard={onDeleteDiscard}
            onDelete={onDeleteConfirm}
            distributions={selectedCards}
          />
          <AddModel
            visible={showCreateModal}
            onDiscard={onAddDiscard}
            onSubmit={(items) => {
              onAddItems({
                id: itemData?.data?.ip_id,
                name: itemData?.data?.name,
                removed: [],
                added: items,
              });
              onAddDiscard();
            }}
          />
          <MergeModal
            visible={showMergeModal}
            onDiscard={onMergeDiscard}
            onSubmit={(items) => {
              if (items) {
                onMergeItems({
                  source: {
                    id: items?.name,
                    name: items?.id,
                  },
                  destination: {
                    id: itemData?.data?.ip_id,
                    name: itemData?.data?.name,
                  },
                });
              }
              onMergeDiscard();
            }}
            ip={itemData?.data?.name}
            ip_id={itemData?.data?.ip_id}
          />
          <FranchiseEditModal
            visible={showEditModal}
            onDiscard={onEditDiscard}
            onSubmit={(e) => {
              onFranchiseEditSubmit(e);
              onEditDiscard();
            }}
            title={itemData?.data?.name || ""}
            description={itemData?.data?.summary || ""}
          />
          <FranchiseDeleteConfirmation
            visible={showFcDeleteModal}
            onDiscard={onDeleteFcDiscard}
            onDelete={() => onFranchiseDeleteSubmit([itemData?.data?.ip_id])}
            instances={[itemData?.data?.ip_id]}
          />
        </Container>
      ) : null}
    </>
  );
}
