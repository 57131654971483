import { Container } from "@cloudscape-design/components";
import { AgChartsReact } from "ag-charts-react";
import React from "react";
import { useChartConfig } from "../hooks/useChartConfig";

export const ChartContainer = ({ data }) => {
  const { options } = useChartConfig(data);

  if (!options) {
    return <div>No data to display</div>;
  }

  return (
    <Container fitHeight>
      <AgChartsReact options={options} />
    </Container>
  );
};
