import {
  Container,
  Spinner,
  ExpandableSection,
  Header,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import React, { useState, useEffect } from "react";
import BoxOfficeTable from "./BoxOfficeTable";
import { COMMON_BOX_OFFICE_REGIONS } from "../../../config-global";
import { fetchItemBoxOfficeData } from "../redux/actions/item-actions";
import { useSelector } from "react-redux";

const countryColors = {
  "US & CA": "#34568B",
  Other: "#6B5B95",
  FR: "#88B04B",
  DE: "#F7CAC9",
  KR: "#92A8D1",
  GB: "#955251",
  AU: "#B565A7",
  MX: "#009B77",
  ES: "#DD4124",
  IT: "#D65076",
  BR: "#45B8AC",
  JP: "#EFC050",
};

const transformAreaCodes = (grossTimeseries) => {
  if (!grossTimeseries || typeof grossTimeseries !== 'object') {
    console.error('Invalid input for transformAreaCodes:', grossTimeseries);
    return {};
  }

  const items = {};
  Object.keys(grossTimeseries).forEach((countryCode) => {
    if (Array.isArray(grossTimeseries[countryCode])) {
      items[countryCode] = grossTimeseries[countryCode].map((item) => ({
        ...item,
        area: COMMON_BOX_OFFICE_REGIONS[countryCode] || countryCode,
      }));
    } else {
      console.error(`Expected an array for ${countryCode}, received:`, grossTimeseries[countryCode]);
      items[countryCode] = [];
    }
  });
  return items;
};


const usdShortOptions = {
  style: "currency",
  currency: "USD",
  notation: "compact",
};
const usdShortFormatter = new Intl.NumberFormat("en-US", usdShortOptions);

const BoxOffice = ({ titleId }) => {
  const [boxOfficeData, setBoxOfficeData] = useState(null);
  const [error, setError] = useState(null);
  const itemBoxOfficeData = useSelector(
    (state) => state?.itemBoxOfficeData?.data
  );
  const isLoading = useSelector((state) => state?.itemBoxOfficeData?.loading);

  useEffect(() => {
    setBoxOfficeData(itemBoxOfficeData);
  }, [itemBoxOfficeData]);

  useEffect(() => {
    if (itemBoxOfficeData === null) {
      fetchItemBoxOfficeData({ id: titleId });
    }
  }, []);

  if (isLoading)
    return (
      <div className="justify-center flex">
        <Spinner size="large" />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  if (!boxOfficeData || Object.keys(boxOfficeData).length === 0) {
    return (
      <div className="p-10 text-center text-base font-semibold text-gray-500">
        No data available
      </div>
    );
  }

  const rankCategories = [
    { area: "XDOM", label: "US Rank" },
    { area: "XNDOM", label: "International Rank" },
    { area: "XWW", label: "WorldWide Rank" },
  ];

  const prepareChartData = (data, areaKey, includeExtra = false) => {
    if (!data.hasOwnProperty(areaKey)) {
      return [];
    }
    const chartData = Object.keys(data[areaKey])
      .filter(
        (countryCode) =>
          !["XDOM", "XWW", "XNDOM", "IN", "TH"].includes(countryCode)
      )
      .map((countryCode) => {
        const valueKey = areaKey === "grossToDate" ? "grossToDate" : "gross";
        const countryData = data[areaKey][countryCode];
        if (!Array.isArray(countryData) || countryData.length === 0) {
          return null;
        }
        return {
          name: COMMON_BOX_OFFICE_REGIONS[countryCode] || countryCode,
          value: countryData[0][valueKey],
          color: countryColors[countryCode] || countryColors["Other"],
        };
      })
      .filter((item) => item !== null);

    if (includeExtra) {
      const extraValueKey = areaKey === "grossToDate" ? "grossToDate" : "gross";
      const xdomData = data[areaKey]["XDOM"];
      if (Array.isArray(xdomData) && xdomData.length > 0) {
        chartData.push({
          name: COMMON_BOX_OFFICE_REGIONS["XDOM"] || "US & CA",
          value: xdomData[0][extraValueKey],
          color: countryColors["US & CA"],
        });
      }
      chartData.push({
        name: "Other",
        value: calculateOtherValue(data, areaKey),
        color: countryColors["Other"],
      });
    }

    return chartData.sort((a, b) => b.value - a.value);
  };

  const calculateOtherValue = (data, areaKey) => {
    if (
      !data[areaKey] ||
      !data[areaKey]["XNDOM"] ||
      data[areaKey]["XNDOM"].length === 0
    ) {
      return 0;
    }
    const total = data[areaKey]["XNDOM"][0].grossToDate;
    const knownTotal = prepareChartData(data, areaKey).reduce(
      (acc, country) => acc + country.value,
      0
    );
    return total - knownTotal;
  };

  const grossCollectionPieChartData = prepareChartData(
    boxOfficeData,
    "grossToDate",
    true
  );
  const openingWeekendPieChartData = prepareChartData(
    boxOfficeData,
    "openingWeekend",
    true
  );

  const sharedSeriesOptions = {
    type: "pie",
    angleKey: "value",
    legendItemKey: "name",
    sectorLabel: {
      color: "white",
      formatter: (params) => usdShortFormatter.format(params.datum.value),
    },
    tooltip: {
      renderer: (params) => {
        return {
          title: params.datum.name,
          content: usdShortFormatter.format(params.datum.value),
        };
      },
    },
  };

  const grossCollectionPieChartOptions = {
    theme: {
      baseTheme: "ag-polychroma-dark",
      overrides: {
        common: {
          background: { fill: "#0F1B2A" },
        },
      },
    },
    legend: { position: "right" },
    series: [
      {
        ...sharedSeriesOptions,
        data: openingWeekendPieChartData,
        outerRadiusRatio: 0.5,
        fills: openingWeekendPieChartData.map((item) => item.color),
        showInLegend: false,
        title: {
          text: "Opening Weekend",
        },
      },
      {
        ...sharedSeriesOptions,
        data: grossCollectionPieChartData,
        fills: grossCollectionPieChartData.map((item) => item.color),
        calloutLabelKey: "name",
        innerRadiusRatio: 0.7,
        title: {
          text: "Gross to Date",
        },
      },
    ],
  };

  return (
    <SpaceBetween size="l">
      <div>
        <ExpandableSection
          defaultExpanded
          headingTagOverride="h4"
          variant="container"
          headerText="All time Box Office Ranking"
        >
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            {rankCategories.map(({ area, label }) => (
              <div
                key={area}
                className="mt-2 overflow-hidden rounded-2xl shadow-lg bg-darkerBg px-4 py-5 sm:p-6 flex items-center"
              >
                <div className="ml-4">
                  <div className="truncate text-sm font-bold text-gray-400">
                    {label}
                  </div>
                  <div className="rank-container">
                    <div className="mt-1 text-2xl font-semibold tracking-tight text-blue-500">
                      {(boxOfficeData.hasOwnProperty("grossToDate") &&
                        boxOfficeData?.grossToDate[area]?.[0]?.rank) ||
                        "N/A"}
                    </div>
                    <div className="mt-1 ml-2 text-xl  tracking-tight text-gray-500">
                      (
                      {(boxOfficeData.hasOwnProperty("grossToDate") &&
                        usdShortFormatter.format(
                          boxOfficeData?.grossToDate[area]?.[0]?.grossToDate
                        )) ||
                        "N/A"}
                      )
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ExpandableSection>
      </div>

      <ExpandableSection
        defaultExpanded
        headingTagOverride="h4"
        variant="container"
        headerText="Box Office Revenue Breakdown: Opening Weekend vs. Cumulative Gross
            to Date"
      >
        <div style={{ height: "600px" }}>
          <AgChartsReact options={grossCollectionPieChartOptions} />
        </div>
      </ExpandableSection>


      <BoxOfficeTable
        data={transformAreaCodes(boxOfficeData?.grossTimeseries)}
      />
    </SpaceBetween>
  );
};

export default BoxOffice;
