import React from "react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { YoutubeHome } from "./components/YoutubeHome";

export const YoutubeHomePage = () => {
  const breadcrumbs = [{ text: "Media Catalog" }, { text: "YouTube" }];

  return (
    <Layout
      title="Youtube"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={<YoutubeHome />}
      contentType="cards"
    />
  );
};
