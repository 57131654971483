import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
import Input from "@cloudscape-design/components/input";
import { FormField } from "@cloudscape-design/components";

// ----------------------------------------------------------------------

TextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function TextField({ name, helperText, ...other }) {
  const { control } = useFormContext();

  const normalStyle =
    "block w-full rounded-md text-gray-700 border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm";
  const errorStyle =
    "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <FormField
              label={other.label}
              description={other.description}
              errorText={error ? error?.message : helperText}
            >
              <Input
                {...field}
                value={field.value}
                {...other}
                onChange={({ detail }) => field.onChange(detail.value)}
                invalid={error?.message}
              />
            </FormField>
         
          </div>
        );
      }}
    />
  );
}
