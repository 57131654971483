import { Box, ColumnLayout, Container, Grid, Link, SpaceBetween } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { dateFormatter } from "../../../utils/dateFormatter";
import shortenInteger from "../../../utils/shortenInteger";
import { getSentimentCategory, getSentimentColor } from "../../youtube/channelPage/components/VideoList/utils";
import { fetchTrailersData } from "../redux/actions/item-actions";

export const defaultColDef = {
    sortable: true,
    suppressHeaderMenuButton: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
};

export const columnDefs = [
    {
        field: "title",
        headerName: "Title",
        cellRenderer: (params) => {
            return (
                <Link href={`/mediacatalog/youtube/videos/${params.data.videoId}`}>
                    {params.value}
                </Link>
            );
        },
        sortable: false,
        flex: 2,
    },
    {
        field: "channel",
        headerName: "Channel",
        flex: 1,
        cellRenderer: (params) => {
            return (
                <Link href={`/mediacatalog/youtube/channels/${params.data.channelId}`}>
                    {params.value}
                </Link>
            );
        }
    },
    {
        field: "publishedAt",
        headerName: "Published At",
        minWidth: 120,
        maxWidth: 120,
        cellRenderer: (params) => {
            const date = params.value * 1000;
            return dateFormatter(date);
        },
    },
    {
        field: "views",
        headerName: "Views",
        minWidth: 90,
        maxWidth: 90,
        cellRenderer: (params) => {
            return shortenInteger(params.value);
        },
        sort: "desc",
    },
    {
        field: "likes",
        headerName: "Likes",
        minWidth: 80,
        maxWidth: 80,
        cellRenderer: (params) => {
            return shortenInteger(params.value);
        },
    },
    {
        field: "comments",
        headerName: "Comments",
        minWidth: 100,
        maxWidth: 100,
        cellRenderer: (params) => {
            return shortenInteger(params.value);
        },
    },
    {
        field: "sentimentScore",
        headerName: "Sentiment Score",
        minWidth: 170,
        maxWidth: 200,
        cellRenderer: (params) => {
            const category = getSentimentCategory(params?.value);
            const score = params.value?.toFixed(2) ?? " ";
            return `${category} (${score})`;
        },
    },

];

const TrailerGrid = ({ id, videoId }) => {

    const itemTrailerData = useSelector( (state) => state?.itemTrailerData?.data || [] );
    const isLoading = useSelector((state) => state?.itemTrailerData?.loading);

    useEffect(() => {
        if (id) {
            if (itemTrailerData.length <= 0) {
                fetchTrailersData({ id });
            }
        }
    }, [id]);

    const sortedData = [...(itemTrailerData?.data ?? [])]
        .map((item, index) => ({ ...item, originalIndex: index }))
        .sort((a, b) => {
            return (b.official ? 1 : 0) - (a.official ? 1 : 0);
        })
        .map(item => {
            const { originalIndex, ...rest } = item;
            return rest;
        });

    const filteredData = sortedData.filter(item => item.videoId !== videoId);
    const gridDefinitions = filteredData?.map(() => ({ colspan: { xxs: 12, s: 6, l: 4, } }));

    return id ? (
        <>
            {isLoading ? (
                <Box textAlign="center" padding="l">
                    <CustomLoadingOverlay />
                </Box>
            ) : filteredData.length > 0 ? (
                <SpaceBetween direction="vertical" size="l">
                            <div className="ag-theme-quartz-dark compact">
                                <AgGridReact
                                    rowData={filteredData}
                                    defaultColDef={defaultColDef}
                                    suppressRowClickSelection={true}
                                    domLayout='autoHeight'
                                    loadingOverlayComponent={() => (<CustomLoadingOverlay compact={true} />)}
                                    noRowsOverlayComponent={() => <CustomLoadingOverlay compact={true} />}
                                    suppressDragLeaveHidesColumns={true}
                                    columnDefs={columnDefs}
                                    suppressContextMenu={true}
                                />
                            </div>
                <Grid gridDefinition={gridDefinitions}>
                        {filteredData?.map((video, index) => {
                        return (
                            <Container
                                disableHeaderPaddings
                                disableContentPaddings
                                fitHeight
                                key={index}
                                media={{
                                    content: <iframe
                                        src={`https://www.youtube.com/embed/${video.videoId}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>,
                                    height: 350,
                                    position: "top"
                                }}
                                footer={
                                    <>
                                        <ColumnLayout minColumnWidth="100" columns={4} variant="text-grid" >
                                            <div>
                                                <Box variant="awsui-key-label" textAlign="center"> Views </Box>
                                                <Box textAlign="center">{shortenInteger(video?.views)}</Box>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label" textAlign="center"> Likes </Box>
                                                <Box textAlign="center">{shortenInteger(video?.likes)}</Box>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label" textAlign="center"> Comments </Box>
                                                <Box textAlign="center"> {shortenInteger(video?.comments)} </Box>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label" textAlign="center"> Score </Box>
                                                <Box textAlign="center"> {video?.sentimentScore?.toFixed(3)} </Box>
                                            </div>
                                        </ColumnLayout>
                                    </>
                                }
                            >

                                <Box padding={{ horizontal: "s", top: "xs" }}>
                                    {video.sentimentScore !== null && video.sentimentScore !== undefined && (
                                        <Box variant="small" color={getSentimentColor(video.sentimentScore)} > {getSentimentCategory(video.sentimentScore)} Sentiment </Box>
                                    )}
                                    <br />
                                    <Link
                                        fontSize="heading-m"
                                        href={`/mediacatalog/youtube/videos/${video.videoId}`}
                                        target="_blank"
                                    >
                                        {video.title}
                                    </Link>
                                    {video?.madeForKids && (
                                        <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
                                            Kids
                                        </span>
                                    )}
                                    {video?.official && (
                                        <span className="ml-2 inline-flex items-center rounded-md bg-amber-400/10 px-1 py-0.5 text-xs font-medium text-amber-400 ring-1 ring-inset ring-amber-400/30">
                                            Official
                                        </span>
                                    )}
                                </Box>
                                <Box variant="small" padding={{ horizontal: "s" }}>
                                    YouTube Channel:
                                    <Link
                                        fontSize="heading-xs"
                                        href={`/mediacatalog/youtube/channels/${video.channelId}`}>
                                        {video.channel}
                                    </Link> |
                                    Published On:
                                    {new Date(video.publishedAt * 1000).toLocaleDateString("en-US", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </Box>
                            </Container>
                        );
                    })}
                </Grid>
                </SpaceBetween>
            ) : (
                <Box textAlign="center" padding="xxl">
                    No trailers found
                </Box>
            )}
        </>
    ) : null;
};

export default TrailerGrid;
