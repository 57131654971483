import React, { useState } from "react";

const ReadMore = ({ children, maxLength=600, className }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if (!children) return null;
  return (
    <a className={"text " + className}>
      {isReadMore ? text.slice(0, maxLength) : text}
      {text.length > maxLength && (
        <span onClick={toggleReadMore} className="read-or-hide font-bold cursor-pointer">
          {isReadMore ? " ...read more" : " show less"}
        </span>
      )}
    </a>
  );
};

export default ReadMore;