import {
  ExpandableSection
} from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { getRecentVideos } from "../../api/request";
import { columnDefs } from "../TopVideos/gridColumnDefs";
import { defaultColDef } from "../TopVideos/gridDefaultColDef";

const RecentVideos = ({ channelId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        const response = await getRecentVideos({ channelId });
        setVideos(response.data.data);
      } catch (error) {
        console.error("Failed to fetch videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [channelId]);

  return (
    videos.length > 0 && (
      <ExpandableSection
        defaultExpanded
        disableContentPaddings
        variant="container"
        fitHeight
        headerText="Recent Videos by Views from the Channel"
      >
        <hr className="border-slate-600 border-t-[1px]" />
        <div className="ag-theme-quartz-dark compact">
          <AgGridReact
            rowData={videos}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            domLayout='autoHeight'
            loadingOverlayComponent={() => (<CustomLoadingOverlay compact={true} />)}
            noRowsOverlayComponent={() => <CustomLoadingOverlay compact={true} />}
            suppressDragLeaveHidesColumns={true}
            columnDefs={columnDefs}
            suppressContextMenu={true}
          />
        </div>
      </ExpandableSection>
    )
  );
};

export default RecentVideos;
