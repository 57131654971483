import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
  Multiselect,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../../../components/forms/FormProvider";
import DOMPurify from "dompurify";
import axiosInstance from "../../../../utils/axios";

const franchiseCreateFormValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title field is required"),
  description: Yup.string().required("Description field is required"),
});

export const FranchiseCreateModal = ({
  visible = false,
  onDiscard = () => { },
  title = "",
  description = "",
  onSubmit = () => { },
}) => {
  const methods = useForm({
    defaultValues: {
      title,
      description,
    },
    mode: "all",
    reValidateMode: "all",
    resolver: yupResolver(franchiseCreateFormValidationSchema),
  });
  const [searchItems, setSearchItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusType, setStatusType] = useState('finished');

  const searchItem = useCallback((query) => {
    if (!query) return;

    axiosInstance
      .get(`topsearch?keyword=${query}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Movies,Television,Gaming`)
      .then((data) => data.data)
      .then((data) => {
        const filteredData = data.filter(
          (item) => !selectedItems.some((selected) => selected.value === item.ip_id)
        );
        setSearchItems(filteredData);
        setStatusType('finished');
      })
      .catch((e) => {
        console.error(e);
        setStatusType('error');
      });
  }, [setSearchItems, selectedItems]);

  useEffect(() => {
    if (query) {
      searchItem(query);
    }
  }, [query, searchItem]);

  useEffect(() => { }, [selectedItems]);

  const { control, handleSubmit, setValue, reset } = methods;

  const onFormSubmit = async (data) => {
    setIsSubmitting(true);
    const payload = {
      name: data.title,
      summary: DOMPurify.sanitize(data.description),
      added: selectedItems.map((item) => item.value),
    };
    try {
      await onSubmit(payload);
    } catch (error) {
      console.error('Form submission failed:', error);
    }
    reset();
  };

  const handleButtonClick = () => {
    handleSubmit(onFormSubmit)();
  };

  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={onDiscard}
      header={"Create Franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <div className="py-2 flex flex-row w-full justify-end">
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={(e) => { e.preventDefault(); onDiscard(); }} > Cancel </Button>
              <Button
                variant="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={!isSubmitting ? handleButtonClick : undefined}
              >
                {isSubmitting ? 'Submitting' : 'Submit'}
              </Button>
            </SpaceBetween>
          </Box>
        </div>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          <Controller
            name={"title"}
            control={control}
            render={({
              field,
              fieldState: { invalid, error },
            }) => (
              <FormField label="Title" stretch errorText={error?.message}>
                <Input
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                />
              </FormField>
            )}
          />

          <Controller
            name={"description"}
            control={control}
            render={({
              field,
              fieldState: { invalid, error },
            }) => (
              <FormField stretch label="Description" errorText={error?.message}>
                <Textarea
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                  autoComplete="on"
                  rows={10}
                  spellcheck={true}
                />
              </FormField>
            )}
          />

          <FormField stretch label="Associate Titles">
            <Multiselect
              selectedOptions={selectedItems}
              options={Object.entries(
                searchItems.reduce((groups, item) => {
                  const group = item.vertical;
                  if (!groups[group]) {
                    groups[group] = [];
                  }
                  groups[group].push({
                    value: item.ip_id,
                    label: `${item.ip} (${new Date(item.release_date).getFullYear()})`,
                  });
                  return groups;
                }, {})
              ).map(([groupLabel, groupItems]) => ({
                label: groupLabel,
                options: groupItems,
              }))}
              onLoadItems={({ detail }) => {
                const { filteringText } = detail;
                if (filteringText) {
                  setStatusType('loading');
                  setSearchItems([]);
                  searchItem(filteringText);
                }
              }}
              onChange={(event) => {
                const newSelectedItems = event.detail.selectedOptions;
                if (Array.isArray(newSelectedItems)) {
                  setSelectedItems(newSelectedItems);
                }
              }}
              statusType={statusType}
              loadingText="Loading..."
              placeholder="Search..."
              filteringPlaceholder="Search for Movies, Series or Games"
              filteringType="manual"
              filteringResultsText={(count) => `${count} results found`}
            />
          </FormField>
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
