import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

export const fetchFranchiseData = createAsyncThunk(
  "franchiseData/fetchFranchiseData",
  async ({ from, size, range }) => {
    const response = await axiosInstance.request({
      url: `explore/Franchise?from=${from}&size=${size}${
        range ? `&gte=${range.gte}&lte=${range.lte}` : ""
      }`,
      method: "GET",
    });
    return response.data;
  }
);

export const fetchFilteredFranchiseData = createAsyncThunk(
  "franchiseData/fetchFilteredFranchiseData",
  async ({ from, size, range, body }) => {
    const response = await axiosInstance.request({
      url: `explore/Franchise?from=${from}&size=${size}${range ? range : ""}`,
      method: "POST",
      data: body,
    });
    return response.data;
  }
);

const franchiseSlice = createSlice({
  name: "franchiseData",
  initialState: {
    data: [],
    total: 0,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFranchiseData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFranchiseData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchFranchiseData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFilteredFranchiseData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilteredFranchiseData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchFilteredFranchiseData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default franchiseSlice.reducer;
