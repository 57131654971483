import { useMutation } from "react-query";
import { createMovie } from "../request";
import { mapCreateMovieResonse } from "../mappers/mapCreateMovieResponse";

export const useCreateMovie = ({ onSuccess, onError }) => {
  const { isLoading, mutate, error, data } = useMutation(
    (id: string) => createMovie(id),
    {
      onSuccess,
      onError: ({ message }) => onError(message),
    },
  );

  return {
    data: data?.data ? mapCreateMovieResonse(data?.data) : {},
    mutate,
    isLoading,
    error,
  };
};
