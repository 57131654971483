import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../../../components/forms/TextField";
import FormProvider from "../../../../components/forms/FormProvider";
import { useSelector } from "react-redux";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import { clearCreateRoleFn, createRoleFn } from "../redux/actions/roles-action";
import { MultiSelectBox } from "../../../../components/forms/MultiSelectBox";
import { Alert, Button, SpaceBetween } from "@cloudscape-design/components";
export default function CreateRoleForm({
  formStatus = true,
  setCancel,
  setCompleted,
  permissionsList,
  ...others
}) {
  const createRole = useSelector((state) => state.createRole);
  const onSubmit = (data) => {
    createRoleFn({ ...data });
  };

  const createUserDefaultValue = {
    roleName: "",
    permissions: [],
  };
  const createUserSchema = Yup.object().shape({
    roleName: Yup.string().required("Role name is required"),
    permissions: Yup.array().min(1, "Permission is required"),
  });

  const methods = useForm({
    defaultValues: createUserDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserSchema),
  });

  const {
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    return () => {
      clearCreateRoleFn();
    };
  }, []);

  useEffect(() => {
    if (
      !createRole.isLoading &&
      createRole.isCompleted &&
      !createRole.isError
    ) {
      setCompleted();
      setCancel(true);
    }
    return () => {
      clearCreateRoleFn();
    };
  }, [createRole]);
    useEffect(() => {
      if (!formStatus) {
        reset();
      }
    }, [formStatus]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      id="role_create_form"
    >
      <div className="py-5">
        {!createRole.isLoading &&
          createRole.isCompleted &&
          createRole.isError && (
            <Alert type="error" dismissible={true}>
              {createRole.message}
            </Alert>
          )}
        {!createRole.isLoading &&
          createRole.isCompleted &&
          !createRole.isError && (
            <Alert type="success" dismissible={true}>
              {createRole.message}
            </Alert>
          )}
        <div className="grid grid-cols-1 gap-6  ">
          <span>
            <TextField
              name={"roleName"}
              label="Role Name"
              placeholder={"Role Name"}
              type={"text"}
            />
          </span>
          <span>
            <MultiSelectBox
              name={"permissions"}
              label="Permissions"
              options={permissionsList.map((d) => ({
                value: d.permission_name,
                label: d.permission_name,
              }))}
            />
          </span>
        </div>
       
      </div>
    </FormProvider>
  );
}
