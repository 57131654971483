import { isSomeOrElse } from "../../../../utils/sugarUtils";

type PlatformPayloadItem = {
  weightage: number;
  isChecked: boolean;
};

export type PayloadRequest = {
  wiki: PlatformPayloadItem;
  gtrends: PlatformPayloadItem;
  a03: PlatformPayloadItem;
  youtube: PlatformPayloadItem;
  fandom: PlatformPayloadItem;
  twitter: PlatformPayloadItem;
  reddit: PlatformPayloadItem;
  instagram: PlatformPayloadItem;
  tiktok: PlatformPayloadItem;
  twitch: PlatformPayloadItem;
  steam: PlatformPayloadItem;
  wattpad: PlatformPayloadItem;
  imdb: PlatformPayloadItem;
};

export const mapFormValueToRequest = (data): PayloadRequest => {
  return {
    wiki: {
      weightage: Number(data.wikipedia.weightage),
      isChecked: data.wikipedia.isChecked,
    },
    gtrends: {
      weightage: Number(data.google.weightage),
      isChecked: data.google.isChecked,
    },
    a03: {
      weightage: Number(data.ao3.weightage),
      isChecked: data.ao3.isChecked,
    },
    youtube: {
      weightage: Number(data.youtube.weightage),
      isChecked: data.youtube.isChecked,
    },
    fandom: {
      weightage: Number(data.fandom.weightage),
      isChecked: data.fandom.isChecked,
    },
    twitter: {
      weightage: Number(data.twitter.weightage),
      isChecked: data.twitter.isChecked,
    },
    reddit: {
      weightage: Number(data.reddit.weightage),
      isChecked: data.reddit.isChecked,
    },
    instagram: {
      weightage: Number(data.instagram.weightage),
      isChecked: data.instagram.isChecked,
    },
    tiktok: {
      weightage: Number(data.tiktok.weightage),
      isChecked: data.tiktok.isChecked,
    },
    twitch: {
      weightage: Number(data.twitch.weightage),
      isChecked: data.twitch.isChecked,
    },
    steam: {
      weightage: Number(data.steam.weightage),
      isChecked: data.steam.isChecked,
    },
    wattpad: {
      weightage: Number(data.wattpad.weightage),
      isChecked: data.wattpad.isChecked,
    },
    imdb: {
      weightage: Number(data.imdb.weightage),
      isChecked: data.imdb.isChecked,
    },
  };
};
