import { Icon, Link } from "@cloudscape-design/components";
import React, { useCallback, useMemo } from "react";
import { isSomeOrElse } from "../../../utils/sugarUtils";
import { isJSON } from "../utils/filter-conditions";

export const useGridConfig = (checkboxConfig, selectedVertical, fetchData) => {
  const initialCheckboxConfig = useMemo(
    () => [
      { label: "TMDB", field: "tmdb_id", visible: true },
      { label: "IMDb", field: "imdb_id", visible: true },
      { label: "Wikipedia", field: "wikidata_id", visible: true },
      { label: "Google Trends", field: "trends", visible: false },
      { label: "Fandom", field: "fandom", visible: true },
      {
        label: "GraceNote",
        field: "tmsId",
        visible: false,
        specialConfig: {
          minWidth: 170,
          cellDataType: "text",
          cellEditor: "autoSuggestEditor",
          editable: true,
          cellEditorPopup: true,
          valueSetter: (params) => {
            const value = params?.newValue
              ? isJSON(params?.newValue)
                ? JSON.parse(params?.newValue)
                : { tmsId: params?.newValue, emsId: params?.data?.emsId }
              : { tmsId: "", emsId: "" };
            params.data.tmsId = value?.tmsId;
            params.data.emsId = value?.emsId;
            params.data.edited = true;
            params.data.vertical = selectedVertical.value.toLowerCase();
            return true;
          },
        },
      },
      { label: "Roten Tomatoes", field: "emsId", visible: false },
      { label: "Instagram", field: "instagram", visible: false },
      { label: "Twitter", field: "twitter", visible: false },
      { label: "YouTube", field: "youtube", visible: false },
      { label: "Wattpad", field: "wattpad", visible: false },
      { label: "Subreddit", field: "subreddit", visible: false },
      { label: "Parent Company", field: "parentCompany", visible: false },
      { label: "TikTok", field: "tiktok", visible: false },
      { label: "A03", field: "a03", visible: false },
      { label: "IGDB", field: "igdb_id", visible: false },
      { label: "Steam", field: "steam", visible: false },
      { label: "Twitch", field: "twitch", visible: false },
      { label: "Discord", field: "discord", visible: false },
    ],
    [selectedVertical],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    const baseColumns = [
      {
        field: "tracked",
        headerName: "Tracked",
        cellDataType: "boolean",
        hide: true,
        filter: true,
      },
      {
        field: "id",
        headerName: "ID",
        cellRenderer: (params) => {
          return params.rowIndex + 1;
        },
        minWidth: 60,
        maxWidth: 60,
      },
      {
        field: "ip",
        headerName: "Title",
        minWidth: 250,
        cellRenderer: (params) => {
          if (!params.value) return null;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.tracked != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link href={`/item/${params.data.ip_id}`}> {params.value} </Link>
            </div>
          );
        },
      },
      {
        field: "release_date",
        headerName: "Release Date",
        minWidth: 100,
        cellDataType: "date",
        valueFormatter: (params) => {
          return params.value
            ? new Date(params.value).toLocaleDateString()
            : "";
        },
      },
    ];

    const visibleColumns = checkboxConfig
      .filter((checkbox) => checkbox.visible)
      .map((checkbox) => {
        const specialConfig = checkbox.specialConfig
          ? checkbox.specialConfig
          : {};
        return {
          field: checkbox.field,
          headerName: checkbox.label,
          minWidth: 100,
          cellDataType: "text",
          editable: true,
          valueSetter: (params) => {
            const value = isSomeOrElse(params?.newValue, "");
            params.data[checkbox.field] = value;
            params.data.edited = true;
            return true;
          },
          ...specialConfig,
        };
      });
    return [...baseColumns, ...visibleColumns];
  }, [checkboxConfig]);

  const onGridReady = useCallback(
    (params) => {
      const advancedFilterElement = document.getElementById(
        "advancedFilterParent",
      );
      if (advancedFilterElement) {
        params.api.setGridOption("advancedFilterParent", advancedFilterElement);
        params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
      }

      fetchData();
    },
    [fetchData],
  );

  return {
    initialCheckboxConfig,
    defaultColDef,
    columnDefs,
    onGridReady,
  };
};
