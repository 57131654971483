import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import renderTrend from "../../../../utils/trendIcon";

export const useGridConfig = () => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
    }),
    [],
  );

  const tooltipRenderer = (params) => {
    const adjustedIndex = 30 - params.xValue;
    const date = moment().subtract(adjustedIndex, "days").format("YYYY-MM-DD");
    return `<div class="bg-white p-2">
              <div class='bg-slate-300 p-1'>${date}</div>
              <hr/>
              <div>
                <div>Views: ${shortenInteger(params.yValue)}</div>
              </div>
          </div>`;
  }

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "daily_rank_change",
        headerName: "Daily Rank Change",
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        headerName: "Rank ",
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "daily_rank",
            "daily_rank_change",
            "daily_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        cellRenderer: (params) => {
          const releaseYear = new Date(params.data.release_date).getFullYear();
          const ipId = params.data.ip_id;
          const destination = `/item/${ipId}`;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.tracked != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link
                color="inverted"
                variant="secondary"
                href={destination}
                onClick={(e) => !ipId && e.preventDefault()}
              >
                {params.value}
              </Link>
              <span className="text-xs text-slate-500 mt-1">
                ({releaseYear})
              </span>
            </div>
          );
        },
      },
      {
        field: "weekly_rank",
        headerName: "Weekly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "weekly_rank",
            "weekly_rank_change",
            "weekly_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.weekly_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        headerName: "All Time Rank",
        cellDataType: "number",
        pinned: "left",
        maxWidth: 110,
        cellRenderer: (params) =>
          renderTrend(params, "rank", "rank_change", "rank_trend"),
        valueGetter: (params) => parseInt(params.data.rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "score",
        headerName: "Pantheon Score",
        headerTooltip: "Pantheon Score based on 50% weightage to daily views, 30% to weekly views, and 20% to monthly views.",
        cellDataType: "number",
        maxWidth: 100,
        pinned: "left",
        valueGetter: (params) => {
          const today = params.data.today_views;
          const weekly = params.data.weekly_sum;
          const monthly = params.data.monthly_sum;
          return parseInt((today * 0.5 + weekly * 0.3 + monthly * 0.2) / 1000);
        },
      },
      {
        field: "peak_date",
        headerName: "Peak Date",
        cellDataType: "date",
        valueFormatter: (params) => {
          return moment(params.value, "YYYYMMDD").format("DD MMM Y");
        },
      },
      {
        field: "peak_views",
        headerName: "Peak Views",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "today_views",
        headerName: "Views (Last Day)",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "weekly_sum",
        headerName: "Last 7 days Views",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "monthly_sum",
        headerName: "Last 30 days Views",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "total_views",
        headerName: "Total Views",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "start_date",
        headerName: "Start Date",
        cellDataType: "date",
        valueFormatter: (params) => {
          return moment(params.value, "YYYYMMDD").format("DD MMM Y");
        },
      },
      {
        field: "days_since_start",
        headerName: "Days Since Start",
        cellDataType: "number",
      },
      {
        field: "avg_views",
        headerName: "Average Views",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "last_30_days_views",
        headerName: "30-day Trend",
        maxWidth: 600,
        minWidth: 200,
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            tooltip: {
              enabled: true,
              renderer: tooltipRenderer,
            },
            type: "area",
            marker: {
              size: 1,
              shape: "circle",
              fill: "white",
            },
            fill: "#0972d3",
            highlightStyle: {
              fill: "#033160",
            },
          },
        },
        filter: false,
        sortable: false,
      },
    ];

    return allColumns;
  }, []);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  const gridOptions = {
    getContextMenuItems: (params) => {
      if (params.column.colId === "ip") {
        return null;
      }
      return params.defaultItems;
    },
  };

  return {
    defaultColDef,
    columnDefs,
    onGridReady,
    gridOptions,
  };
};
