import {
    IncreaseIcon,
    DecreaseIcon,
    NeutralIcon,
    NewIcon,
} from "../../../components/Icons";
import { Icon,SpaceBetween } from "@cloudscape-design/components";

const TableLegend = () => {
    return (
        <div className="text-xs font-thin text-gray-400">
            <SpaceBetween direction="horizontal" size="s">
                <a><Icon name="status-positive" variant="success" /> Title is tracked with advanced metrics</a>
                <a><Icon name="status-positive" variant="disabled" /> Title is available with basic details</a>
                <a><Icon name="status-negative" variant="disabled" /> Title is not available on the platform</a>
                <a><Icon svg={<IncreaseIcon />} variant="success" /> Upward trend</a>
                <a><Icon svg={<DecreaseIcon />} variant="error" /> Downward trend</a>
                <a><Icon svg={<NeutralIcon />} variant="subtle" /> Stable trend</a>
                <a><Icon svg={<NewIcon />} variant="success" /> New entry</a>
            </SpaceBetween>
        </div>
    );
}

export default TableLegend;