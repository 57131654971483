import { getYearList, monthList } from "../../../utils/dateFormatter";
import { PantheonRankingResponseItem } from "./api/request";

export const getOptions = (key: string, pantheonRankingData) => {
  if (key === "category") {
    return [
      { label: "Movies", value: "movies" },
      { label: "Television", value: "television" },
      { label: "Games", value: "gaming" },
    ];
  }

  if (key === "sort") {
    return [
      { label: "Pantheon score", value: "pantheon_score" },
      { label: "Search score", value: "search_score" },
      { label: "Engagement score", value: "engagement_score" },
      { label: "Demand score", value: "demand_score" },
    ];
  }

  if (key === "year") {
    const currentYear = new Date().getFullYear();
    const years = getYearList(currentYear);

    return years.map((year) => ({
      label: year,
      value: year,
    }));
  }

  if (key === "month") {
    return monthList.map((month, index) => ({
      label: month,
      value: index,
    }));
  }

  if (!pantheonRankingData[key]) return [];

  const allOption = { label: "All", value: "All" };
  const options = pantheonRankingData[key].map(
    (item: PantheonRankingResponseItem) => ({
      label: item.toString(),
      value: item.toString(),
    }),
  );
  options.unshift(allOption);

  return options;
};
