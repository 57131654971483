import { Link } from "@cloudscape-design/components";

const PiracyScore = ({ allTimeRank, title, subtitle }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                <img src="/svg/piracy.svg" alt="Votes" className="h-8" />
                <div className="text-xl text-slate-50 font-bold"> {allTimeRank} </div>
            </div>
            <div className="text-xs font-bold"><Link fontSize="body-s" href="/dashboards/piracy">{title}</Link></div>
            <div className="text-xs italic text-slate-400">
                {subtitle}
            </div>
        </div>
    );
};

export default PiracyScore;