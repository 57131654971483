import React from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import Link from "@cloudscape-design/components/link";
import shortenInteger from "../../../utils/shortenInteger";

function exportActiveColumns(params) {
  const columnsToExport = ["ip", "ip_count", "first_seen", "downloads"];
  params.api.exportDataAsExcel({
    columnKeys: columnsToExport,
  });
}

const AgGridComponent = ({ leaderboardData }) => (
  <AgGridReact
    rowHeight={60}
    defaultColDef={{
      suppressMovable: true,
      suppressSizeToFit: true,
      suppressHeaderMenuButton: true,
      sortable: true,
      flex: 1,
      cellStyle: {
        lineHeight: "60px",
        textAlign: "center",
      },
    }}
    gridOptions={{
      getContextMenuItems: (params) => {
        if (params.column.colId === 'ip') {
          return null;
        }
        const itemsToExclude = ["export"];
        const items = params.defaultItems
          .filter((item) => !itemsToExclude.includes(item))
          .concat([
            {
              name: "Export to Excel",
              action: () => exportActiveColumns(params),
            },
          ]);
        return items;
      },
    }}

    columnDefs={[
      {
        field: "rank",
        headerName: "#",
        sortable: false,
        minWidth: 60,
        cellRenderer: (props) => props.node.rowIndex + 1,
      },
      {
        field: "ip",
        headerName: "IP",
        minWidth: 200,
        maxWidth: 400,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          const destination = ipId ? `${window.location.origin}/item/${ipId}` : undefined;

          return (
            <Link href={destination}>
              {params.value}
            </Link>
          );
        },
      },
      {
        headerName: "Days on list",
        field: "ip_count",
        minWidth: 100,
        cellStyle: {
          color: "#FFA500",
        },
      },
      {
        headerName: "First Seen",
        field: "first_seen",
        minWidth: 100,
        cellRenderer: (params) => {
          return moment(params.value).format('MMM DD, YY');
        }
      },
      {
        field: "peak_rank",
        minWidth: 80,
        headerName: "Peak Rank",
      },
      {
        field: "peak_date",
        minWidth: 100,
        headerName: "Peak Date",
        cellRenderer: (params) => {
          return moment(parseInt(params.value) * 1000).format('MMM DD, YY');
        },
      },
      {
        field: "rank_changes",
        minWidth: 250,
        headerName: "Rank Change",
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#886023",
            paddingInner: 0.5,
            paddingOuter: 0,
            padding: {
              top: 10,
              bottom: 10,
            },
            label: {
              enabled: true,
              placement: "outsideEnd",
              color: "#ffffff",
            },
          },
        },
      },
      {
        headerName: "Downloads",
        field: "total_downloads",
        minWidth: 100,
        cellRenderer: function (params) {
          return shortenInteger(params.value);
        },
      },
      {
        headerName: "Downloads Trend",
        field: "downloads",
        minWidth: 200,
        sortable: false,
        cellStyle: {
          textAlign: "center",
          lineHeight: "90px",
        },
        valueGetter: (params) => {
          return params.data.data.map((d) => ({
            downloads: d.downloads,
            timestamp: moment(parseInt(d.timestamp)).format("MM/DD/YYYY"),
          }));
        },
        cellRendererParams: {
          sparklineOptions: {
            type: "area",
            fill: "rgba(255, 165, 0, 0.5)",
            xKey: "timestamp",
            yKey: "downloads",
            padding: {
              top: 20,
              bottom: 0,
            },
            line: {
              stroke: "#FFA500",
              strokeWidth: 3,
            },
            highlightStyle: {
              fill: '#886023',
            },
          },
        },
        cellRenderer: "agSparklineCellRenderer",
      },
    ]}
    rowData={leaderboardData}
  />
);

export default AgGridComponent;
