import { Container, ContentLayout, FormField, Grid, Header, Select, SpaceBetween } from "@cloudscape-design/components";
import React from "react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { BoByStudioChart } from "./components/BoByStudioChart";
import { BoDomesticShareChart } from "./components/BoDomesticShareChart";
import { BoInternationalShareChart } from "./components/BoInternationalShareChart";

export const BoByStudioPage = () => {
    const breadcrumbs = [{ text: "Dashboard" }, { text: "Box Office", href: "/box-office" }, { text: "Box Office By Distributor" }];
    const currentYear = new Date().getFullYear();
    const startYear = 1980;
    const years = Array.from(
        { length: currentYear - startYear + 1 },
        (_, index) => startYear + index
    );
    return (
        <Layout
            title="Box Office By Distributor"
            breadcrumbs={breadcrumbs}
            navItems={navItems}
            content={
                <ContentLayout
                    header={
                        <Header
                            variant="h2"
                            description="Box Office By Distributor Dashboard."
                            actions={
                                <FormField description="Release year">
                                    <Select
                                        options={[
                                            ...years
                                                .slice()
                                                .sort()
                                                .reverse()
                                                .map((y) => ({ label: y, value: y })),
                                        ]}
                                        placeholder="Select a year"
                                    />
                                </FormField>
                            }
                        >
                            Box Office By Distributor
                        </Header>
                    }>
                    <SpaceBetween direction="vertical" size="xs">
                        <Container disableContentPaddings data-style-id="border-radius-0" >
                            <BoByStudioChart />
                        </Container>
                        < Grid
                            gridDefinition={
                                [
                                    { colspan: { xxs: 12, m: 6 } },
                                    { colspan: { xxs: 12, m: 6 } }
                                ]}
                        >
                            <Container disableContentPaddings data-style-id="border-radius-0" >
                                <BoDomesticShareChart />
                            </Container>
                            <Container disableContentPaddings data-style-id="border-radius-0" >
                                <BoInternationalShareChart />
                            </Container>
                        </Grid>
                    </SpaceBetween>
                </ContentLayout>
            }
            contentType="dashboard"
        />
    );
};
