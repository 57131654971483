import {
  Alert,
  Box,
  Button,
  Drawer,
  ExpandableSection,
  Flashbar,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { PlatformWeightageDropdown } from "../../../../components/PlatformWeightageDropdown";
import FormProvider from "../../../../components/forms/FormProvider";
import { InputField } from "../../../../components/forms/InputField";
import { PLATFORMS } from "../../../../services/platformPreference/constants";
import { useGetAllPlatformWeightages } from "../../../../services/platformPreference/hooks/useGetAllPlatformWeightages";
import { useGetPlatformWeightages } from "../../../../services/platformPreference/hooks/useGetPlatformWeightages";
import { isNone } from "../../../../utils/sugarUtils";
import { mapResponseToFormValue } from "../domain/mapResponseToFormValue";
import { mapToPlatformWeightage } from "../domain/mapToPlatformWeightage";
import { CreatePlatformWeightSettingsModal } from "./CreatePlatformWeightSettingsModal";
import { DeletePreferenceModal } from "./DeletePreferenceModal";

export const defaultWeightageValues = {
  wikipedia: {
    weightage: 2,
    isChecked: true,
    category: "Search",
    label: "Wikipedia",
  },
  youtubeViews: {
    weightage: 2,
    isChecked: true,
    category: "Search",
    label: "Youtube Views",
  },
  p2p: {
    weightage: 1,
    isChecked: true,
    category: "Demand",
    label: "Piracy",
  },
  twitch: {
    weightage: 1,
    isChecked: true,
    category: "Demand",
    label: "Twitch",
  },
  imdb: {
    weightage: 1,
    isChecked: true,
    category: "Engagement",
    label: "IMDb",
  },
  rottenTomatoes: {
    weightage: 1,
    isChecked: true,
    category: "Engagement",
    label: "Rotten Tomatoes",
  },
  youtubeLikes: {
    weightage: 1,
    isChecked: true,
    category: "Engagement",
    label: "Youtube Likes",
  },
  youtubeComments: {
    weightage: 1,
    isChecked: true,
    category: "Engagement",
    label: "Youtube Comments",
  },
};

export const PlatformWeightageDrawer = ({
  weightageValues,
  setWeightageValues,
}) => {
  const [showFlashbar, setShowFlashbar] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("default");
  const [formValues, setFormValues] = useState(weightageValues);
  const { data: platformWeightagesData, isLoading } =
    useGetAllPlatformWeightages(PLATFORMS.PANTHEON_RANKING);

  const [weight, setWeight] = useState(null);

  const { user } = useAuthContext();
  const { data: platformWeightage } = useGetPlatformWeightages(weight);

  const updateFormValues = useCallback(
    (weightage) => setFormValues(mapResponseToFormValue(weightage)),
    [],
  );

  useEffect(() => {
    if (isNone(platformWeightagesData)) return;
    const { name, fileData } = getSelectedFileData(platformWeightagesData.data);

    setSelectedFileName(name);
    setWeight(mapToPlatformWeightage({ ...fileData, user }));
  }, [platformWeightagesData, user]);

  useEffect(() => {
    if (!isLoading && platformWeightage) {
      updateFormValues(platformWeightage);
    }
  }, [isLoading, platformWeightage, updateFormValues, weight]);

  const getSelectedFileData = (weightageData) => {
    let fileData = weightageData.find(
      (weight) => weight.filename === "default.json",
    );

    if (isNone(fileData)) fileData = weightageData[0];

    const name = fileData?.filename.replace(".json", "");

    return { name, fileData };
  };

  const methods = useForm({
    values: formValues,
  });

  const getSumOfValues = useCallback(() => {
    const values = methods.watch();

    return (
      Object.values(values).reduce(
        (acc, value) =>
          value.isChecked ? acc + Number(value.weightage) * 10 : acc, // binary nature of JS decimal values creates wrong values
        0,
      ) / 10
    );
  }, [methods]);

  const validateForm = () => getSumOfValues() === 10;

  const disableSave = () => isEqual(formValues, methods.watch());

  const groupedFields = Object.entries(defaultWeightageValues).reduce(
    (acc, [key, value]) => {
      const { category } = value;
      if (!acc[category]) acc[category] = [];
      acc[category].push({ ...value, id: key });
      return acc;
    },
    {},
  );

  const [items, setItems] = React.useState([]);

  const getDeletePreferenceFlashbarContent = (isSuccess, message) =>
    isSuccess
      ? "Preference deleted successfully."
      : message || "Platform preference failed to delete.";

  const getPlatformWeightageFlashbarContent = (isSuccess, message) =>
    isSuccess
      ? "Weightage preference saved successfully."
      : message || "Weightage preference failed to save.";

  const getItems = (content, isSuccess) => [
    {
      type: isSuccess ? "success" : "error",
      content,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1",
    },
  ];

  const getCallback = (content, isSuccess) => {
    const items = getItems(content, isSuccess);
    setItems(items);
    setShowFlashbar(true);
    setTimeout(() => {
      setShowFlashbar(false);
    }, 5000);
  };

  return (
    <Drawer header={<h2>Platform Weightage</h2>}>
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(setWeightageValues)}
      >
        <Box margin={{ bottom: "l" }}>
          <SpaceBetween direction="vertical" size="l">
            {showFlashbar && <Flashbar items={items} />}
            {!validateForm() && (
              <Alert
                statusIconAriaLabel="Error"
                type="error"
                header={`Current sum of weightages (${getSumOfValues()}) is not equal to the required total of (10)`}
              />
            )}
            <div className="flex items-center gap-2 justify-between">
              <TextContent>
                <h4>{selectedFileName}</h4>
              </TextContent>
              {!isLoading && platformWeightagesData.data && (
                <Box float="right">
                  <PlatformWeightageDropdown
                    data={platformWeightagesData.data}
                    platform={PLATFORMS.PANTHEON_RANKING}
                    updateFormValues={updateFormValues}
                    setSelectedFileName={setSelectedFileName}
                  />
                </Box>
              )}
            </div>
            {Object.entries(groupedFields).map(([category, fields]) => (
              <ExpandableSection
                key={category}
                headerText={category}
                defaultExpanded
              >
                <SpaceBetween direction="vertical" size="xxs">
                  {fields.map((field) => (
                    <InputField
                      key={field.id}
                      type="numeric"
                      name={`${field.id}.weightage`}
                      label={field.label}
                      isChecked={`${field.id}.isChecked`}
                    />
                  ))}
                </SpaceBetween>
              </ExpandableSection>
            ))}
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xxs">
                <Button
                  variant="normal"
                  formAction="submit"
                  disabled={!validateForm()}
                >
                  Apply
                </Button>
                <Button
                  variant="secondary"
                  formAction="none"
                  onClick={() => methods.reset(defaultWeightageValues)}
                >
                  Reset
                </Button>
                <Button
                  variant="normal"
                  formAction="none"
                  disabled={!validateForm() || disableSave()}
                  onClick={() => setShowCreateModal(true)}
                >
                  Save
                </Button>
                <Button
                  variant="normal"
                  formAction="none"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        </Box>
        <CreatePlatformWeightSettingsModal
          isVisible={showCreateModal}
          setShowCreateModal={setShowCreateModal}
          setSelectedFileName={setSelectedFileName}
          onSuccess={() => {
            getCallback(getPlatformWeightageFlashbarContent(true), true);
          }}
          onError={() => {
            getCallback(getPlatformWeightageFlashbarContent(false), false);
          }}
        />
        <DeletePreferenceModal
          title="Delete Preference"
          description={`Are you sure that you want to delete the selected preference - ${selectedFileName}`}
          selectedFileName={selectedFileName}
          visible={showDeleteModal}
          onDismiss={() => setShowDeleteModal(false)}
          onSuccess={() => {
            getCallback(getDeletePreferenceFlashbarContent(true), true);
            setShowDeleteModal(false);
          }}
          onError={() => {
            getCallback(getDeletePreferenceFlashbarContent(false), false);
          }}
        />
      </FormProvider>
    </Drawer>
  );
};
