import React, { useState } from "react";
import { CollectionPreferences } from "@cloudscape-design/components";

export const PreferencesComponent = ({
  initialPreferences,
  onPreferencesChange,
}) => {
  const handleConfirm = (newPreferences) => {
    onPreferencesChange(newPreferences);
  };

  return (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      onConfirm={(d) => handleConfirm(d.detail)}
      preferences={initialPreferences}
      pageSizePreference={{
        title: "Page size",
        options: [
          { value: 50, label: "50 items" },
          { value: 100, label: "100 items" },
          { value: 200, label: "200 items" }
        ],
      }}
      wrapLinesPreference={{
        label: "Wrap lines",
        description: "Select to see all the text and wrap the lines",
      }}
      stripedRowsPreference={{
        label: "Striped rows",
        description: "Select to add alternating shaded rows",
      }}
      contentDensityPreference={{
        label: "Compact mode",
        description: "Select to display content in a denser, more compact mode",
      }}
      stickyColumnsPreference={{
        firstColumns: {
          title: "Stick first column(s)",
          description:
            "Keep the first column(s) visible while horizontally scrolling the table content.",
          options: [
            { label: "None", value: 0 },
            { label: "First column", value: 1 },
            { label: "First two columns", value: 2 }
          ]
        },
        lastColumns: {
          title: "Stick last column",
          description:
            "Keep the last column visible while horizontally scrolling the table content.",
          options: [
            { label: "None", value: 0 },
            { label: "Last column", value: 1 }
          ]
        }
      }}
      visibleContentPreference={{
        title: "Select visible content",
        options: [
          {
            label: "Main properties",
            options: [
              { id: "title", label: "Name" },
              {
                id: "genre",
                label: "Genre",
              },
              {
                id: "release_date",
                label: "Release Date",
              },
              {
                id: "providers",
                label: "Watch Provider",
              },
              {
                id: "theatrical",
                label: "Theatrical",
              },
            ],
          },
        ],
      }}
    />
  );
};
