import { Alert, Button, SpaceBetween } from "@cloudscape-design/components";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormProvider from "../../../../components/forms/FormProvider";
import SwitchButton from "../../../../components/forms/SwitchButton";
import TextField from "../../../../components/forms/TextField";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import {
  clearOrgEditFn,
  editOrgFn
} from "../redux/actions/organizations-actions";
export default function EditOrganizationForm({
  defaults,
  setCancel,
  setCompleted,
  ...others
}) {
  const snackbarCtx = useContext(SnackbarContext);
  const onSubmit = async (data) => {
    editOrgFn({ ...data, id: defaults.id });
  };

  const editOrg = useSelector((state) => state.editOrg);
  useEffect(() => {
    if (!editOrg.isLoading && editOrg.isCompleted && !editOrg.isError) {
      setCompleted();
      setCancel(true);
    }
    return () => {
      clearOrgEditFn();
    };
  }, [editOrg]);

  const createUserDefaultValue = {
    email: "",
    name: "",
    org_status: defaults
      ? defaults.org_status === "Active"
        ? true
        : false
      : false,
    unrestrictedAccess: defaults ? defaults.unrestrictedAccess : false,
  };
  const createUserSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    org_status: Yup.boolean().required("Status is required"),
    unrestrictedAccess: Yup.boolean().required(
      "Unrestricted Access is required"
    ),
  });

  const methods = useForm({
    defaultValues: createUserDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserSchema),
  });

  const {
    setValue,
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (defaults) {
      setValue("email", defaults.email);
      setValue("name", defaults.name);
      setValue("org_status", defaults.org_status === "Active" ? true : false);
      setValue("unrestrictedAccess", defaults.unrestrictedAccess);
    }
    return () => {
      clearOrgEditFn();
    };
  }, [defaults]);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="py-5">
        {!editOrg.isLoading && editOrg.isCompleted && editOrg.isError && (
          <Alert type="error" dismissible={true}>
            {editOrg.message}
          </Alert>
        )}
        {!editOrg.isLoading && editOrg.isCompleted && !editOrg.isError && (
          <Alert type="success" dismissible={true}>
            {editOrg.message}
          </Alert>
        )}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
          <span>
            <TextField
              name={"name"}
              label="Name"
              placeholder={"Name"}
              type={"text"}
            />
          </span>
          <span>
            <TextField
              name={"email"}
              label="Email"
              placeholder={"Email"}
              type={"text"}
            />
          </span>
          <span>
            <SwitchButton
              name={"unrestrictedAccess"}
              label="Unrestricted Access"
              placeholder={"Unrestricted Access"}
            />
          </span>
          <span>
            <SwitchButton
              name={"org_status"}
              label="Status"
              placeholder={"Status"}
            />
          </span>
        </div>
        <div className="flex flex-row justify-end items-center pt-3">
          <SpaceBetween direction="horizontal" size="m">
            <span>
              <Button
                formAction="none"
                loading={isSubmitting}
                onClick={() => setCancel(true)}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                variant="primary"
                loading={isSubmitting}
                formAction="submit"
              >
                Submit
              </Button>
            </span>
          </SpaceBetween>
        </div>
      </div>
    </FormProvider>
  );
}
