import { Button, Popover } from "@cloudscape-design/components";
import moment from "moment";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
import shortenInteger from "../../utils/shortenInteger";

const formatDate = (timestamp) => {
  return moment(timestamp * 1000).format("DD MMM");
};

const CustomTick = ({ x, y, payload, axisType }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={axisType === "xAxis" ? 15 : 0}
        dx={axisType === "xAxis" ? 0 : -25}
        textAnchor="middle"
        fill="#666"
        fontSize={12}
        fontFamily="Arial, sans-serif"
      >
        {axisType === "xAxis"
          ? moment(payload.value * 1000).format("DD MMM YYYY")
          : shortenInteger(payload.value)}
      </text>
    </g>
  );
};

const TrendsPopover = ({ data, totalViews }) => {
  return (
    <Popover
      fixedWidth
      size="large"
      header="View Trends"
      position="top"
      triggerType="custom"
      content={
        <AreaChart width={400} height={200} data={data}>
          <defs>
            <linearGradient id="colorViews" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="timestamp"
            tick={<CustomTick axisType="xAxis" />}
            tickCount={10}
            interval={1}
          />
          <YAxis tick={<CustomTick />} tickCount={14} interval={1} />
          <Area
            type="monotone"
            dataKey="views"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorViews)"
          />
          <Tooltip
            contentStyle={{ backgroundColor: "#192533" }}
            formatter={shortenInteger}
            labelFormatter={formatDate}
          />
        </AreaChart>
      }
    >
      <Button variant="inline-link">{`${shortenInteger(totalViews)}`}</Button>
    </Popover>
  );
};

export default TrendsPopover;
