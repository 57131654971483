import { SpaceBetween, Link } from "@cloudscape-design/components";

const ProductionCompanies = ({ item }) => {
    return (
        <SpaceBetween direction="horizontal" size="xxxs">
            
            {item.map(
                (company, index) => (
                    <Link
                        href={`/item/companies-${company?.id}`}
                        variant="primary"
                        key={index}
                    >
                        <button
                            type="button"
                            className="rounded-md bg-red-400/10 px-2.5 py-1 text-xs text-red-400 shadow-sm hover:bg-red-500 hover:text-white ring-1 ring-inset ring-red-400/50"
                        >
                            {company.name}
                        </button>
                    </Link>
                )
            )}
        </SpaceBetween>
    )
};

export default ProductionCompanies;