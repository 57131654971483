import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import renderTrend from "../../../../utils/trendIcon";

export const useGridConfig = () => {

    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            flex: 1,
            suppressHeaderMenuButton: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }),
        [],
    );

    const tooltipRenderer = (params) => {
        const adjustedIndex = 30 - params.xValue;
        const date = moment().subtract(adjustedIndex, "days").format("YYYY-MM-DD");
        return `<div class="bg-white p-2">
              <div class='bg-slate-300 p-1'>${date}</div>
              <hr/>
              <div>
                <div>Votes: ${shortenInteger(params.yValue)}</div>
              </div>
          </div>`;
    }

    const columnDefs = useMemo(() => {
        const allColumns = [
            {
                field: "daily_rank_change",
                headerName: "Daily Rank Change",
                filter: "agNumberColumnFilter",
                hide: true
            },
            {
                headerName: "Rank ",
                maxWidth: 120,
                cellDataType: "number",
                minWidth: 120,
                cellRenderer: (params) => renderTrend(params, "daily_rank", "daily_rank_change", "daily_trend"),
                valueGetter: (params) => parseInt(params.data.daily_rank),
                valueFormatter: (params) => `${params.value}`,
            },
            {
                field: "ip",
                headerName: "Title",
                cellDataType: "text",
                minWidth: 270,
                cellRenderer: (params) => {
                    const releaseYear = new Date(params.data.release_date).getFullYear();
                    const ipId = params.data.ip_id;
                    const destination = ipId
                        ? `/item/${ipId}`
                        : undefined;

                    let iconVariant;
                    if (params.data.tracked === true) {
                        iconVariant = "success";
                    } else if (params.data.tracked === false) {
                        iconVariant = "disabled";
                    } else {
                        iconVariant = "disabled";
                    }
                    return (
                        <div className="flex gap-2 mt-1.5">
                            <Icon name={params.data.tracked != null ? "status-positive" : "status-negative"} variant={iconVariant} />
                            <Link color="inverted" variant="secondary" href={destination} onClick={(e) => !ipId && e.preventDefault()} > {params.value} </Link><span className="text-xs text-slate-500 mt-1">({releaseYear})</span>
                        </div>
                    );
                },
            },
            {
                field: "daily_votes",
                headerName: "Daily Votes",
                minWidth: 100,
            },
            {
                headerName: "All Time Rank",
                cellDataType: "number",
                minWidth: 120,
                cellRenderer: (params) => renderTrend(params, 'rank', 'rank_change', 'trend'),
                valueGetter: (params) => parseInt(params.data.rank),
                valueFormatter: (params) => `${params.value}`,
            },
            {
                field: "votes",
                headerName: "IMDb Votes",
                minWidth: 100,
                cellRenderer: (params) => shortenInteger(params.value),
            },
            {
                field: "rating",
                headerName: "IMDb Rating",
                minWidth: 100,
            },
            {
                field: "weekly_votes",
                headerName: "Weekly Votes",
                minWidth: 100,
                cellDataType: "number",
                cellRenderer: (params) => shortenInteger(params.value),
            },
            {
                field: "weekly_rating",
                headerName: "Weekly Rating Change",
                minWidth: 100,
                cellDataType: "number",
                cellRenderer: (params) => {
                    return params.value === 0 ? 0 : params.value.toFixed(2);
                }
            },
            {
                field: "sparkline",
                headerName: "7-day Trend",
                maxWidth: 600,
                minWidth: 200,
                cellRenderer: "agSparklineCellRenderer",
                cellRendererParams: {
                    sparklineOptions: {
                        tooltip: {
                            enabled: true,
                            renderer: tooltipRenderer,
                        },
                        type: "area",
                        marker: {
                            size: 1,
                            shape: "circle",
                            fill: "white",
                        },
                        fill: "#0972d3",
                        highlightStyle: {
                            fill: "#033160",
                        },
                    },
                },
                filter: false,
                sortable: false,
            },
        ];
        return allColumns;
    }, []);

    const onGridReady = useCallback((params) => {
        const advancedFilterElement = document.getElementById(
            "advancedFilterParent",
        );
        if (advancedFilterElement) {
            params.api.setGridOption("advancedFilterParent", advancedFilterElement);
            params.api.setGridOption('includeHiddenColumnsInAdvancedFilter', true);
        }
    }, []);

    const gridOptions = {
        getContextMenuItems: (params) => {
            if (params.column.colId === "title") {
                return null;
            }
            return params.defaultItems;
        },
    };

    return {
        defaultColDef,
        columnDefs,
        onGridReady,
        gridOptions,
    };
};