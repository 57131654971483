import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../layouts/common/menu/side-menu";
import IndexPageGrid from "../../components/IndexPageGrid";

function TrackedPageCards() {
  const items = [
    {
      link: "/dashboards/pantheonranking",
      image: "/images/pantheonranking.png",
      name: "Pantheon Ranking",
      path: "Tracked Titles",
      description:
        "Curated ranking based on defined platform weights",
    },
    {
      link: "/dashboards/raw-data",
      image: "/images/prankingtable.png",
      name: "Pantheon Platform Score",
      path: "Tracked Titles",
      description: "Pantheon Scores for platforms based on weights that can be customized",
    },
  ];
  const gridDefinition = items.map(() => ({ colspan: { xxs: 6, xs: 6, s:4, m: 3, xl: 2 } }));

  return (
    <IndexPageGrid items={items} gridDefinition={gridDefinition} />
  );
}
function TrackedHomePage() {
  const breadcrumbs = [{ text: "Dashboard" }, { text: "Tracked Titles" }];
  return (
    <>
      <Helmet>
        <title>Tracked Titles</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="cards"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                variant="h2"
                description="A collection of dashboards that provide insights into Tracked titles and their performance."
              >
                Tracked Titles
              </Header>
            }
          >
            <TrackedPageCards />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default TrackedHomePage;
