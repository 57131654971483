import axiosInstance from "../../../../utils/axios";

export const getBoByStudioData = () => {
    return axiosInstance.post(
        `/test/s3path?key=2023_movies_distributor&file_type=CSV`,
        { "sql": "SELECT * FROM s3object" }
    ).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Failed to fetch BoxOffice data:', error);
        throw error;
    });
};
