import React, { useEffect, useMemo, useState, useRef } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Header,
  Toggle,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Box,
  Flashbar,
} from "@cloudscape-design/components";

import { navItems } from "../../../layouts/common/menu/side-menu";

import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { getTarSeriesWikiViewGenrePlatformResponse } from "./redux/actions/tar-series-wiki-genre-platform-actions";
import { AgChartsReact } from "ag-charts-react";
import createFlashMessage from "../../../utils/createFlashMessage";
import numberWithCommas from "../../../utils/ag-utils";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const TarSeriesWikiViewGenrePlatformPage = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment()
      .subtract(12, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const chartRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [, setChartData] = useState([]);

  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [excludeOTT, setExcludeOTT] = React.useState(false);

  const tarSeriesWikiViewGenrePlatform = useSelector(
    (state) => state.tarSeriesWikiViewGenrePlatform
  );

  const [options, setOptions] = useState({});
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (rowData.length > 0) {
      setColumnDefs([
        {
          headerName: "Genre",
          field: "title",
          filter: true,
          rowGroup: true,
        },
        {
          headerName: "Platform",
          field: "platform",
        },
        {
          headerName: "Views",
          field: "pageViews",
          valueFormatter: (params) =>
            params.value ? numberWithCommas(params) : "",
        },
        {
          headerName: "Title Count",
          field: "titleCount",
        },
      ]);
    }
  }, [rowData]);

  useEffect(() => {
    if (dateRange.startDate) {
      getTarSeriesWikiViewGenrePlatformResponse({
        gte: moment(dateRange.startDate).format("YYYYMMDD"),
        lte: moment(dateRange.endDate).format("YYYYMMDD"),
        excludeOTT,
      });
    }
  }, [dateRange, excludeOTT]);

  useEffect(() => {
    if (
      tarSeriesWikiViewGenrePlatform.completed &&
      tarSeriesWikiViewGenrePlatform.hasError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: tarSeriesWikiViewGenrePlatform.message,
        })
      );
    }
  }, [tarSeriesWikiViewGenrePlatform]);

  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Peacock Dashboard", href: "/peacock-dashboard" },
    { text: "Genre-based Wikipedia Insights", href: "/" },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    []
  );

  const colors = [
    "#FF0000", // Red
    "#FF4500", // Orange Red
    "#FF8C00", // Dark Orange
    "#FFA500", // Orange
    "#FFD700", // Gold
    "#FFFF00", // Yellow
    "#ADFF2F", // Green Yellow
    "#7CFC00", // Lawn Green
    "#00FF00", // Lime
    "#00FF7F", // Spring Green
    "#00FFFF", // Cyan
    "#1E90FF", // Dodger Blue
    "#0000FF", // Blue
    "#4B0082", // Indigo
    "#8A2BE2", // Blue Violet
    "#9932CC", // Dark Orchid
    "#8B008B", // Dark Magenta
    "#FF00FF", // Magenta
    "#FF69B4", // Hot Pink
    "#FF1493", // Deep Pink
  ];

  useEffect(() => {
    if (tarSeriesWikiViewGenrePlatform.data !== null) {
      setRowData(
        Object.entries(tarSeriesWikiViewGenrePlatform.data)
          .map(([title, { titleCount, data }]) => {
            return {
              title: title,
              titleCount: titleCount,
              data: data,
            };
          })
          .flatMap(({ title, data }) =>
            data.map((item) => ({
              ...item,
              title: title,
            }))
          )
      );
      let newChartData = [];
      let platforms = [];

      Object.entries(tarSeriesWikiViewGenrePlatform.data).forEach(
        ([genre, { data }]) => {
          let genreRow = { genre: genre };
          data.forEach((datum) => {
            genreRow[datum.platform] = datum.pageViews;
            if (!platforms.includes(datum.platform))
              platforms.push(datum.platform);
          });
          newChartData.push(genreRow);
        }
      );

      setChartData(newChartData);

      setOptions((prevOptions) => ({
        ...prevOptions,
        data: newChartData,
        series: platforms.map((platform, index) => ({
          type: "bar",
          xKey: "genre",
          yKey: platform,
          yName: platform,
          stacked: true,
          // fill: colors[index % colors.length],
          strokeWidth: 0.5,
        })),
        legend: { position: "bottom", item: { marker: { strokeWidth: 0 } } },
        theme: {
          baseTheme: "ag-default-dark",
          overrides: {
            common: {
              background: { fill: "#0F1B2A" },
              axes: {
                number: {
                  tick: {
                    width: 0.5,
                  },
                },
                category: {
                  tick: {
                    width: 0,
                  },
                },
              },
            },
            bar: {
              series: {
                highlightStyle: {
                  series: {
                    dimOpacity: 0.2,
                    strokeWidth: 1,
                  },
                },
              },
            },
          },
        },
        axes: [
          {
            type: "category",
            position: "bottom",
          },
          {
            type: "number",
            position: "left",
            label: {
              // Add this to your axis definition
              formatter: (params) => {
                if (Math.abs(params.value) >= 1.0e9) {
                  return (Math.abs(params.value) / 1.0e9).toFixed(0) + "B";
                } else if (Math.abs(params.value) >= 1.0e6) {
                  return (Math.abs(params.value) / 1.0e6).toFixed(0) + "M";
                } else if (Math.abs(params.value) >= 1.0e3) {
                  return (Math.abs(params.value) / 1.0e3).toFixed(0) + "K";
                }
                return params.value;
              },
            },
          },
        ],
      }));
    }
  }, [tarSeriesWikiViewGenrePlatform]);

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>

      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(12, "months")
                                    .startOf("month")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(12, "months")
                                  .startOf("month")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                      />
                      <Toggle
                        checked={excludeOTT}
                        onChange={() => setExcludeOTT(!excludeOTT)}
                      >
                        Exclude OTT Originals
                      </Toggle>
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="pr-5">
                      Platform Influence on Genre Popularity
                    </span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>

                <CustomFlashBar />
              </>
            }
          >
            <Container>
              <Box>
                {tarSeriesWikiViewGenrePlatform.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : rowData.length > 0 ? (
                  <SpaceBetween direction="vertical" size="xxl">
                    <>
                      <div
                        className="ag-theme-quartz-dark"
                        style={{
                          height: "330px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <AgGridReact
                          rowData={rowData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowSelection="single"
                        />
                      </div>
                      <div style={{ height: "900px" }}>
                        <AgChartsReact options={options} />
                      </div>
                    </>
                  </SpaceBetween>
                ) : null}
              </Box>
            </Container>
          </ContentLayout>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TarSeriesWikiViewGenrePlatformPage;
