import React, { useEffect } from "react";
import { fetchItemAuditData } from "../redux/actions/item-actions";
import { useSelector } from "react-redux";
import {
  Table,
  Popover,
  ColumnLayout,
  SpaceBetween,
  Header,
  StatusIndicator,
  Spinner,
  Box,
} from "@cloudscape-design/components";

function AuditBox({ ip_id }) {
  const itemAuditData = useSelector(
    (state) => state?.itemAuditData?.data || []
  );
  const isLoading = useSelector((state) => state?.itemAuditData?.loading);

  useEffect(() => {
    if (itemAuditData.length <= 0) {
      fetchItemAuditData({ ip_id });
    }
  }, [ip_id]);

  return (
    <>
      {isLoading ? (
        <Box textAlign="center" padding="l">
          <Spinner size="large" />
        </Box>
      ) : (
        <Table
          empty={
            <Box textAlign="center" padding="l">
              No audit data available.
            </Box>
          }
          variant="borderless"
          columnDefinitions={[
            {
              id: "timestamp",
              header: "Timestamp",
              cell: (item) =>
                new Date(item.timestamp * 1000).toLocaleString("en-US", {}),
              minWidth: 200,
              maxWidth: 250,
            },
            {
              id: "event_type",
              header: "Event",
              cell: (item) => item.event_type,
            },
            {
              id: "username",
              header: "Username",
              cell: (item) => item.username,
            },
            {
              id: "user_role",
              header: "User role",
              cell: (item) => item.user_role,
            },
            {
              id: "changes",
              header: "Changes",
              minWidth: 100,
              maxWidth: 100,
              cell: (item) => {
                const { event_details } = item;
                const currentState = event_details?.current_state
                  ? JSON.stringify(event_details.current_state, null, 2)
                  : "N/A";
                const previousState = event_details?.previous_state
                  ? JSON.stringify(event_details.previous_state, null, 2)
                  : "N/A";

                return (
                  <Popover
                    fixedWidth
                    size="large"
                    content={
                      <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                          <strong>Current State:</strong>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                              padding: "1rem",
                              backgroundColor: "black",
                              borderRadius: "0.25rem",
                              overflowX: "auto",
                            }}
                          >
                            {currentState}
                          </pre>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                          <strong>Previous State:</strong>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-word",
                              padding: "1rem",
                              backgroundColor: "black",
                              borderRadius: "0.25rem",
                              overflowX: "auto",
                            }}
                          >
                            {previousState}
                          </pre>
                        </SpaceBetween>
                      </ColumnLayout>
                    }
                  >
                    <StatusIndicator type="info">Info</StatusIndicator>
                  </Popover>
                );
              },
            },
          ]}
          items={itemAuditData}
        />
      )}
    </>
  );
}

export default AuditBox;
