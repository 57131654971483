import {
  Alert,
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  FormField,
  Header,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import shortenInteger from "../../../utils/shortenInteger";
import { Layout } from "../Layout";

export const BoxOfficeRankingPage = () => {
  const gridRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const startYear = 1980;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent"
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
    }
  }, []);

  const [yearOption, setYearOption] = React.useState({
    label: "2024",
    value: "2024",
  });
  const [boxOfficeRanking, setBoxOfficeRanking] = useState({
    data: [],
    status: "idle",
  });
  const [error, setError] = useState(null);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [

        {
          statusPanel: 'agAggregationComponent',
          align: 'right',
          statusPanelParams: {
            aggFuncs: ['sum', 'min', 'max', 'avg']
          },
        },
      ],
    };
  }, []);

  const formatter = (params) => {
    const { yValue } = params;
    return {
      fill:
        yValue < 0
          ? "#b30406"
          : yValue < 30
            ? "#F57C00"
            : yValue < 60
              ? "#036c99"
              : "#0f5600",
    };
  };

  const [columnDefs] = useState([
    {
      headerName: "#",
      maxWidth: 70,
      minWidth: 70,
      cellRenderer: (params) => {
        return params.rowIndex + 1;
      },
      sortable: false,
      filter: false,
    },
    {
      field: "ip",
      headerName: "Title",
      minWidth: 150,
      cellRenderer: (params) => {
        if (!params.value) return null;

        const ipId = params.data.ip_id;
        const destination = ipId
          ? `${window.location.origin}/item/${ipId}`
          : undefined;
        const style = ipId ? {} : { color: "grey", cursor: "default" };

        return (
          <a
            href={destination}
            style={style}
            onClick={(e) => !ipId && e.preventDefault()}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      field: "release_date",
      headerName: "Release Date",
      cellRenderer: (params) => {
        if (params.data && params.data.release_date && moment(params.data.release_date).isValid()) {
          return moment(params.data.release_date).format("MMM DD, YYYY");
        } else {
          return "Unknown";
        }
      },
      valueGetter: (params) => {
        if (params.data && params.data.release_date && moment(params.data.release_date).isValid()) {
          return moment(params.data.release_date).format("YYYY");
        } else {
          return "";
        }
      },
      maxWidth: 150,
      minWidth: 100,
    },


    {
      field: "budget",
      headerName: "Production Budget",
      cellRenderer: (params) => "$" + shortenInteger(params.value),
      cellStyle: {
        textAlign: "right",
      },
      maxWidth: 120,
      minWidth: 100,
    },
    {
      field: "xdomGrossToDate",
      headerName: "Domestic",
      headerTooltip: "Domestic Gross (Rank)",
      cellDataType: "number",
      valueGetter: (params) => {
        if (!params.data) return 0;
        return params.data.xdomGrossToDate === "N/A" ? 0 : parseInt(params.data.xdomGrossToDate, 10);
      },
      cellRenderer: (params) => {
        if (!params.data || params.data.xdomRank === undefined) {
          return '';
        }
        const grossFormatted = shortenInteger(params.value);
        const rank = params.data.xdomRank || null;
        return `$${grossFormatted} (# ${rank})`;
      },
      maxWidth: 150,
      minWidth: 120,
    },
    {
      field: "xndomGrossToDate",
      headerName: "International",
      headerTooltip: "International Gross (Rank)",
      cellDataType: "number",
      valueGetter: (params) => {
        if (!params.data) return 0;
        return params.data.xndomGrossToDate === "N/A" ? 0 : parseInt(params.data.xndomGrossToDate, 10);
      },
      cellRenderer: (params) => {
        if (!params.data || params.data.xndomRank === undefined) {
          return '';
        }
        const grossFormatted = shortenInteger(params.value);
        const rank = params.data.xndomRank || null;
        return `$${grossFormatted} (# ${rank})`;
      },
      maxWidth: 150,
      minWidth: 120,
    },
    {
      field: "xwwGrossToDate",
      headerName: "Worldwide",
      headerTooltip: "Worldwide Gross (Rank)",
      cellDataType: "number",
      valueGetter: (params) => {
        if (!params.data) return 0;
        return params.data.xwwGrossToDate === "N/A" ? 0 : parseInt(params.data.xwwGrossToDate, 10);
      },
      cellRenderer: (params) => {
        if (!params.data || params.data.xwwRank === undefined) {
          return '';
        }
        const grossFormatted = shortenInteger(params.value);
        const rank = params.data.xwwRank || "N/A";
        return `$${grossFormatted} (#${rank})`;
      },
      maxWidth: 150,
      minWidth: 120,
      sort: "desc",
    },

    {
      field: "profit_margin",
      headerName: "Profit Margin",
      minWidth: 150,
      cellDataType: "number",
      headerTooltip:
        "Percentage of the movie's earnings in profit after paying back its budget",
      cellRenderer: "agSparklineCellRenderer",
      cellRendererParams: {
        sparklineOptions: {
          type: "bar",
          valueAxisDomain: [0, 100],
          paddingOuter: 0,
          padding: {
            top: 5,
            bottom: 5,
          },
          axis: {
            strokeWidth: 0,
          },
          label: {
            enabled: true,
            color: "white",
            placement: "center",
            fontSize: 12,
            formatter: (params) => {
              return `${params.value}%`;
            },
          },
          formatter: formatter,
        },
      },
      valueGetter: (params) => {
        if (!params.data) return 0;
        return [parseInt(params.data.profit_margin)];
      },
      valueFormatter: (params) => (params.value ? `${params.value[0]}` : ""),
      comparator: (valueA, valueB) => {
        if (isNaN(valueA)) {
          return 1;
        }
        if (isNaN(valueB)) {
          return -1;
        }
        return valueA - valueB;
      },
    },
  ]);

  const fetchBoxOfficeRankingData = async ({ startDate, endDate }) => {
    setIsLoading(true);
    setError(null);
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay();
    }
    try {
      const response = await axiosInstance.request({
        url: `/boxoffice/yearly?startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Box Office Ranking");
      }

      let itemCount = response.data.length;

      let data = response.data
        .filter(
          (item) =>
            Array.isArray(item.grossToDate) &&
            item.grossToDate.some(
              (gross) => gross.area === "XWW" && gross.grossToDate
            )
        )
        .map((item) => {
          const grossToDate = item.grossToDate;

          const xdomData =
            grossToDate.find((gross) => gross.area === "XDOM") || {};
          const xndomData =
            grossToDate.find((gross) => gross.area === "XNDOM") || {};
          const xwwData =
            grossToDate.find((gross) => gross.area === "XWW") || {};

          return {
            ...item,
            xdomRank: xdomData.rank || "N/A",
            xdomGrossToDate: xdomData.grossToDate || "N/A",
            xndomRank: xndomData.rank || "N/A",
            xndomGrossToDate: xndomData.grossToDate || "N/A",
            xwwRank: xwwData.rank || "N/A",
            xwwGrossToDate: xwwData.grossToDate || "N/A",
          };
        });

      let domesticTotal = data.reduce((acc, item) => acc + (Number(item.xdomGrossToDate) || 0), 0);
      let internationalTotal = data.reduce((acc, item) => acc + (Number(item.xndomGrossToDate) || 0), 0);
      let worldwideTotal = data.reduce((acc, item) => acc + (Number(item.xwwGrossToDate) || 0), 0);
      setBoxOfficeRanking({ data, status: "loaded", itemCount, domesticTotal, internationalTotal, worldwideTotal });
    } catch (error) {
      setError(error.message);
      setBoxOfficeRanking((prevState) => ({ ...prevState, status: "error" }));
    } finally {
      setIsLoading(false);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.showLoadingOverlay();
      }
    }
  };

  useEffect(() => {
    if (yearOption.value === "all") {
      fetchBoxOfficeRankingData({
        startDate: "1980-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    } else {
      fetchBoxOfficeRankingData({
        startDate: `${yearOption.value}-01-01`,
        endDate: `${yearOption.value}-12-31`,
      });
    }
  }, [yearOption]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  );

  return (
    <Layout
      title="Box Office Ranking"
      breadcrumbs={[{ text: "Dashboard" },{ text: "Box Office", href:"/box-office"}, { text: "Box Office Ranking" }]}
      navItems={navItems}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="Box Office Rankings offers a definitive glance at the fiscal success of film releases throughout the year."
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <FormField description="Filter titles from the table">
                    <TextFilter
                      filteringText={filterText}
                      filteringPlaceholder="Search title"
                      filteringAriaLabel="Filter title"
                      onChange={({ detail }) => setFilterText(detail.filteringText)}
                    />
                  </FormField>
                  <FormField description="Release year">
                    <Select
                      filteringType="auto"
                      options={[
                        // { label: "All", value: "all" },
                        ...years
                          .slice()
                          .sort()
                          .reverse()
                          .map((y) => ({ label: y, value: y })),
                      ]}
                      onChange={({ detail }) => {
                        setYearOption(detail.selectedOption);
                      }}
                      selectedOption={yearOption}
                    />
                  </FormField>
                </SpaceBetween>
              }
            >
              Box Office Ranking
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <Container
              disableContentPaddings>
              <div className="pt-2 pb-1">
                <ColumnLayout columns={4} variant="text-grid">
                  <div>
                    <Box variant="awsui-key-label" textAlign="center">Movies</Box>
                    <Box variant="h1" color="text-status-info" textAlign="center">{shortenInteger(boxOfficeRanking.itemCount)}</Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label" textAlign="center">Domestic</Box>
                    <Box variant="h1" color="text-status-info" textAlign="center">{shortenInteger(boxOfficeRanking.domesticTotal)}</Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label" textAlign="center">International </Box>
                    <Box variant="h1" color="text-status-info" textAlign="center">{shortenInteger(boxOfficeRanking.internationalTotal)}</Box>
                  </div>
                  <div>
                    <Box variant="awsui-key-label" textAlign="center">Worldwide</Box>
                    <Box variant="h1" color="text-status-info" textAlign="center">{shortenInteger(boxOfficeRanking.worldwideTotal)}</Box>
                  </div>
                </ColumnLayout>
              </div>
            </Container>
            {/* <div id="advancedFilterParent"></div> */}
            {error !== null ? (
              <Alert type="error" header="Error loading data">
                {error}
              </Alert>
            ) : (

              <div style={{ height: '75vh' }} className="ag-theme-quartz-dark">
                <AgGridReact
                  ref={gridRef}
                  quickFilterText={filterText}
                  rowData={boxOfficeRanking.data}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  loadingOverlayComponent={CustomLoadingOverlay}
                  noRowsOverlayComponent={CustomLoadingOverlay}
                  suppressRowClickSelection={true}
                  suppressDragLeaveHidesColumns={true}
                  enableAdvancedFilter={true}
                  tooltipShowDelay={100}
                  enableRangeSelection={true}
                  suppressAggFuncInHeader={true}
                  statusBar={statusBar}
                  onGridReady={onGridReady}
                />
              </div>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="dashboard"
    />
  );
};
