import axiosInstance from "../../../../utils/axios";

export interface PantheonRankingResponseItem {
  ip: string;
  certificates: string;
  image_url: string;
  demand_score: number;
  engagement_score: number;
  pantheon_score: number;
  imdb_vote: number;
  release_date: number;
  ip_id: number;
  imdb_rating: number;
  isTheatrical: boolean;
  genre: Array<string>;
  distributors: Array<string>;
  production: Array<string>;
  franchise: Array<string>;
}

export type PantheonRankingResponse = Array<PantheonRankingResponseItem>;

export const getPantheonRankingData = ({
  vertical = "movies",
  limit = 100,
  offset = 0,
  sort = "search_score",
  timeSpan = "29April2024-05May2024",
}) =>
  axiosInstance.request({
    url: `ranking/daily/pantheon_weekly_global/v2?ts=${timeSpan}&offset=${offset}&limit=${limit}&sort=${sort}&vertical=${vertical}`,
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
    },
  });
