import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  SpaceBetween,
  Spinner,
  Container,
  Select,
  Multiselect,
  Icon,
  FormField,
  TextFilter,
  Header,
  Link,
} from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import {
  IncreaseIcon,
  DecreaseIcon,
  NeutralIcon,
  NewIcon,
} from "../../../components/Icons";
import axiosInstance from "../../../utils/axios";
import TableLegend from "../components/TableLegend";

function JWStreamingChartsPage() {
  const gridRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [JWSC, setJWSC] = useState({
    data: [],
    status: "idle",
  });
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    label: "Series",
    value: "SHOW",
  });
  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent"
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
    }
  }, []);

  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const checkboxes = [
    { label: "IMDb Score", field: "imdbScore" },
    { label: "IMDb Votes", field: "imdbVotes" },
    { label: "RT Critic Score", field: "rt_critic_score" },
    { label: "RT Fan Score", field: "rt_fan_score" },
    { label: "TMDB Score", field: "tmdbScore" },
    { label: "JustWatch Rating", field: "jwRating" },
  ];

  const [columnVisibility, setColumnVisibility] = useState({
    imdbScore: false,
    imdbVotes: false,
    rt_critic_score: false,
    rt_fan_score: false,
    tmdbScore: false,
    jwRating: false,
  });

  const initialSelectedOptions = checkboxes.filter(({ field }) => columnVisibility[field]).map(({ label, field }) => ({
    label,
    value: field,
  }));

  useEffect(() => {
    setSelectedColumns(initialSelectedOptions);
  }, []);

  const handleMultiselectChange = ({ detail }) => {
    const { selectedOptions } = detail;
    setSelectedColumns(selectedOptions);
    const newColumnVisibility = {};
    checkboxes.forEach(({ field }) => {
      newColumnVisibility[field] = selectedOptions.some(option => option.value === field);
    });
    setColumnVisibility(newColumnVisibility);
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Rank ",
        maxWidth: 120,
        cellDataType: "number",
        minWidth: 120,
        sortable: false,
        cellRenderer: (params) => {
          const rank = params.data.rank;
          const trendchange = params.data.trendDifference;
          const trend = params.data.trend;
          let trendIcon;
          if (trend === "UP") {
            trendIcon = <Icon svg={<IncreaseIcon />} variant="success" />;
          } else if (trend === "DOWN") {
            trendIcon = <Icon svg={<DecreaseIcon />} variant="error" />;
          } else if (trend === "STABLE") {
            trendIcon = <Icon svg={<NeutralIcon />} variant="subtle" />;
          } else if (trend === "NEW") {
            trendIcon = <Icon svg={<NewIcon />} variant="success" />;
          } else {
            trendIcon = null;
          }
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{rank}</span>
              <span
                className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 "
                style={{ width: "50px" }}
              >
                {trendIcon}
                <span style={{ width: "3px" }}></span>
                {trendchange}
              </span>
            </div>
          );
        },
        valueGetter: (params) => parseInt(params.data.rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "title",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          const destination = ipId
            ? `${window.location.origin}/item/${ipId}`
            : undefined;
          const style = ipId ? {} : { color: "grey", cursor: "default" };

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon name={params.data.tracked != null ? "status-positive" : "status-negative"} variant={iconVariant} />
              <Link color="inverted" variant="secondary" href={destination} onClick={(e) => !ipId && e.preventDefault()} > {params.value} </Link>
            </div>
          );
        },
      },
      {
        field: "topRank",
        headerName: "Highest Rank",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "daysInTop3",
        headerName: "Days in Top 3",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "daysInTop10",
        headerName: "Days in Top 10",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "daysInTop100",
        headerName: "Days in Top 100",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "daysInTop1000",
        headerName: "Days in Top 1000",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "imdbScore",
        headerName: "IMDb Score",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "imdbVotes",
        headerName: "IMDb Votes",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "rt_critic_score",
        headerName: "RT Critic Score",
        headerTooltip: "Rotten Tomatoes Critic Score",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "rt_fan_score",
        headerName: "RT Fan Score",
        headerTooltip: "Rotten Tomatoes Fan Score",
        maxWidth: 120,
        minWidth: 100,
      },
      {
        field: "tmdbScore",
        headerName: "TMDB Score",
        maxWidth: 120,
        minWidth: 100,
        cellRenderer: (params) => {
          if (typeof params.value === "number") {
            return Number(params.value.toFixed(1));
          }
          return "";
        },
      },
      {
        field: "jwRating",
        headerName: "JW Rating",
        headerTooltip: "JustWatch Rating",
        maxWidth: 120,
        minWidth: 100,
        cellRenderer: (params) => {
          if (typeof params.value === "number") {
            return Number(params.value.toFixed(3));
          }
          return "";
        },
      },
      {
        field: "providers",
        headerName: "Providers",
        minWidth: 350,
        maxWidth: 550,
        cellRenderer: (params) => {
          if (!params.value || !Array.isArray(params.value)) return null;

          return (
            (<div className="flex flex-wrap gap-2">
              {params.value.map((provider) => {
                const formattedProvider = provider
                  .replace(/\s+/g, "")
                  .replace(/\+/g, "_")
                  .trim();

                const iconUrl = `https://images.searchpantheon.com/serviceProviderIcons/${formattedProvider}.webp`;

                return (
                  <div
                    title={provider}
                    key={provider}
                    style={{
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  >
                    <img
                      src={iconUrl}
                      alt={`${provider} Icon`}
                      className="rounded-lg"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </div>
                );
              })}
            </div>)
          );
        },
      },
    ];
    return allColumns.filter(
      (colDef) => columnVisibility[colDef.field] !== false
    );
  }, [columnVisibility]);

  const fetchJWSCData = async (vertical = "SHOW") => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        url: `/jwsc?vertical=${vertical}`,
        method: "GET",
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.map((item) => {
        if (typeof item.tracked === "string") {
          item.tracked = item.tracked.toLowerCase() === "true";
        }
        return {
          ...item,
        };
      });
      const latestUpdatedAt = data.length > 0 ? data[0].updatedAt : null;

      setJWSC({ data, status: "loaded", latestUpdatedAt });
    } catch (error) {
      setError(error.message);
      setJWSC((prevState) => ({ ...prevState, status: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJWSCData(selectedOption.value);
  }, [selectedOption.value]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  const gridOptions = {
    getContextMenuItems: (params) => {
      if (params.column.colId === "title") {
        return null;
      }
      return params.defaultItems;
    },
  };

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Container
        header={
          <Header
            actions={
              <div>
                <div className="text-sm font-semibold text-gray-100">
                  Last Updated:{" "}
                  {JWSC.latestUpdatedAt
                    ? new Date(JWSC.latestUpdatedAt).toLocaleString()
                    : "Not available"}
                </div>
                <div className="text-xs font-thin text-gray-400">
                  Based on data from the last 7 days
                </div>
              </div>
            }
          >
            <div className="flex justify-between w-full">
              <SpaceBetween direction="horizontal" size="l">
                <FormField description="Filter titles from the table">
                  <TextFilter
                    filteringText={filterText}
                    filteringPlaceholder="Search title"
                    filteringAriaLabel="Filter title"
                    onChange={({ detail }) =>
                      setFilterText(detail.filteringText)
                    }
                  />
                </FormField>
                <FormField description="Categories">
                  <Select
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                      setSelectedOption(detail.selectedOption)
                    }
                    options={[
                      { label: "Movies", value: "MOVIE" },
                      { label: "Series", value: "SHOW" },
                    ]}
                  />
                </FormField>
                <FormField description="Additional columns for display">
                  <Multiselect
                    hideTokens
                    selectedOptions={selectedColumns}
                    onChange={handleMultiselectChange}
                    options={checkboxes.map(({ label, field }) => ({
                      label,
                      value: field,
                    }))}
                    placeholder="Select columns"
                  />
                </FormField>
              </SpaceBetween>
            </div>
          </Header>
        }
      >

        <div className="pt-2" id="advancedFilterParent"></div>
      </Container>

      {isLoading ? (
        <div className="flex flex-row justify-center items-center">
          <Spinner size="large" />
        </div>
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <div style={{ height: "70vh" }} className="ag-theme-quartz-dark">
            <AgGridReact
              ref={gridRef}
              quickFilterText={filterText}
              rowData={JWSC.data}
              rowHeight={35}
              paginationPageSize={100}
              pagination={true}
              defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              enableAdvancedFilter={true}
              tooltipShowDelay={100}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </div>
          <TableLegend />
        </SpaceBetween>
      )}
    </SpaceBetween>
  );
}

export default JWStreamingChartsPage;
