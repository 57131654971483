import { Box, Icon, Link } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../../../utils/axios";
import renderTrend from "../../../../../utils/trendIcon";
import shortenInteger from "../../../../../utils/shortenInteger";

export const wikiSeries = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "Wikipedia Series Page Views",
    description: "Daily Wikipedia PageView stats",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
        {wikiSeries.data.title}
    </Box>
  );
}

function AccountAttributesFooter() {
  return (
    <Box>
      <Link
        fontSize="inherit"
        href="/dashboards/wikipediatrends?category=Series"
        variant="secondary"
      >
        View All
      </Link>
    </Box>
  );
}

function AccountAttributesContent() {
  const [WikiTC, setWikiTC] = useState({ data: [], status: "idle" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maxWeeklySum, setMaxWeeklySum] = useState(0);

  const fetchWikiTCData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        url: `/ranking/daily/wiki_global?vertical=Television&size=10`,
        method: "GET",
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.data.map((item) => {
        return {
          ...item,
          weekly_sum: [item.weekly_sum], 
        };
      });
      setWikiTC({ data, status: "loaded" });

      const maxSum = Math.max(...data.map(item => item.weekly_sum[0])); // Assuming weekly_sum is now an array
      setMaxWeeklySum(maxSum);

    } catch (error) {
      setError(error.message);
      setWikiTC((prevState) => ({ ...prevState, status: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Rank ",
        maxWidth: 80,
        cellDataType: "number",
        minWidth: 80,
        sortable: true,
        cellRenderer: (params) => renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend"),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.data.daily_rank}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span> {params.data.ip}</span>
            </Link>
          );
        },
      },
      {
        field: "weekly_sum",
        headerName: "last 7 days",
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            label: {
              enabled: true,
              placement: 'insideBase',
              color: 'white',
              fontSize: 10,
              fontWeight: 'bold',
              formatter: (params) => shortenInteger(params.value),
            },
            fill: '#0972d3',
            strokeWidth: 0,
            highlightStyle: {
              fill: '#033160',
              strokeWidth: 0,
            },
            valueAxisDomain: [0, maxWeeklySum],
            paddingOuter: 0,
          },
        },
      },
    ];
    return allColumns;
  }, [maxWeeklySum]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  useEffect(() => {
    fetchWikiTCData();
  }, []);

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className="ag-theme-quartz-dark compact" style={{height: '99.5%'}}>
        <AgGridReact
          rowData={WikiTC.data}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
