const RottenTomatesAudienceScore = ({ rtFanSummary }) => {
    if (!rtFanSummary || rtFanSummary.score == null) {
        return (
            <div>
                <div className="pb-1 flex space-x-2 items-center">
                    <div className="pl-8 text-xl text-slate-600 font-bold">- %</div>
                </div>
                <div className="text-xs text-slate-600 font-bold">AUDIENCE SCORE</div>
            </div>
        );
    }

    const isUpright = rtFanSummary.audienceRating === "Upright" || rtFanSummary.rating === "Upright";
    const isSpilled = rtFanSummary.audienceRating === "Spilled" || rtFanSummary.rating === "Spilled";

    const count = rtFanSummary.numReviews || rtFanSummary.count;

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                {isUpright && (<img src="/rticons/aud_score-fresh.svg" alt="Upright" className="h-8" />)}
                {isSpilled && (<img src="/rticons/aud_score-rotten.svg" alt="Spilled" className="h-8" />)}
                <div className="text-xl text-slate-50 font-bold"> {rtFanSummary.score}% </div>
            </div>
            <div className="text-xs font-bold">AUDIENCE SCORE</div>
            {count && (
                <div className="text-xs italic text-slate-400">
                    {parseInt(count, 10).toLocaleString()} ratings
                </div>
            )}
        </div>
    );
};

export default RottenTomatesAudienceScore;