import React, { useState, useMemo, useEffect } from "react";
import { Box, Link } from "@cloudscape-design/components";
import axiosInstance from "../../../../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import renderTrend from "../../../../../utils/trendIcon";
import shortenInteger from "../../../../../utils/shortenInteger";

export const twitchBroadcasted = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "Most Broadcasted Games in Twitch",
    description: "Daily Twitch broadcasted games leaderboard",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
      {twitchBroadcasted.data.title}
    </Box>
  );
}

function AccountAttributesFooter() {
  return (
    <Box>
      <Link
        fontSize="inherit"
        href="/dashboards/twitchtrends"
        variant="secondary"
      >
        View All
      </Link>
    </Box>
  );
}

function AccountAttributesContent() {
  const [twitchTC, setTwitchTC] = useState({ data: [], status: "idle" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTwitchTCData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "ranking/daily/global_twitch/v1?size=10&after=0",
        {
          "_source": [ "ip", "ip_id", "peak_broadcasters", "average_broadcasters" ],
          "sort": [ { "peak_broadcasters": "desc" } ],
          "include": [],
          "exclude": [],
          "condition": "and"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.data;
      setTwitchTC({ data, status: "loaded" });

    }
    catch (error) {
      setError(error.message);
      setTwitchTC((prevState) => ({ ...prevState, status: "error" }));
    }
    finally {
      setIsLoading(false);
    }
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "rank",
        headerName: "#",
        cellDataType: "number",
        minWidth: 40,
        maxWidth: 40,
        sortable: true,
        valueGetter: (params) => params.node.rowIndex + 1,
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span> {params.data.ip}</span>
            </Link>
          );
        },
      },
      {
        field: "peak_broadcasters",
        headerName: "Peak Broadcasters",
        cellDataType: "number",
        minWidth: 100,
        maxWidth: 150,
        sortable: true,
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "average_broadcasters",
        headerName: "Avg Broadcasters",
        cellDataType: "number",
        minWidth: 100,
        maxWidth: 170,
        sortable: true,
        valueFormatter: (params) => shortenInteger(params.value),
      },
    ];
    return allColumns;
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  useEffect(() => {
    fetchTwitchTCData();
  }, []);

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className="ag-theme-quartz-dark compact" style={{ height: '99.5%' }}>
        <AgGridReact
          rowData={twitchTC.data}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
