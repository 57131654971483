import axiosInstance from "../../../../utils/axios";

export const getPiracyData = async (vertical: string = "Movies") =>
  axiosInstance.request({
    url: `/ranking/daily/piracy_ranker/v1?vertical=${vertical}&size=200`,
    method: "POST",
    data: {
      "sort": [
        { "timestamp": "desc" },
        { "vertical_rank_7days": "asc" }
      ],
      "include": [
        { "range": { "vertical_rank_7days": { "gt": 0 } } }
      ],
      "exclude": [],
      "condition": "and"
    }
  });
