export const lineTheme = {
    baseTheme: "ag-default-dark",
    overrides: {
        line: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        bar: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        common: {
            background: { fill: "#0F1B2A" },
        },
    },
};

export const colorPalette = [
    "#1C86EE", 
    "#E31A1C",
    "#008B00",
    "#6A3D9A",
    "#FF7F00",
    "#FFFFFF", 
    "#FFD700",
    "#7EC0EE", 
    "#FB9A99",
    "#90EE90",
    "#CAB2D6",
    "#FDBF6F",
    "#B3B3B3", 
    "#EEE685",
    "#B03060", 
    "#FF83FA", 
    "#FF1493", 
    "#0000FF", 
    "#36648B",
    "#00CED1", 
    "#00FF00", 
    "#8B8B00", 
    "#CDCD00",
    "#8B4500", 
    "#A52A2A",
];