import { SideNavigationProps } from "@cloudscape-design/components";
import React from "react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { GRPantheonRanking } from "./GRPantheonRanking";

const title = "Pantheon Ranking";

export const GRPantheonRankingIndex = () => {
  const breadcrumbs = [{ text: "Dashboard" },{ text: "Global Ranking", href: "/dashboards/globalranking" } ,{ text: title }];

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as ReadonlyArray<SideNavigationProps.Item>}
      content={<GRPantheonRanking />}
      contentType="cards"
    />
  );
};
