import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  Link,
  SideNavigation,
  SpaceBetween,
  Container,
  Select,
  Button,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import WeightIcon from "../../../assets/images/icons/weight.svg";
import { useAuthContext } from "../../../auth/useAuthContext";
import {
  chartThemes,
  customChartThemes,
} from "../../../components/CustomChartTheme";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { navItems } from "../../../layouts/common/menu/side-menu";
import {
  PLATFORMS,
  UserTypes,
} from "../../../services/platformPreference/constants";
import { useGetPlatformWeightages } from "../../../services/platformPreference/hooks/useGetPlatformWeightages";
import createFlashMessage from "../../../utils/createFlashMessage";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { getRankingRawData } from "../redux/actions/leaderboard-actions";
import {
  PlatformWeightageDrawer,
  defaultWeightageValues,
} from "./components/PlatformWeightageDrawer";
import { mapFormValueToRequest } from "./domain/mapFormValueToRequest";
import { mapResponseToFormValue } from "./domain/mapResponseToFormValue";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { AdvancedFilters } from "../../../components/filters/AdvancedFilters";
import { FiltersDropdown } from "../../../components/filters/FiltersDropdown";
import { isNone, isSomeOrElse } from "../../../utils/sugarUtils";

const platforms = [
  { key: "twitter", name: "X (Twitter)" },
  { key: "gtrends", name: "Google Trends" },
  { key: "wiki", name: "Wikipedia" },
  { key: "a03", name: "AO3" },
  { key: "youtube", name: "YouTube" },
  { key: "reddit", name: "Reddit" },
  { key: "fandom", name: "Fandom" },
  { key: "instagram", name: "Instagram" },
  { key: "tiktok", name: "TikTok" },
  { key: "steam", name: "Steam" },
  { key: "twitch", name: "Twitch" },
  { key: "wattpad", name: "Wattpad" },
  { key: "imdb", name: "IMDb" },
];

// We need this becuase of mismatch in backend responses
const getMappedKey = (key) => {
  switch (key) {
    case "google":
      return "gtrends";
    case "wikipedia":
      return "wiki";
    case "ao3":
      return "a03";
    default:
      return key;
  }
};

export const RankingRawDataPage = () => {
  const { user } = useAuthContext();
  const rankingRowData = useSelector((state) => state.rankingRowData);
  const [granularity] = useState({ label: "None", value: "none" });
  const [dateRange, setDateRange] = useState(null);
  const currentDate = moment().unix();
  const currentDateMinusRelativeDays = moment().subtract(7, "days").unix();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [weightageValues, setWeightageValues] = useState(
    defaultWeightageValues,
  );
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filterText, setFilterText] = useState("");
  const title = "Raw Data Table";

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  const platformWeightageProp = {
    user,
    type: UserTypes.PERSONAL,
    platform: PLATFORMS.TRACKED_RANKING,
    isDefault: true,
    service: "weightage",
    fileName: "default.json",
  };

  const { data: preferenceData, isLoading: isPreferenceDataLoading } =
    useGetPlatformWeightages(platformWeightageProp);

  useEffect(() => {
    if (!preferenceData) return;

    setWeightageValues(mapResponseToFormValue(preferenceData));
  }, [preferenceData]);

  const getPreferencesAfterDelete = (name) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.pantheonScore?.filters,
        {},
      ),
    };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingPantheonScorePreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.pantheonScore?.filters,
        {},
      ),
    };
    currentFilters[name] = { ...filter };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingPantheonScorePreferences(currentFilters);
  };

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const getExistingPantheonScorePreferences = (currentFilters) => ({
    ...globalPref,
    dashboards: {
      ...globalPref?.dashboards,
      viewersPulse: {
        ...globalPref?.dashboards?.viewersPulse,
        pantheonScore: {
          filters: { ...currentFilters },
        },
      },
    },
  });

  useEffect(() => {
    getRankingRawData({
      lte: dateRange ? moment(dateRange.endDate).valueOf() / 1000 : currentDate,
      gte: dateRange
        ? moment(dateRange.startDate).valueOf() / 1000
        : currentDateMinusRelativeDays,
      interval: granularity.value,
    });
  }, [granularity, dateRange]);

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (rankingRowData.completed && rankingRowData.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: rankingRowData.message,
          id: "rankingRowData_server_error",
        }),
      );
    }
  }, [rankingRowData]);

  const gridRef = useRef();

  const [columnDefs, setColumnDefs] = useState(null);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      filter: true,
      suppressHeaderMenuButton: true,
    };
  }, []);

  const createChildColumn = (headerName, field) => ({
    headerName,
    field,
    filter: "agNumberColumnFilter",
    cellStyle: { textAlign: "right" },
    width: 100,
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        return params.value === 0 ? 0 : Number(params.value).toFixed(3);
      }
      return null;
    },
  });

  useEffect(() => {
    if (!isPreferenceDataLoading && rankingRowData?.data?.data?.length > 0) {
      const nbcuRankRawData = rankingRowData.data.data[0];
      const keys = Object.keys(nbcuRankRawData).filter(
        (key) => key !== "ip" && key !== "ip_id" && key !== "vertical",
      );

      const selectedPlatforms = Object.keys(weightageValues).reduce(
        (acc, key) => {
          const currentObject = weightageValues[key];
          if (currentObject.isChecked) {
            acc.push(getMappedKey(key));
          }
          return acc;
        },
        [],
      );

      const createTotalScoreColumn = (key) => {
        const weights = mapFormValueToRequest(weightageValues);

        return {
          headerName: "Pantheon Score",
          field: `${key}.total`,
          minWidth: 100,
          filter: "agNumberColumnFilter",
          cellStyle: { textAlign: "right" },
          sortable: true,
          sort: "desc",
          valueGetter: (params) => {
            if (!params.data || !params.data.hasOwnProperty(key)) {
              return null;
            }

            let weightedSum = 0;
            let activeWeightSum = 0; // Sum of weights for platforms with non-zero scores
            const platformScores = params.data[key];

            platforms.forEach((platform) => {
              const platformValue = Number(platformScores[platform.key]);

              if (
                selectedPlatforms.includes(platform.key) &&
                !isNaN(platformValue) &&
                platformValue > 0
              ) {
                const weightage = weights[platform.key].weightage;
                weightedSum += platformValue * weightage;
                activeWeightSum += weightage; // Only add to the weight sum if the score is non-zero
              }
            });

            // If activeWeightSum is zero (all platform scores are zero), return null
            if (activeWeightSum === 0) return null;

            const score = weightedSum / activeWeightSum;
            return score;
          },

          valueFormatter: (params) => {
            if (params.value !== null && params.value !== undefined) {
              return params.value.toFixed(3);
            }
            return null;
          },
        };
      };

      const createFlattenStructure = (key) => {
        return platforms.reduce(
          (acc, platform) => {
            if (selectedPlatforms.includes(platform.key)) {
              acc.push(
                createChildColumn(platform.name, `${key}.${platform.key}`),
              );
            }

            return acc;
          },
          [createTotalScoreColumn(key)],
        );
      };

      const rankValueGetter = (params) => {
        if (params.node.group === true) {
          return "";
        }

        const sortedChildren = params.node.parent.childrenAfterSort.sort(
          (a, b) => a.ipnam,
        );
        const indexInSorted = sortedChildren.findIndex(
          (child) => child === params.node,
        );

        return indexInSorted + 1;
      };

      setColumnDefs([
        {
          headerName: "Category",
          field: "vertical",
          hide: true,
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "Rank",
          field: "index",
          pinned: "left",
          minWidth: 70,
          maxWidth: 70,
          valueGetter: rankValueGetter,
        },
        {
          headerName: "Title",
          field: "ip",
          minWidth: 200,
          pinned: "left",
          cellRenderer: (params) => {
            if (!params.value) return null;
            const ipId = params.data.ip_id;
            return (
              <>
                <Link href={`/item/${ipId}`}>
                  <span>{params.data.ip}</span>
                </Link>
                <span className="text-xs text-slate-500 mt-1 pl-2">
                  ({params.data?.vertical?.charAt(0)})
                </span>
              </>
            );
          },
        },
        ...keys.flatMap((key) => createFlattenStructure(key)),
      ]);
    }
  }, [rankingRowData, weightageValues, isPreferenceDataLoading]);

  if (rankingRowData.loading) {
    gridRef.current?.api?.showLoadingOverlay();
  }

  useEffect(() => {
    if (!rankingRowData.loading && rankingRowData.data?.data) {
      let data = rankingRowData.data.data;
      if (selectedCategory && selectedCategory.value) {
        data = data.filter((item) => item.vertical === selectedCategory.value);
      }
      setFilteredData(data);
    }
  }, [rankingRowData, selectedCategory]);

  const Content = (
    <div style={{ height: "80vh" }} className={"ag-theme-quartz-dark"}>
      <AgGridReact
        rowData={filteredData}
        quickFilterText={filterText}
        ref={gridRef}
        columnDefs={columnDefs}
        paginationPageSize={100}
        pagination={true}
        defaultColDef={defaultColDef}
        enableRangeSelection={true}
        rowHeight={35}
        loadingOverlayComponent={CustomLoadingOverlay}
        noRowsOverlayComponent={CustomLoadingOverlay}
        animateRows={true}
        tooltipShowDelay={500}
        enableAdvancedFilter={true}
        groupRowsSticky={true}
        groupDefaultExpanded={1}
        customChartThemes={customChartThemes}
        chartThemes={chartThemes}
        groupDisplayType={"singleColumn"}
        onGridReady={onGridReady}
        onFilterChanged={(params) => {
          const f = params?.api?.getAdvancedFilterModel();
          setFilter(f);
        }}
      ></AgGridReact>
    </div>
  );

  const breadcrumbs = [
    { text: "Dashboard" },
    {
      text: "Tracked Titles",
      href: "/dashboards/trackedtitles",
    },
    { text: title },
  ];

  const weightageDrawer = {
    id: "weightageDrawer",
    content: (
      <PlatformWeightageDrawer
        weightageValues={weightageValues}
        setWeightageValues={setWeightageValues}
      />
    ),
    trigger: {
      iconName: "Weightage",
      iconSvg: <img src={WeightIcon} alt="Weight" />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
    onResize: (size) => {},
  };

  const handleClearFilter = () =>
    gridRef.current.api.setAdvancedFilterModel(null);

  const handleFilterItemClick = (id, filters) => {
    const filter = filters[id];

    gridRef.current.api.setAdvancedFilterModel(filter);
    setSelectedFilterName(id);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        drawers={[weightageDrawer]}
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup items={breadcrumbs} ariaLabel="Breadcrumbs" />
        }
        content={
          <ContentLayout
            header={
              <Header
                variant="h3"
                description={
                  <span>
                    The Pantheon Score is calculated by aggregating data from
                    various digital platforms with each assigned a specific
                    weight as defined in the drawer. If a platform has a value
                    of zero, indicating no presence or activity, its weight is
                    not included in the final score calculation.
                  </span>
                }
              >
                {title}
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="xs">
              <Container
                header={
                  <Header
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <TextFilter
                          filteringText={filterText}
                          filteringPlaceholder="Search title"
                          filteringAriaLabel="Filter title"
                          onChange={({ detail }) => setFilterText(detail.filteringText)}
                        />
                        <FiltersDropdown
                          handleFilterItemClick={handleFilterItemClick}
                          path="dashboards.viewersPulse.pantheonScore.filters"
                        />
                        <AdvancedFilters
                          handleClearFilter={handleClearFilter}
                          selectedFilterName={selectedFilterName}
                          filter={filter}
                          getPreferencesAfterDelete={getPreferencesAfterDelete}
                          getPreferencesAfterSaveOrUpdate={
                            getPreferencesAfterSaveOrUpdate
                          }
                        />
                        <Button
                          onClick={() =>
                            gridRef.current.api.exportDataAsExcel()
                          }
                        >
                          Export
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <SpaceBetween direction="horizontal" size="xs">
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(7, "days")
                                    .startOf("day")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD",
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                      />
                      <Select
                        placeholder="Category"
                        selectedOption={selectedCategory}
                        onChange={({ detail }) =>
                          setSelectedCategory(detail.selectedOption)
                        }
                        options={[
                          { label: "All", value: "" },
                          { label: "Movies", value: "Movies" },
                          { label: "Series", value: "Television" },
                          { label: "Games", value: "Gaming" },
                        ]}
                      />
                    </SpaceBetween>
                  </Header>
                }
              >
                <div className="pt-2" id="advancedFilterParent"></div>
              </Container>
              {Content}
            </SpaceBetween>
          </ContentLayout>
        }
      />
    </>
  );
};
