import { Box, ContentLayout } from "@cloudscape-design/components";
import AppLayout from "@cloudscape-design/components/app-layout";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAuthContext } from "../../auth/useAuthContext";
import PantheonFlashbar from "../../components/PantheonFlashbar";
import CustomLoadingOverlay from "../../components/PantheonLoading";
import { navItems } from "../../layouts/common/menu/side-menu";
import axiosInstance from "../../utils/axios";
import { ItemTypes } from "../../utils/itemUtils";
import {
  baseOptions,
  trackedFandomTrendsChartOption,
  trackedInstagramTrendsChartOption,
  trackedRedditTrendsChartOption,
  trackedWikiTrendsChartOption,
} from "./chartConfig";
import ItemCompany from "./company";
import ItemFilm from "./film";
import ItemFranchise from "./franchise";
import {
  deleteFranchiseAction,
  deleteItemAction,
  fetchGoogleRealTimeData,
  fetchPiracyTrendsData,
  fetchTrackedFandomData,
  fetchTrackedGoogleTrendsData,
  fetchTrackedInstagramData,
  fetchTrackedRedditData,
  fetchTrackedWikiRealTimeData,
  fetchWikiRealTimeData,
  getInstagramHashtagsData,
  getItemData,
  getTikTokHashtagsData,
  mergeFranchiseAction,
  trackIP,
  unTrackIP,
  updateFranchiseAction,
  updateFranchiseCoverImageAction,
  updateTagAction,
} from "./redux/actions/item-actions";
import ItemTelevisionSeries from "./television-series";
import ItemVideoGame from "./video-game";

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric",
  }).format(date);
};

const groupDataByKey = (data, key) => {
  return data.reduce((acc, item) => {
    const groupKey = item[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(item);
    return acc;
  }, {});
};

const generateDateHandlers = (itemData) => {
  const ipId = itemData?.data?.ip_id;
  return {
    setSearchDate: (e) => {
      fetchTrackedWikiRealTimeData({
        ip_id: ipId,
        startDate: moment(e?.startDate).format("YYYYMMDD"),
        endDate: moment(e?.endDate).format("YYYYMMDD"),
      });
      fetchTrackedGoogleTrendsData({
        ip_id: ipId,
        startDate: moment(e?.startDate).unix(),
        endDate: moment(e?.endDate).unix(),
      });
    },
    setFandomDate: (e) => {
      fetchTrackedFandomData({
        ip_id: ipId,
        startDate: moment(e?.startDate).unix(),
        endDate: moment(e?.endDate).unix(),
      });
    },
    setRedditDate: (e) => {
      fetchTrackedRedditData({
        ip_id: ipId,
        startDate: moment(e?.startDate).unix(),
        endDate: moment(e?.endDate).unix(),
      });
    },
    setInstagramDate: (e) => {
      fetchTrackedInstagramData({
        ip_id: ipId,
        startDate: moment(e?.startDate).unix(),
        endDate: moment(e?.endDate).unix(),
      });
    },
    setPiracyDate: (e) => {
      fetchPiracyTrendsData({
        ip_id: ipId,
        startDate: moment(e?.startDate).unix(),
        endDate: moment(e?.endDate).unix(),
      });
    },
  };
};

export default function Item() {
  const itemData = useSelector((state) => state.itemData);
  const itemDataLoading = useSelector((state) => state.itemData.loading);
  const { id } = useParams();
  const flashbarRef = useRef(null);
  const { user } = useAuthContext();

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      text: "Media Catalog",
    },
    {
      text: "Explore",
      href: "/explore",
    },
  ]);

  const tiktokHashtagsData = useSelector((state) => state.tiktokHashtagsData);
  const instagramHashtagsData = useSelector(
    (state) => state.instagramHashtagsData,
  );
  const generalTrendsData = useSelector((state) => state.generalTrendsData);
  const trackedWikiTrends = useSelector((state) => state.trackedWikiTrends);
  const trackedFandomTrends = useSelector((state) => state.trackedFandomTrends);
  const trackedRedditTrends = useSelector((state) => state.trackedRedditTrends);
  const trackedInstagramTrends = useSelector(
    (state) => state.trackedInstagramTrends,
  );
  const trackedGoogleTrends = useSelector((state) => state.trackedGoogleTrends);
  const piracyTrends = useSelector((state) => state.piracyTrends);

  const [trendLabels, setTrendLabels] = useState({});
  const [trendNotes, setTrendNotes] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemProps, setItemProps] = useState({});
  const [attributeProcessing, setAttributeProcessing] = useState(false);

  const countryMapping = {
    // US: "United States",
    // GB: "United Kingdom",
    WW: "Worldwide",
    // CA: "Canada",
    // AU: "Australia",
    // DE: "Germany",
    // FR: "France",
    // IT: "Italy",
    // ES: "Spain",
    // BR: "Brazil",
    // MX: "Mexico",
    // SE: "Sweden",
    // JP: "Japan",
    // KR: "South Korea",
    // TH: "Thailand",
    // IE: "Ireland",
  };

  const piracyTrendsChartOption = useMemo(() => {
    if (piracyTrends?.data && piracyTrends.data.length > 0) {
      const groupedData = groupDataByKey(piracyTrends.data, "country");

      const newSeries = Object.keys(groupedData).map((country) => {
        return {
          type: "line",
          xKey: "timestamp",
          yKey: "downloads",
          yName: country,
          visible:
            country === "United States" ||
            country === "Canada" ||
            country === "United Kingdom",
          data: groupedData[country],
          marker: { enabled: false },
          tooltip: {
            renderer: (params) => {
              return {
                title: params.datum.country.toLocaleString(),
                content: `
									<b>Rank:</b> ${params.datum.rank}<br/>
									<b>Downloads:</b> ${params.datum.downloads.toLocaleString()}<br/>
									<b>Date:</b> ${formatDate(params.datum.timestamp / 1000)}
									`,
              };
            },
          },
        };
      });

      return {
        ...trackedWikiTrendsChartOption,
        series: newSeries,
        axes: [
          {
            type: "time",
            position: "bottom",
            label: {
              format: "%d %b %Y",
              avoidCollisions: true,
              minSpacing: 20,
              autoRotate: false,
            },
          },
          {
            type: "number",
            position: "left",
            label: {
              formatter: (params) =>
                Intl.NumberFormat("en-US", {
                  notation: "compact",
                  maximumFractionDigits: 1,
                }).format(params.value),
            },
            min: 0,
            keys: ["country", "downloads"],
          },
        ],
        legend: {
          position: "bottom",
        },
      };
    } else {
      return null;
    }
  }, [piracyTrends, trackedWikiTrendsChartOption]);

  const trackedGoogleTrendsChartOption = useMemo(() => {
    if (!trackedGoogleTrends?.data?.data?.length) return null;

    const filteredData = trackedGoogleTrends.data.data.filter(
      (record) => "WW" in record,
    );
    if (filteredData.length === 0) return null;

    const availableKeys = ["WW"];

    return {
      ...trackedWikiTrendsChartOption,
      title: {
        text: "Google Search Trends",
        fontSize: 14,
        fontFamily: "Open Sans",
      },
      series: availableKeys.map((key) => ({
        type: "line",
        xKey: "timestamp",
        yKey: key,
        yName: countryMapping[key] || key,
        visible: true,
        marker: { enabled: true },
        tooltip: {
          renderer: (params) => ({
            title: countryMapping[key] || key,
            content: `${formatDate(params.datum.timestamp)} : ${params.datum[
              key
            ].toLocaleString()}`,
          }),
        },
      })),
      axes: [
        {
          type: "category",
          position: "bottom",
          label: {
            formatter: (params) => formatDate(params.value),
            minSpacing: 20,
            autoRotate: false,
          },
        },
        {
          type: "number",
          position: "left",
          gridLine: {
            style: [{ stroke: "#192e47", lineDash: [10, 10] }],
          },
          label: {
            formatter: (params) =>
              Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1,
              }).format(params.value),
          },
          min: 0,
          tick: { minSpacing: 10 },
          keys: availableKeys,
        },
      ],
      legend: { position: "right" },
      data: filteredData,
    };
  }, [trackedGoogleTrends]);

  const setTrendLabel = (tag, label) => {
    let newTrendLabels = { ...trendLabels, [tag]: label };
    setTrendLabels(newTrendLabels);
  };
  const setTrendNote = (tag, note) => {
    let newTrendNotes = { ...trendNotes, [tag]: note };
    setTrendNotes(newTrendNotes);
  };

  useEffect(() => {
    getItemData(id, {});
  }, [id]);

  useEffect(() => {
    if (isLoaded) return;
    if (!itemData.completed) return;
    if (itemData?.data?.type?.id == ItemTypes.FRANCHISE) return;
    if (itemData?.data?.type?.id == ItemTypes.COMPANY) return;
    // To Get Trend Data from charts
    fetchPiracyTrendsData({
      ip_id: itemData?.data?.ip_id,
      startDate: "now-6M",
      endDate: "now",
    });
    if (itemData?.data?.hasOwnProperty("tracked") && itemData?.data?.tracked) {
      // fetchTrackedWikiRealTimeData({
      //   ip_id: itemData?.data?.ip_id,
      //   startDate: "now-6M",
      //   endDate: "now",
      // });
      // fetchTrackedGoogleTrendsData({
      //   ip_id: itemData?.data?.ip_id,
      //   startDate: "now-6M",
      //   endDate: "now",
      // });
      // fetchTrackedFandomData({
      //   ip_id: itemData?.data?.ip_id,
      //   startDate: "now-6M",
      //   endDate: "now",
      // });
    } else {
      let trendsPayload = {
        article: itemData?.data?.wikipedia,
      };
      if (itemData?.data?.trends) {
        trendsPayload.keyword = itemData?.data?.trends;
      }
      fetchWikiRealTimeData({
        article: itemData?.data?.wikipedia,
      });
      fetchGoogleRealTimeData(trendsPayload);
      trendsPayload.property = "youtube";
    }
    if (itemData && itemData.data && itemData.data.tracked) {
      if (itemData.data.tiktok) {
        getTikTokHashtagsData({ tags: itemData.data.tiktok.join(",") });
      }
      if (itemData.data.instagram) {
        getInstagramHashtagsData({ tags: itemData.data.instagram.join(",") });
      }
    }

    let newTrendLabels = {};
    itemData.data.tiktok?.map((x) => {
      newTrendLabels[x] = "";
    });
    setTrendLabels(newTrendLabels);
    setTrendNotes(newTrendLabels);

    setIsLoaded(true);
  }, [itemData]);

  const wikipediaChartOptions = useMemo(() => {
    if (!generalTrendsData.data) return;
    const newWikipediaChartOptions = {
      ...baseOptions,
      title: {
        text: "Wikipedia Page Views",
        fontSize: 14,
        fontFamily: "Open Sans",
      },
      series: [
        {
          type: "bar",
          xKey: "time",
          yKey: "wikipedia_pageviews",
          tooltip: {
            renderer: (params) => {
              const date = new Date(params.datum.time * 1000);
              const dateFormatter = new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              });
              return {
                title: "Wikipedia Page Views",
                content: `${dateFormatter.format(
                  date,
                )}: ${params.datum.wikipedia_pageviews.toLocaleString()} (${Math.round(
                  params.datum.wikipedia,
                )}%)`,
              };
            },
          },
        },
      ],
      data: generalTrendsData?.data?.wikipedia?.map((w) => ({
        time: w.time,
        wikipedia_pageviews: w.pageviews,
        wikipedia: w.value,
      })),
    };
    return newWikipediaChartOptions;
  }, [generalTrendsData, itemData]);

  const googleTrendsChartOptions = useMemo(() => {
    if (!generalTrendsData.data || !generalTrendsData.data.googleTrends) return;
    const release_date =
      itemData.data.release_date ||
      itemData.data.first_release_date ||
      itemData.data.first_air_date;
    const newGoogleTrendsChartOptions = {
      ...baseOptions, // Spread the base options
      title: {
        text: "Google Search Trends",
        fontSize: 14,
        fontFamily: "Open Sans",
      },
      series: [
        {
          type: "line",
          xKey: "time",
          yKey: "google_trends",
          yName: "Google Search",
          marker: { enabled: false },
          tooltip: {
            renderer: (params) => {
              const date = new Date(params.datum.time * 1000);
              const dateFormatter = new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              });
              return {
                title: "Google Search Trends",
                content: `${dateFormatter.format(date)}: ${Math.round(
                  params.datum.google_trends,
                )}%`,
              };
            },
          },
        },
      ],
      data: generalTrendsData.data.googleTrends.map((g) => ({
        time: g.time,
        google_trends: g.value,
      })),
    };
    newGoogleTrendsChartOptions.axes[0].crossLines[0].value =
      new Date(Date.parse(release_date)).getTime() / 1000;
    return newGoogleTrendsChartOptions;
  }, [generalTrendsData, itemData]);

  const trackedWikiTrendChartOption = useMemo(() => {
    if (!trackedWikiTrends.data || !trackedWikiTrends.data.data) {
      return null;
    }
    if (trackedWikiTrends?.data?.data?.length <= 0) {
      return null;
    }
    const newChartOptions = {
      ...trackedWikiTrendsChartOption,
      title: {
        text: "Wikipedia Page Views",
        fontSize: 14,
        fontFamily: "Open Sans",
      },
      data: trackedWikiTrends.data.data.map((d) => {
        return {
          ...d,
          timestamp: moment(d.timestamp, "YYYYMMDD").format("MMM DD, YYYY"),
        };
      }),
    };
    const release_date =
      itemData.data.release_date ||
      itemData.data.first_release_date ||
      itemData.data.first_air_date;
    newChartOptions.axes[0].crossLines[0].value =
      new Date(Date.parse(release_date)).getTime() / 1000;
    if (!trackedWikiTrends?.data?.data[0]) {
      return null;
    }
    const dataKeys = Object.keys(trackedWikiTrends?.data?.data[0]);
    newChartOptions.series = newChartOptions.series.filter((x) =>
      dataKeys.includes(x.yKey),
    );
    return newChartOptions;
  }, [trackedWikiTrends, itemData]);

  const trackedFandomTrendChartOption = useMemo(() => {
    if (!trackedFandomTrends.data || !trackedFandomTrends.data.data) {
      return null;
    }
    if (trackedFandomTrends?.data?.data?.length <= 0) {
      return null;
    }
    const firstDataPoint = trackedFandomTrends.data.data[0];
    const initialUsers = firstDataPoint.users;
    const initialArticles = firstDataPoint.articles;
    const initialPages = firstDataPoint.pages;
    const dataWithGrowth = trackedFandomTrends.data.data.map((d, index) => {
      return {
        ...d,
        timestamp: moment(d.timestamp * 1000).format("MMM DD, YYYY"),
        usersGrowth: ((d.users - initialUsers) / initialUsers) * 100,
        articlesGrowth:
          ((d.articles - initialArticles) / initialArticles) * 100,
        pagesGrowth: ((d.pages - initialPages) / initialPages) * 100,
      };
    });
    const newDataKeys = Object.keys(dataWithGrowth[0]);
    const newChartOptions = {
      ...trackedFandomTrendsChartOption,
      data: dataWithGrowth,
      series: trackedFandomTrendsChartOption.series.filter((x) =>
        newDataKeys.includes(x.yKey),
      ),
    };
    return newChartOptions;
  }, [trackedFandomTrends, itemData]);

  const trackedRedditTrendChartOption = useMemo(() => {
    if (
      !trackedRedditTrends.data ||
      !trackedRedditTrends.data.data ||
      trackedRedditTrends.data.data.length === 0
    ) {
      return null;
    }

    const dataWithDailyGrowth = trackedRedditTrends.data.data.map(
      (d, index, array) => {
        const previousDaySubscribers =
          index === 0 ? d.subscriber_count : array[index - 1].subscriber_count;
        return {
          ...d,
          timestamp: moment(d.timestamp * 1000).format("MMM DD, YYYY"),
          subscriberGrowth: d.subscriber_count - previousDaySubscribers,
          activeUsers: d.active_user_count,
          comments: d.comments,
        };
      },
    );

    const newDataKeys = Object.keys(dataWithDailyGrowth[0]);
    const newChartOptions = {
      ...trackedRedditTrendsChartOption,
      data: dataWithDailyGrowth,
      series: trackedRedditTrendsChartOption.series
        .map((series) => ({
          ...series,
          visible:
            newDataKeys.includes(series.yKey) ||
            series.yKey === "subscriberGrowth",
        }))
        .filter((x) => newDataKeys.includes(x.yKey)),
    };
    return newChartOptions;
  }, [trackedRedditTrends, itemData]);

  const trackedInstagramTrendChartOption = useMemo(() => {
    if (
      !trackedInstagramTrends.data ||
      !trackedInstagramTrends.data.data ||
      trackedInstagramTrends.data.data.length === 0
    ) {
      return null;
    }

    const dataWithDailyGrowth = trackedInstagramTrends.data.data.map(
      (d, index, array) => {
        const previousDayPosts =
          index === 0 ? d.num_posts : array[index - 1].num_posts;
        return {
          ...d,
          timestamp: moment(d.timestamp * 1000).format("MMM DD, YYYY"),
          newPosts: d.num_posts - previousDayPosts,
        };
      },
    );
    const newDataKeys = Object.keys(dataWithDailyGrowth[0]);
    const newChartOptions = {
      ...trackedInstagramTrendsChartOption,
      data: dataWithDailyGrowth,
      series: trackedInstagramTrendsChartOption.series
        .map((series) => ({
          ...series,
          visible: newDataKeys.includes(series.yKey),
        }))
        .filter((x) => newDataKeys.includes(x.yKey)),
    };
    return newChartOptions;
  }, [trackedInstagramTrends, itemData]);

  useEffect(() => {
    setItemProps({
      piracyTrends,
      piracyTrendsChartOption,
      trackedGoogleTrends,
      trackedGoogleTrendsChartOption,
      trackedFandomTrendChartOption: trackedFandomTrendChartOption,
      trackedRedditTrendChartOption: trackedRedditTrendChartOption,
      trackedInstagramTrendChartOption: trackedInstagramTrendChartOption,
      trackedWikiTrendChartOption: trackedWikiTrendChartOption,
      wikiOptions: wikipediaChartOptions,
      gtrendsOptions: googleTrendsChartOptions,
      tiktokHashtagsData: tiktokHashtagsData,
      instagramHashtagsData: instagramHashtagsData,
      generalTrendsData: generalTrendsData,
      trendLabels: trendLabels,
      trendNotes: trendNotes,
      setTrendLabel: setTrendLabel,
      setTrendNote: setTrendNote,
    });
  }, [
    wikipediaChartOptions,
    googleTrendsChartOptions,
    tiktokHashtagsData,
    instagramHashtagsData,
    generalTrendsData,
    trendLabels,
    trendNotes,
    trackedWikiTrendChartOption,
    trackedFandomTrendChartOption,
    trackedRedditTrendChartOption,
    trackedInstagramTrendChartOption,
    trackedGoogleTrendsChartOption,
    piracyTrends,
  ]);

  useEffect(() => {
    if (!itemData.completed) return;

    if (
      !itemData.data.type ||
      (itemData.hasError && itemData.message.includes("not found"))
    ) {
      return;
    }

    const typeBcLabels = {
      [ItemTypes.TELEVISION_SERIES]: "Television",
      [ItemTypes.FILM]: "Movies",
      [ItemTypes.VIDEO_GAME]: "Games",
      [ItemTypes.FRANCHISE]: "Franchise",
      [ItemTypes.COMPANY]: "Companies",
    };

    let bc = [
      { text: "Media Catalog" },
      { text: "Explore", href: "/explore" },
      {
        text: typeBcLabels[itemData.data.type.id],
        href: `/explore/${typeBcLabels[itemData.data.type.id].toLowerCase()}`,
      },
      { text: itemData.data ? itemData.data.name : "Not Found" },
    ];

    setBreadcrumbs(bc);
  }, [itemData]);

  const updateFranchiseCards = (item) => {
    axiosInstance
      .put("franchise", {
        ...item,
      })
      .then((res) => res.data)
      .then((data) => {
        getItemData(id, {});
      })
      .catch(() => {});
  };

  const mergeFranchise = (data) => {
    mergeFranchiseAction(data?.source, data?.destination)
      .then((data) => {
        getItemData(id, {});
      })
      .catch((err) => {});
  };
  const updateFranchise = (data) => {
    updateFranchiseAction(id, data.name, data.summary)
      .then((data) => {
        getItemData(id, {});
      })
      .catch((err) => {});
  };
  const deleteFranchise = (ip_id) => {
    deleteFranchiseAction(ip_id).then((d) => {
      window.location = "/explore/franchise";
    });
  };

  //track Movie
  useEffect(() => {}, []);

  const trackItem = (item) => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>
        Tracking title <b>{item?.data.name}</b>
      </div>,
    );
    trackIP(
      item?.ip_id,
      item?.ip_list,
      user.username,
      user["custom:role"],
    ).then((d) => {
      setTimeout(() => {
        getItemData(id, {});
        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>
            Successfully tracked title <b>{item?.data.name}</b>
          </div>,
          null,
          5000,
        );
      }, 1000 * 6);
    });
  };
  const unTrackItem = (item) => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>
        Untracking title <b>{item?.data.name}</b>
      </div>,
    );
    unTrackIP(
      item?.ip_id,
      item?.ip_list,
      user.username,
      user["custom:role"],
    ).then((d) => {
      setTimeout(() => {
        getItemData(id, {});
        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>
            Successfully untracked title <b>{item?.data.name}</b>
          </div>,
          null,
          5000,
        );
      }, 1000 * 6);
    });
  };

  const deleteItem = (ip_id) => {
    deleteItemAction(ip_id).then((d) => {
      window.location = "/explore/movies";
    });
  };

  const tagUpdateSubmit = (data) => {
    setAttributeProcessing(true);
    updateTagAction(id, data)
      .then((data) => {
        setAttributeProcessing(false);
        getItemData(id, {});
      })
      .catch((err) => {})
      .finally(() => {
        setAttributeProcessing(false);
      });
  };

  const dateHandlers = useMemo(() => {
    return generateDateHandlers(itemData);
  }, [itemData]);

  return (
    <>
      <Helmet>
        <title>{itemData.data ? itemData.data.name : "Not Found"}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        headerSelector="#header"
        contentType="cards"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        content={
          <ContentLayout header={<PantheonFlashbar ref={flashbarRef} />}>
            {itemDataLoading && (
              <div className="min-h-screen flex items-center justify-center">
                <CustomLoadingOverlay />
              </div>
            )}
            {itemData &&
              itemData.data &&
              itemData.completed &&
              itemData.data.type && (
                <Box disableContentPaddings>
                  {itemData.data.type.id === ItemTypes.TELEVISION_SERIES && (
                    <ItemTelevisionSeries
                      itemData={itemData}
                      {...itemProps}
                      {...dateHandlers}
                      onTrackItem={trackItem}
                      onUntrackItem={unTrackItem}
                      onDelete={deleteItem}
                      onAddFranchise={(item) => updateFranchiseCards(item)}
                      onTagUpdateSubmit={tagUpdateSubmit}
                      attributeProcessing={attributeProcessing}
                    />
                  )}
                  {itemData.data.type.id === ItemTypes.FILM && (
                    <ItemFilm
                      itemData={itemData}
                      {...itemProps}
                      {...dateHandlers}
                      onTrackItem={trackItem}
                      onUntrackItem={unTrackItem}
                      onDelete={deleteItem}
                      onAddFranchise={(item) => updateFranchiseCards(item)}
                      onTagUpdateSubmit={tagUpdateSubmit}
                      attributeProcessing={attributeProcessing}
                    />
                  )}
                  {itemData.data.type.id === ItemTypes.VIDEO_GAME && (
                    <ItemVideoGame
                      itemData={itemData}
                      {...itemProps}
                      {...dateHandlers}
                      onTrackItem={trackItem}
                      onUntrackItem={unTrackItem}
                      onDelete={deleteItem}
                      onAddFranchise={(item) => updateFranchiseCards(item)}
                      onTagUpdateSubmit={tagUpdateSubmit}
                      attributeProcessing={attributeProcessing}
                    />
                  )}
                  {itemData.data.type.id === ItemTypes.FRANCHISE && (
                    <ItemFranchise
                      itemData={itemData}
                      {...itemProps}
                      onDelete={(item) => updateFranchiseCards(item)}
                      onAddItems={(item) => updateFranchiseCards(item)}
                      onMergeItems={(item) => mergeFranchise(item)}
                      onFranchiseEditSubmit={(item) => updateFranchise(item)}
                      onFranchiseDeleteSubmit={(item) => deleteFranchise(item)}
                      onFranchiseUpdateCoverImage={(id) => {
                        updateFranchiseCoverImageAction(id).then(() =>
                          getItemData(id, {}),
                        );
                      }}
                    />
                  )}
                  {itemData.data.type.id === ItemTypes.COMPANY && (
                    <ItemCompany itemData={itemData} {...itemProps} />
                  )}
                </Box>
              )}
          </ContentLayout>
        }
      />
    </>
  );
}
