
import { yupResolver } from '@hookform/resolvers/yup';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useAuthContext } from '../../auth/useAuthContext';
import FormProvider from '../../components/forms/FormProvider';
import LoadingButton from '../../components/forms/LoadingButton';
import TextField from '../../components/forms/TextField';
import { COGNITO_API } from '../../config-global';
import SnackbarContext from '../../hooks/notify/snack-bar/snackbar-context';
import { ForgotPasswordLayout } from '../../layouts/ForgotPasswordLayout';

const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_API.userPoolId || '',
    ClientId: COGNITO_API.clientId || '',
});

export const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { login } = useAuthContext();
    const snackbarCtx = useContext(SnackbarContext);

    const loginFormDefaultData = {
        username: localStorage.getItem('email'),
    }
    const loginSchema = Yup.object().shape({
        username: Yup.string().email("Invalid email address").required("Email is required"),
    });

    const methods = useForm({
        defaultValues: loginFormDefaultData,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(loginSchema)
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors,isLoading, isSubmitting, isSubmitSuccessful },
    } = methods;

    const onSubmit = async (data) => {
        try {

            const userData = new CognitoUser({
                Username: data.username,
                Pool: userPool,
            });

            await userData.forgotPassword({
                onSuccess: (result) => {
                    navigate('/reset-password');
                    sessionStorage.setItem('email', data.username);
                },
                onFailure: (error) => {
                },
                inputVerificationCode: (d) => {
                    navigate('/reset-password');
                    sessionStorage.setItem('email', data.username);
                }
            });


        } catch (error) {
            console.error(error.message);
            snackbarCtx.displayMsg("Error", error.message, "error", 3000);
        }
    }

    return <ForgotPasswordLayout>
        <Helmet>
            <title> Forgot Password | Pantheon</title>
        </Helmet>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <TextField name={'username'} label="Email" placeholder={"Email"} type={'text'} />
            <div className='mt-5'>
                <LoadingButton text={"Send Request"} loading={isSubmitting} type={'submit'} classes={'bg-blue-600'} />
            </div>
        </FormProvider>
        <div className="flex items-center justify-end mt-5">
            <div className="text-sm">
                <a href="/" className="font-medium text-blue-500 hover:text-blue-500">
                    Back to login
                </a>
            </div>
        </div>
    </ForgotPasswordLayout>
}