/* PLEASE DO NOT REFORMAT THIS FILE (breaks spacing in certain areas) */

import { 
  AppLayout,
  ContentLayout,
  Container,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Icon,
  DatePicker,
  Button,
  Table,
  Modal,
  SpaceBetween,
  Box,
  Input,
  Autosuggest,
  TokenGroup,
  Select,
  FormField,
  ButtonDropdown,
  StatusIndicator,
  Tabs,
  TextFilter,
  Pagination,
  ColumnLayout,
  Toggle,
  Spinner,
  SplitPanel,
  Multiselect,
  Popover,
  Link,
  Cards,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import 'ag-grid-enterprise';
import { AgChartsReact } from "ag-charts-react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { lineTheme, colorPalette } from "./chartConfig";
import TopicSearchBar from "../components/TopicSearchBar";
import { addToFavorites, removeFromFavorites, callPreferences } from "./preferences";
import { rawNameToTitle, sanitizeAgKey, slugify } from "../utils/name-utils";
import { RangeDateSelector } from "../../../components/RangeDateSelector";

const TopicModelingTrueCrimeNamePageV2 = () => {

  const flashbarRef = useRef(null);

  const dl_overviewGridRef = useRef(null);
  const dl_postsOverTimeGridRef = useRef(null);
  const dl_postsGridRef = useRef(null);
  const dl_videosGridRef = useRef(null);
  const dl_wikipediaOverTimeGridRef = useRef(null);
  const dl_wikipediaArticlesGridRef = useRef(null);
  const dl_newsGridRef = useRef(null);

  const { name } = useParams();

  const domain = "true-crime";
  const [ breadcrumbs, setBreadcrumbs ] = useState([{ text: "Topic Modeling", }, { text: "True Crime", href: "/topic-modeling/true-crime" }, { text: "Person", href: "/" },]);
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment().subtract(2, 'weeks').format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const granularityOptions = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ];
  const sortOptions = [
    { label: "Popularity", value: "popularity" },
    { label: "Date", value: "date" },
  ];
  const postsPageSize = 50;


  const [ namesOverTimeResponse, setNamesOverTimeResponse ] = useState(defaultResponseState);
  const [ postsByNameResponse, setPostsByNameResponse ] = useState(defaultResponseState);
  const [ videosByNameResponse, setVideosByNameResponse ] = useState(defaultResponseState);
  const [ newsResponse, setNewsResponse ] = useState(defaultResponseState);

  const [ nameSeriesData, setNameSeriesData ] = useState([]);
  const [ nameTotalPosts, setNameTotalPosts ] = useState(0);
  const [ showMarkers, setShowMarkers ] = useState(false);
  const [ namesShowMore, setNamesShowMore ] = useState(false);
  const [ topicsShowMore, setTopicsShowMore ] = useState(false);
  const [ granularity, setGranularity ] = useState(granularityOptions[1]);

  const [ wikipediaSeriesData, setWikipediaSeriesData ] = useState([]);
  const [ selectedWikipediaArticles, setSelectedWikipediaArticles ] = useState([]);
  const [ wikipediaGranularity, setWikipediaGranularity ] = useState(granularityOptions[1]);
  const [ wikipediaShowMarkers, setWikipediaShowMarkers ] = useState(false);

  const [ newsDateRange, setNewsDateRange ] = useState(defaultDateRange);

  const [ postsPageIndex, setPostsPageIndex ] = useState(1);
  const [ postsTargetDate, setPostsTargetDate ] = useState(defaultDateRange);
  const [ selectedPostsSort, setSelectedPostsSort ] = useState(sortOptions[0]);
  const [ postsCache, setPostsCache ] = useState({});
  const [ currentPosts, setCurrentPosts ] = useState([]);

  const [ isFavorite, setIsFavorite ] = useState(null);


  const handleNamesOverTimeResponse = (response) => {
    const timeseriesData = response.data;
    const wikipediaData = response.wikipedia_articles[name];

    const seriesData = timeseriesData.map((item) => (
      {
        timestamp: new Date(moment(item.timestamp).toDate()),
        value: item.data[0].count,
      }
    ));

    const allWikipediaTimestamps = wikipediaData.map(item => item.pageviews.map(view => view.timestamp)).flat();
    const orderedUniqueWikipediaTimestamps = [...new Set(allWikipediaTimestamps)].sort((a, b) => moment(a) - moment(b));
    
    const wikipediaSeriesData = [];
    orderedUniqueWikipediaTimestamps.forEach(timestamp => {
      const dataPoint = {
        timestamp: new Date(moment(timestamp).toDate()),
      };
      wikipediaData.forEach(article => {
        const pageview = article.pageviews.find(view => view.timestamp === timestamp);
        dataPoint[sanitizeAgKey(article.title)] = pageview ? pageview.value : 0;
      });
      wikipediaSeriesData.push(dataPoint);
    });

    if (seriesData.slice(-1)[0].timestamp - seriesData[0].timestamp < 1000 * 60 * 60 * 24 * 7 * 4) {
      setGranularity(granularityOptions[0]);
    }

    setNameSeriesData(seriesData);
    setNameTotalPosts(timeseriesData.reduce((acc, item) => acc + item.data[0].count, 0));
    setWikipediaSeriesData(wikipediaSeriesData);
    setSelectedWikipediaArticles(wikipediaData.map(article => ({ title: article.title })));
    setBreadcrumbs([{ text: "Topic Modeling", }, { text: "True Crime", href: "/topic-modeling/true-crime" }, { text: "Name: " + rawNameToTitle(name), href: `/topic-modeling/true-crime/person/${name}` },]);
  };

  const groupSeries = (seriesData, groupBy) => {
    const grouped = {};
  
    seriesData.forEach((item) => {
      const startOfGroup = moment(item.timestamp).startOf(groupBy).toDate();
      const key = moment(startOfGroup).format('YYYY-MM-DD');
  
      const otherValueKeys = Object.keys(item).filter(k => k !== 'timestamp');
      if (!grouped[key]) {
        grouped[key] = { timestamp: startOfGroup, ...otherValueKeys.reduce((acc, k) => ({ ...acc, [k]: 0 }), {}) };
      }
      otherValueKeys.forEach(k => {
        grouped[key][k] += item[k];
      });
    });

    const newSeriesData = Object.values(grouped).sort((a, b) => a.timestamp - b.timestamp);
  
    return newSeriesData;
  };

  const setPostsPage = (dateRange, page, sort) => {
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    const key = `${startDate}_${endDate}_${sort}_${name}_${page}`;
    const newPostsCache = { ...postsCache };
    if (!(key in newPostsCache)) {
      let params = {
        start_date: startDate,
        end_date: endDate,
        names: name,
        size: postsPageSize,
        page: page - 1,
        order: sort,
      };
      const existingQueryKey = Object.keys(newPostsCache).find(key => key.startsWith(`${startDate}_${endDate}_${sort}_`));
      if (existingQueryKey) {
        const executionId = newPostsCache[existingQueryKey].execution_id;
        params.execution_id = executionId;
      }
      getPostsByName(params).then(data => {
        newPostsCache[key] = data;
        setPostsCache(newPostsCache);
        setCurrentPosts(data);
      });
    } else {
      setCurrentPosts(newPostsCache[key]);
    }
  };

  const downloadPage = () => {
    const spreadsheets = [
      dl_overviewGridRef.current.api.getSheetDataForExcel({ sheetName: "Topic Overview" }),
      dl_postsOverTimeGridRef.current.api.getSheetDataForExcel({ sheetName: "Posts Over Time" }),
      dl_postsGridRef.current.api.getSheetDataForExcel({ sheetName: "Reddit Posts" }),
      dl_videosGridRef.current.api.getSheetDataForExcel({ sheetName: "YouTube Videos" }),
      dl_wikipediaArticlesGridRef.current.api.getSheetDataForExcel({ sheetName: "Wikipedia Articles" }),
      dl_wikipediaOverTimeGridRef.current.api.getSheetDataForExcel({ sheetName: "Wikipedia Page Views" }),
      dl_newsGridRef.current.api.getSheetDataForExcel({ sheetName: "News Articles" }),
    ];
    dl_overviewGridRef.current.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `person_${slugify(name).slice(0, 32)}_${moment().format("YYYY-MM-DD")}.xlsx`,
    });
  };



  const getNamesOverTime = (params) => {
    setNamesOverTimeResponse({ ...namesOverTimeResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/namesovertime",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setNamesOverTimeResponse({ ...namesOverTimeResponse, data: response.data, loading: false, completed: true, error: null });
      handleNamesOverTimeResponse(response.data);
    }).catch(error => {
      setNamesOverTimeResponse({ ...namesOverTimeResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getPostsByName = (params) => {
    setPostsByNameResponse({ ...postsByNameResponse, loading: true, completed: false, error: null });

    return axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/postsbyname",
      params: {
        domain: domain,
        platform: "reddit",
        ...params,
      },
    }).then(response => {
      setPostsByNameResponse({ ...postsByNameResponse, data: response.data, loading: false, completed: true, error: null });
      return response.data;
    }).catch(error => {
      setPostsByNameResponse({ ...postsByNameResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getVideosByName = (params) => {
    setVideosByNameResponse({ ...videosByNameResponse, loading: true, completed: false, error: null });

    return axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/postsbyname",
      params: {
        domain: domain,
        platform: "youtube",
        ...params,
      },
    }).then(response => {
      setVideosByNameResponse({ ...videosByNameResponse, data: response.data, loading: false, completed: true, error: null });
      return response.data;
    }).catch(error => {
      setVideosByNameResponse({ ...videosByNameResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getNews = (params) => {
    setNewsResponse({ ...newsResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/googlenews/search",
      params: params,
    }).then(response => {
      setNewsResponse({ ...newsResponse, data: response.data, loading: false, completed: true, error: null });
    }).catch(error => {
      setNewsResponse({ ...newsResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };


  useEffect(() => {
    if (!name) return;
    setPostsCache({});
    const getPreferences = async () => {
      const newPreferences = await callPreferences(domain, "get_preferences");
      const favoriteNames = newPreferences?.favorites?.names;
      setIsFavorite(favoriteNames?.includes(name));
    };
    getPreferences();
    getNamesOverTime({
      start_date: "2008-01-01", 
      end_date: moment().format("YYYY-MM-DD"), 
      granularity: "day",
      names: name,
    });
  }, [name]);

  useEffect(() => {
    getVideosByName({
      start_date: moment(postsTargetDate.startDate).format("YYYY-MM-DD"),
      end_date: moment(postsTargetDate.endDate).format("YYYY-MM-DD"),
      names: name,
      order: selectedPostsSort.value,
    }).then(data => {
      console.log(data)
    });
    setPostsPage(postsTargetDate, 1, selectedPostsSort.value);
  }, [postsTargetDate, selectedPostsSort.value]);

  useEffect(() => {
    if (!name) return;
    getNews({ 
      q: name.toLowerCase(), 
      start_date: newsDateRange.startDate, 
      end_date: newsDateRange.endDate,
    });
  }, [name, newsDateRange]);

  return (
    <>
      <Helmet><title>{name ? "Person: " + rawNameToTitle(name) : "Person"}</title></Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <>
            <ContentLayout
              header={
                <>
                  <Header
                    actions={
                      <div className="flex space-x-2 items-center">
                        <Button
                          loading={namesOverTimeResponse?.loading || postsByNameResponse?.loading || videosByNameResponse?.loading || newsResponse?.loading}
                          onClick={downloadPage}
                        >
                          Download
                        </Button>
                        <TopicSearchBar
                          domain={domain}
                          searchApiUrl="/topicmodeling/pantheon/search"
                          namePageUrl="/topic-modeling/true-crime/person"
                          topicPageUrl="/topic-modeling/true-crime/topic"
                          limit={10}
                        />
                      </div>
                    }
                  >
                    <div className="flex items-center space-x-1">
                      {isFavorite === null ? (
                        <Spinner size="normal" />
                      ) : (
                        <Button 
                          ariaLabel={isFavorite ? "Remove from favorites" : "Add to favorites"}
                          iconName={isFavorite ? "star-filled" : "star"}
                          variant="inline-icon" 
                          onClick={() => {
                            if (isFavorite) {
                              removeFromFavorites(domain, { names: name });
                            } else {
                              addToFavorites(domain, { names: name });
                            }
                            setIsFavorite(!isFavorite);
                          }}
                        />
                      )}
                      <div>{name ? "Person: " + rawNameToTitle(name) : "Person"}</div>
                    </div>
                  </Header>
                  <PantheonFlashbar ref={flashbarRef} />
                </>
              }
            >
              <SpaceBetween size="m">
                <Container>
                  <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="s">
                      <FormField
                        label="Name"
                      >
                        {namesOverTimeResponse?.loading ? <Spinner /> : rawNameToTitle(name)}
                      </FormField>
                      <FormField
                        label="Total posts"
                      >
                        {namesOverTimeResponse?.loading ? <Spinner /> : <div>{nameTotalPosts.toLocaleString()}{" "}(from {moment(nameSeriesData?.[0]?.timestamp).format("l")} to {moment().format("l")})</div>}
                      </FormField>
                    </SpaceBetween>
                    <SpaceBetween size="s">
                      <FormField
                        label="Associated topics"
                      >
                        {namesOverTimeResponse?.loading ? <Spinner /> : <div>
                          {namesOverTimeResponse?.data?.connected_topics?.[name] && namesOverTimeResponse?.data?.connected_topics?.[name]?.length > 0 ? (<>
                            <span>
                              {namesOverTimeResponse?.data?.connected_topics?.[name]?.slice(0, topicsShowMore ? 1000 : 10).map(topic => (
                                <Link href={`/topic-modeling/true-crime/topic/${topic.id}`}>{topic.label}</Link>
                              )).reduce((prev, curr) => [prev, " \u2022 ", curr])}
                            </span>
                            <span>
                              {(namesOverTimeResponse?.data?.connected_topics?.[name]?.length > 10) && (
                                <span className="ml-2">
                                  <Button 
                                    variant="inline-link"
                                    onClick={() => {
                                      setTopicsShowMore(!topicsShowMore);
                                    }}
                                  >
                                    {topicsShowMore ? "show less" : (
                                      <span>...and {namesOverTimeResponse?.data?.connected_topics?.[name]?.length - 10} more</span>
                                    )}
                                  </Button>
                                </span>
                              )}
                            </span>
                          </>) : (
                            <div>No associated topics</div>
                          )}
                        </div>}
                      </FormField>
                      <FormField
                        label="Associated people"
                      >
                        {namesOverTimeResponse?.loading ? <Spinner /> : <div>
                          {namesOverTimeResponse?.data?.connected_names?.[name] && namesOverTimeResponse?.data?.connected_names?.[name]?.length > 0 ? (<>
                            <span>
                              {namesOverTimeResponse?.data?.connected_names?.[name]?.slice(0, namesShowMore ? 1000 : 10).map(name => (
                                <Link href={`/topic-modeling/true-crime/person/${name}`}>{rawNameToTitle(name)}</Link>
                              )).reduce((prev, curr) => [prev, " \u2022 ", curr])}
                            </span>
                            <span>
                              {(namesOverTimeResponse?.data?.connected_names?.[name]?.length > 10) && (
                                <span className="ml-2">
                                  <Button 
                                    variant="inline-link"
                                    onClick={() => {
                                      setNamesShowMore(!namesShowMore);
                                    }}
                                  >
                                    {namesShowMore ? "show less" : (
                                      <span>...and {namesOverTimeResponse?.data?.connected_names?.[name]?.length - 10} more</span>
                                    )}
                                  </Button>
                                </span>
                              )}
                            </span>
                          </>) : (
                            <div>No associated people</div>
                          )}
                        </div>}
                      </FormField>
                    </SpaceBetween>
                  </ColumnLayout>
                </Container>
                <Tabs
                  tabs={[
                    {
                      id: "Conversation",
                      label: "Conversation",
                      content: (
                        <Container
                          header={
                            <Header
                              description="Number of posts matching this name over time"
                              actions={
                                <div className="flex space-x-2 items-center">
                                  <Select
                                    options={granularityOptions}
                                    selectedOption={granularity}
                                    onChange={({ detail }) => {
                                      setGranularity(detail.selectedOption);
                                    }}
                                  />
                                  <Toggle
                                    onChange={({ detail }) => {
                                      setShowMarkers(detail.checked);
                                    }}
                                    checked={showMarkers}
                                  >
                                    Show markers
                                  </Toggle>
                                </div>
                              }
                            >
                              Posts over time
                              {" "}
                              <Popover
                                position="right"
                                size="small"
                                triggerType="custom"
                                content={
                                  <div>Click any point on the chart to see posts from that time period</div>
                                }
                              >
                                <Button iconName="status-info" variant="inline-icon" />
                              </Popover>
                            </Header>
                          }
                        >
                          {namesOverTimeResponse?.loading ? (
                            <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                              <Spinner size="large" />
                            </div> 
                          ) : (
                            <div style={{ height: "400px", width: "100%" }} className="ag-theme-quartz-dark">
                              <AgChartsReact 
                                options={{
                                  data: granularity.value === "day" ? nameSeriesData : groupSeries(nameSeriesData, granularity.value),
                                  series: [
                                    {
                                      xKey: "timestamp",
                                      yKey: "value",
                                      title: "Post count",
                                      tooltip: {
                                        renderer: (params) => (
                                          {
                                            title: params.datum.title,
                                            content: (granularity.value === "day" ? 
                                              moment(params.datum.timestamp).format("ll")
                                              : granularity.value === "week" ?
                                              moment(params.datum.timestamp).format("ll") + " - " + moment(params.datum.timestamp).add(6, "days").format("ll")
                                              : moment(params.datum.timestamp).format("MMM YYYY")) + ": " + params.datum.value.toLocaleString() + " posts"
                                          }
                                        ),
                                      },
                                      marker: {
                                        fillOpacity: showMarkers ? 0.5 : 0,
                                      },
                                      connectMissingData: false,
                                      listeners: {
                                        nodeClick: (params) => {
                                          const clickedDate = moment(params.datum.timestamp).format("YYYY-MM-DD");
                                          const startDate = clickedDate;
                                          const endDate = granularity.value === "day" ? clickedDate : granularity.value === "week" ? moment(clickedDate).add(6, "days").format("YYYY-MM-DD") : moment(clickedDate).endOf("month").format("YYYY-MM-DD");
                                          const dateRange = {
                                            type: "absolute",
                                            startDate: startDate,
                                            endDate: endDate,
                                          };
                                          setPostsTargetDate(dateRange);
                                          setPostsPage(dateRange, 1, selectedPostsSort.value);
                                        },
                                      },
                                      nodeClickRange: "nearest",
                                    },
                                  ],
                                  axes: [
                                    {
                                      type: 'time',
                                      position: 'bottom',
                                      title: "Date",
                                      min: nameSeriesData?.[0]?.timestamp,
                                      max: new Date(),
                                      label: {
                                        format: '%b %d, %Y',
                                      },
                                      tick: {
                                        minSpacing: 50,
                                        maxSpacing: 200,
                                      },
                                    },
                                    {
                                      type: 'number',
                                      position: 'left',
                                      title: "Post count",
                                      label: {
                                        formatter: (params) => {
                                          const n = params.value;
                                          if (n < 1e3) return n;
                                          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                          if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                                        }
                                      },
                                    },
                                  ],
                                  theme: lineTheme,
                                  navigator: {
                                    enabled: true
                                  },
                                }}
                              />
                            </div>
                          )}
                        </Container>
                      ),
                    },
                    {
                      id: "wikipedia",
                      label: "Wikipedia",
                      content: (
                        <Container
                          header={
                            <Header
                              description="Wikipedia articles associated with this person along with their page views over time"
                              actions={
                                <div className="flex space-x-2 items-center">
                                  <Select
                                    options={granularityOptions}
                                    selectedOption={wikipediaGranularity}
                                    onChange={({ detail }) => {
                                      setWikipediaGranularity(detail.selectedOption);
                                    }}
                                  />
                                  <Toggle
                                    onChange={({ detail }) => {
                                      setWikipediaShowMarkers(detail.checked);
                                    }}
                                    checked={wikipediaShowMarkers}
                                  >
                                    Show markers
                                  </Toggle>
                                </div>
                              }
                            >
                              Wikipedia page views over time
                            </Header>
                          }
                        >
                          {namesOverTimeResponse?.loading ? (
                            <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                              <Spinner size="large" />
                            </div>
                          ) : namesOverTimeResponse.data?.wikipedia_articles?.[name]?.length > 0 ? (<>
                            <div style={{ height: "400px", width: "100%" }} className="ag-theme-quartz-dark">
                              <AgChartsReact 
                                options={{
                                  data: wikipediaGranularity.value === "day" ? wikipediaSeriesData : groupSeries(wikipediaSeriesData, wikipediaGranularity.value),
                                  series: namesOverTimeResponse.data.wikipedia_articles[name].map((article, i) => (
                                    {
                                      xKey: "timestamp",
                                      yKey: sanitizeAgKey(article.title),
                                      title: article.title,
                                      stroke: colorPalette[i % colorPalette.length],
                                      tooltip: {
                                        renderer: (params) => (
                                          {
                                            title: params.datum.title,
                                            content: (wikipediaGranularity.value === "day" ? 
                                              moment(params.datum.timestamp).format("ll")
                                              : wikipediaGranularity.value === "week" ?
                                              moment(params.datum.timestamp).format("ll") + " - " + moment(params.datum.timestamp).add(6, "days").format("ll")
                                              : moment(params.datum.timestamp).format("MMM YYYY")) + ": " + params.datum[sanitizeAgKey(article.title)].toLocaleString() + " page views"
                                          }
                                        ),
                                      },
                                      marker: {
                                        fillOpacity: wikipediaShowMarkers ? 0.5 : 0,
                                        fill: colorPalette[i % colorPalette.length],
                                      },
                                      connectMissingData: false,
                                      enabled: selectedWikipediaArticles.map(article => article.title).includes(article.title),
                                    }
                                  )),
                                  axes: [
                                    {
                                      type: 'time',
                                      position: 'bottom',
                                      title: "Date",
                                      min: wikipediaSeriesData?.[0]?.timestamp,
                                      max: wikipediaSeriesData?.slice(-1)[0]?.timestamp,
                                      label: {
                                        format: '%b %d, %Y',
                                      },
                                      tick: {
                                        minSpacing: 50,
                                        maxSpacing: 200,
                                      },
                                    },
                                    {
                                      type: 'number',
                                      position: 'left',
                                      title: "Page views",
                                      label: {
                                        formatter: (params) => {
                                          const n = params.value;
                                          if (n < 1e3) return n;
                                          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                          if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                                        }
                                      },
                                    },
                                  ],
                                  theme: lineTheme,
                                  navigator: {
                                    enabled: true,
                                  },
                                  legend: {
                                    enabled: false,
                                  },
                                }}
                              />
                            </div>
                            <Cards
                              onSelectionChange={({ detail }) =>
                                setSelectedWikipediaArticles(detail?.selectedItems ?? [])
                              }
                              selectedItems={selectedWikipediaArticles}
                              cardDefinition={{
                                header: item => (
                                  <Link href={item.url} target="_blank">
                                    <div className="flex space-x-2 items-center">
                                      <div style={{
                                        width: 15,
                                        height: 5,
                                        borderRadius: 5,
                                        backgroundColor: item.color,
                                      }} />
                                      <div>{item.title} <Icon name="external" variant="link" /></div>
                                    </div>
                                  </Link>
                                ),
                                sections: [
                                  {
                                    id: "summary",
                                    header: "Summary",
                                    content: item => item.summary ? (item.summary.length > 200 ? item.summary.substring(0, 200) + "..." : item.summary) : "No summary available",
                                  }
                                ],
                              }}
                              cardsPerRow={[
                                { cards: 3 },
                              ]}
                              items={namesOverTimeResponse?.data?.wikipedia_articles?.[name]?.map((article, i) => (
                                {
                                  title: article.title,
                                  color: colorPalette[i % colorPalette.length],
                                  summary: article.summary,
                                  url: `https://en.wikipedia.org/wiki/${article.title}`,
                                }
                              ))}
                              selectionType="multi"
                              trackBy="title"
                            />
                          </>) : (
                            <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                              <div>No Wikipedia articles associated for this person</div>
                            </div>
                          )}
                        </Container>
                      ),
                    },
                    {
                      id: "news",
                      label: "News",
                      content: (
                        <Container
                          header={
                            <Header
                              description={(newsDateRange.startDate !== defaultDateRange.startDate && newsDateRange.endDate !== defaultDateRange.endDate) ? (newsDateRange.startDate === newsDateRange.endDate ? `News articles about this person on ${moment(newsDateRange.startDate).format("ll")}` : `News articles about this person from ${moment(newsDateRange.startDate).format("ll")} to ${moment(newsDateRange.endDate).format("ll")}`) : "Latest news articles about this person"}
                              actions={
                                <RangeDateSelector
                                  defaults={newsDateRange}
                                  onChange={(e) => setNewsDateRange(e)}
                                />
                              }
                            >
                              News articles
                            </Header>
                          }
                        >
                          {newsResponse?.loading ? (
                            <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                              <Spinner size="large" />
                            </div> 
                          ) : (newsResponse.data?.[0]?.results?.length > 0 ? (
                            <ColumnLayout columns={1} borders="horizontal">
                              {newsResponse.data?.[0]?.results?.map(article => (
                                <Box>
                                  <div className="flex space-x-2">
                                    <img src={article.thumbnail} className="rounded-md" style={{width: "92px", height: "92px"}} />
                                    <div className="space-y-1">
                                      <span className="space-x-1">
                                        <Link href={article.article_link} external><b>{article.title}</b></Link>
                                      </span>
                                      <div className="text-sm">{article.snippet}</div>
                                      <div className="text-xs text-slate-400">{article.date} &bull; {article.source}</div>
                                    </div>
                                  </div>
                                </Box>
                              ))}
                            </ColumnLayout>
                          ) : (
                            <div className="py-8 flex flex-1 flex-row justify-center items-center">
                              <div>No news available for this time period</div>
                            </div>
                          ))}
                        </Container>
                      ),
                    },
                  ]}
                />
                
                <div className="flex justify-end space-x-2">
                  <Select
                    selectedOption={selectedPostsSort}
                    onChange={({ detail }) => {
                      setSelectedPostsSort(detail.selectedOption);
                      setPostsPage(postsTargetDate, 1, detail.selectedOption.value);
                    }}
                    options={sortOptions}
                  />
                  <RangeDateSelector
                    defaults={postsTargetDate}
                    onChange={(e) => setPostsTargetDate(e)}
                  />
                </div>
                <ColumnLayout columns={2}>
                  <Container
                    header={
                      <Header
                        actions={
                          <Pagination
                            currentPageIndex={postsPageIndex}
                            onChange={({ detail }) => {
                              setPostsPageIndex(detail.currentPageIndex);
                              setPostsPage(postsTargetDate, detail.currentPageIndex, selectedPostsSort.value);
                            }}
                            pagesCount={Math.ceil(currentPosts?.total_rows / postsPageSize)}
                          />
                        }
                        counter={!postsByNameResponse?.loading && currentPosts?.total_rows ? `(${currentPosts?.total_rows.toLocaleString()})` : null}
                        description={(postsTargetDate.startDate !== defaultDateRange.startDate && postsTargetDate.endDate !== defaultDateRange.endDate) ? (postsTargetDate.startDate === postsTargetDate.endDate ? `Posts matching this name on ${moment(postsTargetDate.startDate).format("ll")}` : `Posts matching this name from ${moment(postsTargetDate.startDate).format("ll")} to ${moment(postsTargetDate.endDate).format("ll")}`) : "Latest posts matching this name"}
                      >
                        Reddit posts
                      </Header>
                    }
                  >
                    {postsByNameResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {currentPosts?.reddit?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {currentPosts?.reddit?.map(post => (
                              <div className="grid">
                                <div className="flex justify-between">
                                  <div className="flex flex-col flex-wrap h-full">
                                    <div className="grow">
                                      <div>
                                        <a className="text-sm font-bold" href={post.url} target="_blank">{post.title}</a>
                                      </div>
                                      <div>
                                        <a className="text-xs text-slate-400" href={`https://reddit.com/r/${post.subreddit}`} target="_blank">Posted by u/{post.author} to r/{post.subreddit}{" "}&bull;{" "}{moment(post.created_at * 1000).format("ll")}</a>
                                      </div>
                                      {post.body?.length > 0 && (
                                        <div 
                                          className="mt-2 text-xs" 
                                          style={{ 
                                            overflow: "hidden",
                                            maskImage: "linear-gradient(180deg, #000 60%, transparent)",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <div 
                                            style={{
                                              wordBreak: "break-word",
                                              overflow: "auto",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            {post.body}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <div className="text-sm mt-2"><Icon name="arrow-left" className="rotate-90 saturate-[10] hue-rotate-180 brightness-90" />{" "}{post.num_upvotes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{post.num_comments.toLocaleString()}</div>
                                    </div>
                                  </div>
                                  <div className="mx-4 min-w-24">
                                    {post.thumbnail && (
                                      <img 
                                        src={post.thumbnail} 
                                        alt="thumbnail" 
                                        className="w-24 h-24 object-cover rounded-md shadow-md" 
                                        onError={(e) => {
                                          e.target.style.display = "none";
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No posts found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                  <Container
                    header={
                      <Header
                      description={(postsTargetDate.startDate !== defaultDateRange.startDate && postsTargetDate.endDate !== defaultDateRange.endDate) ? (postsTargetDate.startDate === postsTargetDate.endDate ? `Videos matching this name on ${moment(postsTargetDate.startDate).format("ll")}` : `Videos matching this name from ${moment(postsTargetDate.startDate).format("ll")} to ${moment(postsTargetDate.endDate).format("ll")}`) : "Latest videos matching this name"}
                      >
                        YouTube videos
                      </Header>
                    }
                  >
                    {videosByNameResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {videosByNameResponse?.data?.youtube?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {videosByNameResponse.data.youtube.map(video => (
                              <div key={video.thumbnail} className="h-32 flex space-x-4">
                                <a href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank"
                                  style={{
                                    minWidth: 320/1.5,
                                    minHeight: 180/1.5,
                                  }} 
                                >
                                  <img 
                                    src={video.thumbnail} 
                                    style={{
                                      width: 320/1.5,
                                      height: 180/1.5,
                                    }} 
                                    className="object-cover rounded-md shadow-md"
                                  />
                                </a>
                                <div>
                                  <div>
                                    <div>
                                      <a className="text-sm font-bold" href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank">{video.title}</a>
                                    </div>
                                    <div>
                                      <a className="text-xs text-slate-400" href={video.channel_url} target="_blank">{video.channel}{" "}&bull;{" "}{new Date(video.created_at * 1000).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric',})}</a>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div className="text-sm"><Icon url="/rticons/eye.svg" className="brightness-75" />{" "}{video.num_views.toLocaleString()}{" "}&bull;{" "}<Icon name="thumbs-up" />{" "}{video.num_likes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{video.num_comments.toLocaleString()}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No videos found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                </ColumnLayout>
              </SpaceBetween>
              <div className="hidden">
                {namesOverTimeResponse?.loading === false && namesOverTimeResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_overviewGridRef}
                        columnDefs={[
                          {
                            field: "name",
                            headerName: "Name",
                          },
                          {
                            field: "total_posts",
                            headerName: "Total posts",
                          },
                          {
                            field: "connected_names",
                            headerName: "Associated people",
                          },
                          {
                            field: "connected_topics",
                            headerName: "Associated topics",
                          },
                        ]}
                        rowData={[
                          {
                            name: name,
                            total_posts: nameTotalPosts,
                            connected_names: namesOverTimeResponse.data?.connected_names?.[name]?.map(name => rawNameToTitle(name)).join(", "),
                            connected_topics: namesOverTimeResponse.data?.connected_topics?.[name]?.map(topic => topic.label).join(", "),
                          },
                        ]}
                      />
                    </div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_postsOverTimeGridRef}
                        columnDefs={[
                          {
                            field: "timestamp_formatted",
                            headerName: "Date (formatted)",
                          },
                          {
                            field: "timestamp",
                            headerName: "Date",
                          },
                          {
                            field: "count",
                            headerName: "Post count",
                          },
                        ]}
                        rowData={nameSeriesData.map(item => (
                          { 
                            timestamp_formatted: moment(item.timestamp).format("ll"), 
                            timestamp: moment(item.timestamp).format("YYYY-MM-DD"), 
                            count: item.value,
                          }
                        ))}
                      />
                    </div>
                    {namesOverTimeResponse.data.wikipedia_articles && (<>
                      <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                        <AgGridReact
                          ref={dl_wikipediaOverTimeGridRef}
                          columnDefs={[
                            {
                              field: "timestamp_formatted",
                              headerName: "Date (formatted)",
                            },
                            {
                              field: "timestamp",
                              headerName: "Date",
                            },
                            ...namesOverTimeResponse.data.wikipedia_articles[name].map((article, i) => (
                              {
                                field: sanitizeAgKey(article.title),
                                headerName: article.title,
                              }
                            ))
                          ]}
                          rowData={wikipediaSeriesData.map(item => (
                            { 
                              ...item,
                              timestamp_formatted: moment(item.timestamp).format("ll"), 
                              timestamp: moment(item.timestamp).format("YYYY-MM-DD"), 
                            }
                          ))}
                        />
                      </div>
                      <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                        <AgGridReact
                          ref={dl_wikipediaArticlesGridRef}
                          columnDefs={[
                            {
                              field: "title",
                              headerName: "Title",
                            },
                            {
                              field: "summary",
                              headerName: "Summary",
                            },
                            {
                              field: "url",
                              headerName: "URL",
                            },
                          ]}
                          rowData={namesOverTimeResponse.data.wikipedia_articles[name].map(article => (
                            {
                              title: article.title,
                              summary: article.summary,
                              url: `https://en.wikipedia.org/wiki/${article.title}`,
                            }
                          ))}
                        />
                      </div>
                    </>)}
                  </div>
                )}
                {postsByNameResponse?.loading === false && postsByNameResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_postsGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "author",
                            headerName: "Author",
                          },
                          {
                            field: "subreddit",
                            headerName: "Subreddit",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_upvotes",
                            headerName: "Upvotes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Post ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                          {
                            field: "body",
                            headerName: "Body",
                          },
                        ]}
                        rowData={currentPosts.reddit}
                      />
                    </div>
                  </div>
                )}
                {videosByNameResponse?.loading === false && videosByNameResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_videosGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "channel",
                            headerName: "Channel",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_views",
                            headerName: "Views",
                          },
                          {
                            field: "num_likes",
                            headerName: "Likes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Video ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                        ]}
                        rowData={videosByNameResponse.data.youtube.map(video => ({ ...video, url: `https://youtube.com/watch?v=${video.post_id}` }))}
                      />
                    </div>
                  </div>
                )}
                {newsResponse?.loading === false && newsResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_newsGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "snippet",
                            headerName: "Snippet",
                          },
                          {
                            field: "date",
                            headerName: "Date",
                          },
                          {
                            field: "source",
                            headerName: "Source",
                          },
                          {
                            field: "article_link",
                            headerName: "URL",
                          },
                        ]}
                        rowData={newsResponse.data[0].results}
                      />
                    </div>
                  </div>
                )}
              </div>
            </ContentLayout>
          </>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TopicModelingTrueCrimeNamePageV2;
