import { Link } from "@cloudscape-design/components";

export const WikiScore = ({ rank, title, subtitle }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                <img src="/svg/wikipedia.svg" alt="Votes" className="h-8" />
                <div className="text-xl text-slate-50 font-bold"> {rank} </div>
            </div>
            <div className="text-xs font-bold"><Link fontSize="body-s" href="/dashboards/wikipediatrends">{title}</Link></div>
            <div className="text-xs italic text-slate-400">
                {subtitle}
            </div>
        </div>
    );
};

export const YoutubeScore = ({ rank, title, subtitle }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                <img src="/svg/youtube.svg" alt="Votes" className="h-8" />
                <div className="text-xl text-slate-50 font-bold"> {rank} </div>
            </div>
            <div className="text-xs font-bold"><Link fontSize="body-s" href="/dashboards/youtubetrends">{title}</Link></div>
            <div className="text-xs italic text-slate-400">
                {subtitle}
            </div>
        </div>
    );
};

export const SearchScore = ({ rank, title, subtitle }) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex space-x-2 items-center">
          <img src="/svg/search.svg" alt="Votes" className="h-8" />
          <div className="text-xl text-slate-50 font-bold"> {rank} </div>
        </div>
        <div className="text-xs font-bold">
          {/* <Link fontSize="body-s" href="/dashboards/youtubetrends"> */}
            {title}
          {/* </Link> */}
        </div>
        <div className="text-xs italic text-slate-400">{subtitle}</div>
      </div>
    );
};

export const EngagementScore = ({ rank, title, subtitle }) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex space-x-2 items-center">
          <img src="/svg/engagement.svg" alt="Votes" className="h-8" />
          <div className="text-xl text-slate-50 font-bold"> {rank} </div>
        </div>
        <div className="text-xs font-bold">
          {/* <Link fontSize="body-s" href="/dashboards/youtubetrends"> */}
            {title}
          {/* </Link> */}
        </div>
        <div className="text-xs italic text-slate-400">{subtitle}</div>
      </div>
    );
};

export const PantheonScore = ({ rank, title, subtitle }) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex space-x-2 items-center">
          <img src="/svg/pantheon.svg" alt="Votes" className="h-8" />
          <div className="text-xl text-slate-50 font-bold"> {rank} </div>
        </div>
        <div className="text-xs font-bold">
          {/* <Link fontSize="body-s" href="/dashboards/youtubetrends"> */}
            {title}
          {/* </Link> */}
        </div>
        <div className="text-xs italic text-slate-400">{subtitle}</div>
      </div>
    );
};

export const DemandScore = ({ rank, title, subtitle }) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="flex space-x-2 items-center">
          <img src="/images/demand.png" alt="Votes" className="h-8" />
          <div className="text-xl text-slate-50 font-bold"> {rank} </div>
        </div>
        <div className="text-xs font-bold">
          {/* <Link fontSize="body-s" href="/dashboards/youtubetrends"> */}
            {title}
          {/* </Link> */}
        </div>
        <div className="text-xs italic text-slate-400">{subtitle}</div>
      </div>
    );
}