import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeries } from "./series-non-ott-slice";
import { CustomPagination } from "../../../../components/CustomPagination";
import {
    Grid,
    Container,
    Box,
    PropertyFilter,
    Spinner,
    FormField,
    Link,
    SpaceBetween,
} from "@cloudscape-design/components";
import RottenTomatesCriticScore from "../../../item/components/RTCriticScore";
import RottenTomatesAudienceScore from "../../../item/components/RTAudienceScore";
import IMDbRating from "../../../item/components/IMDbRating";
import WikiScore from "../../../item/components/GlobalScores";
import Genre from "../../../item/components/genre";
import ProductionCompanies from "../../../item/components/ProductionCompanies";

function SeriesNonOTTComponent() {
    const dispatch = useDispatch();
    const {
        seriesNonOtt = [],
        loading,
        error,
    } = useSelector((state) => state.seriesNonOtt);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [query, setQuery] = useState({ operation: "and", tokens: [] });
    const [filteringOptions, setFilteringOptions] = useState([]);


    useEffect(() => {
        dispatch(
            fetchSeries({
                sort: [{ release_date: "desc" }],
                include: [],
                exclude: [],
                includeCondition: "and",
            })
        );
    }, [dispatch]);

    useEffect(() => {
        const genreOptions = seriesNonOtt
            ?.flatMap((series) => series?.genre ?? [])
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((genre) => ({ propertyKey: "genre", value: genre, label: genre }));

        setFilteringOptions(genreOptions ?? []);
        const newPageCount = Math.ceil(seriesNonOtt.length / itemsPerPage);
        if (currentPage > newPageCount) {
            setCurrentPage(1);
        }
    }, [seriesNonOtt, itemsPerPage, currentPage]);

    const filteringProperties = [
        {
            key: "genre",
            propertyLabel: "Genre",
            operators: ["=", "!="],
        },
        {
            key: "production_company",
            propertyLabel: "Production Company",
            operators: ["=", "!="],
        },
        {
            key: "imdb_vote_average",
            propertyLabel: "IMDb Score",
            operators: [">", "<"],
        },
        {
            key: "number_of_episodes",
            propertyLabel: "Number of Episodes",
            operators: [">", "<"],
        },
        {
            key: "number_of_seasons",
            propertyLabel: "Number of Seasons",
            operators: [">", "<"],
        },
        {
            key: "rtCriticSummary.score",
            propertyLabel: "RT Critic Score",
            operators: [">", "<"],
        },
        {
            key: "rtFanSummary.score",
            propertyLabel: "RT Audience Score",
            operators: [">", "<"],
        },
        {
            key: "release_date",
            propertyLabel: "Release Date",
            operators: [">", "<"],
        },
        {
            key: "last_air_date",
            propertyLabel: "Last Air Date",
            operators: [">", "<"],
        },
    ];
    const handleLoadItems = async (detail) => {
        return filteringOptions;
    };

    const handleQueryChange = (event) => {
        const newQuery = event.detail;
        if (!newQuery.tokens) {
            console.error("New query does not have tokens:", newQuery);
            return;
        }

        setQuery(newQuery);

        let includeFilters = [
            { "range": { "release_date": { "lt": "now-1y/d" } } }
        ];
        let releaseDateFilterIndex = 0;
        const excludeFilters = [];

        newQuery.tokens.forEach(token => {
            let filter = token.operator === "=" ?
                { "match": { [token.propertyKey]: token.value } } :
                { "range": { [token.propertyKey]: { [token.operator === "<" ? "lt" : "gt"]: token.value } } };
            if (token.propertyKey === "release_date") {
                filter = {
                    "range": {
                        "release_date": {
                            [token.operator === "<" ? "lt" : "gt"]: token.value
                        }
                    }
                };
                includeFilters[releaseDateFilterIndex] = filter;
            }
            else if (token.propertyKey === "production_company") {
                filter = { "match": { "production_company.name": token.value } };
                includeFilters.push(filter);
            }
            else if (token.operator === "=" || token.operator === "<" || token.operator === ">") {
                includeFilters.push(filter);
            } else if (token.operator === "!=") {
                excludeFilters.push({ "match": { [token.propertyKey]: token.value } });
            }
        });

        dispatch(fetchSeries({
            sort: [{ release_date: "desc" }],
            include: includeFilters,
            exclude: excludeFilters,
            includeCondition: newQuery.operation,
        }));

    };


    if (loading)
        return (
            <Container>
                <Spinner size="large" />
            </Container>
        );
    if (error) return <div>Error: {error}</div>;

    const pageCount = Math.ceil(seriesNonOtt.length / itemsPerPage);
    const currentData = seriesNonOtt.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SpaceBetween direction="vertical" size="m">
            <Container>
                <FormField description="Advanced filter">
                    <PropertyFilter
                        i18nStrings={{
                            applyActionText: "Apply",
                            clearFiltersText: "Clear filters",
                            groupPropertiesText: "Properties",
                            filteringAriaLabel: "Query builder",
                            filteringPlaceholder: "Query builder",
                            operationAndText: "and",
                            operationOrText: "or",
                            operatorContainsText: "contains",
                            operatorDoesNotContainText: "does not contain",
                            operatorDoesNotEqualText: "does not equal",
                            operatorEqualsText: "equals",
                            operatorGreaterOrEqualText: "greater than or equal to",
                            operatorGreaterText: "greater than",
                            operatorLessOrEqualText: "less than or equal to",
                            operatorLessText: "less than",
                            operatorStartsWithText: "starts with",
                        }}
                        expandToViewport
                        filteringProperties={filteringProperties}
                        filteringOptions={filteringOptions}
                        query={query}
                        onChange={handleQueryChange}
                        onLoadItems={handleLoadItems}
                        countText={seriesNonOtt.length >= 500 ? "500+ Matches" : `${seriesNonOtt.length} Matches`}
                    />
                </FormField>
            </Container>
            <Grid
                gridDefinition={currentData.map(() => ({
                    colspan: { xxs: 12, s: 6, l: 4, xl: 3 },
                }))}
            >
                {currentData.map((series, index) => (
                    <Container
                        fitHeight
                        key={index}
                        footer={
                            <div className="flex justify-center items-start gap-5">
                                {series.rtCriticSummary && (
                                    <RottenTomatesCriticScore
                                        rtCriticSummary={series.rtCriticSummary}
                                    />
                                )}
                                {series.rtFanSummary && (
                                    <RottenTomatesAudienceScore
                                        rtFanSummary={series.rtFanSummary}
                                    />
                                )}
                                {!!series.imdb_vote_average && (
                                    <IMDbRating
                                        percent={series.imdb_vote_average}
                                        title="IMDb SCORE"
                                        subtitle={`${series.imdb_vote_count.toLocaleString()} votes`}
                                    />
                                )}
                            </div>
                        }
                    >
                        <div className="text-slate-400 text-xs">
                            Released:{" "}
                            {new Date(series.release_date).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            })}
                            {" "}&bull; Last Aired on{" "}
                            {new Date(series.last_air_date).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            })}
                        </div>
                        <Box margin={{ bottom: "xxs" }}>
                            <div className="text-base font-bold">
                                <Link
                                    color="inverted"
                                    fontSize="inherit"
                                    href={`/item/${series.ip_id}`}
                                >
                                    {series.name}
                                </Link>
                            </div>
                        </Box>
                        <Box margin={{ bottom: "xs" }}>
                            <div className="text-slate-200 text-xs">
                                {series.number_of_seasons} Seasons •{" "}
                                {series.number_of_episodes} Episodes
                            </div>
                        </Box>
                        <Box margin={{ bottom: "xs" }}>
                            <Genre item={series.genre} />
                        </Box>
                        <Box margin={{ bottom: "xs" }}>
                            <ProductionCompanies item={series.production_company} />
                        </Box>
                        {series.homepage && (
                            <Box>
                                <Link
                                    color="inverted"
                                    variant="info"
                                    href={series.homepage}
                                    external
                                >
                                    Homepage
                                </Link>
                            </Box>
                        )}
                    </Container>
                ))}
            </Grid>
            <CustomPagination
                currentPage={currentPage}
                totalPages={pageCount}
                onNext={() => setCurrentPage(currentPage + 1)}
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onPageClick={setCurrentPage}
            />
        </SpaceBetween>
    );
}

export default SeriesNonOTTComponent;
