import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import JWStreamingChartsPage from "./jw-streaming-charts";

function JWStreamingChartsIndexPage() {
  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Viewer's Pulse", href: "/" },
    { text: "OTT Streaming Charts" },
  ];
  return (
    <>
      <Helmet>
        <title>OTT Streaming Charts</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  description="The streaming charts are calculated by user activity on JustWatch from the last 7 days. This includes clicking on a streaming offer, adding a title to a watchlist, and marking a title as ‘seen’ in JustWatch."
                  variant="h2"
                >
                  OTT Streaming Charts
                </Header>
                <CustomFlashBar />
              </>
            }
          >
            <JWStreamingChartsPage />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default JWStreamingChartsIndexPage;
