import { Badge } from "@cloudscape-design/components";

export const navItems = [
  {
    type: "section-group",
    title: "Dashboard",
    items: [
      {
        type: "expandable-link-group",
        text: "Viewer's Pulse",
        href: "/",
        items: [
          {
            type: "link",
            text: "Personal Dashboard",
            href: "/dashboards/wizard",
          },
          {
            type: "link",
            text: "OTT Streaming Chart",
            href: "/dashboards/jwstreaming",
          },
          {
            type: "link",
            text: "IMDb User Engagement",
            href: "/dashboards/imdbtrends",
          },
          {
            type: "link",
            text: "RT User Engagement",
            href: "/dashboards/rttrends",
          },
          {
            type: "link",
            text: "Wikipedia User Search",
            href: "/dashboards/wikipediatrends",
          },
          {
            type: "link",
            text: "Twitch Stats",
            href: "/dashboards/twitchtrends",
          },
          {
            type: "link",
            text: "YouTube Stats",
            href: "/dashboards/youtubetrends",
          },
          { type: "link", text: "Piracy Rankings", href: "/dashboards/piracy" },
        ],
      },
      {
        type: "expandable-link-group",
        text: "Global Ranking",
        href: "/dashboards/globalranking",
        items: [
          {
            type: "link",
            text: "Pantheon Ranking",
            href: "/dashboards/globalranking/pantheonranking",
            info: <Badge color="red">WIP</Badge >,
          },
          {
            type: "link",
            text: "RawData Table",
            href: "/dashboards/globalranking/raw-data",
          },
        ],
      },
      {
        type: "expandable-link-group",
        text: "Box Office",
        href: "/box-office",
        items: [
          { type: "link", text: "Ranking", href: "/box-office/ranking" },
          {
            type: "link",
            text: "Timeseries",
            href: "/box-office/grosstimeseries",
          },
          {
            type: "link",
            text: "By Distributor",
            href: "/box-office/bo-by-studio",
            info: <Badge color="red">WIP</Badge >,
          },
        ],
      },
      {
        type: "expandable-link-group",
        text: "Peacock Dashboard",
        href: "/peacock-dashboard",
        items: [
          {
            type: "expandable-link-group",
            text: "Series Analysis",
            items: [
              {
                type: "link",
                text: "Genre Insights",
                href: "/tar/series/wiki/view/genre",
              },
              {
                type: "link",
                text: "Platform Influence",
                href: "/tar/series/wiki/view/genre/platform",
              },
              {
                type: "link",
                text: "Platform Ratio by Genre",
                href: "/tar/series/wiki/view/genre/platform/marketshare",
              },
            ],
          },
          {
            type: "link",
            text: "Series: Beyond OTT",
            href: "/peacock-dashboard/non-ott-titles/series",
          },
          {
            type: "link",
            text: "Movies: Unstreamed Picks",
            href: "/peacock-dashboard/non-ott-titles/movies",
          },
        ],
      },
      {
        type: "expandable-link-group",
        text: "Tracked Titles",
        href: "/dashboards/trackedtitles",
        items: [
          {
            type: "link",
            text: "Pantheon Ranking",
            href: "/dashboards/pantheonranking",
          },
          {
            type: "link",
            text: "Raw Data Table",
            href: "/dashboards/raw-data",
          },
        ],
      },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Platforms",
    items: [
      // {
      //   type: "expandable-link-group",
      //   text: "Search Demand",
      //   items: [
      //     {
      //       type: "link",
      //       text: "Wikipedia",
      //       href: "/platforms/wikipedia",
      //     },
      //     {
      //       type: "link",
      //       text: "Google Trends",
      //       href: "/platforms/googletrends",
      //     },
      //   ],
      // },
      {
        type: "link",
        text: "Wikipedia",
        href: "/platforms/wikipedia",
      },
      {
        type: "link",
        text: "Google Trends",
        href: "/platforms/googletrends",
      },
      {
        type: "link",
        text: "Google Realtime",
        href: "/platforms/googletrends/realtime",
      },
      {
        type: "link",
        text: "X Query Search",
        href: "/platforms/content-consumption/twitter/query-tool",
      },
      {
        type: "link",
        text: "Amazon Search",
        href: "/platforms/consumer-products/amazon-search",
        info: <Badge color="green">POC</Badge >,
      },
      {
        type: "link",
        text: "Piracy",
        href: "/platforms/piracy/generic/us/summary",
      },
      {
        type: "link",
        text: "IMDb",
        href: "/platforms/imdb",
        info: <Badge color="red">WIP</Badge >,
      },
      {
        type: "expandable-link-group",
        text: "Raw Data",
        items: [
          {
            type: "link",
            text: "Wikipedia",
            href: "/platforms/wikipedia/raw-data",
          },
          {
            type: "link",
            text: "Google Trends",
            href: "/platforms/google/raw-data",
          },
          {
            type: "link",
            text: "X (Twitter)",
            href: "/platforms/content-consumption/twitter/raw-data",
          },
          {
            type: "link",
            text: "TikTok",
            href: "/platforms/content-consumption/tiktok/raw-data",
          },
          {
            type: "link",
            text: "Instagram",
            href: "/platforms/content-consumption/instagram/raw-data",
          },
          {
            type: "link",
            text: "Reddit",
            href: "/platforms/fan-sites/reddit/raw-data",
          },
          {
            type: "link",
            text: "Fandom",
            href: "/platforms/fan-sites/fandom/raw-data",
          },
          {
            type: "link",
            text: "Wattpad",
            href: "/platforms/fan-sites/wattpad/raw-data",
          },
          {
            type: "link",
            text: "AO3",
            href: "/platforms/fan-sites/ao3/raw-data",
          },
        ],
      },
      {
        type: "expandable-link-group",
        text: "Topic Modeling",
        items: [
          {
            type: "link",
            text: "General",
            href: "/topic-modeling/pantheon",
          },
          {
            type: "link",
            text: "True Crime",
            href: "/topic-modeling/true-crime",
          },
        ],
      },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Media Catalog",
    items: [
      {
        type: "expandable-link-group",
        text: "Explore",
        href: "/explore",
        items: [
          { type: "link", text: "Movies", href: "/explore/movies" },
          { type: "link", text: "Series", href: "/explore/television" },
          { type: "link", text: "Games", href: "/explore/games" },
          { type: "link", text: "Franchises", href: "/explore/franchise" },
          { type: "link", text: "Companies", href: "/explore/companies" },
        ],
      },
      {
        type: "expandable-link-group",
        text: "OTT Availability",
        items: [
          {
            type: "link",
            text: "Movies",
            href: "/tar/movie/titleavailability",
          },
          {
            type: "link",
            text: "Series",
            href: "/tar/television/titleavailability",
          },
        ],
      },
      {
        type: "expandable-link-group",
        text: "YouTube",
        href: "/mediacatalog/youtube",
        items: [
          {
            type: "link",
            text: "Channels",
            href: "/mediacatalog/youtube/channels",
          },
          {
            type: "link",
            text: "Movie Trailers",
            href: "/mediacatalog/youtube/movietrailers",
          },
          {
            type: "link",
            text: "TV Show Trailers",
            href: "/mediacatalog/youtube/seriestrailers",
          },
          {
            type: "link",
            text: "Game Trailers",
            href: "/mediacatalog/youtube/gamestrailers",
          },
          {
            type: "link",
            text: "Kids Trailers",
            href: "/mediacatalog/youtube/kidstrailers",
          },
          {
            type: "link",
            text: "Un-Mapped Trailers",
            href: "/mediacatalog/youtube/unmappedtrailers",
          },
          {
            type: "link",
            text: "Unofficial Trailers",
            href: "/mediacatalog/youtube/unofficialtrailers",
          },
          {
            type: "link",
            text: "All Trailers",
            href: "/mediacatalog/youtube/alltrailers",
          },
        ],
      },
    ],
  },
];
