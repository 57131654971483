import axiosInstance from "../../../../utils/axios";
import { ID_TYPES, checkIdType } from "../utils/checkIdType";

export const createMovie = (id: string) => {
  const idType = checkIdType(id);

  const url = `/movies?${idType === ID_TYPES.IMDB ? "imdb_id" : "id"}=${id}`;

  return axiosInstance.request({
    url,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
  });
};
