import {
    AppLayout,
    BreadcrumbGroup,
    ContentLayout,
    Header,
    SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../layouts/common/menu/side-menu";
import IndexPageGrid from "../../components/IndexPageGrid";

function ExploreCards() {
    const items = [
        {
            link: "/explore/movies",
            image: "/images/movie_catalog-min.png",
            name: "Movies",
            alt: "movies",
            description:
                "Access comprehensive analytics for cinematic content, encompassing metadata, performance trends, and audience sentiment analysis sourced from IMDb, Rotten Tomatoes, search trends, and social media engagement metrics.",
        },
        {
            link: "/explore/television",
            image: "/images/television_series-min.png",
            name: "Television Series",
            alt: "series",
            description:
                "Gain insights into television series popularity and viewer engagement. Our analytics dashboard aggregates data from social media buzz, search trends, rating aggregators, and more to deliver a data-driven perspective on TV shows.",
        },
        {
            link: "/explore/games",
            image: "/images/games-min.png",
            name: "Video Games",
            alt: "games",
            description:
                "Uncover gaming trends and user engagement with our in-depth analytics tools. We compile data from gaming platforms, social media, and critic reviews to provide a multifaceted view of the video game industry.",
        },
        {
            link: "/explore/franchise",
            image: "/images/franchise-min.png",
            name: "Media Franchises",
            alt: "franchise",
            description:
                "Explore the vast data landscape of media franchises. From box office numbers to online presence, our platform provides detailed trend analysis, sentiment scoring, and engagement statistics for the biggest names in entertainment.",
        },
        {
            link: "/explore/companies",
            image: "/images/companies-min.png",
            name: "Companies",
            alt: "companies",
            description:
                "Investigate production companies with our analytical profiles. We combine data from industry databases, financial reports, and social trends to give you a clear picture of the business side of media production.",
        },
        {
            link: "#",
            image: "/images/talent-min.png",
            name: "Talent",
            alt: "talent",
            description:
                "Coming Soon",
        },
        {
            link: "#",
            image: "/images/books-min.png",
            name: "Books",
            alt: "books",
            description:
                "Coming Soon",
        }
    ];

    const gridDefinition = items.map(() => ({ colspan: { xxs: 12, xs: 6, m: 3, xl: 2 } }));

    return (
        <IndexPageGrid items={items} gridDefinition={gridDefinition} />
    );
}
function ExploreHomePage() {
    const breadcrumbs = [{ text: "Media Catalog"},{ text: "Explore" }];
    return (
        <>
            <Helmet>
                <title>Explore</title>
            </Helmet>
            <AppLayout
                stickyNotifications
                toolsHide
                contentType="cards"
                ariaLabels={{ navigationClose: "close" }}
                navigation={
                    <SideNavigation
                        activeHref={window.location.pathname}
                        items={navItems}
                    />
                }
                content={
                    <ContentLayout
                        disableOverlap
                        header={
                            <Header
                                variant="h2"
                                description="Explore enables you to discover an array of movies, series, games, and franchises, all through customizable filters and specific metadata parameters. Dive into a world of data-driven insights and uncover trends that shape the entertainment landscape."
                            >
                                Explore
                            </Header>
                        }
                    >
                        <ExploreCards />
                    </ContentLayout>
                }
                breadcrumbs={
                    <BreadcrumbGroup
                        items={breadcrumbs}
                        expandAriaLabel="Show path"
                        ariaLabel="Breadcrumbs"
                    />
                }
            />
        </>
    );
}

export default ExploreHomePage;