import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

export const fetchTelevisionData = createAsyncThunk(
  "televisionData/fetchTelevisionData",
  async ({ from, size, range }) => {
    const response = await axiosInstance.request({
      url: `explore/Television?from=${from}&size=${size}${
        range ? `&gte=${range.gte}&lte=${range.lte}` : ""
      }`,
      method: "GET",
    });
    return response.data;
  }
);

export const fetchFilteredTelevisionData = createAsyncThunk(
  "televisionData/fetchFilteredTelevisionData",
  async ({ from, size, range, body }) => {
    const response = await axiosInstance.post(
      `explore/Television?from=${from}&size=${size}${range ? range : ""}`,
      { ...body }
    );
    return response.data;
  }
);

const televisionSlice = createSlice({
  name: "televisionData",
  initialState: {
    data: [],
    total: 0,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTelevisionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTelevisionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchTelevisionData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFilteredTelevisionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilteredTelevisionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchFilteredTelevisionData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default televisionSlice.reducer;
