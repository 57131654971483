import React from 'react';
import { AttributeEditor, Box, Button, Modal, SpaceBetween, TagEditor } from "@cloudscape-design/components";
import { TAGS_LIST } from "../../../config-global";
import _ from "lodash";

export const AttributesEditModal = ({ visible, onDiscard, tags, onSubmit, loading }) => {
  const [currentTags, setCurrentTags] = React.useState(tags);

  // Simplified comparator for checking tag equality
  const isSameTag = (tag1, tag2) => tag1.key === tag2.key;

  return (
    <Modal
      size="max"
      visible={visible}
      onDismiss={onDiscard}
      header={"Add/Edit Attributes"}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>Cancel</Button>
            <Button
              variant="primary"
              onClick={() => {
                const removedTags = _.differenceWith(tags, currentTags, isSameTag);
                const addedOrUpdatedTags = _.differenceWith(currentTags, tags, _.isEqual);

                const updates = _.reduce(addedOrUpdatedTags, (result, tag) => {
                  result[tag.key] = tag.value;
                  return result;
                }, {});

                onSubmit({ update: updates, remove: _.map(removedTags, "key") });
              }}
              disabled={loading}
              loading={loading}
              loadingText={"Processing"}
              data-testid="submit"
            >
              {loading ? "Submitting" : "Submit"}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <p>
        This interface allows users to assign and manage tags. Tags can include optional values representing specific details or identifiers. They serve as metadata to enhance searchability, categorization, and relevance in backend processes.
      </p>
      <br />
      <p>
        To remove a tag-value pair, click the "Remove" button next to it. Set your tags and values as needed, then click 'Submit' to save changes or 'Cancel' to discard.
      </p>
      <br />
      <TagEditor
        onChange={({ detail }) => setCurrentTags(detail.tags)}
        i18nStrings={{
          removeButton: "Remove",
          addButton: "Add New Tag",
          keyHeader: "Tag",
          valueHeader: "Value",
          optional: "Optional",
          undoButton: "Undo",
          undoPrompt: "This tag will be removed upon saving changes",
          duplicateKeyError: "Unique tag key required.",
          emptyKeyError: "Tag key required.",
          emptyValueError: "Tag value required.",
        }}
        tags={currentTags}
        keysRequest={() => Promise.resolve(TAGS_LIST)}
        valuesRequest={(key, value) => key ? Promise.resolve([]) : Promise.reject()}
      />
    </Modal>
  );
};
