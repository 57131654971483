import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTrackedFandomDataApi = createAsyncThunk(
  "trackedFandomTrends/fetchTrackedFandomDataApi",

  async ({ params }) => {
    const response = await axiosInstance.get(
      `fandom/${params.ip_id}?gte=${params.startDate}&lte=${params.endDate}&fetch=ByID`
    );
    return response;
  }
);

const trackedFandomDataSlice = createSlice({
  name: "trackedFandomTrends",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackedFandomDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrackedFandomDataApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        _.set(state, "data", action.payload.data);
      })
      .addCase(fetchTrackedFandomDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const trackedFandomDataReducer = trackedFandomDataSlice.reducer;

export default trackedFandomDataReducer;
