import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getPiracyData } from "../request";

export const useGetPiracyData = (vertical: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_PIRACY_DATA,vertical],
    () => getPiracyData(vertical),
  );

  return {
    data,
    isLoading,
    error,
  };
};
