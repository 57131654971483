import { 
  AppLayout,
  ContentLayout,
  Container,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Button,
  SpaceBetween,
  Select,
  FormField,
  StatusIndicator,
  Popover,
  Spinner,
  ColumnLayout,
  Toggle,
} from "@cloudscape-design/components";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { colorPalette } from "./chartConfig";
import ResultsTable from "./ResultsTable";
import ResultsChart from "./ResultsChart";
import { slugify } from "./utils";
  
const TwitterQueryToolResults = () => {
  
  const { id } = useParams();

  const flashbarRef = useRef(null);
  const timeseriesTableRef = useRef(null);


  const platformName = "X";
  const postName = platformName === "Twitter" ? "Tweet" : "Post";
  const [ breadcrumbs, setBreadcrumbs ] = useState([{ text: "Platforms", }, { text: platformName, href: "/platforms/content-consumption/twitter" }, { text: "Query Tool", href: "/platforms/content-consumption/twitter/query-tool", }, { text: "Search", href: "/", },]);
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const granularityOptions = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ];
  const axisTypeOptions = [
    { label: "Linear", value: "number" },
    { label: "Logarithmic", value: "log" },
  ];
  const seriesTypeOptions = [
    { label: "Line", value: "line" },
    { label: "Bar", value: "bar" },
  ];

  const [ getJobResponse, setGetJobResponse ] = useState(defaultResponseState);

  const [ timeseriesTableData, setTimeseriesTableData ] = useState([]);
  const [ timeseriesChartData, setTimeseriesChartData ] = useState([]);
  const [ seriesColors, setSeriesColors ] = useState({});
  const [ chartGranularity, setChartGranularity ] = useState(granularityOptions[1]);
  const [ chartAxisType, setChartAxisType ] = useState(axisTypeOptions[0]);
  const [ chartSeriesType, setChartSeriesType ] = useState(seriesTypeOptions[0]);
  const [ chartShowMarkers, setChartShowMarkers ] = useState(false);
  const [ selectedQueryRows, setSelectedQueryRows ] = useState([]);


  const processJobData = (data) => {
    setBreadcrumbs([{ text: "Platforms", }, { text: platformName, href: "/platforms/content-consumption/twitter" }, { text: "Query Tool", href: "/platforms/content-consumption/twitter/query-tool", }, { text: `Search: ${data.name}`, href: "/", },]);

    const timeseriesTable = data.parameters.queries.map((query, queryIndex) => {
      const point = { 
        query: query.query, 
        query_id: query.query_id, 
        color: colorPalette[queryIndex % colorPalette.length],
      };
      const timestamps = {};
    
      data.timeseries.forEach(dataPoint => {
        point.total = (point.total || 0) + dataPoint.data[queryIndex];
        timestamps[dataPoint.timestamp] = dataPoint.data[queryIndex];
      });
    
      return { ...point, ...timestamps };
    });
    setTimeseriesTableData(timeseriesTable);

    const timeseriesChart = data.timeseries.map(dataPoint => (
      {
        timestamp: moment.utc(dataPoint.timestamp * 1000).local(true).unix() * 1000,
        ...dataPoint.data.map((value, index) => (
          { [data.parameters.queries[index].query_id]: value }
        )).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
      }
    ));
    setTimeseriesChartData(timeseriesChart);

    setSeriesColors(timeseriesTable.map(row => ({ [row.query]: row.color })).reduce((acc, obj) => ({ ...acc, ...obj }), {}));

    const totalDays = moment.utc(data.timeseries.slice(-1)[0].timestamp * 1000).diff(moment.utc(data.timeseries[0].timestamp * 1000), "days");
    if (totalDays <= 365) {
      setChartGranularity(granularityOptions[0]);
    } else if (totalDays >= 365 * 3) {
      setChartGranularity(granularityOptions[2]);
    }
  };


  const getJob = (jobId) => {  
    setGetJobResponse({ ...getJobResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/twitter/querytool/getjob",
      params: {
        id: jobId,
        fetch_results: true,
      }
    }).then(response => {
      setGetJobResponse({ ...getJobResponse, loading: false, completed: true, data: response.data, error: null });
      processJobData(response.data);
    }).catch(e => {
      setGetJobResponse({ ...getJobResponse, loading: false, completed: true, error: e });
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to get search job.", "Failed to get search job");
    });
  };


  useEffect(() => {
    if (!id) return;
    getJob(id);
  }, [id]);


  return (
    <>
      <Helmet><title>{getJobResponse?.data ? getJobResponse.data.name : "Loading..."}</title></Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <>
            <ContentLayout
              header={
                <>
                  <PantheonFlashbar ref={flashbarRef} />
                  <Header
                    actions={
                      <Button
                        loading={getJobResponse.loading}
                        onClick={() => {
                          const spreadsheets = [
                            timeseriesTableRef.current.api.getSheetDataForExcel({ sheetName: "Post Counts" }),
                          ];
                          timeseriesTableRef.current.api.exportMultipleSheetsAsExcel({
                            data: spreadsheets,
                            fileName: `${slugify(getJobResponse.data.name).slice(0, 32)}_${moment().format("YYYY-MM-DD")}.xlsx`,
                          });
                        }}
                      >Download</Button>
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <span>{getJobResponse?.data ? getJobResponse.data.name : "Loading..."}</span>
                      {getJobResponse?.data?.shared_with?.length > 0 && (
                        <Popover
                          dismissButton={false}
                          position="right"
                          triggerType="custom"
                          size="large"
                          content={
                            <div className="space-y-2">
                              <p>
                                Shared with: <b>{getJobResponse.data.shared_with.join(", ")}</b>
                              </p>
                            </div>
                          }
                        >
                          <Button iconName="group-active" variant="inline-icon" />
                        </Popover>
                      )}
                    </div>
                  </Header>
                </>
              }
            >
              <SpaceBetween size="m">
                <Container>
                  <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="m">
                      <FormField
                        label="Name"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : (
                          getJobResponse?.data?.name
                        )}
                      </FormField>
                      <FormField
                        label="Status"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : getJobResponse?.data?.status === "RUNNING" ? (
                          <StatusIndicator type="pending">
                            Running
                          </StatusIndicator>
                        ) : getJobResponse?.data?.status === "FINISHED" ? (
                          <StatusIndicator>
                            Finished
                          </StatusIndicator>
                        ) : getJobResponse?.data?.status === "FAILED" ? (
                          <StatusIndicator type="error">
                            Failed
                          </StatusIndicator>
                        ) : null}
                      </FormField>
                      <FormField
                        label="Started at"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : (
                          new Date(getJobResponse?.data?.created_at * 1000).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true })
                        )}
                      </FormField>
                    </SpaceBetween>
                    <SpaceBetween size="m">
                      <FormField
                        label="Started by"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : (
                          getJobResponse?.data?.created_by
                        )}
                      </FormField>
                      <FormField
                        label="Search date range"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : (
                          new Date(getJobResponse?.data?.parameters?.start_ts * 1000).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", timeZone: "UTC" }) + " to " + moment.utc(getJobResponse?.data?.parameters?.end_ts * 1000).toDate().toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", timeZone: "UTC" })
                        )}
                      </FormField>
                      <FormField
                        label="Number of queries"
                      >
                        {getJobResponse?.loading ? (
                          <Spinner />
                        ) : (
                          getJobResponse?.data?.parameters?.queries?.length
                        )}
                      </FormField>
                    </SpaceBetween>
                  </ColumnLayout>
                </Container>
                <Container
                  header={
                    <Header
                      description={`Number of ${postName.toLowerCase()}s matching each query over time`}
                      actions={
                        <div className="flex space-x-2 items-center">
                          <Toggle
                            checked={chartShowMarkers}
                            onChange={({ detail }) => setChartShowMarkers(detail.checked)}
                          >Show markers</Toggle>
                          <Select
                            options={seriesTypeOptions}
                            selectedOption={chartSeriesType}
                            onChange={({ detail }) => setChartSeriesType(detail.selectedOption)}
                          />
                          <Select
                            options={granularityOptions}
                            selectedOption={chartGranularity}
                            onChange={({ detail }) => setChartGranularity(detail.selectedOption)}
                          />
                          <Select
                            options={axisTypeOptions}
                            selectedOption={chartAxisType}
                            onChange={({ detail }) => setChartAxisType(detail.selectedOption)}
                          />
                        </div>
                      }
                    >
                      {postName} count over time
                      {" "}
                      <Popover
                        position="right"
                        size="small"
                        triggerType="custom"
                        content={
                          <div>Click any point on the chart to open a new {platformName} tab and see {postName.toLowerCase()}s from that time period</div>
                        }
                      >
                        <Button iconName="status-info" variant="inline-icon" />
                      </Popover>
                    </Header>
                  }
                >
                  {getJobResponse?.loading ? (
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div> 
                  ) : getJobResponse?.data?.parameters ? (
                    <>
                      <ResultsChart
                        seriesData={timeseriesChartData}
                        granularity={chartGranularity.value}
                        queryData={getJobResponse.data.parameters.queries}
                        selectedQueryData={selectedQueryRows.map(node => node.data.query_id)}
                        axisType={chartAxisType.value}
                        seriesColors={seriesColors}
                        postName={postName}
                        showMarkers={chartShowMarkers}
                        seriesType={chartSeriesType.value}
                      />
                      <ResultsTable
                        ref={timeseriesTableRef}
                        seriesData={timeseriesTableData}
                        onSelectionChanged={(e) => {
                          setSelectedQueryRows(e.api.getSelectedNodes());
                        }}
                        onFirstDataRendered={(e) => {
                          const nodesToSelect = [];
                          e.api.forEachNode((node, i) => {
                            if (i < 5)
                              nodesToSelect.push(node);
                          });
                          e.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
                        }}
                      />
                    </>
                  ) : (null)}
                </Container>
              </SpaceBetween>
            </ContentLayout>
          </>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TwitterQueryToolResults;