import { dispatch } from "../../../../app/store";
import { fetchMoviesData, fetchFilteredMoviesData } from "./movies_slice";

export async function getMoviesData(from, size, range) {
  await dispatch(fetchMoviesData({ from, size, range }));
}

export async function getFilteredMoviesData(from, size, range, body) {
  await dispatch(fetchFilteredMoviesData({ from, size, range, body }));
}
