import {
  AppLayout,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";

import { MoviesHome } from "./MoviesHome";

const breadcrumbs = [
  { text: "Media Catalog" },
  { text: "Explore", href: "/explore" },
  { text: "Movies" },
];

export default function ExploreMovies() {
  return (
    <>
      <Helmet>
        <title>Explore Movies</title>
      </Helmet>

      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={<MoviesHome />}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}
