import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

// Create an asynchronous thunk for fetching Google Trends data
export const fetchTrackedGoogleTrendsDataApi = createAsyncThunk(
  "trackedGoogleTrends/fetchTrackedGoogleTrendsDataApi",

  async ({ params }) => {

    try {
      const response = await axiosInstance.get(
        `googletrends/${params?.ip_id}?gte=${params?.startDate}&lte=${params?.endDate}&fetch=ByID`
      );
      return response.data; // Return the data from the response
    } catch (error) {
      throw error; // Propagate the error to be handled in the rejected case
    }
  }
);

const trackedGoogleTrendsSlice = createSlice({
  name: "trackedGoogleTrends",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackedGoogleTrendsDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrackedGoogleTrendsDataApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload; // Update state with the fetched data
      })
      .addCase(fetchTrackedGoogleTrendsDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const trackedGoogleTrendsReducer = trackedGoogleTrendsSlice.reducer;

export default trackedGoogleTrendsReducer;
