import React, { useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ReactQueryWrapper } from "../src/app/ReactQueryWrapper.tsx";
import "./App.css";
import { persistor, store } from "./app/store";
import { AuthProvider } from "./auth/AwsCognitoContext";
import SnackBar from "./components/notify/SnackBar/SnackBar";
import SnackbarContext from "./hooks/notify/snack-bar/snackbar-context";
import Navigator from "./router/Navigator";

function App() {
  const snackbarCtx = useContext(SnackbarContext);
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);

  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <ReactQueryWrapper>
            <PersistGate loading={null} persistor={persistor}>
              <Navigator />
              {snackbarCtx.isDisplayed && <SnackBar />}
            </PersistGate>
          </ReactQueryWrapper>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
