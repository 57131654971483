import { isSomeOrElse } from "../../../../utils/sugarUtils.ts";

export const mapResponseToFormValue = ({ data }) => {
  return {
    wikipedia: {
      weightage: Number(isSomeOrElse(data.wiki?.weightage, 0)),
      isChecked: isSomeOrElse(data.wiki?.isChecked, false),
    },
    youtubeViews: {
      weightage: Number(isSomeOrElse(data.youtubeViews?.weightage, 0)),
      isChecked: isSomeOrElse(data.youtubeViews?.isChecked, false),
    },
    p2p: {
      weightage: Number(isSomeOrElse(data.p2p?.weightage, 0)),
      isChecked: isSomeOrElse(data.p2p?.isChecked, false),
    },
    twitch: {
      weightage: Number(isSomeOrElse(data.twitch?.weightage, 0)),
      isChecked: isSomeOrElse(data.twitch?.isChecked, false),
    },
    imdb: {
      weightage: Number(isSomeOrElse(data.imdb?.weightage, 0)),
      isChecked: isSomeOrElse(data.imdb?.isChecked, false),
    },
    rottenTomatoes: {
      weightage: Number(isSomeOrElse(data.rottenTomatoes?.weightage, 0)),
      isChecked: isSomeOrElse(data.rottenTomatoes?.isChecked, false),
    },
    youtubeLikes: {
      weightage: Number(isSomeOrElse(data.youtubeLikes?.weightage, 0)),
      isChecked: isSomeOrElse(data.youtubeLikes?.isChecked, false),
    },
    youtubeComments: {
      weightage: Number(isSomeOrElse(data.youtubeComments?.weightage, 0)),
      isChecked: isSomeOrElse(data.youtubeComments?.isChecked, false),
    },
  };
};
