import { Autosuggest } from "@cloudscape-design/components";
import React, { FC, useContext, useEffect } from "react";
import { useTopSearchData } from "../api/hooks/useTopSearch";
import { TopSearchResponseType } from "../api/request";
import "./AutoSuggestEditor.css";
import { YoutubeVideosContext } from "../../Layout";

type AutoSuggestEditorProps = {
  videoId: string;
};

export const AutoSuggestEditor: FC<AutoSuggestEditorProps> = ({ videoId }) => {
  const [value, setValue] = React.useState("");
  const { modifiedData, setModifiedData } = useContext(YoutubeVideosContext);
  const { data: topSearchData, isLoading, error } = useTopSearchData(value);

  useEffect(() => {
    if (Object.keys(modifiedData).length === 0) setValue("");
  }, [modifiedData]);

  const getStatusType = () => {
    if (error) return "error";
    if (isLoading) return "loading";
    if (topSearchData.length > 0) return "finished";

    return "pending";
  };

  const getMappedData = (data: Array<TopSearchResponseType>) =>
    data.map(({ ip, vertical, ip_id, release_date }) => ({
      value: ip,
      description: `${vertical}  • ${new Date(
        release_date,
      ).getFullYear()}  • ${ip_id}`,
    }));

  return (
    <Autosuggest
      onChange={({ detail }) => {
        setValue(detail.value);

        if (detail.value === "") {
          delete modifiedData[videoId];
          setModifiedData({ ...modifiedData });
        }
      }}
      onSelect={({ detail }) => {
        const { ip, ip_id } = topSearchData?.find(
          (item: TopSearchResponseType) =>
            detail.selectedOption.description.includes(item.ip_id),
        );
        const newModifiedValue = {
          ...modifiedData,
          [videoId]: {
            ip,
            ip_id,
          },
        };

        setModifiedData(newModifiedValue);
      }}
      value={value}
      options={getMappedData(topSearchData)}
      ariaLabel="Autosuggest example with suggestions"
      placeholder="Enter IP"
      empty="No matches found"
      expandToViewport
      statusType={getStatusType()}
    />
  );
};
