import React from 'react';
import ExpandableSection  from '@cloudscape-design/components/expandable-section';
import Icon from '@cloudscape-design/components/icon';
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { AgChartsReact } from "ag-charts-react";
import "ag-grid-enterprise";
import moment from 'moment';
import {
    IncreaseIcon,
    DecreaseIcon,
    NeutralIcon,
} from "../../../components/Icons";
import { SpaceBetween } from '@cloudscape-design/components';


const PiracyComponent = ({ itemData, piracyTrends, piracyTrendsChartOption, piracyDateRange, setPiracyDateRange }) => {
    const mapping = {
        "Last 7 days": ["rank_7days", "us_rank_7days", "vertical_rank_7days"],
        "Current Year": ["rank_current", "us_rank_current", "vertical_rank_current"],
        "All Time": ["rank_lifetime", "us_rank_lifetime", "vertical_rank_lifetime"],
    };
    const categories = [
        { title: "Global Ranking", index: 0 },
        { title: "US Ranking", index: 1 },
        { title: `${itemData.vertical} Ranking`, index: 2 },
    ];

    const renderStats = (index) => {
        let hasData = false;
        const stats = Object.keys(mapping).map((key) => {
            const currentRank = itemData.piracy_ranker?.[mapping[key][index]];
            const previousRank =
                itemData.piracy_ranker?.[`prev_${mapping[key][index]}`];
            const rankDifference =
                previousRank !== undefined ? previousRank - currentRank : null;

            if (currentRank !== undefined && currentRank !== 0) {
                hasData = true;
                return (
                    <div
                        key={key}
                        className="overflow-hidden rounded-2xl bg-darkerBg shadow-lg px-10 py-2 "
                    >
                        <dt className="truncate text-sm font-medium text-gray-200">
                            {key}
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between">
                            <div className="flex items-baseline">
                                <span className="text-lg font-semibold tracking-tight text-gray-100">
                                    # {currentRank}
                                </span>
                                {previousRank !== undefined && (
                                    <span className="ml-2 text-sm font-medium text-gray-500">
                                        from # {previousRank}
                                    </span>
                                )}
                            </div>
                            {rankDifference !== null ? (
                                <div className="inline-flex items-center justify-center rounded-full text-md font-medium">
                                    <div className="pr-1">
                                        {rankDifference > 0 ? (
                                            <Icon svg={<IncreaseIcon />} variant="success" />
                                        ) : rankDifference < 0 ? (
                                            <Icon svg={<DecreaseIcon />} variant="error" />
                                        ) : (
                                            <Icon svg={<NeutralIcon />} variant="subtle" />
                                        )}
                                    </div>
                                    <div className="pl-1">{Math.abs(rankDifference)}</div>
                                </div>
                            ) : null}
                        </dd>
                    </div>
                );
            } else {
                return (
                    <div
                        key={key}
                        className="overflow-hidden rounded-2xl bg-darkerBg shadow-lg px-4 py-5 sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-200">
                            {key}
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between">
                            <span className="text-lg font-semibold tracking-tight text-red-300">
                                N/A
                            </span>
                        </dd>
                    </div>
                );
            }
        });

        return { stats, hasData };
    };

    return (
        <>
            {piracyTrends?.data?.length > 0 ? (
                <SpaceBetween direction="vertical" size="l">
                <ExpandableSection
                    // headerActions={
                    //     <RangeDateSelector
                    //         defaults={piracyDateRange}
                    //         onChange={(e) => {
                    //             const formattedStartDate = moment(
                    //                 e.startDate
                    //             ).format("YYYY-MM-DD");
                    //             const formattedEndDate = moment(
                    //                 e.endDate
                    //             ).format("YYYY-MM-DD");
                    //             setPiracyDateRange({
                    //                 ...e,
                    //                 startDate: formattedStartDate,
                    //                 endDate: formattedEndDate,
                    //             });
                    //         }}
                    //     />
                    // }
                    variant="container"
                    defaultExpanded
                    headerText="Piracy Trends"
                >
                    {piracyTrendsChartOption && (
                        <div className="h-96">
                            <AgChartsReact
                                options={piracyTrendsChartOption}
                            />
                        </div>
                    )}
                    </ExpandableSection>
                    <div>
                        {categories.map((category) => {
                            const { stats, hasData } = renderStats(
                                category.index
                            );

                            return (
                                <>
                                    {hasData && (
                                        <h3 className="text-base font-semibold leading-6 text-blue-300 mt-4">
                                            {category.title}
                                        </h3>
                                    )}
                                    <dl className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                                        {stats}
                                    </dl>
                                </>
                            );
                        })}
                    </div>
                    </SpaceBetween>
                
            ) : (
                <div className="p-10 text-center text-base font-semibold text-gray-500">No data available</div>
            )}
        </>
    );
};

export default PiracyComponent;
