
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchHashtagsData = createAsyncThunk(
    "tiktok/fetchHashtagsData",
  
    async ({ params }) => {
      const response = await axiosInstance.request({
        url: `/realtime/tiktok`,
        method: `GET`,
        params: params,
      });
      return response;
    }
);

const hashtagsDataSlice = createSlice({
    name: "hashtagsData",
    initialState,
    reducers: {
      clearData: () => {
        return initialState;
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchHashtagsData.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchHashtagsData.fulfilled, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        })
        .addCase(fetchHashtagsData.rejected, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        });
    },
});

const hashtagsDataReducer = hashtagsDataSlice.reducer;

export default hashtagsDataReducer;

export const hashtagsDataActions = hashtagsDataSlice.actions;

