import {
  Button,
  Checkbox,
  FormField,
  Input,
  Modal,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import { Controller, Resolver, useForm, useFormContext } from "react-hook-form";
import { useAuthContext } from "../../../../auth/useAuthContext";
import FormProvider from "../../../../components/forms/FormProvider";
import { PLATFORMS } from "../../../../services/platformPreference/constants";
import { usePostPlatformPreference } from "../../../../services/platformPreference/hooks/usePostPlatformPreference";
import { mapFormValueToRequest } from "../domain/mapFormValueToRequest";
import { isNone } from "../../../../utils/sugarUtils";

type CreatePlatformWeightSettingsModalProps = {
  isVisible: boolean;
  setSelectedFileName: (name: string) => void;
  setShowCreateModal: (showModal: boolean) => void;
  onSuccess: () => void;
  onError: () => void;
};

type FormValues = {
  name: string;
  type: { value: string };
  isDefault: boolean;
};

export const CreatePlatformWeightSettingsModal: FC<
  CreatePlatformWeightSettingsModalProps
> = ({
  isVisible,
  setShowCreateModal,
  setSelectedFileName,
  onSuccess,
  onError,
}) => {
  const { user } = useAuthContext();
  const { watch } = useFormContext();
  const { mutateAsync: postPreferenceData } = usePostPlatformPreference(
    PLATFORMS.TRACKED_RANKING,
    onSuccess,
    onError,
  );

  const resolver: Resolver<FormValues> = async (values) => {
    return {
      values,
      errors: {
        ...(!values.name && !values.isDefault
          ? {
              name: {
                type: "required",
                message: "This is required.",
              },
            }
          : {}),
        ...(!values.type
          ? {
              type: {
                type: "required",
                message: "This is required.",
              },
            }
          : {}),
      },
    };
  };

  const methods = useForm({
    resolver,
    defaultValues: {
      isDefault: false,
    },
  });

  const onSubmit = ({ isDefault, name, type }) => {
    const fileName = isDefault ? "default" : name;
    const data = {
      isDefault,
      name: fileName,
      type: type.value,
      payload: mapFormValueToRequest(watch()),
    };

    postPreferenceData(data).then((result) => {
      if (isNone(result.error)) setSelectedFileName(fileName);
    });
    closeModal();
  };

  const closeModal = () => {
    setShowCreateModal(false);
    methods.reset();
  };

  const getOptions = () => {
    const role = (user as any).role;
    const options = [{ label: "Personal", value: "personal" }];

    if (role === "superAdmin" || role === "admin") {
      options.push({
        label: "Organization",
        value: "organization",
      });
    }

    return options;
  };

  return (
    <Modal
      size="medium"
      onDismiss={closeModal}
      visible={isVisible}
      closeAriaLabel="Close modal"
      header="Create weight settings"
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          <Controller
            name="isDefault"
            control={methods.control}
            render={({ field }) => {
              return (
                <FormField label="Set as default" stretch={true}>
                  <Checkbox
                    onChange={({ detail }) => field.onChange(detail.checked)}
                    checked={field.value}
                  >
                    Set as default
                  </Checkbox>
                </FormField>
              );
            }}
          />
          <Controller
            name="type"
            control={methods.control}
            render={({ field }) => {
              return (
                <FormField
                  label="Type"
                  stretch={true}
                  errorText={methods.formState.errors?.type?.message ?? ""}
                >
                  <Select
                    {...field}
                    selectedOption={field.value}
                    onChange={({ detail }) =>
                      field.onChange(detail.selectedOption)
                    }
                    options={getOptions()}
                    selectedAriaLabel="Selected"
                  />
                </FormField>
              );
            }}
          />
          {!methods.watch("isDefault") && (
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => {
                return (
                  <FormField
                    label="Name"
                    description="Title of the weight settings"
                    stretch={true}
                    errorText={methods.formState.errors?.name?.message ?? ""}
                  >
                    <Input
                      {...field}
                      onChange={(e) => field.onChange(e.detail.value)}
                    />
                  </FormField>
                );
              }}
            />
          )}
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="normal" onClick={closeModal}>
              Cancel
            </Button>
            <Button formAction="submit" variant="primary">
              Save
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
