import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  SegmentedControl,
  Container,
  ContentLayout,
  Grid,
  Header,
  Select,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Tabs,
  FormField,
} from "@cloudscape-design/components";
import moment from "moment";
import AgGridComponent from "./AgGridComponent";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import axiosInstance from "../../../utils/axios";
import {
  getPiracyMoviesSummaryData,
  getPiracyTelevisionSummaryData,
} from "./redux/actions/piracy-generic-summary-actions";
import { PiracyOptionLayer } from "./PiracyOptionLayer";
import { PiracyOptionListLayer } from "./PiracyOptionListLayer";
import PantheonFlashbar from "../../../components/PantheonFlashbar";

const COUNTRIES_OPTIONS = [
  { label: "United States", value: "United States" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "Canada", value: "Canada" },
  { label: "Australia", value: "Australia" },
  { label: "France", value: "France" },
  { label: "Germany", value: "Germany" },
  { label: "Italy", value: "Italy" },
  { label: "Japan", value: "Japan" },
  { label: "Spain", value: "Spain" },
  { label: "Ireland", value: "Ireland" },
  { label: "Mexico", value: "Mexico" },
  { label: "Thailand", value: "Thailand" },
  { label: "Brazil", value: "Brazil" },
  { label: "South Korea", value: "South Korea" },
  { label: "Other", value: "Other" },
  { label: "All", value: "all" },
];

export const PiracyGenericSummaryPage = () => {
  const flashbarRef = useRef(null);
  const piracyGenericMoviesSummary = useSelector(
    (state) => state.piracyGenericMoviesSummary
  );
  const piracyGenericTelevisionSummary = useSelector(
    (state) => state.piracyGenericTelevisionSummary
  );
  const listOptionsData = useSelector((state) => state.listOptionsData);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardDataLoading, setLeaderboardDataLoading] = useState(false);
  const [visibleType, setVisibleType] = useState("grid");
  const [selectedId, setSelectedId] = useState("seg-1");
  const [activeTabId, setActiveTabId] = useState("summary");
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, "months").startOf("day").unix(),
    endDate: moment().endOf("day").unix(),
  });
  const [selectedCountry, setSelectedCountry] = useState({
    label: "United States",
    value: "United States",
  });

  // Leaderboard
  const [lbDateRange, setLbDateRange] = useState({
    startDate: moment().subtract(15, "days").startOf("day").unix(),
    endDate: moment().endOf("day").unix(),
  });
  const [lbSelectedOption, setLbSelectedOption] = useState({
    label: "All",
    value: "all",
  });
  const [lbSelectedSize, setLbSelectedSize] = useState({
    label: "20",
    value: "20",
  });
  const [lbSelectedCountry, setLbSelectedCountry] = useState({
    label: "United States",
    value: "United States",
  });

  const handleTabChange = (newActiveTabId) => {
    setActiveTabId(newActiveTabId);

    if (
      newActiveTabId === "summary" &&
      piracyGenericMoviesSummary.data?.length === 0 &&
      piracyGenericTelevisionSummary.data?.length === 0
    ) {
      fetchSummaryData();
    } else if (
      newActiveTabId === "leaderboard" &&
      leaderboardData?.length === 0
    ) {
      fetchLeaderboardData();
    }
  };

  const fetchSummaryData = async () => {
    const range = {
      gte: dateRange.startDate,
      lte: dateRange.endDate,
      size: 500,
    };
    await Promise.all([
      getPiracyMoviesSummaryData({
        ...range,
        vertical: { label: "Movies", value: "Movies" },
        country: selectedCountry.value,
      }),
      getPiracyTelevisionSummaryData({
        ...range,
        vertical: { label: "Television", value: "Television" },
        country: selectedCountry.value,
      }),
    ]);
  };

  const fetchLeaderboardData = async () => {
    await Promise.all([
      getLeaderBoardData(
        lbSelectedOption.value,
        lbSelectedSize.value,
        lbDateRange,
        lbSelectedCountry.value
      ),
    ]);
  };

  useEffect(() => {
    fetchSummaryData();
  }, [dateRange, selectedCountry]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [lbDateRange, lbSelectedCountry, lbSelectedOption, lbSelectedSize]);

  const breadcrumbs = [{ text: "Piracy" }];

  useEffect(() => {
    if (listOptionsData.completed && listOptionsData.hasError) {
      flashbarRef?.current?.setFlashbarMessage(
        "error",
        listOptionsData.message
      );
    }
  }, [listOptionsData]);

  const getLeaderBoardData = (category, size, range, country) => {
    setLeaderboardDataLoading(true);
    let apiUrl = `/piracy/generic/us/leaderboard/v5?size=${size}&gte=${range.startDate}&lte=${range.endDate}&country=${country}`;

    if (category !== "all") {
      apiUrl += `&category=${category}`;
    }

    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setLeaderboardData(response.data);
        setLeaderboardDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLeaderboardDataLoading(false);
        flashbarRef?.current?.setFlashbarMessage("error", error?.message);
      });
  };

  return (
    <>
      <Helmet>
        <title>Piracy</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        contentType="dashboard"
        ariaLabels={{ navigationClose: "close" }}
        toolsHide
        content={
            <ContentLayout
              header={
                <>
                  <PantheonFlashbar ref={flashbarRef} />
                  <Header>Piracy (P2P)</Header>
                </>
              }
            >
              <Container>
                <Tabs
                  onChange={({ detail }) => handleTabChange(detail.activeTabId)}
                  activeTabId={activeTabId}
                  tabs={[
                    {
                      label: "Summary",
                      id: "summary",
                      content: (
                        <>
                          <div className="mx-auto pb-4">
                            <Header
                              variant="h3"
                              actions={
                                <>
                                  <SpaceBetween direction="horizontal" size="s">
                                    <FormField description="Country">
                                      <Select
                                        expandToViewport
                                        selectedOption={selectedCountry}
                                        onChange={({ detail }) => {
                                          setSelectedCountry(
                                            detail.selectedOption
                                          );
                                        }}
                                        options={COUNTRIES_OPTIONS}
                                      />
                                    </FormField>
                                    <FormField description="Date range">
                                      <RangeDateSelector
                                        defaults={
                                          dateRange
                                            ? dateRange?.type === "relative"
                                              ? {
                                                  ...dateRange,
                                                  startDate: moment()
                                                    .subtract(15, "days")
                                                    .startOf("day")
                                                    .format("YYYY-MM-DD"),
                                                  endDate: moment(
                                                    dateRange.endDate
                                                  )
                                                    .endOf("day")
                                                    .format("YYYY-MM-DD"),
                                                }
                                              : dateRange
                                            : {
                                                type: "absolute",
                                                startDate: moment()
                                                  .subtract(15, "days")
                                                  .startOf("day")
                                                  .format("YYYY-MM-DD"),
                                                endDate: moment()
                                                  .endOf("day")
                                                  .format("YYYY-MM-DD"),
                                              }
                                        }
                                        onChange={(e) => {
                                          if (e.type === "relative") {
                                            const newDateRange = {
                                              startDate: moment(e.startDate)
                                                .startOf("day")
                                                .unix(),
                                              endDate: moment(e.endDate)
                                                .endOf("day")
                                                .unix(),
                                            };
                                            setDateRange({
                                              ...e,
                                              ...newDateRange,
                                            });
                                          } else {
                                            const newDateRange = {
                                              startDate: moment(e.startDate)
                                                .startOf("day")
                                                .unix(),
                                              endDate: moment(e.endDate)
                                                .endOf("day")
                                                .unix(),
                                            };
                                            setDateRange({
                                              ...e,
                                              ...newDateRange,
                                            });
                                          }
                                        }}
                                        /*   defaults={{
                                          type: "absolute",
                                          startDate: moment()
                                            .subtract(15, "days")
                                            .startOf("day")
                                            .format("YYYY-MM-DD"),
                                          endDate:
                                            moment().format("YYYY-MM-DD"),
                                        }}
                                        onChange={(e) => {
                                          const newDateRange = {
                                            startDate: moment(e.startDate)
                                              .startOf("day")
                                              .unix(),
                                            endDate: moment(e.endDate)
                                              .endOf("day")
                                              .unix(),
                                          };
                                          setDateRange(newDateRange);
                                        }} */
                                      />
                                    </FormField>
                                    <FormField description="View">
                                      <SegmentedControl
                                        selectedId={selectedId}
                                        onChange={({ detail }) => {
                                          setSelectedId(detail.selectedId);
                                          setVisibleType(
                                            detail.selectedId === "seg-1"
                                              ? "grid"
                                              : "list"
                                          );
                                        }}
                                        label="View options"
                                        options={[
                                          {
                                            text: "Slider view",
                                            iconName: "view-full",
                                            id: "seg-1",
                                          },
                                          {
                                            text: "Table view",
                                            iconName: "view-horizontal",
                                            id: "seg-2",
                                          },
                                        ]}
                                      />
                                    </FormField>
                                  </SpaceBetween>
                                </>
                              }
                            >
                              Top Downloads by Category
                            </Header>
                          </div>
                          <div className="mx-auto">
                            {visibleType === "grid" ? (
                              <>
                                <div key="Movies">
                                  <PiracyOptionLayer
                                    loading={piracyGenericMoviesSummary.loading}
                                    vertical={{
                                      label: "Movies",
                                      value: "Movies",
                                    }}
                                    optionData={piracyGenericMoviesSummary.data}
                                  />
                                </div>
                                <div key="Television">
                                  <PiracyOptionLayer
                                    loading={piracyGenericMoviesSummary.loading}
                                    vertical={{
                                      label: "Television",
                                      value: "Television",
                                    }}
                                    optionData={
                                      piracyGenericTelevisionSummary.data
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <Grid
                                gridDefinition={[
                                  { colspan: { default: 12, xs: 6 } },
                                  { colspan: { default: 12, xs: 6 } },
                                ]}
                              >
                                <div key="Movieslist">
                                  <PiracyOptionListLayer
                                    loading={piracyGenericMoviesSummary.loading}
                                    vertical={{
                                      label: "Movies",
                                      value: "Movies",
                                    }}
                                    optionData={piracyGenericMoviesSummary.data}
                                  />
                                </div>
                                <div key="Televisionlist">
                                  <PiracyOptionListLayer
                                    loading={piracyGenericMoviesSummary.loading}
                                    vertical={{
                                      label: "Television",
                                      value: "Television",
                                    }}
                                    optionData={
                                      piracyGenericTelevisionSummary.data
                                    }
                                  />
                                </div>
                              </Grid>
                            )}
                          </div>
                        </>
                      ),
                    },
                    {
                      label: "Leaderboard",
                      id: "leaderboard",
                      content: (
                        <>
                          <Header
                            variant="h3"
                            actions={
                              <>
                                <SpaceBetween direction="horizontal" size="s">
                                  <FormField description="Country">
                                    <Select
                                      expandToViewport
                                      selectedOption={lbSelectedCountry}
                                      onChange={({ detail }) => {
                                        setLbSelectedCountry(
                                          detail.selectedOption
                                        );
                                      }}
                                      options={COUNTRIES_OPTIONS}
                                    />
                                  </FormField>
                                  <FormField description="Size">
                                    <Select
                                      selectedOption={lbSelectedSize}
                                      onChange={({ detail }) =>
                                        setLbSelectedSize(detail.selectedOption)
                                      }
                                      options={[
                                        "10",
                                        "20",
                                        "30",
                                        "40",
                                        "50",
                                        "75",
                                        "500",
                                      ].map((value) => ({
                                        label: value,
                                        value,
                                      }))}
                                      selectedAriaLabel="Selected Size"
                                    />
                                  </FormField>
                                  <FormField description="Date range">
                                    <RangeDateSelector
                                      defaults={
                                        dateRange
                                          ? dateRange?.type === "relative"
                                            ? {
                                                ...dateRange,
                                                startDate: moment
                                                  .unix(lbDateRange.startDate)
                                                  .format("YYYY-MM-DD"),
                                                endDate: moment
                                                  .unix(lbDateRange.endDate)
                                                  .format("YYYY-MM-DD"),
                                              }
                                            : dateRange
                                          : {
                                              type: "absolute",
                                              startDate: moment
                                                .unix(lbDateRange.endDate)
                                                .format("YYYY-MM-DD"),
                                              endDate: moment
                                                .unix(lbDateRange.endDate)
                                                .format("YYYY-MM-DD"),
                                            }
                                      }
                                      onChange={(e) => {
                                        if (e.type === "relative") {
                                          const newDateRange = {
                                            startDate: moment(e.startDate)
                                              .startOf("day")
                                              .unix(),
                                            endDate: moment(e.endDate)
                                              .endOf("day")
                                              .unix(),
                                          };
                                          setLbDateRange({
                                            ...e,
                                            ...newDateRange,
                                          });
                                        } else {
                                          const newDateRange = {
                                            startDate: moment(e.startDate)
                                              .startOf("day")
                                              .unix(),
                                            endDate: moment(e.endDate)
                                              .endOf("day")
                                              .unix(),
                                          };
                                          setLbDateRange({
                                            ...e,
                                            ...newDateRange,
                                          });
                                        }
                                      }}
                                      /*  onChange={(e) => {
                                        const newDateRange = {
                                          startDate: moment(e.startDate)
                                            .startOf("day")
                                            .unix(),
                                          endDate: moment(e.endDate)
                                            .endOf("day")
                                            .unix(),
                                        };
                                        setLbDateRange(newDateRange);
                                      }} */
                                    />
                                  </FormField>
                                  <FormField description="Category">
                                    <Select
                                      selectedOption={lbSelectedOption}
                                      expandToViewport
                                      onChange={({ detail }) =>
                                        setLbSelectedOption(
                                          detail.selectedOption
                                        )
                                      }
                                      options={[
                                        { label: "All", value: "all" },
                                        {
                                          label: "Television",
                                          value: "Television",
                                        },
                                        { label: "Movies", value: "Movies" },
                                      ]}
                                      selectedAriaLabel="Selected"
                                    />
                                  </FormField>
                                </SpaceBetween>
                              </>
                            }
                          >
                            {`${moment
                              .unix(lbDateRange.startDate)
                              .format("MMM D, YYYY")} - ${moment
                              .unix(lbDateRange.endDate)
                              .format("MMM D, YYYY")}`}
                          </Header>
                          <div
                            className="mx-auto container"
                            style={{ marginTop: "20px" }}
                          >
                            {leaderboardDataLoading && (
                              <Box textAlign="center" color="inherit">
                                <Spinner size="large" />
                              </Box>
                            )}
                            {!leaderboardDataLoading &&
                              leaderboardData?.length > 0 && (
                                <>
                                  <div
                                    style={{ height: "944px" }}
                                    className={"ag-theme-quartz-dark"}
                                  >
                                    <AgGridComponent
                                      leaderboardData={leaderboardData}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </Container>
            </ContentLayout>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};
