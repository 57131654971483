export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getYearList = (currentYear: number) =>
  Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index);

export const dateFormatter = (date: number) => {
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return new Intl.DateTimeFormat("en-US", options as any).format(date);
};

export const getWeeksInMonth = (year, month) => {
  console.log("year getWeeksInMonth ", year);
  const weeks = [];

  // Get the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);

  // Get the last day of the month
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // Start with the first day of the month
  let weekStart = new Date(firstDayOfMonth);

  // Iterate over each week of the month
  while (weekStart <= lastDayOfMonth) {
    // Calculate the end of the week
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6 - weekStart.getDay());

    // Format the start and end dates of the week
    const start = formatDate(weekStart);
    const end = formatDate(weekEnd);

    // Push the formatted date range to the weeks array
    weeks.push(`${start}-${end}`);

    // Move to the next week
    weekStart.setDate(weekStart.getDate() + 7);
  }

  // Add weeks from the previous month
  const firstWeekOfMonth = new Date(weeks[0].split("-")[0]);
  if (firstWeekOfMonth.getDate() > 1) {
    let prevMonth = new Date(year, month, 0);
    while (prevMonth >= firstDayOfMonth) {
      const weekStart = new Date(prevMonth);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);
      const start = formatDate(weekStart);
      const end = formatDate(weekEnd);
      weeks.unshift(`${start}-${end}`);
      prevMonth.setDate(prevMonth.getDate() - 7);
    }
  }

  // Add weeks from the next month
  const lastWeekOfMonth = new Date(weeks[weeks.length - 1].split("-")[1]);
  if (lastWeekOfMonth.getMonth() === month) {
    let nextMonth = new Date(lastDayOfMonth);
    nextMonth.setDate(nextMonth.getDate() + 1);
    while (nextMonth.getMonth() === month) {
      const weekStart = new Date(nextMonth);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);
      const start = formatDate(weekStart);
      const end = formatDate(weekEnd);
      weeks.push(`${start}-${end}`);
      nextMonth.setDate(nextMonth.getDate() + 7);
    }
  }

  return weeks;
};

const formatDate = (date) => {
  const monthIndex = date.getMonth();
  const day = date.getDate().toString().padStart(2, "0");
  const month = monthList.slice(monthIndex, monthIndex + 1);
  const year = date.getFullYear();
  console.log("year", year, date);
  return `${day}${month}${year}`;
};
