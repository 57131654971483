import React from "react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { VideosTable } from "./VideosTable";

export const YoutubeVideos = ({ type }) => {
  let pageTitle;
  switch (type) {
    case "film":
      pageTitle = "Movie Trailers";
      break;
    case "series":
      pageTitle = "TV Show Trailers";
      break;
    case "game":
      pageTitle = "Game Trailers";
      break;
    case "unmapped":
      pageTitle = "Un-Mapped Trailers";
      break;
    case "unofficial":
      pageTitle = "Unofficial Trailers";
      break;
    case "kids":
      pageTitle = "Kids Trailers";
      break;
    case "all":
      pageTitle = "All Trailers";
      break;
    default:
      pageTitle = type;
  }

  const breadcrumbs = [
    { text: "Media Catalog" },
    { text: "YouTube", href: "/mediacatalog/youtube" },
    { text: pageTitle },
  ];

  return (
    <Layout
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={<VideosTable type={type} pageTitle={pageTitle} />}
      contentType="table"
    />
  );
};
