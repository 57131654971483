import React, { useEffect, useState } from "react";
import ReadMore from "../../components/ReadMore";
import IMDbRating from "./components/IMDbRating";

import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  Popover,
  PropertyFilter,
  SpaceBetween,
  StatusIndicator,
  Table,
  Tabs,
  TextContent,
} from "@cloudscape-design/components";
import { AgChartsReact } from "ag-charts-react";
import { CompareModal } from "./modals/CompareModal/CompareModal";
import "ag-grid-enterprise";
import _, { set } from "lodash";
import moment from "moment";
import { Chrono } from "react-chrono";
import { useAuthContext } from "../../auth/useAuthContext";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import { IP_LIST, TAGS_LIST } from "../../config-global";
import createTableSortLabelFn from "../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../utils/getTextFilterCounterText";
import shortenInteger from "../../utils/shortenInteger";
import { timelineClasses, timelineTheme } from "./chartConfig";
import AuditBox from "./components/AuditBox";
import BoxOffice from "./components/BoxOffice";
import CastAndCrewComponent from "./components/CastAndCrewComponent";
import FranchiseTag from "./components/FranchiseTag";
import NewsBox from "./components/News";
import OTTProvider from "./components/OTTProvider";
import PiracyComponent from "./components/PiracyComponent";
import PiracyScore from "./components/PiracyScore";
import ProdnDistrib from "./components/ProdnDistrib";
import ProductionCompanies from "./components/ProductionCompanies";
import RottenTomatesAudienceScore from "./components/RTAudienceScore";
import RottenTomatesCriticScore from "./components/RTCriticScore";
import SocialTrends from "./components/SocialTrends";
import StreamingWindowComponent from "./components/StreamingWindow";
import TagsAndIDsComponent from "./components/TagsAndIDsComponent";
import TrailerGrid from "./components/Trailer";
import { WikiScore, YoutubeScore } from "./components/GlobalScores";
import Genre from "./components/genre";
import { AddToFranchiseModal } from "./modals/AddToFranchiseModal";
import { AttributesEditModal } from "./modals/AttributesEditModal";
import { DeleteConfirmationModal } from "./modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "./modals/TrackUnTrackConfirmationModal";
import WikiSummaryBox from "./components/WikipediaTab";
import { PantheonScore, SearchScore, EngagementScore, DemandScore } from "./components/GlobalScores";

const defaultDateRange = {
  type: "absolute",
  startDate: moment().subtract(6, "months").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${monthNames[date.getUTCMonth()]
    } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};

const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  parts.push(`${minutes}m`);
  return parts.join(" ");
};

export default function ItemFilm({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
  onAddFranchise,
  onTagUpdateSubmit,
  attributeProcessing,
  setPiracyDate = (e) => { },
  setSearchDate = (e) => { },
  setFandomDate = (e) => { },
  setRedditDate = (e) => { },
  setInstagramDate = (e) => { },
  ...props
}) {
  const { user } = useAuthContext();
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);
  const [isTracked, setIsTracked] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const [searchDateRange, setSearchDateRange] = useState(defaultDateRange);
  const [piracyDateRange, setPiracyDateRange] = useState(defaultDateRange);
  const [fandomDateRange, setFandomDateRange] = useState(defaultDateRange);
  const [redditDateRange, setRedditDateRange] = useState(defaultDateRange);
  const [instagramDateRange, setInstagramDateRange] =
    useState(defaultDateRange);

  useEffect(() => {
    setSearchDate(searchDateRange);
  }, [searchDateRange]);
  useEffect(() => {
    setFandomDate(fandomDateRange);
  }, [fandomDateRange]);
  useEffect(() => {
    setRedditDate(redditDateRange);
  }, [redditDateRange]);
  useEffect(() => {
    setInstagramDate(instagramDateRange);
  }, [instagramDateRange]);
  useEffect(() => {
    setPiracyDate(piracyDateRange);
  }, [piracyDateRange]);

  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  useEffect(() => {
    let newTimelineItems = [];
    if (itemData?.data?.release_dates) {
      itemData?.data?.release_dates?.forEach((x) => {
        const formattedDate = formatDate(new Date(Date.parse(x?.release_date)));
        newTimelineItems.push({
          title: formattedDate,
          cardTitle: `${x.release_type}`,
          cardDetailedText: x.note,
          rawDate: new Date(Date.parse(x?.release_date)),
        });
      });
    }

    if (itemData.data.timeline_events) {
      itemData.data.timeline_events.forEach((event) => {
        const eventDate = new Date(event.timestamp * 1000);
        const formattedDate = formatDate(eventDate);
        newTimelineItems.push({
          title: formattedDate,
          cardTitle: event.eventType,
          cardDetailedText: event.details,
          rawDate: eventDate,
        });
      });
    }
    if (itemData.data.productionStatus) {
      itemData.data.productionStatus.forEach((event) => {
        const eventDate = new Date(Date.parse(event.date));
        const formattedDate = formatDate(eventDate);
        newTimelineItems.push({
          title: formattedDate,
          cardTitle: event.status,
          rawDate: new Date(Date.parse(event.date)),
        });
      });
    }

    newTimelineItems.sort((a, b) => a.rawDate - b.rawDate);

    newTimelineItems = newTimelineItems.map(({ rawDate, ...item }) => item);

    setTimelineItems(newTimelineItems);
  }, [itemData]);

  const awardColumnDefinition = [
    {
      id: "category",
      header: "Category",
      cell: (item) => item.category || "N/A",
      sortingField: "category",
    },
    {
      id: "year",
      header: "Year",
      cell: (item) => item.year || "N/A",
      sortingField: "year",
    },
    {
      id: "name",
      header: "Name",
      cell: (item) => item.name || "N/A",
      sortingField: "name",
    },
    {
      id: "event",
      header: "Event",
      cell: (item) => item.event || "N/A",
      sortingField: "event",
    },
  ];

  const rawColumns = awardColumnDefinition.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const organizationFilteringProperties = [
    {
      propertyLabel: "Category",
      key: "category",
      groupValuesLabel: "Category",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Year",
      key: "year",
      groupValuesLabel: "Year",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Name",
      key: "name",
      groupValuesLabel: "Name",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Event",
      key: "event",
      groupValuesLabel: "Event",
      operators: [":", "!:", "=", "!="],
    },
  ].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));

  const { items, collectionProps, propertyFilterProps, filteredItemsCount } =
    useCollection(itemData.data.awards, {
      sorting: { defaultState: { sortingColumn: rawColumns[0] } },
      propertyFiltering: {
        filteringProperties: organizationFilteringProperties,
      },
    });

  const awardEvents = [
    "Academy Awards, USA",
    "Golden Globes, USA",
    "Sundance Film Festival",
    "Critics Choice Awards",
    "Screen Actors Guild Awards",
  ];
  const shortenAwardName = (fullName) => {
    const nameMap = {
      "Academy Awards, USA": "Academy Awards",
      "Golden Globes, USA": "Golden Globes",
      "Sundance Film Festival": "Sundance",
      "Critics Choice Awards": "Critics' Choice",
      "Screen Actors Guild Awards": "SAG Awards",
    };

    return nameMap[fullName] || fullName;
  };
  const awardCounts = {};

  // Calculate award counts
  awardEvents.forEach((event) => {
    awardCounts[event] = itemData.data.awards
      ? itemData.data.awards.filter((award) => award.event === event).length
      : 0;
  });

  const awardstats = Object.keys(awardCounts)
    .filter((event) => awardCounts[event] > 0)
    .map((event) => ({
      name: event,
      stat: awardCounts[event],
    }));

  function getIconPath(awardTitle) {
    const formattedTitle = awardTitle.toLowerCase().replace(/[^a-z0-9]/g, "_");
    return `/awards/award_${formattedTitle}.png`;
  }

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const rtCriticSummary = itemData?.data?.rtCriticSummary;
  const rtFanSummary = itemData?.data?.rtFanSummary;
  const wikiTotalViews = shortenInteger(itemData?.data?.global_rank?.wiki?.total_views);
  const youtubeTotalViews = shortenInteger(itemData?.data?.global_rank?.youtube?.views);
  const newsBoxId = new Date(itemData?.data?.release_dates[0]?.release_date,).getFullYear();
  return (
    <>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => setShowCompareModal(true)}
                >
                  Compare
                </Button>
                <ButtonDropdown
                  expandToViewport
                  onItemClick={({ detail }) => {
                    if (detail.id === "tt") {
                      setAddTrack(true);
                      onTrackUnTrackInit();
                    }
                    if (detail.id === "ut") {
                      setAddTrack(false);
                      onTrackUnTrackInit();
                    }
                    if (detail.id === "dt") {
                      onDeleteModalInit();
                    }

                    if (detail.id === "af") {
                      onAddFranchiseModalInit();
                    }
                    if (detail.id === "et") {
                      onShowAttrInit();
                    }
                  }}
                  items={[
                    {
                      text: "Track title",
                      id: "tt",
                      disabled: !isAdmin
                        ? true
                        : checkIpList(itemData?.data?.ip_list) ||
                        itemData?.data?.tracked,
                    },
                    {
                      text: "Untrack title",
                      id: "ut",
                      disabled: !isAdmin ? true : !itemData?.data?.tracked,
                    },
                    { text: "Edit tags", id: "et", disabled: !isAdmin },
                    { text: "Add to franchise", id: "af" },
                    { text: "Delete title", id: "dt", disabled: !isAdmin },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            <div className="flex items-center">
              <div className="text-xl text-blue-400 font-bold inline-block">
                {itemData.data.name}
              </div>
              <div className="inline-block whitespace-nowrap ml-4 ">
                {isTracked ? (
                  <div className="flex space-x-2">
                    {itemData.data?.ip_list?.length > 0 ? (
                      itemData.data.ip_list.map((ip) => (
                        <span
                          key={ip}
                          className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                        >
                          <svg
                            className="h-1.5 w-1.5 fill-green-400"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {ip}
                        </span>
                      ))
                    ) : (
                      <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                        Tracked
                      </span>
                    )}
                  </div>
                ) : (
                  <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                      <span className="text-slate-400 text-sm">
                        Not Tracked
                      </span>
                    }
                  >
                    <StatusIndicator type="stopped" />
                  </Popover>
                )}
              </div>
            </div>

            <div className="text-slate-400 text-xs">
              {itemData.data?.certificates?.rating} {" • "}
              {itemData.data?.type.name} {" • "}
              {itemData.data?.original_language?.toUpperCase()} {" • "}
              {itemData.data?.countries
                ?.map((country) => country.toUpperCase())
                .join(", ")}{" "}
              {" • "}
              {formatDate(new Date(Date.parse(itemData?.data?.release_date)))}
              {" • "}
              {toHoursAndMinutes(itemData.data.runtime)} {" • "}
              {itemData.data.isTheatrical ? <>Theatrical</> : <>TV/OTT</>}
            </div>
          </Header>
        }
      >
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
            <div className="w-full sm:w-auto">
              {itemData.data.image_url ? (
                <div className="w-48 xl:w-64 h-auto">
                  <img
                    src={`https://image.tmdb.org/t/p/w342/${itemData.data.image_url}`}
                    alt="..."
                    className="object-cover w-full h-full drop-shadow-lg rounded-md"
                  />
                </div>
              ) : (
                <div className="w-48 h-full xl:w-64 flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>

            <div className="flex-grow w-full h-full sm:w-auto flex flex-col">
              <div className="flex flex-col">
                <SpaceBetween direction="vertical" size="xs">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "8px" }}>Genre: </span>
                    <Genre item={itemData.data.genre} />
                  </div>
                  {itemData.data.production_company &&
                    itemData.data.production_company.length > 0 && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "8px" }}>
                          Production Companies:{" "}
                        </span>
                        <ProductionCompanies
                          item={itemData.data.production_company}
                        />
                      </div>
                    )}
                  {itemData.data.franchise &&
                    itemData.data.franchise.length > 0 && (
                      <FranchiseTag itemData={itemData} />
                    )}
                  {itemData.data.summary && (
                    <div>
                      {" "}
                      Overview: <ReadMore>
                        {" "}
                        {itemData.data.summary}{" "}
                      </ReadMore>{" "}
                    </div>
                  )}
                  {itemData.data.providers && (
                    <OTTProvider itemData={itemData} />
                  )}
                  {
                    <div className="mt-4">
                      <SpaceBetween direction="horizontal" size="xxl">
                        {!!rtCriticSummary?.score && (
                          <RottenTomatesCriticScore
                            rtCriticSummary={rtCriticSummary}
                          />
                        )}
                        {!!rtFanSummary?.score && (
                          <RottenTomatesAudienceScore
                            rtFanSummary={rtFanSummary}
                          />
                        )}
                        {!!itemData.data.imdb_vote_average && (
                          <IMDbRating
                            percent={itemData.data.imdb_vote_average}
                            title={"IMDB SCORE"}
                            subtitle={`${itemData.data.imdb_vote_count.toLocaleString()} votes`}
                          />
                        )}
                        {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_pan_rank !== null && itemData.data.global_rank?.pantheon?.pantheon_rank !== null && (
                          <PantheonScore
                            rank={itemData.data.global_rank?.pantheon?.at_pan_rank}
                            title={"PANTHEON RANK"}
                            subtitle={`Weekly Rank: ${itemData.data.global_rank?.pantheon?.pantheon_rank}`}
                          />
                        )}
                        {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_src_rank !== null && itemData.data.global_rank?.pantheon?.search_rank !== null && (
                          <SearchScore
                            rank={itemData.data.global_rank?.pantheon?.at_src_rank}
                            title={"SEARCH RANK"}
                            subtitle={`Weekly Rank: ${itemData.data.global_rank?.pantheon?.search_rank}`}
                          />
                        )}
                        {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_eng_rank !== null && itemData.data.global_rank?.pantheon?.engagement_rank !== null && (
                          <EngagementScore
                            rank={itemData.data.global_rank?.pantheon?.at_eng_rank}
                            title={"ENGAGEMENT RANK"}
                            subtitle={`Weekly Rank: ${itemData.data.global_rank?.pantheon?.engagement_rank}`}
                          />
                        )}
                        {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_dmd_rank !== null && itemData.data.global_rank?.pantheon?.demand_rank !== null && (
                          <DemandScore
                            rank={itemData.data.global_rank?.pantheon?.at_dmd_rank}
                            title={"DEMAND RANK"}
                            subtitle={itemData.data.global_rank?.pantheon?.demand_rank ? `Weekly Rank: ${itemData.data.global_rank?.pantheon?.demand_rank}` : " `Weekly Rank: N/A"}
                          />
                        )}
                        {!!itemData.data.piracy_ranker && (
                          <PiracyScore
                            allTimeRank={
                              itemData.data.piracy_ranker.vertical_rank_lifetime
                            }
                            title={"PIRACY RANK"}
                            subtitle={`US Rank ${itemData.data.piracy_ranker.us_rank_lifetime}`}
                          />
                        )}
                        {!!itemData.data?.global_rank?.wiki && (
                          <WikiScore
                            rank={itemData.data.global_rank.wiki.rank}
                            title={"WIKIPEDIA RANK"}
                            subtitle={`Article Views: ${wikiTotalViews}`}
                          />
                        )}
                        {itemData.data?.global_rank?.youtube && itemData.data.global_rank.youtube.rank !== null && itemData.data.global_rank.youtube.views !== null && (
                          <YoutubeScore
                            rank={itemData.data.global_rank.youtube.rank}
                            title={"YOUTUBE RANK"}
                            subtitle={`Trailer Views: ${youtubeTotalViews}`}
                          />
                        )}
                        {/* {!!itemData.data.tmdb_vote_average && ( <RatingPercent percent={itemData.data.tmdb_vote_average * 10} title={"TMDB\nSCORE"} subtitle={`Based on ${itemData.data.tmdb_vote_count.toLocaleString()} votes`} /> )} */}
                      </SpaceBetween>
                    </div>
                  }
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-2 ">
        <div className="w-full ">
          <Tabs
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <SpaceBetween direction="vertical" size="l">
                    {itemData.data.tracked && (
                      <ExpandableSection
                        headingTagOverride="h4"
                        variant="container"
                        defaultExpanded
                        headerText="Social Trends"
                      >
                        <SocialTrends
                          itemData={itemData}
                          instagramHashtagsData={props.instagramHashtagsData}
                        />
                        {itemData?.data?.hasOwnProperty("tracked") &&
                          itemData?.data?.tracked &&
                          itemData?.data?.instagram &&
                          props.trackedInstagramTrendChartOption && (
                            <div className="h-60 mt-6">
                              <AgChartsReact
                                options={props.trackedInstagramTrendChartOption}
                              />
                            </div>
                          )}
                      </ExpandableSection>
                    )}
                    {itemData?.data?.hasOwnProperty("tracked") &&
                      !itemData?.data?.tracked && (
                        <>
                          <ExpandableSection
                            headingTagOverride="h4"
                            variant="container"
                            defaultExpanded
                            headerText="Search Trends"
                          >

                            {props.wikiOptions && (
                              <div className="h-60">
                                <AgChartsReact options={props.wikiOptions} />
                              </div>
                            )}
                            <br />
                            {props.gtrendsOptions && (
                              <div className="h-60">
                                <AgChartsReact options={props.gtrendsOptions} />
                              </div>
                            )}
                          </ExpandableSection>
                        </>
                      )}

                    {itemData?.data?.hasOwnProperty("tracked") &&
                      itemData?.data?.tracked && (
                        <ExpandableSection
                          headerActions={
                            <RangeDateSelector
                              defaults={searchDateRange}
                              onChange={(e) => {
                                const formattedStartDate = moment(
                                  e.startDate,
                                ).format("YYYY-MM-DD");
                                const formattedEndDate = moment(
                                  e.endDate,
                                ).format("YYYY-MM-DD");
                                setSearchDateRange({
                                  ...e,
                                  startDate: formattedStartDate,
                                  endDate: formattedEndDate,
                                });
                              }}
                            />
                          }
                          variant="container"
                          defaultExpanded
                          headerText="Search Trends"
                        >
                          {props.trackedWikiTrendChartOption && (
                            <div className="h-60">
                              <AgChartsReact
                                options={props.trackedWikiTrendChartOption}
                              />
                            </div>
                          )}

                          {props.trackedGoogleTrendsChartOption && (
                            <div className="h-60">
                              <AgChartsReact
                                options={props.trackedGoogleTrendsChartOption}
                              />
                            </div>
                          )}
                        </ExpandableSection>
                      )}

                    {/* {itemData?.data?.hasOwnProperty("tracked") &&
                      itemData?.data?.tracked &&
                      itemData?.data?.fandom && (
                        <ExpandableSection
                          headerActions={
                            <RangeDateSelector
                              defaults={fandomDateRange}
                              onChange={(e) => {
                                const formattedStartDate = moment(
                                  e.startDate,
                                ).format("YYYY-MM-DD");
                                const formattedEndDate = moment(
                                  e.endDate,
                                ).format("YYYY-MM-DD");
                                setFandomDateRange({
                                  ...e,
                                  startDate: formattedStartDate,
                                  endDate: formattedEndDate,
                                });
                              }}
                            />
                          }
                          variant="container"
                          defaultExpanded
                          headerText="Fandom Trends"
                        >
                          {props.trackedFandomTrendChartOption && (
                            <div className="h-60">
                              <AgChartsReact
                                options={props.trackedFandomTrendChartOption}
                              />
                            </div>
                          )}
                        </ExpandableSection>
                      )} */}

                    {itemData?.data?.hasOwnProperty("tracked") &&
                      itemData?.data?.tracked &&
                      itemData?.data?.subreddit && (
                        <ExpandableSection
                          headerActions={
                            <RangeDateSelector
                              defaults={redditDateRange}
                              onChange={(e) => {
                                const formattedStartDate = moment(
                                  e.startDate,
                                ).format("YYYY-MM-DD");
                                const formattedEndDate = moment(
                                  e.endDate,
                                ).format("YYYY-MM-DD");
                                setRedditDateRange({
                                  ...e,
                                  startDate: formattedStartDate,
                                  endDate: formattedEndDate,
                                });
                              }}
                            />
                          }
                          variant="container"
                          defaultExpanded
                          headerText="Reddit Trends"
                        >
                          {props.trackedRedditTrendChartOption && (
                            <div className="h-60">
                              <AgChartsReact
                                options={props.trackedRedditTrendChartOption}
                              />
                            </div>
                          )}
                        </ExpandableSection>
                      )}
                  </SpaceBetween>
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: <WikiSummaryBox id={itemData.data.wikidata_id} />,
              },
              {
                label: "Piracy",
                id: "piracy",
                content: (
                  <PiracyComponent
                    itemData={itemData.data}
                    piracyTrends={props?.piracyTrends}
                    piracyTrendsChartOption={props?.piracyTrendsChartOption}
                    piracyDateRange={piracyDateRange}
                    setPiracyDateRange={setPiracyDateRange}
                  />
                ),
              },
              {
                label: "News",
                id: "news",
                content: (
                  <NewsBox
                    id={`${itemData?.data?.ip}+${newsBoxId}+${itemData?.data?.vertical}`}
                  />
                ),
              },
              {
                label: "Cast & Crew",
                id: "CCT",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="Cast & Crew"
                  >
                    <CastAndCrewComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "Tags & IDs",
                id: "tags",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="Tags & IDs"
                  >
                    <TagsAndIDsComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: <TrailerGrid id={itemData.data.ip_id} />,
              },
              ...(itemData.data.distributors &&
                itemData.data.distributors.length > 0
                ? [
                  {
                    label: "Distribution",
                    id: "PD",
                    content: (
                      <ProdnDistrib
                        distributors={itemData.data.distributors}
                      />
                    ),
                  },
                ]
                : []),
              {
                label: "Box Office",
                id: "BO",
                content: (
                  <SpaceBetween direction="vertical" size="s">
                    {(itemData.data?.production_budget ||
                      itemData.data?.box_office_collection?.life_time_gross
                        ?.domestic !== undefined ||
                      itemData.data?.box_office_collection?.life_time_gross
                        ?.international !== undefined ||
                      itemData.data?.box_office_collection?.life_time_gross
                        ?.worldwide !== undefined) && (
                        <ExpandableSection
                          defaultExpanded
                          headingTagOverride="h4"
                          variant="container"
                          headerText="Summary"
                        >
                          <ColumnLayout
                            columns={4}
                            variant="text-grid"
                            minColumnWidth={190}
                          >
                            {itemData.data && itemData.data.production_budget && (
                              <div className="flex-column">
                                <Box variant="awsui-key-label">Budget</Box>
                                <TextContent>
                                  $
                                  {itemData.data.production_budget.toLocaleString()}
                                </TextContent>
                              </div>
                            )}
                            {itemData.data?.box_office_collection?.life_time_gross
                              ?.domestic !== undefined && (
                                <div className="flex-column">
                                  <Box variant="awsui-key-label">
                                    Domestic Gross
                                  </Box>
                                  <TextContent>
                                    $
                                    {itemData.data.box_office_collection.life_time_gross.domestic.toLocaleString()}
                                  </TextContent>
                                </div>
                              )}
                            {itemData.data?.box_office_collection?.life_time_gross
                              ?.international !== undefined && (
                                <div className="flex-column">
                                  <Box variant="awsui-key-label">
                                    International Gross
                                  </Box>
                                  <TextContent>
                                    $
                                    {itemData.data.box_office_collection.life_time_gross.international.toLocaleString()}
                                  </TextContent>
                                </div>
                              )}
                            {itemData.data?.box_office_collection?.life_time_gross
                              ?.worldwide !== undefined && (
                                <div className="flex-column">
                                  <Box variant="awsui-key-label">
                                    Worldwide Gross
                                  </Box>
                                  <TextContent>
                                    $
                                    {itemData.data.box_office_collection.life_time_gross.worldwide.toLocaleString()}
                                  </TextContent>
                                </div>
                              )}
                          </ColumnLayout>
                        </ExpandableSection>
                      )}
                    <BoxOffice titleId={itemData.data.imdb_id} />
                  </SpaceBetween>
                ),
              },
              ...(itemData.data.awards && itemData.data.awards.length > 0
                ? [
                  {
                    label: "Awards",
                    id: "awds",
                    content: (
                      <Container
                        variant="borderless"
                        header={
                          <Header>
                            <div>
                              <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                {awardstats.map((item, index) => (
                                  <div
                                    key={index}
                                    className="mt-2 overflow-hidden rounded-2xl shadow-lg bg-darkerBg px-4 py-5 sm:p-6 flex items-center"
                                  >
                                    <div className="award-icon">
                                      <img
                                        src={getIconPath(item.name)}
                                        alt={`${item.name} icon`}
                                        className="h-[50px] object-contain"
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <div className="truncate text-sm font-bold text-gray-400">
                                        {shortenAwardName(item.name)}
                                      </div>
                                      <div className="mt-1 text-3xl font-semibold tracking-tight text-gray-500">
                                        {item.stat}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Header>
                        }
                      >
                        <Table
                          {...collectionProps}
                          columnDefinitions={awardColumnDefinition}
                          items={items}
                          variant="embedded"
                          filter={
                            <PropertyFilter
                              countText={getTextFilterCounterText(
                                filteredItemsCount,
                              )}
                              {...propertyFilterProps}
                              i18nStrings={{
                                filteringAriaLabel: "your choice",
                                dismissAriaLabel: "Dismiss",
                                clearAriaLabel: "Clear",

                                filteringPlaceholder:
                                  "Filter users by text, property or value",
                                groupValuesText: "Values",
                                groupPropertiesText: "Properties",
                                operatorsText: "Operators",

                                operationAndText: "and",
                                operationOrText: "or",

                                operatorLessText: "Less than",
                                operatorLessOrEqualText: "Less than or equal",
                                operatorGreaterText: "Greater than",
                                operatorGreaterOrEqualText:
                                  "Greater than or equal",
                                operatorContainsText: "Contains",
                                operatorDoesNotContainText:
                                  "Does not contain",
                                operatorEqualsText: "Equals",
                                operatorDoesNotEqualText: "Does not equal",

                                editTokenHeader: "Edit filter",
                                propertyText: "Property",
                                operatorText: "Operator",
                                valueText: "Value",
                                cancelActionText: "Cancel",
                                applyActionText: "Apply",
                                allPropertiesLabel: "All properties",

                                tokenLimitShowMore: "Show more",
                                tokenLimitShowFewer: "Show fewer",
                                clearFiltersText: "Clear filters",
                                removeTokenButtonAriaLabel: (token) =>
                                  `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                                enteredTextLabel: (text) => `Use: "${text}"`,
                              }}
                              {...propertyFilterProps}
                              expandToViewport={true}
                            />
                          }
                        />
                      </Container>
                    ),
                  },
                ]
                : []),
              {
                label: "Timeline",
                id: "tl",
                content: (
                  <SpaceBetween direction="vertical" size="xl">
                    <StreamingWindowComponent
                      titleId={itemData.data.imdb_id}
                      vertical={itemData.data.vertical}
                    />
                    <Chrono
                      items={timelineItems}
                      mode="VERTICAL_ALTERNATING"
                      allowDynamicUpdate={true}
                      showAllCardsHorizontal={true}
                      theme={timelineTheme}
                      classNames={timelineClasses}
                      scrollable={{ scrollbar: true }}
                      cardHeight={50}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Audit Events",
                id: "AU",
                content: <AuditBox ip_id={itemData?.data?.ip_id} />,
              },
            ]}
          />
        </div>
      </div>
      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onSubmit={(selectedOption) => {
          onAddFranchise({
            id: selectedOption.value,
            name: selectedOption.label,
            removed: [],
            added: [itemData?.data?.ip_id],
          });

          onAddFranchiseModalClose();
        }}
        onDiscard={onAddFranchiseModalClose}
      />
      <AttributesEditModal
        loading={attributeProcessing}
        visible={showAttrModal}
        tags={_.map(_.pick(itemData?.data, TAGS_LIST), (value, key) => ({
          key,
          value,
        }))}
        onDiscard={onShowAttrClose}
        onSubmit={(data) => {
          onTagUpdateSubmit(data);
        }}
      />
      <CompareModal
        showCompareModal={showCompareModal}
        setShowCompareModal={setShowCompareModal}
        ipId={itemData?.data?.ip_id}
        imdbId={itemData?.data?.imdb_id}
        ip={itemData?.data?.ip}
      />
    </>
  );
}
