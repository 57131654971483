import React from "react";
import IndexPageGrid from "../../../../components/IndexPageCompact";

export const YoutubeCards = () => {
  const items = [
    {
      link: "/mediacatalog/youtube/channels",
      image: "/images/youtube_channel.png",
      name: "Channels",
      description:
        "Discover the most popular YouTube channels and their content.",
    },
    {
      link: "/mediacatalog/youtube/movietrailers",
      name: "Movie Trailers",
      image: "/images/youtube_movie.png",
      description:
        "Explore the latest movie trailers and discover the most anticipated movies.",
    },
    {
      link: "/mediacatalog/youtube/seriestrailers",
      name: "TV Show Trailers",
      image: "/images/youtube_tv.png",
      description:
        "Discover the latest TV show trailers and find out what's trending.",
    },
    {
      link: "/mediacatalog/youtube/gamestrailers",
      name: "Game Trailers",
      image: "/images/youtube_gaming.png",
      description:
        "Explore the latest game trailers and discover the most anticipated games.",
    },
  ];

  const gridDefinition = items.map(() => ({
    colspan: { xxs: 12, xs: 6, m: 3 },
  }));

  return <IndexPageGrid items={items} gridDefinition={gridDefinition} />;
};
