import React from "react";
import {
    ColumnLayout,
    Link,
    Box,
    SpaceBetween,
} from "@cloudscape-design/components";

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

const CastAndCrewComponent = ({ itemData }) => {
    const renderItemCategory = (
        title,
        items,
        columns = 1,
        groupByJob = false
    ) => {
        const groupItemsByJob = (items) => {
            return items.reduce((acc, item) => {
                const [,name, job] = item.split(":");
                const key = job || "";
                if (!acc[key]) acc[key] = [];
                acc[key].push(name + (job ? ":" + job : ""));
                return acc;
            }, {});
        };

        const chunkArray = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));


        let itemChunks;
        if (groupByJob) {
            const groupedByJob = groupItemsByJob(items);
            const flattenedGroupedItems = Object.values(groupedByJob).flat();
            itemChunks =
                columns > 1
                    ? chunkArray(
                        flattenedGroupedItems,
                        Math.ceil(flattenedGroupedItems.length / columns)
                    )
                    : [flattenedGroupedItems];
        } else {
            itemChunks =
                columns > 1
                    ? chunkArray(items, Math.ceil(items.length / columns))
                    : [items];
        }

        return (
            <SpaceBetween>
                <Box variant="h3" padding="mb-6">
                    {title}
                </Box>
                <ColumnLayout columns={columns} variant="text-grid">
                    {itemChunks.map((chunk, index) => (
                        <div key={index}>
                            {chunk.map((x) => {
                                const [,name, job] = x.split(":");
                                return (
                                    <ValueWithLabel label="" key={x}>
                                        <Link href={`#`} variant="primary">
                                            {name}
                                        </Link>
                                        <span className="text-slate-500"> ({job})</span>
                                    </ValueWithLabel>
                                );
                            })}
                        </div>
                    ))}
                </ColumnLayout>
            </SpaceBetween>
        );
    };

    const renderDivider = () => (
        <hr
            className="my-1"
            style={{ borderColor: "#4a5568", margin: "0.25rem 0" }}
        />
    );

    return (
        <SpaceBetween direction="vertical" size="s">
            {renderItemCategory("Cast", itemData.cast, 4)}
            {itemData.director && renderDivider()}
            {renderItemCategory("Director", itemData.director, 3)}
            {itemData.writer && renderDivider()}
            {renderItemCategory("Writer", itemData.writer, 3)}
            {itemData.producer && renderDivider()}
            {renderItemCategory("Producer", itemData.producer, 3)}
        </SpaceBetween>
    );
};

export default CastAndCrewComponent;
