import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

export const fetchMoviesData = createAsyncThunk(
  "moviesData/fetchMoviesData",
  async ({ from, size, range }) => {
    const response = await axiosInstance.request({
      url: `explore/Movies?from=${from}&size=${size}${
        range ? `&gte=${range.gte}&lte=${range.lte}` : ""
      }`,
      method: "GET",
    });
    return response.data;
  }
);

export const fetchFilteredMoviesData = createAsyncThunk(
  "moviesData/fetchFilteredMoviesData",
  async ({ from, size, range, body }) => {
    const response = await axiosInstance.request({
      url: `explore/Movies?from=${from}&size=${size}${range ? range : ""}`,
      method: "POST",
      data: body,
    });
    return response.data;
  }
);

const moviesSlice = createSlice({
  name: "moviesData",
  initialState: {
    data: [],
    total: 0,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMoviesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMoviesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchMoviesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFilteredMoviesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilteredMoviesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchFilteredMoviesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default moviesSlice.reducer;
