import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import renderTrend from "../../../../utils/trendIcon";

export const useGridConfig = () => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "daily_rank_change",
        headerName: "Daily Rank Change",
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        headerName: "Rank ",
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "daily_rank",
            "daily_rank_change",
            "daily_trend",
          ),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        cellRenderer: (params) => {
          const releaseYear = new Date(params.data.release_date).getFullYear();
          const ipId = params.data.ip_id;
          const destination = `/item/${ipId}`;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.tracked != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link
                variant="primary"
                href={destination}
                onClick={(e) => !ipId && e.preventDefault()}
              >
                {params.value}
              </Link>
              <span className="text-xs text-slate-500 mt-1">
                ({releaseYear})
              </span>
            </div>
          );
        },
      },
      {
        field: "weekly_rank",
        headerName: "Weekly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "weekly_rank",
            "weekly_rank_change",
            "weekly_trend",
          ),
        valueGetter: (params) => parseInt(params.data.weekly_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        headerName: "All Time Rank",
        cellDataType: "number",
        pinned: "left",
        maxWidth: 110,
        cellRenderer: (params) =>
          renderTrend(params, "rank", "rank_change", "trend"),
        valueGetter: (params) => parseInt(params.data.rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "score",
        headerName: "Pantheon Score",
        headerTooltip: "Pantheon Score based on Views, Likes, and Comments.",
        cellDataType: "number",
        maxWidth: 100,
        pinned: "left",
        valueGetter: (params) => params.data.score,
        valueFormatter: (params) => shortenInteger(params.data.score),
        comparator: (valueA, valueB) => {
          return valueA - valueB;
        }
      },
      {
        field: "todayViews",
        headerName: "Views (Last Day)",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "todayLikes",
        headerName: "Likes (Last Day)",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "todayComments",
        headerName: "Comments (Last Day)",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "views",
        headerName: "Total Views",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "likes",
        headerName: "Total Likes",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "comments",
        headerName: "Total comments",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "daily_score",
        headerName: "Daily Score",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "weekly_score",
        headerName: "Weekly Score",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      // {
      //   field: "publishedAt",
      //   headerName: "Days since Published",
      //   cellDataType: "number",
      //   valueGetter: (params) => {
      //     const days = daysSincePublished(params.data.publishedAt * 1000);
      //     return days;
      //   },
      // },
      // {
      //   field: "avg_views",
      //   headerName: "Average Views",
      //   cellDataType: "number",
      //   valueGetter: (params) => {
      //     const days = daysSincePublished(params.data.publishedAt * 1000);
      //     if (days > 0) {
      //       const avgViews = params.data.views / days;
      //       return avgViews;
      //     }
      //     return 0;
      //   },
      //   valueFormatter: (params) => {
      //     if (params.value !== 0) {
      //       return shortenInteger(params.value);
      //     }
      //     return "0";
      //   }
      // },
      {
        field: "sparkline",
        headerName: "30-day Trend",
        maxWidth: 600,
        minWidth: 200,
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            tooltip: {
              enabled: true,
              renderer: (params) => {
                const totalDays = params.context.data.sparkline.length;
                const adjustedIndex = totalDays - params.xValue;
                const date = moment().subtract(adjustedIndex, "days").format("YYYY-MM-DD");
                return `<div class="bg-white p-2">
                        <div class='bg-slate-300 p-1'>${date}</div>
                        <hr/>
                        <div>
                          <div>Score: ${shortenInteger(params.yValue)}</div>
                        </div>
                      </div>`;
              }
            },
            type: "area",
            marker: {
              size: 1,
              shape: "circle",
              fill: "white",
            },
            fill: "#0972d3",
            highlightStyle: {
              fill: "#033160",
            },
          },
        },
        filter: false,
        sortable: false,
      }
    ];
    return allColumns;
  }, []);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  const gridOptions = {
    getContextMenuItems: (params) => {
      if (params.column.colId === "ip") {
        return null;
      }
      return params.defaultItems;
    },
  };

  return {
    defaultColDef,
    columnDefs,
    onGridReady,
    gridOptions,
  };
};
