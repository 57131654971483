import { useMemo } from "react";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { mapGetPantheonResponse } from "../mappers/mapGetPantheonResponse";
import { getPantheonRankingData } from "../request";

export type MoviesType = "movies" | "television" | "games";

type UseGetPantheonRankingDataProps = {
  vertical: MoviesType;
  limit: number;
  offset: number;
  sort: string;
  timeSpan: string;
};

export const useGetPantheonRankingData = ({
  vertical,
  limit,
  offset,
  sort,
  timeSpan,
}: UseGetPantheonRankingDataProps) => {
  const { isLoading, data, error } = useQuery(
    [
      QUERY_KEYS.GET_PANTHEON_RANKING_DATA,
      vertical,
      limit,
      offset,
      sort,
      timeSpan,
    ],
    () => getPantheonRankingData({ vertical, limit, offset, sort, timeSpan }),
  );

  const memoData = useMemo(
    () => mapGetPantheonResponse(data?.data),
    [data?.data],
  );

  return {
    data: data?.data ? memoData : [],
    isLoading,
    ...(error as any),
  };
};
