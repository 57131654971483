import {
  Box,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CommonPreference } from "../../../components/CommonPreference";
import { TableFilter } from "../../../components/filters/TableFilter";
import { DEFAULT_FILTERING_QUERY } from "../../../config-global";
import { usePostGenericPreference } from "../../../services/generic/hooks/usePostGenericPreference";
import createFlashMessage from "../../../utils/createFlashMessage";
import {
  generateNestedObject,
  getNestedObjectValue,
} from "../../../utils/helpers";
import { isNone, isSome, isSomeOrElse } from "../../../utils/sugarUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { useChannelsListData } from "./api/hooks/useChannelsListData";
import { DEFAULT_API_PARAMS } from "./api/request";
import { CreateChannelModal } from "./components/CreateChannelModal";
import { filteringProperties } from "./filterConfig";
import { columnDefinitions, visibleContentPreference } from "./gridConfig";
import { useFiltering } from "../hooks/useFiltering";

const PREFERENCE_PATH = "mediaCatalog.youtube.channels.preferences";
const FILTERS_PATH = "mediaCatalog.youtube.channels.filters";
const initialApiParams = { ...DEFAULT_API_PARAMS };

const defaultFilters = {
  "General Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "General" }],
  },
  "News Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "News" }],
  },
  "Movie Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "Movies" }],
  },
  "Series Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "TV" }],
  },
  "OTT Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "OTT" }],
  },
  "Company Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "Company" }],
  },
  "Franchise Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "Franchise" }],
  },
  "Gaming Channels": {
    operation: "and",
    tokens: [{ propertyKey: "type", operator: "=", value: "Gaming" }],
  },
};

export const ChannelsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [apiParams, setApiParams] = useState({ ...initialApiParams });
  const [apiEnabled, setApiEnabled] = useState(false);
  const [count, setCount] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedChannelData, setSelectedChannelData] = useState(null);
  const [preferences, setPreferences] = useState({
    visibleContent: [
      "title",
      "image",
      "publishedAt",
      "type",
      "subscriberCount",
      "viewCount",
      "videoCount",
      "todaysViews",
    ],
  });

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );
  const hasApiCompleted = useSelector(
    (state) => state?.globalPersonalPref?.hasApiCompleted,
  );

  useEffect(() => {
    const pref = getNestedObjectValue(globalPref, PREFERENCE_PATH);

    if (isSome(pref)) setPreferences(pref);
    if (hasApiCompleted) setApiEnabled(true);
  }, [globalPref, hasApiCompleted]);

  useFiltering({
    currentPage,
    preferences,
    initialApiParams,
    filteringQuery,
    sortingColumn,
    sortingDescending,
    setApiParams,
    setCurrentPage,
    setFilteringQuery,
  });

  const { mutate: postGenericPreference } = usePostGenericPreference();
  const { data: channelsListData, isLoading } = useChannelsListData(
    { ...apiParams, size: preferences?.pageSize || apiParams.size },
    apiEnabled,
  );

  useEffect(() => {
    if (channelsListData?.count) setCount(channelsListData.count);
  }, [channelsListData, count]);

  const handlePreferenceChange = ({ detail }) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      PREFERENCE_PATH,
      detail,
    );

    postGenericPreference(newGlobalPreferences);
  };

  const getPaginationCount = (count, preferences) =>
    Math.ceil(
      (count > 10000 ? 10000 : count) /
        isSomeOrElse(preferences?.pageSize, DEFAULT_API_PARAMS.size),
    );

  const getPreferencesAfterDelete = (name) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };
    currentFilters[name] = { ...filteringQuery };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingPreferences(currentFilters);
  };

  const getExistingPreferences = (currentFilters) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      FILTERS_PATH,
      currentFilters,
    );

    return newGlobalPreferences;
  };

  return (
    <>
      <Table
        columnDefinitions={columnDefinitions}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        loading={isLoading}
        items={channelsListData?.data}
        loadingText="Loading resources"
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        error={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              Error displaying the data.
            </Box>
          </Box>
        }
        header={
          <SpaceBetween direction="vertical" size="xs">
            <CustomFlashBar />
            <Header
              variant="h3"
              counter={
                <span>({channelsListData?.count?.toLocaleString()})</span>
              }
              actions={
                <Button
                  variant="primary"
                  onClick={() => {
                    setSelectedChannelData(null);
                    setOpenCreateModal(true);
                  }}
                >
                  Add Channel
                </Button>
              }
            >
              YouTube Channels
            </Header>
          </SpaceBetween>
        }
        preferences={
          <CommonPreference
            preferences={preferences}
            onConfirm={handlePreferenceChange}
            contentDisplayOptions={visibleContentPreference}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={getPaginationCount(count, preferences)}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <TableFilter
            defaultFilters={defaultFilters}
            filteringQuery={filteringQuery}
            filteringProperties={filteringProperties}
            setFilteringQuery={setFilteringQuery}
            getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
            getPreferencesAfterDelete={getPreferencesAfterDelete}
            filtersPath={FILTERS_PATH}
          />
        }
      />
      <CreateChannelModal
        title="Add channel from YouTube"
        visible={openCreateModal}
        selectedChannelData={selectedChannelData}
        setSelectedChannelData={setSelectedChannelData}
        onDismiss={() => setOpenCreateModal(false)}
        onSuccess={() => {
          setOpenCreateModal(false);
          addMessageToFlash(
            createFlashMessage({
              type: "success",
              message: "Channel Created!",
            }),
          );
          setTimeout(cleanAllFlashMessage, 3000);
        }}
        onFailure={() => {
          setOpenCreateModal(false);
          addMessageToFlash(
            createFlashMessage({
              type: "error",
              message: "Unable to create channel!",
            }),
          );
          setTimeout(cleanAllFlashMessage, 3000);
        }}
      />
    </>
  );
};
