import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

export const fetchCompaniesData = createAsyncThunk(
  "companiesData/fetchCompaniesData",
  async ({ from, size, range }) => {
    const response = await axiosInstance.request({
      url: `explore/company?from=${from}&size=${size}${
        range ? `&gte=${range.gte}&lte=${range.lte}` : ""
      }`,
      method: "GET",
    });
    return response.data;
  }
);

export const fetchFilteredCompaniesData = createAsyncThunk(
  "companiesData/fetchFilteredCompaniesData",
  async ({ from, size, range, body }) => {
    
    const response = await axiosInstance.post(`explore/company?from=${from}&size=${size}${range ? range : ""}`,{ ...body });
    return response.data;
  }
);

const companiesSlice = createSlice({
  name: "companiesData",
  initialState: {
    data: [],
    total: 0,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompaniesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchCompaniesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchFilteredCompaniesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilteredCompaniesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchFilteredCompaniesData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default companiesSlice.reducer;
