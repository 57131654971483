import React, { useState, useMemo, useEffect } from "react";
import { Box, Link } from "@cloudscape-design/components";
import axiosInstance from "../../../../../utils/axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import renderTrend from "../../../../../utils/trendIcon";
import shortenInteger from "../../../../../utils/shortenInteger";

export const twitchPopular = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "Popular Games in Twitch",
    description: "Daily Twitch popular games stats",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
      {twitchPopular.data.title}
    </Box>
  );
}

function AccountAttributesFooter() {
  return (
    <Box>
      <Link
        fontSize="inherit"
        href="/dashboards/twitchtrends"
        variant="secondary"
      >
        View All
      </Link>
    </Box>
  );
}

function AccountAttributesContent() {
  const [twitchTC, setTwitchTC] = useState({ data: [], status: "idle" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maxGrade, setMaxGrade] = useState(0);

  const fetchTwitchTCData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "ranking/daily/global_twitch/v1?size=10&after=0",
        {
          "_source": ["ip", "ip_id", "grade", "daily_rank", "rank_change", "rank_trend"],
          "sort": [{ "daily_rank": "asc" }],
          "include": [],
          "exclude": [],
          "condition": "and"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error("Error fetching Data");
      }

      let data = response.data.data.map((item) => {
        return {
          ...item,
          grade: [item.grade],
        };
      });
      setTwitchTC({ data, status: "loaded" });

      const maxValue = Math.max(...data.map(item => item.grade[0]));
      setMaxGrade(maxValue);

    }
    catch (error) {
      setError(error.message);
      setTwitchTC((prevState) => ({ ...prevState, status: "error" }));
    }
    finally {
      setIsLoading(false);
    }
  };

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Rank ",
        maxWidth: 80,
        cellDataType: "number",
        minWidth: 80,
        sortable: true,
        cellRenderer: (params) => renderTrend(params, "daily_rank", "rank_change", "rank_trend"),
        valueGetter: (params) => parseInt(params.data.daily_rank),
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span> {params.data.ip}</span>
            </Link>
          );
        },
      },
      {
        field: "grade",
        headerName: "Score",
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            label: {
              enabled: true,
              placement: 'insideBase',
              color: 'white',
              fontSize: 10,
              fontWeight: 'bold',
              formatter: (params) => shortenInteger(params.value),
            },
            fill: '#D46E0F',
            strokeWidth: 0,
            highlightStyle: {
              fill: '#033160',
              strokeWidth: 0,
            },
            valueAxisDomain: [0, maxGrade],
            paddingOuter: 0,
          },
        },
      },
    ];
    return allColumns;
  }, [maxGrade]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    [],
  );

  useEffect(() => {
    fetchTwitchTCData();
  }, []);

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className="ag-theme-quartz-dark compact" style={{ height: '99.5%' }}>
        <AgGridReact
          rowData={twitchTC.data}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
