import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween
} from "@cloudscape-design/components";
import React from "react";
import IndexPageGrid from "../../components/IndexPageGrid";
import { navItems } from "../../layouts/common/menu/side-menu";
import { Layout } from "./Layout";
import { BOChart } from "./components/BOChart";

function BoxOfficeCards() {
  const items = [
    {
      link: "/box-office/ranking",
      image: "/images/BoxofficeRanking.png",
      name: "Box Office Ranking",
      path: "Box Office",
      description:
        "Box Office Rankings offers a definitive glance at the fiscal success of film releases throughout the year.",
    },
    {
      link: "/box-office/grosstimeseries",
      image: "/images/Timeseries.png",
      name: "Box Office Timeseries",
      path: "Box Office",
      description:
        "Dive into the timeseries dashboard, illustrating daily revenue trends across selected movies.",
    },
    {
      link: "/box-office/bo-by-studio",
      image: "/images/BoxofficeByStudio.png",
      name: "Box Office By Studio",
      path: "Box Office",
      description:
        "Box Office By Studio Dashboard provide insights into the performance of studios",
    }
  ];

  const gridDefinition = items.map(() => ({ colspan: { xxs:12,xs:6, m: 3, xl: 2 } }));

  return (
    <IndexPageGrid items={items} gridDefinition={gridDefinition} />
  );
}
export const BoxOfficeHomePage = () => {
  const breadcrumbs = [{ text: "Dashboard" }, { text: "Box Office" }];
  return (
    <Layout
      title="Box Office"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="Comprehensive box office performance analysis and financial performance of films across various markets. This section offers detailed insights into daily, weekly, weekend and cumulative gross earnings."
            >
              Box Office
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <Container>
              <BOChart/>
              </Container>
            <BoxOfficeCards />
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="dashboard"
      />
  );
};
