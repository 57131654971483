export const mapCreateMovieResonse = ({
  ip,
  release_date,
  image_url,
  summary,
}) => ({
  ip,
  summary,
  url: image_url,
  releaseDate: release_date,
});
