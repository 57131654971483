import {
  Box,
  Button,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import React, { FC, useEffect, useState } from "react";
import { arrayDifference } from "../../../../utils/helpers";
import { useGetItemCompareData } from "../../api/hooks/useGetItemCompareData";
import { getTopSearchData } from "../../api/request";
import { ItemsComparisonChart } from "../../components/ItemsComparisonChart/ItemsComparisonChart";
import { RawDataType, getChartContent, mapData } from "./chartUtils";

type CompareModalProps = {
  showCompareModal: boolean;
  setShowCompareModal: (showCompareModal: boolean) => void;
  ipId: string;
  imdbId: string;
  ip: string;
};

export const CompareModal: FC<CompareModalProps> = ({
  ip,
  ipId,
  imdbId,
  showCompareModal,
  setShowCompareModal,
}) => {
  const [searchItems, setSearchItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [statusType, setStatusType] =
    useState<DropdownStatusProps.StatusType>("finished");
  const [selectedOptions, setSelectedOptions] = React.useState<
    ReadonlyArray<OptionDefinition>
  >([]);
  const [rawData, setRawData] = useState<RawDataType>({});
  const [apiParams, setApiParams] = useState({ ip, ipId, imdbId });
  const [selectedIps, setSelectedIps] = useState<Array<string>>([]);
  const { data: itemCompareData } = useGetItemCompareData(apiParams);
  let timeoutId;

  useEffect(() => {
    const ips = selectedOptions.reduce((acc, item) => {
      acc[item.value] = item.value;
      return acc;
    }, {});

    // Logic for handling unchecking the MultiSelect
    if (Object.keys(ips).length < selectedIps.length) {
      const rawDataCopy = { ...rawData };
      const removedIp = arrayDifference(Object.keys(ips), selectedIps)[0];

      delete rawDataCopy[removedIp];
      setRawData(rawDataCopy);
      setSelectedIps(Object.keys(ips));
      return;
    }

    setSelectedIps(Object.keys(ips));

    const newlyAddedIp = arrayDifference(
      Object.keys(ips),
      Object.keys(rawData),
    )[0];

    const selectedItem = searchItems
      .filter((item) => item.ip_id === newlyAddedIp)
      .map((item) => ({
        ip: item.ip,
        ipId: item.ip_id,
        imdbId: item.imdb_id,
      }));

    if (selectedItem.length > 0) setApiParams(selectedItem[0]);
  }, [selectedOptions]);

  useEffect(() => {
    if (Object.keys(itemCompareData).length > 0)
      setRawData((prevData) => ({
        ...prevData,
        [apiParams.ipId]: mapData(apiParams.ip, itemCompareData),
      }));
  }, [itemCompareData]);

  const handleSelectionChange = ({ detail }) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setSelectedOptions(detail.selectedOptions);
    }, 500);
  };

  const searchItem = React.useCallback(
    (query) => {
      if (!query) return;

      getTopSearchData(query)
        .then((data) => data.data)
        .then((data) => {
          const filteredData = data.filter(
            (item) =>
              !selectedItems.some((selected) => selected.value === item.ip_id),
          );
          setSearchItems(filteredData);
          setStatusType("finished");
        })
        .catch((e) => {
          console.error(e);
          setStatusType("error");
        });
    },
    [setSearchItems, selectedItems],
  );

  return (
    <Modal
      size="max"
      onDismiss={() => setShowCompareModal(false)}
      visible={showCompareModal}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={() => setShowCompareModal(false)}>
              Cancel
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Compare Titles"
    >
      <SpaceBetween direction="vertical" size="xl">
        <Multiselect
          selectedOptions={selectedOptions}
          onChange={handleSelectionChange}
          options={searchItems.map((item) => {
            return {
              value: item.ip_id,
              label: `${item.ip} (${new Date(
                item.release_date,
              ).getFullYear()})`,
            };
          })}
          onLoadItems={({ detail }) => {
            const { filteringText } = detail;
            if (filteringText) {
              setStatusType("loading");
              setSearchItems([]);
              searchItem(filteringText);
            }
          }}
          statusType={statusType}
          loadingText="Loading..."
          placeholder="Select Movies to Compare"
          filteringPlaceholder="Search ..."
          filteringType="manual"
          filteringResultsText={(count) => `${count} results found`}
        />
        <div style={{ height: "80vh" }}>
          <ItemsComparisonChart data={getChartContent(rawData)} />
        </div>
      </SpaceBetween>
    </Modal>
  );
};
