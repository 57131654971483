import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import React from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetChannelTrends } from "../api/hooks/useGetChannelTrends";
import { Grid } from "@cloudscape-design/components";

export const ChannelTrends = ({ channelId }) => {
  const { data: channelTrendsData } = useGetChannelTrends(channelId);

  const pantheonTheme = {
    baseTheme: "ag-vivid-dark",
    overrides: {
      common: {
        background: { fill: "#0c1521" },
      },
    },
  };

  const viewsThemeOverrides = {
    ...pantheonTheme,
    palette: {
      fills: ["#E69138"],
    },
  };

  const videoThemeOverrides = {
    ...pantheonTheme,
    palette: {
      fills: ["#e63a38"],
    },
  };

  const viewsOptions = {
    theme: viewsThemeOverrides,
    autoSize: true,
    zoom: {
      enabled: true,
    },
    title: {
      text: "Viewership Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: channelTrendsData,
    series: [
      {
        type: "area",
        xKey: "timestamp",
        yKey: "viewCount",
        yName: "Views",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Daily Views",
              content: `
                <b>Date:</b> ${date.toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })} <br />
                <b>Views:</b> ${Math.round(
                params.datum.viewCount,
              ).toLocaleString()}<br/>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Views",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["viewCount"],
        label: {
          formatter: (params) => {
            return String(shortenInteger(params.value));
          },
        },
      }
    ],
  };

  const subscriberOptions = {
    theme: pantheonTheme,
    autoSize: true,
    zoom: {
      enabled: true,
    },
    title: {
      text: "Subscription Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: channelTrendsData,
    series: [
      {
        type: "line",
        xKey: "timestamp",
        yKey: "subscriberCount",
        yName: "Subscribers",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Subscribers",
              content: `
                          <b>Date:</b> ${date.toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })} <br />
                          <b>Subscibers:</b> ${Math.round(
                params.datum.subscriberCount,
              ).toLocaleString()}<br/>
                          <b>New Subscibers:</b> ${Math.round(
                params.datum.subscriberGrowth,
              ).toLocaleString()}<br/>
                          `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Subscribers",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["subscriberCount"],
        label: {
          formatter: (params) => {
            return shortenInteger(params.value);
          },
        },
      }
    ],
  };

  const videoOptions = {
    theme: videoThemeOverrides,
    autoSize: true,
    zoom: {
      enabled: true,
    },
    title: {
      text: "Video Count Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: channelTrendsData,
    series: [
      {
        type: "bar",
        xKey: "timestamp",
        yKey: "videoGrowth",
        yName: "Videos",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Videos",
              content: `
                          <b>Date:</b> ${date.toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })} <br />
                          <b>Videos:</b> ${Math.round(
                params.datum.videoCount,
              ).toLocaleString()}<br/>
                          <b>New Videos:</b> ${Math.round(
                params.datum.videoGrowth,
              ).toLocaleString()}<br/>
                          `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Videos",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["videoGrowth"],
      }
    ],
  };

  return (
    < Grid
      gridDefinition={
        [
          { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } },
          { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } },
          { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } }
        ]}
    >
      <div className="h-60">
        <AgChartsReact options={viewsOptions} />
      </div>
      <div className="h-60">
        <AgChartsReact options={subscriberOptions} />
      </div>
      <div className="h-60">
        <AgChartsReact options={videoOptions} />
      </div>
    </Grid>
  );
};
