import { SpaceBetween, Link } from "@cloudscape-design/components";

const FranchiseTag = ({ itemData }) => {
    return (
        <SpaceBetween direction="horizontal" size="xxxs">
            Franchises:
            {itemData.data.franchise.map((f, index) => (
                <span key={f.id}>
                    <Link href={`/item/${f?.id}`} variant="primary">
                        <button
                            type="button"
                            className="ml-2 rounded-md bg-green-400/10 px-2.5 py-1 text-xs  text-green-400 shadow-sm hover:bg-green-500  hover:text-white ring-1 ring-inset ring-green-400/50"
                        >
                            {f.name}
                        </button>
                    </Link>
                </span>
            ))}
        </SpaceBetween>
    )
}

export default FranchiseTag;