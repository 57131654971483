import { useMutation, useQueryClient } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { QUERY_KEYS } from "../constants";
import { getGenericPreference, postGenericPreference } from "../requests";

export const usePostAndGetGenericPreference = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  const postAndGetMutation = useMutation((payload) =>
    postGenericPreference(user, payload),
  );

  const postAndGetGenericPreference = async (payload) => {
    try {
      await postAndGetMutation.mutateAsync(payload);
      const newData = await getGenericPreference(user);
      // Invalidate existing cache for the GET request
      queryClient.setQueryData(QUERY_KEYS.GET_GENERAL_PREFERENCE, newData);
    } catch (error) {}
  };

  return {
    postAndGetGenericPreference,
    isLoading: postAndGetMutation.isLoading,
    error: postAndGetMutation.error,
  };
};
