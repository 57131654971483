
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchTwitchData = createAsyncThunk(
    "trends/fetchTwitch",
  
    async ({ params }) => {
      const response = await axiosInstance.request({
        url: `/realtime/twitch`,
        method: `GET`,
        params: params,
      });
      return response;
    }
);

const twitchDataSlice = createSlice({
    name: "twitchData",
    initialState,
    reducers: {
      clearData: () => {
        return initialState;
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchTwitchData.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchTwitchData.fulfilled, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        })
        .addCase(fetchTwitchData.rejected, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        });
    },
});

const twitchDataReducer = twitchDataSlice.reducer;

export default twitchDataReducer;

export const twitchDataActions = twitchDataSlice.actions;

