import { ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Spinner from "@cloudscape-design/components/spinner";
import Link from "@cloudscape-design/components/link";
import shortenInteger from "../../../utils/shortenInteger";

export const PiracyOptionLayer = ({ optionData, loading, vertical }) => {

  const renderItems = (item, index) => {
    const CommonJSX = (
      <div key={item.IP} className="inline-block relative group">
      <div className="w-30 h-[12rem] max-w-xs overflow-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out border-2 border-slate-600 p-1">
          {item.images === "https://pantheon-website-public.s3.us-east-2.amazonaws.com/noimage.png_V1_QL100_UX150_CR0,0,150,210_.jpg" ? (
            <div className="w-28 h-[12rem] rounded-md bg-black bg-opacity-50 flex items-center justify-center p-2 flex-wrap">
              <span className="text-white text-sm font-bold">{item.ip}</span>
            </div>
          ) : (
            <img src={item.images} alt={item.ip} className={"w-28 rounded-md"} loading="lazy" />
          )}
        <div className="absolute inset-0 rounded-lg items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-90 p-4">
          <span className="text-white text-sm font-bold">{item.ip}</span>

        </div>

        <div className="absolute bottom-1 left-1.5 bg-sidebarLow border-2 border-slate-600 rounded-full px-1.5 py-1">
          <span className="text-amber-500 text-sm font-bold flex flex-row items-center">
            <p>#</p>
            {index + 1}
          </span>
        </div>
        <div className="absolute  text-sm bottom-1 right-1.5 bg-sidebarLow border-2 border-slate-600 rounded-full px-1.5 py-1">
          <span
            className="text-slate-400 flex flex-row items-center"
            title={item.downloads.toLocaleString() + " Downloads"}
          >
            <ArrowDownOnSquareIcon className="w-4 h-4 " />
            {shortenInteger(item.downloads)}
          </span>
        </div>
      </div>
      </div>
    );

    return (
      <div key={item.ip_id} className="inline-block relative mr-2 mb-2">
        <Link
          href={item.ip_id ? `${window.location.origin}/item/${item.ip_id}` : undefined}
          fontSize="heading-m"
        >
          {CommonJSX}
        </Link>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <ExpandableSection 
      defaultExpanded 
      disableContentPaddings
      headerText={vertical.label} >
        <div className="flex flex-wrap justify-center pb-4 max-h-[25rem] overflow-y-auto">
          {loading ? (
            <Spinner size="large" />
          ) : Array.isArray(optionData) && optionData.length > 0 ? (
            optionData.map(renderItems)
          ) : (
            <div>No results</div>
          )}
        </div>
      </ExpandableSection>
    </div>
  );
};
