import React from 'react';
import { Grid, Container, Box, Link, SpaceBetween } from "@cloudscape-design/components";

function IndexPageGrid({ items, gridDefinition }) {
    return (
        <div className="pt-10">
            <Grid gridDefinition={gridDefinition}>
                {items.map((item, index) => (
                    <Container
                    variant="stacked"
                        fitHeight
                        key={index}
                        media={{
                            content: (
                                <Link href={item.link}>
                                <img src={item.image} alt={item.name} />
                                </Link>
                            ),
                            height: 200,
                            position: "top"
                        }}
                    >
                        <SpaceBetween direction="vertical" size="s">
                            <div>
                                <Box color="text-status-inactive" fontSize="body-s" > {item.path} </Box>
                                <Box variant="h2">
                                    <Link variant="primary" fontSize="heading-m" href={item.link}>
                                        {item.name}
                                    </Link>
                                </Box>
                            </div>
                            {item.description}
                        </SpaceBetween>
                    </Container>
                ))}
            </Grid>
        </div>
    );
}

export default IndexPageGrid;
