import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchPiracyTrendsDataApi = createAsyncThunk(
  "piracyTrends/fetchPiracyTrendsDataApi",

  async ({ params }) => {
    try {
      const response = await axiosInstance.get(
        `piracy_generic/${params?.ip_id}?gte=now-5y&lte=now&fetch=ByID`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        const convertedData = response.data.data.map(item => {
          return {
            ...item,
            timestamp: item.timestamp * 1000
          };
        });
        return convertedData; 
      } else {
        return response.data; 
      }

    } catch (error) {
      throw error; 
    }
  }
);


const piracyTrendsSlice = createSlice({
  name: "piracyTrends",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPiracyTrendsDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPiracyTrendsDataApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload; 
      })
      .addCase(fetchPiracyTrendsDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const PiracyTrendsReducer = piracyTrendsSlice.reducer;

export default PiracyTrendsReducer;
