/* PLEASE DO NOT REFORMAT THIS FILE (breaks spacing in certain areas) */

import { 
  AppLayout,
  ContentLayout,
  Container,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Icon,
  DatePicker,
  Button,
  Table,
  Modal,
  SpaceBetween,
  Box,
  Input,
  Autosuggest,
  TokenGroup,
  Select,
  FormField,
  ButtonDropdown,
  StatusIndicator,
  Tabs,
  TextFilter,
  Pagination,
  ColumnLayout,
  Toggle,
  Spinner,
  SplitPanel,
  Multiselect,
  Cards,
  Checkbox,
  Link,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import moment from "moment";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import {
  DecreaseIcon,
  IncreaseIcon,
  NeutralIcon,
  NewIcon,
} from "../../../components/Icons";
import TopicSearchBar, { getSearch } from "../components/TopicSearchBar";
import { addToFavorites, removeFromFavorites } from "./preferences";
import { rawNameToTitle, sanitizeAgKey } from "../utils/name-utils";
import { colorPalette, lineTheme } from "./chartConfig";

const TopicModelingTrueCrimeV2 = () => {

  const flashbarRef = useRef(null);

  const risingTopicsGridRef = useRef(null);
  const risingNamesGridRef = useRef(null);
  const favoritesGridRef = useRef(null);
  const dl_postsGridRef = useRef(null);
  const dl_videosGridRef = useRef(null);

  const domain = "true-crime";
  const breadcrumbs = [{ text: "Topic Modeling", }, { text: "True Crime", href: "/" },];
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment()
      .subtract(1, "month")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const granularityOptions = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ];
  const sortOptions = [
    { label: "Popularity", value: "popularity" },
    { label: "Date", value: "date" },
  ];


  const [ topicsDateRange, setTopicsDateRange ] = useState(defaultDateRange);
  const [ topicsOverTimeResponse, setTopicsOverTimeResponse ] = useState(defaultResponseState);
  const [ risingTopicsRowData, setRisingTopicsRowData ] = useState({ rising: [], favorites: [] });
  const [ namesOverTimeResponse, setNamesOverTimeResponse ] = useState(defaultResponseState);
  const [ risingNamesRowData, setRisingNamesRowData ] = useState({ rising: [], favorites: [] });
  const [ compareResponse, setCompareResponse ] = useState(defaultResponseState);
  const [ searchResponse, setSearchResponse ] = useState(defaultResponseState);
  const [ postsByNameResponse, setPostsByNameResponse ] = useState(defaultResponseState);
  const [ videosByNameResponse, setVideosByNameResponse ] = useState(defaultResponseState);

  const [ selectedTopicNodes, setSelectedTopicNodes ] = useState([]);
  const [ selectedNameNodes, setSelectedNameNodes ] = useState([]);
  const [ selectedFavoriteNodes, setSelectedFavoriteNodes ] = useState([]);

  const [ compareDateRange, setCompareDateRange ] = useState({
    type: "absolute",
    startDate: moment()
      .subtract(1, "year")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [ selectedCompareGranularity, setSelectedCompareGranularity ] = useState(granularityOptions[1]);
  const [ selectedCompareOptions, setSelectedCompareOptions ] = useState([]);
  const [ compareSeries, setCompareSeries ] = useState([]);
  const [ compareBarSeriesData, setCompareBarSeriesData ] = useState([]);
  const [ legendItems, setLegendItems ] = useState([]);
  const [ showMarkers, setShowMarkers ] = useState(true);

  const [ selectedPostsSort, setSelectedPostsSort ] = useState(sortOptions[0]);
  const [ risingNames, setRisingNames ] = useState([]);


  const openComparePanel = () => {
    const xpath = "//h2[text()='Compare names and topics']";
    const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    matchingElement.parentNode.click();
  };

  const processTopicsOverTimeData = (data) => {
    const risingData = data.rising;
    const topicLabels = data.topic_labels;
    const differences = data.differences;
    if (!risingData || risingData.length == 0 || !topicLabels || Object.keys(topicLabels).length == 0) {
      setRisingTopicsRowData({ rising: [], favorites: [] });
      return;
    }

    const topTopics = risingData.map(monthData => monthData.data.filter(item => item.is_rising === true).map(item => item.topic_id)).flat(1).filter((v, i, a) => a.indexOf(v) === i);
    const favTopics = risingData.map(monthData => monthData.data.filter(item => item.is_favorite === true).map(item => item.topic_id)).flat(1).filter((v, i, a) => a.indexOf(v) === i);
    const allTopics = [...new Set([...topTopics, ...favTopics])];
    const countTrends = allTopics.reduce((obj, key) => (obj[key] = [], obj), {});

    risingData.forEach(timePeriod => {
      const data = timePeriod.data;
      const timestamp = timePeriod.timestamp;
      allTopics.forEach(topicId => {
        const topicData = data.find(item => item.topic_id == topicId);
        if (!topicData) {
          countTrends[topicId].push([timestamp, 0]);
        } else {
          countTrends[topicId].push([timestamp, topicData.count]);
        }
      });
    });

    const rowData = allTopics.map(topicId => (
      {
        topic_id: topicId,
        topic_label: topicLabels[topicId],
        count: countTrends[topicId].map(x => x[1]).reduce((a, b) => a + b, 0),
        count_difference: differences[topicId].count_difference,
        trend: countTrends[topicId],
        is_rising: topTopics.includes(topicId),
        is_favorite: favTopics.includes(topicId),
      }
    )).sort((a, b) => b.count_difference - a.count_difference);

    setRisingTopicsRowData({ 
      rising: rowData.filter(x => x.is_rising && x.count > 10), 
      favorites: rowData.filter(x => x.is_favorite),
    });
  };

  const processNamesOverTimeData = (data) => {
    const risingData = data.rising;
    const differences = data.differences;
    if (!risingData || risingData.length == 0) {
      setRisingNamesRowData({ rising: [], favorites: [] });
      return;
    }

    const topNames = risingData.map(monthData => monthData.data.filter(item => item.is_rising === true).map(item => item.name)).flat(1).filter((v, i, a) => a.indexOf(v) === i);
    const favNames = risingData.map(monthData => monthData.data.filter(item => item.is_favorite === true).map(item => item.name)).flat(1).filter((v, i, a) => a.indexOf(v) === i);
    const allNames = [...new Set([...topNames, ...favNames])];
    const countTrends = allNames.reduce((obj, key) => (obj[key] = [], obj), {});

    risingData.forEach(timePeriod => {
      const data = timePeriod.data;
      const timestamp = timePeriod.timestamp;
      allNames.forEach(name => {
        const topicData = data.find(item => item.name == name);
        if (!topicData) {
          countTrends[name].push([timestamp, 0]);
        } else {
          countTrends[name].push([timestamp, topicData.count]);
        }
      });
    });

    const rowData = allNames.map(name => (
      {
        name: name,
        name_label: rawNameToTitle(name),
        count: countTrends[name].map(x => x[1]).reduce((a, b) => a + b, 0),
        count_difference: differences[name].count_difference,
        trend: countTrends[name],
        is_rising: topNames.includes(name),
        is_favorite: favNames.includes(name),
      }
    )).sort((a, b) => b.count_difference - a.count_difference).filter(x => x.count > 10);

    setRisingNamesRowData({ 
      rising: rowData.filter(x => x.is_rising), 
      favorites: rowData.filter(x => x.is_favorite),
    });

    setRisingNames(rowData.map(x => x.name));
  };

  const favoritesRowData = useMemo(() => {
    const newFavoritesRowData = [
      ...risingTopicsRowData.favorites.map(x => (
        { 
          type: "Topics", 
          name: x.topic_label, 
          value: x.topic_id, 
          count: x.count, 
          count_difference: x.count_difference, 
          trend: x.trend,
        }
      )),
      ...risingNamesRowData.favorites.map(x => (
        { 
          type: "Names", 
          name: x.name_label, 
          value: x.name, 
          count: x.count, 
          count_difference: x.count_difference, 
          trend: x.trend,
        }
      )),
    ];

    return newFavoritesRowData;
  }, [risingTopicsRowData.favorites, risingNamesRowData.favorites]);

  const compareTimeSeriesData = useMemo(() => {
    if (compareResponse.data && compareResponse.data?.comparison) {
      const compareData = compareResponse.data.comparison;
      const wikipediaCompareData = compareResponse.data.pageviews;
      const compareTimeSeries = [];
      const uniqueKeys = [];
      const uniqueWikipediaKeys = [];
      const seriesMetadata = {};
      const barData = {};

      const uniqueTimestamps = [
        ...compareData.map(item => item.timestamp),
        ...wikipediaCompareData?.map(item => item.timestamp),
      ].filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => new Date(a) - new Date(b));

      uniqueTimestamps.forEach(timestamp => {
        const compareDataPoint = compareData.find(item => item.timestamp == timestamp);
        const wikipediaCompareDataPoint = wikipediaCompareData?.find(item => item.timestamp == timestamp);

        const dataPoint = {
          timestamp: moment(timestamp).toDate(),
          ...compareDataPoint?.data?.reduce((acc, dataItem) => {
            const key = `${dataItem.field_type}_${sanitizeAgKey(dataItem.field_value)}`;
            if (!uniqueKeys.includes(key)) {
              uniqueKeys.push(key);
            }
            acc[key] = dataItem.count;
            seriesMetadata[key] = {
              label: dataItem.label,
              type: dataItem.field_type,
              value: dataItem.field_value,
            };
            return acc;
          }, {}),
          ...wikipediaCompareDataPoint?.data?.reduce((acc, dataItem) => {
            const key = `wikipedia_${sanitizeAgKey(dataItem.label)}`;
            if (!uniqueWikipediaKeys.includes(key)) {
              uniqueWikipediaKeys.push(key);
            }
            acc[key] = dataItem.num_pageviews;
            seriesMetadata[key] = {
              label: dataItem.label,
              type: "wikipedia",
              value: dataItem.label,
            };
            return acc;
          }, {}),
        };
        compareTimeSeries.push(dataPoint);
      });

      const compareSeries = [
        ...uniqueKeys.map(key => (
          {
            type: "line",
            xKey: "timestamp",
            yKey: key,
            title: seriesMetadata[key].label,
            marker: { enabled: false },
            platform: "compare",
            metadata: seriesMetadata[key],
            tooltip: {
              renderer: (params) => {
                return {
                  title: params.datum.title,
                  content: moment(params.datum.timestamp).format("ll") + ": " + params.datum[key].toLocaleString() + " posts"
                };
              },
            },
          }
        )),
        ...uniqueWikipediaKeys.map(key => (
          {
            type: "line",
            xKey: "timestamp",
            yKey: key,
            title: seriesMetadata[key].label,
            marker: { enabled: false },
            platform: "wikipedia",
            metadata: seriesMetadata[key],
            lineDash: [4, 4],
            tooltip: {
              renderer: (params) => {
                return {
                  title: params.datum.title,
                  content: moment(params.datum.timestamp).format("ll") + ": " + params.datum[key].toLocaleString() + " pageviews"
                };
              },
            },
          }
        )),
      ].map((x, i) => ({...x, stroke: colorPalette[i % colorPalette.length]}));
      setCompareSeries(compareSeries);

      const legendItems = compareSeries.map(series => (
        {
          key: series.yKey,
          selected: true,
          color: series.stroke,
        }
      ));
      setLegendItems(legendItems);
      console.log(legendItems)

      compareTimeSeries.forEach((item) => {
        [...uniqueKeys, ...uniqueWikipediaKeys].forEach((key) => {
          if (!barData[key]) {
            barData[key] = {
              label: seriesMetadata[key].label,
              value: 0,
              key: key,
              color: compareSeries.find(series => series.yKey == key)?.stroke,
              metadata: seriesMetadata[key],
            };
          }
          if (item[key]) {
            barData[key].value += item[key];
          } else {
            item[key] = 0;
          }
        });
      });
      setCompareBarSeriesData(Object.values(barData));

      return compareTimeSeries;
    }
    return [];
  }, [compareResponse.data]);

  const searchOptions = useMemo(() => {
    if (searchResponse.data) {
      const namesGroup = searchResponse.data.names?.map(name => ({ 
        label: rawNameToTitle(name.name), 
        value: name.name, 
        href: `/topic-modeling/true-crime/person/${name.name}`,
        iconName: "user-profile",
        type: "name",
      }));
      const topicsGroup = searchResponse.data.topics?.map(topic => ({ 
        label: topic.label, 
        value: topic.id, 
        href: `/topic-modeling/true-crime/topic/${topic.id}`,
        iconName: "contact",
        type: "topic",
      }));
      return [
        {
          label: "Names",
          options: namesGroup,
        },
        {
          label: "Topics",
          options: topicsGroup,
        },
      ];
    }
    return [];
  }, [searchResponse.data]);

  const downloadPage = () => {
    const spreadsheets = [
      risingNamesGridRef.current.api.getSheetDataForExcel({ sheetName: "Rising Names" }),
      risingTopicsGridRef.current.api.getSheetDataForExcel({ sheetName: "Rising Topics" }),
      favoritesGridRef.current.api.getSheetDataForExcel({ sheetName: "Favorites" }),
      dl_postsGridRef.current.api.getSheetDataForExcel({ sheetName: "Reddit Posts" }),
      dl_videosGridRef.current.api.getSheetDataForExcel({ sheetName: "YouTube Videos" }),
    ];
    risingNamesGridRef.current.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `${domain}_overview_${moment().format("YYYY-MM-DD")}.xlsx`,
    });
  };



  const getTopicsOverTime = (params) => {
    setTopicsOverTimeResponse({ ...topicsOverTimeResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/topicsovertime",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setTopicsOverTimeResponse({ ...topicsOverTimeResponse, data: response.data, loading: false, completed: true, error: null });
      processTopicsOverTimeData(response.data);
    }).catch(error => {
      setTopicsOverTimeResponse({ ...topicsOverTimeResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getNamesOverTime = (params) => {
    setNamesOverTimeResponse({ ...namesOverTimeResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/namesovertime",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setNamesOverTimeResponse({ ...namesOverTimeResponse, data: response.data, loading: false, completed: true, error: null });
      processNamesOverTimeData(response.data);
    }).catch(error => {
      setNamesOverTimeResponse({ ...namesOverTimeResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getCompare = (params) => {
    setCompareResponse({ ...compareResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/compare",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setCompareResponse({ ...compareResponse, data: response.data, loading: false, completed: true, error: null });
    }).catch(error => {
      setCompareResponse({ ...compareResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getSearchResponse = (params) => {
    setSearchResponse({ ...searchResponse, loading: true, completed: false, error: null });
    getSearch(domain, "/topicmodeling/pantheon/search", params).then(response => {
      setSearchResponse({ data: response, loading: false, completed: true, error: null });
    }).catch(error => {
      setSearchResponse({ ...searchResponse, loading: false, error: error, completed: true });
    });
  };

  const getPostsByName = (params) => {
    setPostsByNameResponse({ ...postsByNameResponse, loading: true, completed: false, error: null });

    return axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/postsbyname",
      params: {
        domain: domain,
        platform: "reddit",
        ...params,
      },
    }).then(response => {
      setPostsByNameResponse({ ...postsByNameResponse, data: response.data, loading: false, completed: true, error: null });
      return response.data;
    }).catch(error => {
      setPostsByNameResponse({ ...postsByNameResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getVideosByName = (params) => {
    setVideosByNameResponse({ ...videosByNameResponse, loading: true, completed: false, error: null });

    return axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/postsbyname",
      params: {
        domain: domain,
        platform: "youtube",
        ...params,
      },
    }).then(response => {
      setVideosByNameResponse({ ...videosByNameResponse, data: response.data, loading: false, completed: true, error: null });
      return response.data;
    }).catch(error => {
      setVideosByNameResponse({ ...videosByNameResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };



  const refreshOverview = () => {
    const payload = {
      start_date: moment(topicsDateRange.startDate).format("YYYY-MM-DD"), 
      end_date: moment(topicsDateRange.endDate).format("YYYY-MM-DD"), 
      n: 30,
      min_prob: 0,
      include_favorites: true,
    };
    getTopicsOverTime(payload);
    getNamesOverTime(payload);
  };


  useEffect(() => {
    refreshOverview();
  }, [topicsDateRange]);

  useEffect(() => {
    if (!risingTopicsRowData?.rising || risingTopicsRowData.rising.length == 0 || !risingNames || risingNames.length == 0) {
      return;
    }
    getPostsByName({
      start_date: moment(topicsDateRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(topicsDateRange.endDate).format("YYYY-MM-DD"),
      names: risingNames.join(","),
      order: selectedPostsSort.value,
    });
    getVideosByName({
      start_date: moment(topicsDateRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(topicsDateRange.endDate).format("YYYY-MM-DD"),
      names: risingNames.join(","),
      order: selectedPostsSort.value,
    });
  }, [topicsDateRange, selectedPostsSort, risingNames]);

  return (
    <>
      <Helmet><title>True Crime Topics</title></Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <>
            <ContentLayout
              header={
                <>
                  <Header
                    actions={
                      <TopicSearchBar
                        domain={domain}
                        searchApiUrl="/topicmodeling/pantheon/search"
                        namePageUrl="/topic-modeling/true-crime/person"
                        topicPageUrl="/topic-modeling/true-crime/topic"
                        limit={10}
                      />
                    }
                  >
                    True Crime Topics
                  </Header>
                  <PantheonFlashbar ref={flashbarRef} />
                </>
              }
            >
              <SpaceBetween size="m">
                <Container>
                  <ColumnLayout columns={1} disableGutters>
                    <div className="pb-2">
                      <Header
                        description="What people are discussing within true crime communities"
                        actions={
                          <SpaceBetween direction="horizontal" size="s">
                            <ButtonDropdown
                              items={[
                                {
                                  text: "Add to compare",
                                  id: "add-to-compare",
                                  disabled: selectedTopicNodes.length + selectedNameNodes.length < 1,
                                },
                                {
                                  text: "Add to favorites",
                                  id: "add-to-favorites",
                                  disabled: selectedTopicNodes.length + selectedNameNodes.length < 1,
                                },
                                {
                                  text: "Download page",
                                  id: "download-overview",
                                  disabled: namesOverTimeResponse.loading || topicsOverTimeResponse.loading || postsByNameResponse.loading || videosByNameResponse.loading,
                                },
                              ]}
                              onItemClick={({ detail }) => {
                                switch(detail.id) {
                                  case "add-to-compare": {
                                    const namesToAdd = selectedNameNodes.map(node => (
                                      {
                                        label: node.data.name_label, 
                                        value: node.data.name, 
                                        iconName: "user-profile",
                                        type: "name",
                                      }
                                    )).filter(item => !selectedCompareOptions.map(item => item.value).includes(item.value));
                                    const topicsToAdd = selectedTopicNodes.map(node => (
                                      {
                                        label: node.data.topic_label, 
                                        value: node.data.topic_id, 
                                        iconName: "contact",
                                        type: "topic",
                                      }
                                    )).filter(item => !selectedCompareOptions.map(item => item.value).includes(item.value));
                                    setSelectedCompareOptions([...selectedCompareOptions, ...namesToAdd, ...topicsToAdd]);
                                    openComparePanel();
                                    risingNamesGridRef.current.api.deselectAll();
                                    risingTopicsGridRef.current.api.deselectAll();
                                    break;
                                  }
                                  case "add-to-favorites": {
                                    const namesToAdd = selectedNameNodes.map(node => node.data.name);
                                    const topicsToAdd = selectedTopicNodes.map(node => node.data.topic_id);
                                    const toAdd = {};
                                    if (topicsToAdd.length > 0) {
                                      toAdd.topics = topicsToAdd.join(",");
                                    }
                                    if (namesToAdd.length > 0) {
                                      toAdd.names = namesToAdd.join(",");
                                    }
                                    risingTopicsGridRef.current.api.deselectAll();
                                    risingNamesGridRef.current.api.deselectAll();
                                    setSelectedTopicNodes([]);
                                    setSelectedNameNodes([]);

                                    const newRisingTopicsRowData = { ...risingTopicsRowData };
                                    newRisingTopicsRowData.rising = newRisingTopicsRowData.rising.map(x => { 
                                      if (topicsToAdd.includes(x.topic_id)) {
                                        x.is_favorite = true; 
                                      }
                                      return x; 
                                    });
                                    newRisingTopicsRowData.favorites = [...newRisingTopicsRowData.favorites, ...risingTopicsRowData.rising.filter(x => topicsToAdd.includes(x.topic_id))];
                                    newRisingTopicsRowData.favorites = newRisingTopicsRowData.favorites.filter((v, i, a) => a.findIndex(t => (t.topic_id === v.topic_id)) === i);
                                    setRisingTopicsRowData(newRisingTopicsRowData); 

                                    const newRisingNamesRowData = { ...risingNamesRowData };
                                    newRisingNamesRowData.rising = newRisingNamesRowData.rising.map(x => { 
                                      if (namesToAdd.includes(x.name)) {
                                        x.is_favorite = true; 
                                      }
                                      return x; 
                                    });
                                    newRisingNamesRowData.favorites = [...newRisingNamesRowData.favorites, ...risingNamesRowData.rising.filter(x => namesToAdd.includes(x.name))];
                                    newRisingNamesRowData.favorites = newRisingNamesRowData.favorites.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
                                    setRisingNamesRowData(newRisingNamesRowData); 

                                    addToFavorites(domain, toAdd);
                                    break;
                                  }
                                  case "download-overview": {
                                    downloadPage();
                                    break;
                                  }
                                }
                              }}
                            >
                              Actions
                            </ButtonDropdown>
                            <RangeDateSelector
                              defaults={topicsDateRange}
                              onChange={(e) => setTopicsDateRange(e)}
                            />
                          </SpaceBetween>
                        }
                      >
                        Overview
                      </Header>
                    </div>
                    <ColumnLayout columns={2}>
                      {namesOverTimeResponse?.loading ? (
                        <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                          <Spinner size="large" />
                        </div> 
                      ) : (
                        <div style={{ height: "580px", width: "100%" }} className="ag-theme-quartz-dark">
                          <AgGridReact
                            ref={risingNamesGridRef}
                            rowData={risingNamesRowData.rising}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            onSelectionChanged={(e) => {
                              setSelectedNameNodes(e.api.getSelectedNodes());
                            }}
                            columnDefs={[
                              {
                                field: "name_label",
                                headerName: "Name",
                                cellRenderer: (props) => (
                                  <div>
                                    <Button 
                                      ariaLabel={props.data.is_favorite ? "Remove from favorites" : "Add to favorites"}
                                      iconName={props.data.is_favorite ? "star-filled" : "star"}
                                      variant="inline-icon" 
                                      onClick={() => {
                                        const newRisingNamesRowData = { ...risingNamesRowData };
                                        const isFavorite = props.data.is_favorite;
                                        newRisingNamesRowData.rising.find(x => x.name == props.data.name).is_favorite = !props.data.is_favorite;
                                        if (isFavorite) {
                                          removeFromFavorites(domain, { names: props.data.name });
                                          newRisingNamesRowData.favorites = newRisingNamesRowData.favorites.filter(x => x.name != props.data.name);
                                        } else {
                                          addToFavorites(domain, { names: props.data.name });
                                          newRisingNamesRowData.favorites = [...newRisingNamesRowData.favorites, props.data];
                                        }
                                        setRisingNamesRowData(newRisingNamesRowData);
                                      }}
                                    />
                                    <a href={`/topic-modeling/true-crime/person/${props.data.name}`}>{props.value}</a>
                                  </div>
                                ),
                                flex: 1,
                                headerCheckboxSelection: true,
                                checkboxSelection: true,
                                showDisabledCheckboxes: true,
                              },
                              {
                                field: "count",
                                headerName: "Posts",
                                type: "numericColumn",
                                maxWidth: 120,
                                minWidth: 120,
                                cellRenderer: (props) => (
                                  <div 
                                    className="space-x-2"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "right" }}
                                  >
                                    <span
                                      className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 px-2"
                                    >
                                      {props.data.count_difference > 0 ? (
                                        <Icon svg={<IncreaseIcon />} variant="success" />
                                      ) : props.data.count_difference < 0 ? (
                                        <Icon svg={<DecreaseIcon />} variant="error" />
                                      ) : (
                                        <Icon svg={<NeutralIcon />} variant="subtle" />
                                      )}
                                      <span style={{ width: "3px" }}></span>
                                      {props.data.count_difference.toLocaleString()}
                                    </span>
                                    <span>{props.value.toLocaleString()}</span>
                                  </div>
                                ),
                              },
                              {
                                field: "trend",
                                headerName: "Trend",
                                cellRenderer: "agSparklineCellRenderer",
                                maxWidth: 150,
                                minWidth: 150,
                                cellRendererParams: {
                                  sparklineOptions: {
                                    tooltip: {
                                      renderer: (params) => {
                                        return {
                                          title: new Date(Date.parse(params.xValue)).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            timeZone: "UTC",
                                          }),
                                          content: params.yValue
                                        };
                                      },
                                    },
                                    marker: {
                                      formatter: (params) => {
                                        const { min, max } = params;
                                        return {
                                          size: max ? 5 : 2,
                                          fill: max ? '#3ba272' : 'skyBlue',
                                          stroke: max ? '#3ba272' : 'skyBlue',
                                        };
                                      },
                                    },
                                  },
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                      {topicsOverTimeResponse?.loading ? (
                        <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                          <Spinner size="large" />
                        </div> 
                      ) : (
                        <div style={{ height: "580px", width: "100%" }} className="ag-theme-quartz-dark">
                          <AgGridReact
                            ref={risingTopicsGridRef}
                            rowData={risingTopicsRowData.rising}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            onSelectionChanged={(e) => {
                              setSelectedTopicNodes(e.api.getSelectedNodes());
                            }}
                            columnDefs={[
                              {
                                field: "topic_label",
                                headerName: "Topic",
                                cellRenderer: (props) => (
                                  <div>
                                    <Button 
                                      ariaLabel={props.data.is_favorite ? "Remove from favorites" : "Add to favorites"}
                                      iconName={props.data.is_favorite ? "star-filled" : "star"}
                                      variant="inline-icon" 
                                      onClick={() => {
                                        const newRisingTopicsRowData = { ...risingTopicsRowData };
                                        const isFavorite = props.data.is_favorite;
                                        newRisingTopicsRowData.rising.find(x => x.topic_id == props.data.topic_id).is_favorite = !props.data.is_favorite;
                                        if (isFavorite) {
                                          removeFromFavorites(domain, { topics: props.data.topic_id });
                                          newRisingTopicsRowData.favorites = newRisingTopicsRowData.favorites.filter(x => x.topic_id != props.data.topic_id);
                                        } else {
                                          addToFavorites(domain, { topics: props.data.topic_id });
                                          newRisingTopicsRowData.favorites = [...newRisingTopicsRowData.favorites, props.data];
                                        }
                                        setRisingTopicsRowData(newRisingTopicsRowData);
                                      }}
                                    />
                                    <a href={`/topic-modeling/true-crime/topic/${props.data.topic_id}`}>{props.value}</a>
                                  </div>
                                ),
                                flex: 1,
                                headerCheckboxSelection: true,
                                checkboxSelection: true,
                                showDisabledCheckboxes: true,
                              },
                              {
                                field: "count",
                                headerName: "Posts",
                                type: "numericColumn",
                                maxWidth: 120,
                                minWidth: 120,
                                cellRenderer: (props) => (
                                  <div 
                                    className="space-x-2"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "right" }}
                                  >
                                    <span
                                      className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 px-2"
                                    >
                                      {props.data.count_difference > 0 ? (
                                        <Icon svg={<IncreaseIcon />} variant="success" />
                                      ) : props.data.count_difference < 0 ? (
                                        <Icon svg={<DecreaseIcon />} variant="error" />
                                      ) : (
                                        <Icon svg={<NeutralIcon />} variant="subtle" />
                                      )}
                                      <span style={{ width: "3px" }}></span>
                                      {props.data.count_difference.toLocaleString()}
                                    </span>
                                    <span>{props.value.toLocaleString()}</span>
                                  </div>
                                ),
                              },
                              {
                                field: "trend",
                                headerName: "Trend",
                                cellRenderer: "agSparklineCellRenderer",
                                maxWidth: 150,
                                minWidth: 150,
                                cellRendererParams: {
                                  sparklineOptions: {
                                    tooltip: {
                                      renderer: (params) => {
                                        return {
                                          title: new Date(Date.parse(params.xValue)).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            timeZone: "UTC",
                                          }),
                                          content: params.yValue
                                        };
                                      },
                                    },
                                    marker: {
                                      formatter: (params) => {
                                        const { min, max } = params;
                                        return {
                                          size: max ? 5 : 2,
                                          fill: max ? '#3ba272' : 'skyBlue',
                                          stroke: max ? '#3ba272' : 'skyBlue',
                                        };
                                      },
                                    },
                                  },
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                    </ColumnLayout>
                    {topicsOverTimeResponse?.data && namesOverTimeResponse?.data && (
                      topicsOverTimeResponse?.loading || namesOverTimeResponse?.loading ? (
                        <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                          <Spinner size="large" />
                        </div> 
                      ) : (
                        <div className="mt-4">
                          <Header
                            description="Topics and names you favorite will appear here"
                            actions={
                              <SpaceBetween direction="horizontal" size="s">
                                <ButtonDropdown
                                  items={[
                                    {
                                      text: "Add to compare",
                                      id: "add-to-compare",
                                      disabled: selectedFavoriteNodes.length < 1,
                                    },
                                    {
                                      text: "Remove from favorites",
                                      id: "remove-from-favorites",
                                      disabled: selectedFavoriteNodes.length < 1,
                                    },
                                  ]}
                                  onItemClick={({ detail }) => {
                                    switch(detail.id) {
                                      case "add-to-compare": {
                                        const itemsToAdd = selectedFavoriteNodes.map(node => ({
                                          label: node.data.name, 
                                          value: node.data.value, 
                                          iconName: node.data.type === "Names" ? "user-profile" : "contact",
                                          type: node.data.type === "Names" ? "name" : "topic",
                                        })).filter(item => !selectedCompareOptions.map(item => item.value).includes(item.value));
                                        setSelectedCompareOptions([...selectedCompareOptions, ...itemsToAdd]);
                                        openComparePanel();
                                        favoritesGridRef.current.api.deselectAll();
                                        break;
                                      }
                                      case "remove-from-favorites": {
                                        const namesToRemove = selectedFavoriteNodes.filter(x => x.data.type === "Names").map(node => node.data.value);
                                        const topicsToRemove = selectedFavoriteNodes.filter(x => x.data.type === "Topics").map(node => node.data.value);
                                        const toRemove = {};
                                        if (namesToRemove.length > 0) {
                                          toRemove.names = namesToRemove.join(",");
                                        }
                                        if (topicsToRemove.length > 0) {
                                          toRemove.topics = topicsToRemove.join(",");
                                        }
                                        favoritesGridRef.current.api.deselectAll();
                                        setSelectedFavoriteNodes([]);

                                        const newRisingTopicsRowData = { ...risingTopicsRowData };
                                        newRisingTopicsRowData.rising = newRisingTopicsRowData.rising.map(x => { 
                                          if (topicsToRemove.includes(x.topic_id)) {
                                            x.is_favorite = false; 
                                          }
                                          return x; 
                                        });
                                        newRisingTopicsRowData.favorites = newRisingTopicsRowData.favorites.filter(x => !topicsToRemove.includes(x.topic_id));
                                        newRisingTopicsRowData.favorites = newRisingTopicsRowData.favorites.filter((v, i, a) => a.findIndex(t => (t.topic_id === v.topic_id)) === i);
                                        setRisingTopicsRowData(newRisingTopicsRowData);

                                        const newRisingNamesRowData = { ...risingNamesRowData };
                                        newRisingNamesRowData.rising = newRisingNamesRowData.rising.map(x => { 
                                          if (namesToRemove.includes(x.name)) {
                                            x.is_favorite = false; 
                                          }
                                          return x; 
                                        });
                                        newRisingNamesRowData.favorites = newRisingNamesRowData.favorites.filter(x => !namesToRemove.includes(x.name));
                                        newRisingNamesRowData.favorites = newRisingNamesRowData.favorites.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
                                        setRisingNamesRowData(newRisingNamesRowData);

                                        removeFromFavorites(domain, toRemove);
                                        break;
                                      }
                                    }
                                  }}
                                >
                                  Actions
                                </ButtonDropdown>
                              </SpaceBetween>
                            }
                          >
                            Favorites
                          </Header>
                          <div>
                            {topicsOverTimeResponse?.loading ? (
                              <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                                <Spinner size="large" />
                              </div> 
                            ) : (
                              <div style={{ height: "500px" }} className="ag-theme-quartz-dark mt-2">
                                <AgGridReact
                                  ref={favoritesGridRef}
                                  rowData={favoritesRowData || []}
                                  rowSelection="multiple"
                                  suppressRowClickSelection={true}
                                  onSelectionChanged={(e) => {
                                    setSelectedFavoriteNodes(e.api.getSelectedNodes());
                                  }}
                                  columnDefs={[
                                    {
                                      field: "type",
                                      rowGroup: true,
                                      hide: true,
                                      checkboxSelection: false,
                                    },
                                    {
                                      field: "name",
                                      headerName: "Name",
                                      cellRenderer: (props) => (
                                        props.data && (
                                          props.data?.type == "Names" ? (
                                            <a href={`/topic-modeling/true-crime/person/${props.value?.toLowerCase()}`}>{props.value}</a>
                                          ) : (
                                            <a href={`/topic-modeling/true-crime/topic/${props.data?.value}`}>{props.value}</a>
                                          )
                                        )
                                      ),
                                      width: 300,
                                      headerCheckboxSelection: true,
                                      checkboxSelection: true,
                                      showDisabledCheckboxes: true,
                                      flex: 1,
                                    },
                                    {
                                      field: "count",
                                      headerName: "Posts",
                                      type: "numericColumn",
                                      maxWidth: 120,
                                      minWidth: 120,
                                      cellRenderer: (props) => (
                                        props.data && (
                                          <div 
                                            className="space-x-2"
                                            style={{ display: "flex", alignItems: "center", justifyContent: "right" }}
                                          >
                                            <span
                                              className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 px-2"
                                            >
                                              {props.data?.count_difference > 0 ? (
                                                <Icon svg={<IncreaseIcon />} variant="success" />
                                              ) : props.data?.count_difference < 0 ? (
                                                <Icon svg={<DecreaseIcon />} variant="error" />
                                              ) : (
                                                <Icon svg={<NeutralIcon />} variant="subtle" />
                                              )}
                                              <span style={{ width: "3px" }}></span>
                                              {props.data?.count_difference?.toLocaleString()}
                                            </span>
                                            <span>{props.value?.toLocaleString()}</span>
                                          </div>
                                        )
                                      ),
                                    },
                                    {
                                      field: "trend",
                                      headerName: "Trend",
                                      maxWidth: 300,
                                      minWidth: 300,
                                      cellRenderer: "agSparklineCellRenderer",
                                      cellRendererParams: {
                                        sparklineOptions: {
                                          tooltip: {
                                            renderer: (params) => {
                                              return {
                                                title: new Date(Date.parse(params.xValue)).toLocaleDateString("en-US", {
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                  timeZone: "UTC",
                                                }),
                                                content: params.yValue
                                              };
                                            },
                                          },
                                          marker: {
                                            formatter: (params) => {
                                              const { min, max } = params;
                                              return {
                                                size: max ? 5 : 2,
                                                fill: max ? '#3ba272' : 'skyBlue',
                                                stroke: max ? '#3ba272' : 'skyBlue',
                                                
                                              };
                                            },
                                          },
                                        },
                                      },
                                    },
                                  ]}
                                  groupDefaultExpanded={-1}
                                  groupSelectsChildren={true}
                                  groupDisplayType="groupRows"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </ColumnLayout>
                </Container>
                <div className="flex justify-end space-x-2">
                  <Select
                    selectedOption={selectedPostsSort}
                    onChange={({ detail }) => {
                      setSelectedPostsSort(detail.selectedOption);
                    }}
                    options={sortOptions}
                  />
                </div>
                <ColumnLayout columns={2}>
                  <Container
                    header={
                      <Header
                        description={(topicsDateRange.startDate !== defaultDateRange.startDate && topicsDateRange.endDate !== defaultDateRange.endDate) ? (topicsDateRange.startDate === topicsDateRange.endDate ? `Posts matching rising names on ${moment(topicsDateRange.startDate).format("ll")}` : `Posts matching rising names from ${moment(topicsDateRange.startDate).format("ll")} to ${moment(topicsDateRange.endDate).format("ll")}`) : "Latest posts matching rising names"}
                      >
                        Reddit posts
                      </Header>
                    }
                  >
                    {postsByNameResponse?.loading || namesOverTimeResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {postsByNameResponse?.data?.reddit?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {postsByNameResponse?.data?.reddit?.map(post => (
                              <div className="grid">
                                <div className="flex justify-between">
                                  <div className="flex flex-col flex-wrap h-full">
                                    <div className="grow">
                                      <div>
                                        <a className="text-sm font-bold" href={post.url} target="_blank">{post.title}</a>
                                      </div>
                                      <div>
                                        <a className="text-xs text-slate-400" href={`https://reddit.com/r/${post.subreddit}`} target="_blank">Posted by u/{post.author} to r/{post.subreddit}{" "}&bull;{" "}{moment(post.created_at * 1000).format("ll")}</a>
                                      </div>
                                      {post.body?.length > 0 && (
                                        <div 
                                          className="mt-2 text-xs" 
                                          style={{ 
                                            overflow: "hidden",
                                            maskImage: "linear-gradient(180deg, #000 60%, transparent)",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <div 
                                            style={{
                                              wordBreak: "break-word",
                                              overflow: "auto",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            {post.body}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <div className="text-sm mt-2"><Icon name="arrow-left" className="rotate-90 saturate-[10] hue-rotate-180 brightness-90" />{" "}{post.num_upvotes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{post.num_comments.toLocaleString()}</div>
                                    </div>
                                  </div>
                                  <div className="mx-4 min-w-24">
                                    {post.thumbnail && (
                                      <img 
                                        src={post.thumbnail} 
                                        alt="thumbnail" 
                                        className="w-24 h-24 object-cover rounded-md shadow-md" 
                                        onError={(e) => {
                                          e.target.style.display = "none";
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No posts found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                  <Container
                    header={
                      <Header
                      description={(topicsDateRange.startDate !== defaultDateRange.startDate && topicsDateRange.endDate !== defaultDateRange.endDate) ? (topicsDateRange.startDate === topicsDateRange.endDate ? `Videos matching rising names on ${moment(topicsDateRange.startDate).format("ll")}` : `Videos matching rising names from ${moment(topicsDateRange.startDate).format("ll")} to ${moment(topicsDateRange.endDate).format("ll")}`) : "Latest videos matching rising names"}
                      >
                        YouTube videos
                      </Header>
                    }
                  >
                    {videosByNameResponse?.loading || namesOverTimeResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {videosByNameResponse?.data?.youtube?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {videosByNameResponse.data.youtube.map(video => (
                              <div key={video.thumbnail} className="h-32 flex space-x-4">
                                <a href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank"
                                  style={{
                                    minWidth: 320/1.5,
                                    minHeight: 180/1.5,
                                  }} 
                                >
                                  <img 
                                    src={video.thumbnail} 
                                    style={{
                                      width: 320/1.5,
                                      height: 180/1.5,
                                    }} 
                                    className="object-cover rounded-md shadow-md"
                                  />
                                </a>
                                <div>
                                  <div>
                                    <div>
                                      <a className="text-sm font-bold" href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank">{video.title}</a>
                                    </div>
                                    <div>
                                      <a className="text-xs text-slate-400" href={video.channel_url} target="_blank">{video.channel}{" "}&bull;{" "}{new Date(video.created_at * 1000).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric',})}</a>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div className="text-sm"><Icon url="/rticons/eye.svg" className="brightness-75" />{" "}{video.num_views.toLocaleString()}{" "}&bull;{" "}<Icon name="thumbs-up" />{" "}{video.num_likes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{video.num_comments.toLocaleString()}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No videos found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                </ColumnLayout>
              </SpaceBetween>
              <div className="hidden">
                {postsByNameResponse?.loading === false && postsByNameResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_postsGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "author",
                            headerName: "Author",
                          },
                          {
                            field: "subreddit",
                            headerName: "Subreddit",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_upvotes",
                            headerName: "Upvotes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Post ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                          {
                            field: "body",
                            headerName: "Body",
                          },
                        ]}
                        rowData={postsByNameResponse.data.reddit}
                      />
                    </div>
                  </div>
                )}
                {videosByNameResponse?.loading === false && videosByNameResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_videosGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "channel",
                            headerName: "Channel",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_views",
                            headerName: "Views",
                          },
                          {
                            field: "num_likes",
                            headerName: "Likes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Video ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                        ]}
                        rowData={videosByNameResponse.data.youtube.map(video => ({ ...video, url: `https://youtube.com/watch?v=${video.post_id}` }))}
                      />
                    </div>
                  </div>
                )}
              </div>
            </ContentLayout>
          </>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        splitPanel={
          <SplitPanel
            header="Compare names and topics"
            hidePreferencesButton
          >
            <div className="flex space-x-2">
              <div className="grow">
                <Multiselect
                  selectedOptions={selectedCompareOptions}
                  onChange={({ detail }) => setSelectedCompareOptions(detail.selectedOptions)}
                  options={searchOptions}
                  onLoadItems={({ detail }) => {
                    if (detail.filteringText.length > 2) {
                      getSearchResponse({ q: detail.filteringText, limit: 10 });
                    }
                  }}
                  placeholder="Search for people and topics"
                  empty="No matches found"
                  statusType={searchResponse.loading ? "loading" : "finished"}
                  filteringType="manual"
                  hideTokens
                />
              </div>
              <RangeDateSelector
                defaults={compareDateRange}
                onChange={(e) => setCompareDateRange(e)}
              />
              <Select
                selectedOption={selectedCompareGranularity}
                onChange={({ detail }) => setSelectedCompareGranularity(detail.selectedOption)}
                options={granularityOptions}
              />
              <Button
                onClick={() => {
                  const names = selectedCompareOptions.filter(item => item.type == "name").map(item => item.value).join(",");
                  const topics = selectedCompareOptions.filter(item => item.type == "topic").map(item => item.value).join(",");
                  let params = {
                    start_date: moment(compareDateRange.startDate).format("YYYY-MM-DD"), 
                    end_date: moment(compareDateRange.endDate).format("YYYY-MM-DD"),
                    granularity: selectedCompareGranularity.value,
                  };
                  if (names) {
                    params.names = names;
                  }
                  if (topics) {
                    params.topic_ids = topics;
                  }
                  getCompare(params);
                }}
                disabled={selectedCompareOptions.length < 2}
                variant="primary"
              >
                Compare
              </Button>
            </div>
            <div className="flex space-x-2 items-start">
              <div className="grow">
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setSelectedCompareOptions([
                      ...selectedCompareOptions.slice(0, itemIndex),
                      ...selectedCompareOptions.slice(itemIndex + 1)
                    ]);
                  }}
                  items={selectedCompareOptions}
                />
              </div>
              {selectedCompareOptions.length > 0 && (
                <div className="w-32">
                  <div className="float-right mt-2">
                    <Button
                      onClick={() => { setSelectedCompareOptions([]) }}
                      variant="normal"
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {compareResponse?.loading ? (
              <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                <Spinner size="large" />
              </div> 
            ) : compareSeries.length > 0 ? (
              <>
              <div className="flex flex-col mt-2">
                <div style={{ height: "400px" }} className="grow">
                  <Header
                    actions={
                      <Toggle
                        onChange={({ detail }) => {
                          setShowMarkers(detail.checked);
                        }}
                        checked={showMarkers}
                      >
                        Show markers
                      </Toggle>
                    }
                  >
                    Posts over time
                  </Header>
                  <AgChartsReact 
                    options={{
                      data: compareTimeSeriesData,
                      series: compareSeries.map(series => ({
                        ...series,
                        visible: legendItems.find(legendItem => legendItem.key === series.yKey)?.selected,
                        marker: {
                          fillOpacity: showMarkers ? 0.5 : 0,
                          fill: series.stroke,
                        },
                      })),
                      axes: [
                        {
                          type: 'time',
                          position: 'bottom',
                          label: {
                            format: '%b %d, %Y',
                          }
                        },
                        {
                          type: 'number',
                          position: 'left',
                          title: {
                            text: "Social Media Posts",
                          },
                          keys: compareSeries.filter(item => item.platform === "compare").map(item => item.yKey),
                          label: {
                            formatter: (params) => {
                              const n = params.value;
                              if (n < 1e3) return n;
                              if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                              if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                              if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                              if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                            }
                          },
                        },
                        {
                          type: 'number',
                          position: 'right',
                          title: {
                            text: "Wikipedia Page Views",
                          },
                          keys: compareSeries.filter(item => item.platform === "wikipedia").map(item => item.yKey),
                          label: {
                            formatter: (params) => {
                              const n = params.value;
                              if (n < 1e3) return n;
                              if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                              if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                              if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                              if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                            }
                          },
                        },
                      ],
                      theme: lineTheme,
                      navigator: {
                        enabled: true,
                      },
                      legend: { enabled: false },
                    }}
                  />
                </div>
                <div className="mt-6">
                 <Cards
                    items={compareSeries.filter(item => item.platform === "compare")}
                    cardDefinition={{
                      header: item => (
                        <Checkbox
                          onChange={({ detail }) => {
                            const newLegendItems = legendItems.map(legendItem => {
                              if (legendItem.key === item.yKey) {
                                return { ...legendItem, selected: detail.checked };
                              }
                              return legendItem;
                            });
                            setLegendItems(newLegendItems);
                          }}
                          checked={legendItems.find(legendItem => legendItem.key === item.yKey)?.selected}
                        >
                          <div className="flex items-center space-x-1">
                            <div style={{
                              width: 15,
                              height: 5,
                              borderRadius: 5,
                              backgroundColor: item.stroke,
                            }} />
                            <Icon name={item.metadata.type === "name" ? "user-profile" : "contact"} />
                            <span><b>{item.title}</b></span>
                          </div>
                        </Checkbox>
                      ),
                      sections: [
                        {
                          id: "articles",
                          content: item => {
                            if (item.metadata.type === "name") {
                              return (
                                <>
                                  <div>
                                    <b className="mr-4">Articles</b>
                                    <span>
                                      <Link 
                                        variant="primary"
                                        fontSize="body-s"
                                        onFollow={({ detail }) => {
                                          const articleKeys = compareResponse?.data?.articles?.[item.metadata.value]?.map(article => `wikipedia_${sanitizeAgKey(article.title)}`);
                                          const newLegendItems = legendItems.map(legendItem => {
                                            if (articleKeys.includes(legendItem.key)) {
                                              return { ...legendItem, selected: true };
                                            }
                                            return legendItem;
                                          });
                                          setLegendItems(newLegendItems);
                                        }}
                                      >
                                        select all
                                      </Link>
                                      {" "}&bull;{" "}
                                      <Link 
                                        variant="primary"
                                        fontSize="body-s"
                                        onFollow={({ detail }) => {
                                          const articleKeys = compareResponse?.data?.articles?.[item.metadata.value]?.map(article => `wikipedia_${sanitizeAgKey(article.title)}`);
                                          const newLegendItems = legendItems.map(legendItem => {
                                            if (articleKeys.includes(legendItem.key)) {
                                              return { ...legendItem, selected: false };
                                            }
                                            return legendItem;
                                          });
                                          setLegendItems(newLegendItems);
                                        }}
                                      >
                                        deselect all
                                      </Link>
                                    </span>
                                  </div>
                                  {compareResponse?.data?.articles?.[item.metadata.value]?.map(article => (
                                    <div className="flex space-x-2">
                                      <Checkbox
                                        onChange={({ detail }) => {
                                          const newLegendItems = legendItems.map(legendItem => {
                                            if (legendItem.key === `wikipedia_${sanitizeAgKey(article.title)}`) {
                                              return { ...legendItem, selected: detail.checked };
                                            }
                                            return legendItem;
                                          });
                                          setLegendItems(newLegendItems);
                                        }}
                                        checked={legendItems.find(legendItem => legendItem.key === `wikipedia_${sanitizeAgKey(article.title)}`)?.selected}
                                      >
                                        <div style={{
                                          width: 15,
                                          height: 5,
                                          borderRadius: 5,
                                          backgroundImage: `repeating-linear-gradient(90deg, ${legendItems.find(legendItem => legendItem.key === `wikipedia_${sanitizeAgKey(article.title)}`)?.color} 0 3px, #000 3px 6px)`,
                                          marginTop: "8px",
                                        }} />
                                      </Checkbox>
                                      <span><Link external href={`https://en.wikipedia.com/wiki/${article.title}`} variant="secondary">{article.title}</Link></span>
                                    </div>
                                  ))}
                                </>
                              );
                            } else {
                              return null;
                            }
                          },
                        }
                      ],
                    }}
                    cardsPerRow={[
                      { cards: 3 },
                    ]}
                  />
                </div>
                <div style={{ width: "100%", height: "450px" }} className="flex space-x-4 justify-center">
                  <div>
                    <Header>Post volume</Header>
                    <AgChartsReact 
                      options={{
                        data: compareBarSeriesData.filter(item => item.metadata?.type !== "wikipedia"),
                        series: [
                          {
                            type: "bar",
                            xKey: "label",
                            yKey: "value",
                            yName: "Post count",
                            formatter: (params) => {
                              return {
                                fill: params.datum.color,
                              };
                            },
                            tooltip: {
                              renderer: (params) => {
                                return {
                                  title: "Post count",
                                  content: `${params.datum.label}: ${params.datum.value.toLocaleString()}`,
                                };
                              },
                            },
                          }
                        ],
                        axes: [
                          {
                            type: "number",
                            position: "left",
                            label: {
                              formatter: (params) => {
                                const n = params.value;
                                if (n < 1e3) return n;
                                if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                              },
                            },
                          },
                          {
                            type: "category",
                            position: "bottom",
                          },
                        ],
                        theme: lineTheme,
                        legend: { enabled: false },
                      }}
                    />
                  </div>
                  {compareBarSeriesData.filter(item => item.metadata?.type === "wikipedia").length > 0 && (
                    <div>
                      <Header>Wikipedia page views</Header>
                      <AgChartsReact 
                        options={{
                          data: compareBarSeriesData.filter(item => item.metadata?.type === "wikipedia"),
                          series: [
                            {
                              type: "bar",
                              xKey: "label",
                              yKey: "value",
                              yName: "Page views",
                              formatter: (params) => {
                                return {
                                  fill: params.datum.color,
                                };
                              },
                              tooltip: {
                                renderer: (params) => {
                                  return {
                                    title: "Page views",
                                    content: `${params.datum.label}: ${params.datum.value.toLocaleString()}`,
                                  };
                                },
                              },
                            },
                          ],
                          axes: [
                            {
                              type: "number",
                              position: "left",
                              label: {
                                formatter: (params) => {
                                  const n = params.value;
                                  if (n < 1e3) return n;
                                  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                                }
                              },
                            },
                            {
                              type: "category",
                              position: "bottom",
                            },
                          ],
                          theme: lineTheme,
                          legend: { enabled: false },
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              </>
            ) : (
              <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                Use the search bar to add names and topics to compare
              </div> 
            )}
          </SplitPanel>
        }
      />
    </>
  );
};

export default TopicModelingTrueCrimeV2;
