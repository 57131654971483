import { widgetOne } from "../components/widgets/imdb-series-engagement";
import { widgetTwo } from "../components/widgets/imdb-movies-engagement";
import { movieLastWeek } from "../components/widgets/movies-last-week";
import { seriesLastWeek } from "../components/widgets/series-last-week";
import { gamesLastWeek } from "../components/widgets/games-last-week";
import { movieUpcoming } from "../components/widgets/movies-upcoming";
import { seriesUpcoming } from "../components/widgets/series-upcoming";
import { gamesUpcoming } from "../components/widgets/games-upcoming";
import { ipAdded } from "../components/widgets/ip_added";
import { wikiMovie } from "../components/widgets/wiki-movies-trends";
import { wikiSeries } from "../components/widgets/wiki-series-trends";
import { wikiGames } from "../components/widgets/wiki-games-trends";
import { twitchPopular } from "../components/widgets/twitch-popular";
import { twitchBroadcasted } from "../components/widgets/twitch-streamed";
import { twitchViewed } from "../components/widgets/twitch-viewed";

export const allWidgets = {
  widgetOne: widgetOne,
  widgetTwo: widgetTwo,
  movieLastWeek: movieLastWeek,
  seriesLastWeek: seriesLastWeek,
  gamesLastWeek: gamesLastWeek,
  movieUpcoming: movieUpcoming,
  seriesUpcoming: seriesUpcoming,
  gamesUpcoming: gamesUpcoming,
  ipAdded: ipAdded,
  wikiMovie: wikiMovie,
  wikiSeries: wikiSeries,
  wikiGames: wikiGames,
  twitchPopular: twitchPopular,
  twitchBroadcasted: twitchBroadcasted,
  twitchViewed: twitchViewed,
};

const defaultLayout = [];

function merge(src, overrides) {
  return src.map((item) => {
    const match = overrides.find((override) => override.id === item.id);
    return match ? { ...item, ...match } : item;
  });
}

export function getDefaultLayout(width) {
  if (width >= 2160) {
    // 6-col layout
    return merge(defaultLayout, [{ id: "widgetOne", columnOffset: { 6: 0 } }]);
  }
  if (width > 1045) {
    // 4-col layout with 4-col overview
    return defaultLayout;
  }
  if (width > 911) {
    // 4-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  if (width > 708) {
    // 2-col layout with 4-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 2 }]);
  }
  if (width > 687) {
    // 2-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  if (width > 485) {
    // 1-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  // 1-col layout with 1-col overview
  return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 5 }]);
}

export function exportLayout(items) {
  return items.map((item) => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan,
  }));
}

export function getBoardWidgets(layout) {
  return layout.map((position) => {
    const widget = allWidgets[position.id];
    return {
      ...position,
      ...widget,
      columnSpan:
        position?.columnSpan ?? widget?.definition?.defaultColumnSpan ?? 1,
      rowSpan: position?.rowSpan ?? widget?.definition?.defaultRowSpan ?? 2,
    };
  });
}

export function getPaletteWidgets(layout) {
  return Object.entries(allWidgets)
    .filter(([id]) => !layout.find((position) => position.id === id))
    .map(([id, widget]) => ({ id, ...widget }));
}
