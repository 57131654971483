import {
  Alert,
  Box,
  Button,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";

export function DeleteModal({ distributions, visible, onDiscard, onDelete }) {
  const [confirmationText, setConfirmationText] = useState("");
  const isMultiple = distributions.length > 1;

  const handleDelete = () => {
    if (confirmationText.toLowerCase() === "confirm") {
      onDelete();
    }
  };

  useEffect(() => {
    setConfirmationText("");
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={
        isMultiple
          ? "Disassociate Titles from Franchise"
          : "Disassociate Title from Franchise"
      }
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleDelete}
              disabled={confirmationText.toLowerCase() !== "confirm"}
            >
              Disassociate
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {distributions.length > 0 && (
        <SpaceBetween size="m">
          {isMultiple ? (
            <Box variant="span">
              Are you sure you want to disassociate
              <Box variant="div" fontWeight="bold" mt="xs">
                {distributions.length} titles
              </Box>
              from the franchise? This action cannot be undone.
            </Box>
          ) : (
            <Box variant="span">
              Are you sure you want to disassociate the title
              <Box variant="div" fontWeight="bold" mt="xs">
                {distributions[0].id}
              </Box>
              from the franchise? This action cannot be undone.
            </Box>
          )}

          <Alert statusIconAriaLabel="Error" type="error">
            Proceeding with this action will disassociate the
            {isMultiple ? (
              <Box variant="span" ml="xs">
                titles
              </Box>
            ) : (
              <Box variant="span" ml="xs">
                title
              </Box>
            )}
            and may affect related resources.
          </Alert>

          <Box padding={{ bottom: "l", top: "l" }}>
            To confirm, type "confirm" in the box below:
            <Input
              value={confirmationText}
              onChange={(event) => setConfirmationText(event.detail.value)}
            />
          </Box>
        </SpaceBetween>
      )}
    </Modal>
  );
}
