import { Calendar, DateInput, FormField, Link } from "@cloudscape-design/components";
import React from "react";

export const TableColumns = [
  {
    id: "name",
    header: "Name",
    cell: (e) => (
      <Link href={`/item/${e.ip_id}`} variant="primary">
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>{e.name}</span>
      </Link>
    ),
    sortingField: "name",
    minWidth: 100,
    width: 250,
  },

  {
    id: "providers",
    header: "Watch Provider",
    minWidth: 200,
    width: 300,
    cell: (e) =>
      Array.isArray(e.providers) ? (
        <div className="flex flex-wrap gap-4">
          {e.providers.map((service) => {
            const formattedService = service
              .replace(/\s+/g, "")
              .replace(/\+/g, "_");
            return (
              <div
                title={service}
                key={service}
                style={{
                  transition: "transform 0.2s ease-in-out",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.5)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <img
                  src={`https://images.searchpantheon.com/serviceProviderIcons/${formattedService}.webp`}
                  alt={`${service} Icon`}
                  className="rounded-lg"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            );
          })}
        </div>
      ) : (
        ""
      ),
  },
  {
    id: "genre",
    header: "Genre",
    minWidth: 200,
    width: 200,
    cell: (e) => {
      const allGenres = [].concat(...e.genre.map((g) => g.split(", ")));
      const uniqueGenres = Array.from(new Set(allGenres));
      return (
        <div className="flex flex-wrap gap-2">
          {uniqueGenres.map((genre) => (
            <span
              className="rounded-full bg-green-900 px-2.5 py-0.5 text-xs text-white "
              key={genre}
            >
              {genre}
            </span>
          ))}
        </div>
      );
    },
  },

  {
    id: "release_date",
    header: "Release Date",
    cell: (e) => e.release_date,

    minWidth: 120,
    width: 120,
    sortingField: "release_date"
  },
  {
    id: "last_air_date",
    header: "Last Air Date",
    cell: (e) => e.last_air_date,

    minWidth: 120,
    width: 120,
    sortingField: "last_air_date",
  },
];
