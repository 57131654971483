import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import IndexPageGrid from "../../components/IndexPageGrid";
import { navItems } from "../../layouts/common/menu/side-menu";

function GRPageCards() {
  const items = [
    {
      link: "/dashboards/globalranking/raw-data",
      image: "/images/gr-rawdata.png",
      name: "Raw Data Table",
      path: "Global Ranking",
      description:
        "Global Ranking Normalized Scores and Category weighted scores for all titles.",
    },
    {
      link: "/dashboards/globalranking/pantheonranking",
      image: "/images/gr-pantheon.png",
      name: "Pantheon Ranking",
      path: "Global Ranking",
      description: "Curated ranking based on defined platform weights.",
    },
  ];
  const gridDefinition = items.map(() => ({
    colspan: { xxs: 6, xs: 6, s: 4, m: 3, xl: 2 },
  }));

  return <IndexPageGrid items={items} gridDefinition={gridDefinition} />;
}

const title = "Global Ranking";
function GRHomePage() {
  const breadcrumbs = [{ text: "Dashboard" }, { text: title }];
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="cards"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                variant="h2"
                description="A collection of dashboards that provide insights into titles and their performance at global level."
              >
                {title}
              </Header>
            }
          >
            <GRPageCards />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default GRHomePage;
