import { React, useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import { AgChartsReact } from "ag-charts-react";
import { Container, ExpandableSection, SpaceBetween } from "@cloudscape-design/components";

const usdShortFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
});

function BoxOfficeTable({ data }) {
  const [rowData, setRowData] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const [chartsOptions, setChartsOptions] = useState(null);

  const currencyFormatter = (params) => {
    if (typeof params.value === 'number') {
      return usdShortFormatter.format(params.value);
    }
    return '';
  };

  useEffect(() => {
    if (data) {
      const flattenedData = Object.values(data).flat();
      setRowData(flattenedData);

      const filteredData = Object.values(data)
        .flat()
        .filter(item => item.area === 'Domestic' && item.occasionId === 'daily');

      const startDate = new Date(filteredData[0]?.startDate);

      const dataWithDayNumbers = filteredData.map(item => {
        const currentDate = new Date(item.startDate);
        const dayNumber = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
        return {
          ...item,
          dayLabel: `Day ${dayNumber}`,
          gross: item.gross,
          numTheaters: item.numTheaters,
        };
      });
      setChartsData(dataWithDayNumbers);
    }
  }, [data]);

  useEffect(() => {
    setChartsOptions({
      data: chartsData,
      theme: {
        baseTheme: "ag-default-dark",
        overrides: {
          common: {
            background: { fill: "#0F1B2A" },
          },
        },
      },
      series: [
        {
          type: 'bar',
          xKey: 'dayLabel',
          yKey: 'gross',
          yName: 'Gross',
          tooltip: {
            renderer: (params) => {
              return {
                content: usdShortFormatter.format(params.datum[params.yKey])
              };
            }
          },
        },
        {
          type: 'line',
          xKey: 'dayLabel',
          yKey: 'numTheaters',
          yName: 'Number of Theaters',
          tooltip: {
            renderer: (params) => {
              return {
                content: `${params.datum[params.yKey]} theaters`
              };
            }
          }
        }
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: { rotation: 315 },
          nice: true,
        },
        {
          type: 'number',
          position: 'left',
          title: { text: 'Gross' },
          keys: ['gross'],
          label: {
            formatter: (params) => usdShortFormatter.format(params.value),
          },
          gridLine: {
            style: [{
              stroke: '#27323f',
              lineDash: [4, 8],
            }]
          },
        },
        {
          type: 'number',
          position: 'right',
          title: { text: 'Number of Theaters' },
          keys: ['numTheaters'],
          gridLine: {
            style: [{
              stroke: '#0F1B2A',
              lineDash: [4, 2],
            }]
          },
        }
      ],
      legend: {
        position: 'bottom',
        spacing: 20
      },
      tooltip: {
        range: 'nearest',
      },

      zoom: {
        enabled: true,
        enableAxisDragging: true,
        enablePanning: true,
        enableScrolling: true,
        enableSelecting: false,
      }
    });
  }, [chartsData]);

  const columnDefs = useMemo(() => [
    { field: 'area', rowGroup: true, hide: true, },
    { field: 'occasionId', rowGroup: true, hide: true },
    { headerName: 'Rank', field: 'rank' },
    { headerName: 'Number of Theaters', field: 'numTheaters' },
    { headerName: 'Gross', field: 'gross', valueFormatter: currencyFormatter },
    { headerName: 'Start Date', field: 'startDate' },
    { headerName: 'End Date', field: 'endDate' },
    { headerName: 'Gross to Date', field: 'grossToDate', valueFormatter: currencyFormatter },
  ], []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      suppressMovable: true,
      lockPosition: 'left',
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  return (
    <SpaceBetween direction="vertical" size="l">
      <ExpandableSection
        defaultExpanded
        headingTagOverride="h4"
        variant="container"
        headerText="Daily Domestic BoxOffice"
      >
      <div
        className="ag-theme-quartz-dark"
        style={{ height: '400px' }}
      >
        {chartsData && chartsOptions && (
          <AgChartsReact options={chartsOptions} />
        )}
      </div>
      </ExpandableSection>
      <ExpandableSection
        defaultExpanded
        variant="stacked"
        headerText="BoxOffice Details"
      >
      <div
        className="ag-theme-quartz-dark"
        style={{ height: '600px' }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          autoGroupColumnDef={autoGroupColumnDef}
          enableAdvancedFilter={true}
        />
      </div>
      </ExpandableSection>
    </SpaceBetween>
  );
}

export default BoxOfficeTable;
