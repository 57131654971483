import Popover from "@cloudscape-design/components/popover";
const RottenTomatesCriticScore = ({ rtCriticSummary }) => {

    if (!rtCriticSummary || rtCriticSummary.score == null) {
        return (
            <div>
                <div className="pb-1 flex space-x-2 items-center">
                    <img src="/rticons/tomatometer-empty.svg" alt="No Score" className="h-7" />
                    <div className="text-xl text-slate-600 font-bold">- %</div>
                </div>
                <div className="text-xs text-slate-600 font-bold">TOMATOMETER</div>
            </div>
        );
    }

    const isFresh = rtCriticSummary.criticRating === "Fresh" || rtCriticSummary.rating === "Fresh";
    const isCertifiedFresh = rtCriticSummary.criticRating === "Certified Fresh" || rtCriticSummary.rating === "Certified Fresh";
    const isRotten = rtCriticSummary.criticRating === "Rotten" || rtCriticSummary.rating === "Rotten";

    const score = rtCriticSummary.score;
    const count = rtCriticSummary.count || rtCriticSummary.numReviews;

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                {isCertifiedFresh && (
                    <img src="/rticons/certified_fresh.svg" alt="Fresh" className="h-8" />
                )}
                {isFresh && score >= 75 && count > 80 && (
                    <img src="/rticons/certified_fresh.svg" alt="Fresh" className="h-8" />
                )}
                {isFresh && score < 75 && (
                    <img src="/rticons/tomatometer-fresh.svg" alt="Fresh" className="h-8" />
                )}
                {isFresh && score >= 75 && count < 80 && (
                    <img src="/rticons/tomatometer-fresh.svg" alt="Fresh" className="h-8" />
                )}
                {isRotten && (
                    <img src="/rticons/tomatometer-rotten.svg" alt="Rotten" className="h-8" />
                )}
                <div className="text-xl text-slate-50 font-bold">
                    {score}%
                </div>
            </div>
            <div className="text-xs font-bold">TOMATOMETER</div>
            <div className="text-xs italic text-slate-400">{count} reviews {rtCriticSummary.consensus && (
                <Popover
                    header="Critic Consensus"
                    position="bottom"
                    triggerType="text"
                    content={<div dangerouslySetInnerHTML={{ __html: rtCriticSummary.consensus }} />}>
                    <span className="cursor-pointer">🔍</span>
                </Popover>
            )}</div>

        </div>
    );
};

export default RottenTomatesCriticScore;