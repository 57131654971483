import {
  Box,
  Button,
  Header,
  Icon,
  Link,
  Pagination,
  Table,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import Filters from "../components/Filters";
import Preferences from "../components/Preferences";
import { getFilteredMoviesData } from "./redux/movies_action";
import { AddMovieModal } from "./components/AddMovieModal";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

const TableColumns = [
  {
    id: "poster",
    header: "Poster",
    width: 120,
    cell: (e) => {
      return e.image_url ? (
        <a href={`${window.location.origin}/item/${e.ip_id}`}>
          <img
            src={"https://image.tmdb.org/t/p/w92" + e.image_url}
            alt={e.name}
            className="rounded"
            loading="lazy"
          />
        </a>
      ) : (
        <div className="h-32 flex items-center justify-center rounded-md bg-gray-400">
          <span className="text-black text-xs truncate">{e.ip}</span>
        </div>
      );
    },
  },
  {
    id: "name",
    header: "Title & Summary",
    width: 600,
    minWidth: 300,
    cell: (e) => {
      const truncatedSummary =
        e.summary.length > 250
          ? e.summary.substring(0, 250) + "..."
          : e.summary;

      return (
        <div>
          <Link
            href={`${window.location.origin}/item/${e.ip_id}`}
            fontSize="heading-m"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <span className="font-bold">{e.ip}</span>
          </Link>
          <div className="flex space-x-2 mt-1">
            {e.genre.map((genre) => (
              <span
                key={genre}
                className="inline-flex items-center rounded-md bg-purple-400/10 px-2 py-1 text-xs font-medium text-purple-400 ring-1 ring-inset ring-purple-400/30"
              >
                {genre}
              </span>
            ))}
          </div>
          <div className="text-slate-400 mt-2">{truncatedSummary}</div>
        </div>
      );
    },
    sortingField: "name",
  },
  {
    id: "status",
    header: "Status",
    width: 150,
    cell: (e) => {
      return <span>{e.status}</span>;
    },
    sortingField: "status",
  },
  {
    id: "release_date",
    header: "Release Date",
    width: 180,
    cell: (e) => {
      return <span>{formatDate(new Date(e.release_date))}</span>;
    },
    sortingField: "release_date",
  },
  {
    id: "tracked",
    header: "Tracked",
    cell: (e) =>
      e.tracked ? (
        <div className="text-green-500 whitespace-nowrap">
          <Icon name="status-positive" variant="success" />
          {e.ip_list?.length > 0 ? e.ip_list.join(", ") : "Tracked"}
        </div>
      ) : (
        <div className="text-slate-400 whitespace-nowrap">
          <Icon name="status-stopped" variant="subtle" /> Not Tracked
        </div>
      ),
    sortingField: "tracked",
  },
];

const idMap = new Map([
  ["name", "ip.keyword"],
  ["status", "status"],
  ["release_date", "release_date"],
  ["tracked", "tracked"],
]);

export const MoviesHome = () => {
  const { user } = useAuthContext();
  const { data, total, status, error } = useSelector(
    (state) => state.moviesData,
  );
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: ["name", "poster", "release_date", "status", "tracked"],
  });
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [displayAddMovieModal, setDisplayAddMovieModal] = useState(false);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          explore: {
            movies: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          }),
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.response?.data?.message || "Failed to save preference ",
          }),
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.explore?.movies?.preferences) {
          setPreferences(d.explore?.movies?.preferences);
        }
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  const formatFilter = (token, operator) => {
    if (!token.propertyKey) {
      return {
        match_phrase: {
          ip: `*${token.value}*`,
        },
      };
    }

    switch (token.propertyKey) {
      case "name":
        return operator === "contain"
          ? {
              query_string: {
                default_field: "ip",
                query: `*${token.value}*`,
              },
            }
          : {
              match: {
                ip: {
                  query: token.value,
                  auto_generate_synonyms_phrase_query: false,
                },
              },
            };

      case "tracked":
        return {
          query_string: {
            default_field: "tracked",
            query: token.value,
          },
        };

      case "status":
        return {
          match: { status: token.value },
        };

      default:
        return null;
    }
  };

  useEffect(() => {
    const from = (currentPage - 1) * preferences.pageSize;

    const sort = sortingColumn
      ? [
          {
            [`${idMap.get(sortingColumn.id)}`]: sortingDescending
              ? "desc"
              : "asc",
          },
        ]
      : [];
    const include = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "=") {
          return formatFilter(t);
        }
        if (t.operator === ":") {
          return formatFilter(t, "contain");
        }
        return null;
      })
      .filter((e) => e);
    const exclude = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "!=") {
          return formatFilter(t);
        }
        return null;
      })
      .filter((e) => e);
    const body = {
      sort,
      include,
      exclude,
      include_condition: filteringQuery.operation,
    };
    const range = () => {
      if (
        !filteringQuery.tokens
          .map((e) => e.propertyKey)
          .includes("release_date")
      ) {
        return null;
      }

      const rangeParams = filteringQuery.tokens
        .map((t) => {
          if (t.propertyKey === "release_date") {
            switch (t.operator) {
              case ">=":
                return `&gte=${t?.value}`;

              case "<=":
                return `&lte=${t?.value}`;

              case ">":
                return `&gt=${t?.value}`;

              case "<":
                return `&lt=${t?.value}`;

              default:
                return null;
            }
          }
          return "";
        })
        .filter((param) => param !== "");

      return rangeParams.length > 0 ? rangeParams.join("") : null;
    };

    getFilteredMoviesData(from, preferences.pageSize, range(), body);
  }, [
    currentPage,
    preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
  ]);

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (status === "failed" && error) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        }),
      );
    }
  }, [status, error]);

  return (
    <>
      <Table
        visibleColumns={preferences.visibleContent}
        columnDefinitions={TableColumns}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        loading={status === "loading"}
        items={data}
        loadingText="Loading resources"
        wrapLines
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        header={
          <>
            <CustomFlashBar />
            <Header
              variant="h3"
              counter={<span>({total.toLocaleString()})</span>}
              actions={
                <Button
                  variant="primary"
                  onClick={() => setDisplayAddMovieModal(true)}
                >
                  Add Movie
                </Button>
              }
            >
              Movies
            </Header>
          </>
        }
        preferences={
          <Preferences
            preferences={preferences}
            handleChange={handlePreferencesChange}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={Math.ceil(
              (total > 10000 ? 10000 : total) / preferences.pageSize,
            )}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <Filters
            data={data}
            preferences={preferences}
            query={filteringQuery}
            handleChange={(detail) => {
              setFilteringQuery(detail);
              setCurrentPage(1);
            }}
          />
        }
      />
      <AddMovieModal
        visible={displayAddMovieModal}
        onDismiss={() => setDisplayAddMovieModal(false)}
      />
    </>
  );
};
