import {
    AppLayout,
    BreadcrumbGroup,
    ContentLayout,
    Header,
    Container,
    SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../../layouts/common/menu/side-menu"
import CustomFlashBar from "../../../common/CustomFlashBar";
import MoviesNonOTTComponent from "./movies-non-ott";

function MoviesNonOTTIndex() {
    const breadcrumbs = [
        { text: "Dashboard" },
        { text: "Peacock Dashboard", href: "/peacock-dashboard" },
        { text: "Movies: Unstreamed Picks" },
    ];
    return (
        <>
            <Helmet>
                <title>Movies: Unstreamed Picks</title>
            </Helmet>
            <AppLayout
                stickyNotifications
                toolsHide
                contentType="cards"
                ariaLabels={{ navigationClose: "close" }}
                navigation={
                    <SideNavigation
                        activeHref={window.location.pathname}
                        items={navItems}
                    />
                }
                content={
                    <ContentLayout
                        header={
                            <>
                                <Header variant="h2" description="Our collection brings to light exceptional films, from overlooked masterpieces to regional cinematic wonders. This platform serves as a gateway to uncover and delve into the depths of rare and captivating movie experiences, offering insights and identifying opportunities for diverse and enriching cinematic exploration.">Movies: Unstreamed Picks</Header>
                                <CustomFlashBar />
                            </>
                        }
                    >
                            <MoviesNonOTTComponent />
                    </ContentLayout>
                }
                breadcrumbs={
                    <BreadcrumbGroup
                        items={breadcrumbs}
                        expandAriaLabel="Show path"
                        ariaLabel="Breadcrumbs"
                    />
                }
            />
        </>
    );
}

export default MoviesNonOTTIndex;
