import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Header from "@cloudscape-design/components/header";
import Spinner from "@cloudscape-design/components/spinner";
import Link from "@cloudscape-design/components/link";
import shortenInteger from "../../../utils/shortenInteger";

export const PiracyOptionListLayer = ({ optionData, loading, vertical }) => {

  return (
   <>
        <div className="flex flex-row justify-center items-center">
          {loading && <Spinner />}
        </div>

        <Table
          className={"pr-4 pl-4"}
          columnDefinitions={[
            {
              id: "#",
              header: "Rank",
              maxWidth: 80,
              cell: (item) => item.index || "-",
            },
            {
              id: "ip",
              header: "IP Name",
              maxWidth: 300,
              cell: (item) => {
                return item.ip ? (
                  <Link
                    href={item.ip_id ? `${window.location.origin}/item/${item.ip_id}` : undefined}
                    fontSize="heading-xs"
                  >
                    {item.ip}
                  </Link>
                ) : (
                  "-"
                );
              },
            },
            {
              id: "downloads",
              header: "Downloads",
              cell: (item) => shortenInteger(item.downloads),
            },
          ]}
          items={
            !loading && Array.isArray(optionData)
              ? optionData.map((item, index) => ({ ...item, index: index + 1 }))
              : []
          }
          loadingText="Loading..."
          sortingDisabled
          empty={
            <></>
          }
          // variant="container"
          stickyHeader
          stickyHeaderVerticalOffset={0}
          header={<Header variant="h3">{vertical.label}</Header>}
        />
</>
  );
};
