import { dispatch } from "../../../../../app/store";
import { fetchPiracyGenericMoviesSummary } from "../slices/piracy-generic-movies-summary-slice";
import { fetchPiracyGenericTelevisionSummary } from "../slices/piracy-generic-television-summary-slice";

export async function getPiracyMoviesSummaryData({
  gte,
  lte,
  option,
  size,
  vertical,
  country,
}) {
  await dispatch(
    fetchPiracyGenericMoviesSummary({
      gte,
      lte,
      option,
      size,
      vertical,
      country,
    })
  );
}

export async function getPiracyTelevisionSummaryData({
  gte,
  lte,
  option,
  size,
  vertical,
  country,
}) {
  await dispatch(
    fetchPiracyGenericTelevisionSummary({
      gte,
      lte,
      option,
      size,
      vertical,
      country,
    })
  );
}
