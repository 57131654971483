import React, { useCallback, useState, useEffect } from "react";
import axiosInstance from "../utils/axios";
import { Autosuggest } from "@cloudscape-design/components";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const convertData = (data) => {
  const convertedData = data.map(item => {
    const imageUrl = getImageUrl(item.vertical, item.ip_id, item.image_url);
    const labelTags = [item.vertical];
    if (item.release_date) {
      labelTags.push(new Date(item.release_date).getFullYear().toString());
    }
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: imageUrl,
      iconAlt: `${item.ip} cover`,
      tags: labelTags,
    };
  });

  return convertedData;
};

const CommonSearch = () => {
  const [query, setQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSearch = useCallback(
    (query_string) => {
      setLoading(true);
      axiosInstance
        .get(
          `topsearch?keyword=${query_string}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Movies,Television,Gaming,Franchise`
        )
        .then((d) => d?.data)
        .then((d) => {
          if (d) {
            setLoading(false);
            setSearchResults(d);
          }
        })
        .catch((e) => setLoading(false));
    },
    [setSearchResults]
  );

  return (
      <Autosuggest
        onChange={({ detail }) => setQuery(detail.value)}
        onLoadItems={({ detail }) => fetchSearch(detail.filteringText)}
        onSelect={({ detail }) => {
          window.location = `${window.location.origin}/item/${detail.value}`;
        }}
        expandToViewport
        value={query}
        options={ (searchResults && searchResults?.length > 0) ? convertData(searchResults) : [] }
        placeholder="Search"
        filteringType="none"
        empty="No results found"
        statusType={loading ? "loading" : "finished"}
      />
  );
};

export default CommonSearch;