import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link
} from "@cloudscape-design/components";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { fetchItemNewsData } from "../redux/actions/item-actions";

function NewsBox({ id }) {
  const itemNewsData = useSelector(
    (state) => state?.itemNewsData?.data || []
  );
  const isLoading = useSelector((state) => state?.itemNewsData?.loading);

  useEffect(() => {
    if (itemNewsData.length <= 0) {
      const formattedId = id.replace(/,/g,'_');
      fetchItemNewsData({ id: formattedId });
    }
  }, [id]);

  const getDaysAgo = (dateString) => {
    const parts = dateString.split(' ');
    const number = parseInt(parts[0], 10); // Extract the number
    const unit = parts[1]; // Extract the unit (days, weeks, months)

    if (isNaN(number)) return 0; // Return 0 if not a number

    // Convert all units to days
    switch (unit) {
      case 'day':
      case 'days':
        return number;
      case 'week':
      case 'weeks':
        return number * 7; // 1 week = 7 days
      case 'month':
      case 'months':
        return number * 30; // Approximate 1 month = 30 days
      default:
        return 0; // Default to 0 if unit is unrecognized
    }
  };

  const sortedArticles = itemNewsData?.[0]?.results?.slice().sort((a, b) => {
    return getDaysAgo(a.date) - getDaysAgo(b.date);
  });
  
  return (<>
    {isLoading ? (
      <Box textAlign="center" padding="l">
        <CustomLoadingOverlay/>
      </Box>
    ) : (
      <Container
        header={
          <Header>
            Recent news
          </Header>
        }
      >
          {
            sortedArticles?.length > 0 ? (
              <ColumnLayout columns={1} borders="horizontal">
                {sortedArticles.map((article, index) => ( // Added index as key, but prefer a unique ID if available
                  (<Box key={index}>
                    <div className="flex space-x-2">
                      <img src={article.thumbnail} alt="Article thumbnail" className="rounded-md" style={{ width: "92px", height: "92px" }} />
                      <div className="space-y-1">
                        <div className="text-xs text-slate-400">{article.date} &bull; {article.source}</div>
                        <span className="space-x-1">
                          <Link href={article.article_link} external><b>{article.title}</b></Link>
                        </span>
                        <div className="text-sm">{article.snippet}</div>
                      </div>
                    </div>
                  </Box>)
                ))}
              </ColumnLayout>
            ) : (
              <div className="py-8 flex flex-1 flex-row justify-center items-center">
                <b>No news available</b>
              </div>
            )
          }
      </Container>
    )}
  </>);
}

export default NewsBox;
