import { Flashbar } from "@cloudscape-design/components";
import { useSelector } from "react-redux";
import { getFlashMessage } from "./redux/flash-selector";

const CustomFlashBar = () => {
  const flashes = useSelector(getFlashMessage);

  return (
    <Flashbar
      items={flashes}
      i18nStrings={{
        ariaLabel: "Notifications",
        notificationBarAriaLabel: "View all notifications",
        notificationBarText: "Notifications",
        errorIconAriaLabel: "Error",
        warningIconAriaLabel: "Warning",
        successIconAriaLabel: "Success",
        infoIconAriaLabel: "Info",
        inProgressIconAriaLabel: "In progress",
      }}
      stackItems
    />
  );
};

export default CustomFlashBar;
