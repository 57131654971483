import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";


const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTarSeriesWikiViewGenrePlatformMarketshare = createAsyncThunk(
  "tarSeriesWikiViewGenrePlatformMarketshare/fetchTarSeriesWikiViewGenrePlatformMarketshare",
  async ({ gte, lte, interval, excludeOTT }) => {
    const params = excludeOTT
      ? { gte, lte, interval, exclude: "ott" }
      : { gte, lte, interval };
    const response = await axiosInstance.request({
      url: `/tar/wiki/views/genre/platform/marketshare`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

const tarSeriesWikiViewGenrePlatformResposeSlice = createSlice({
  name: "tarSeriesWikiViewGenrePlatformMarketshare",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTarSeriesWikiViewGenrePlatformMarketshare.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchTarSeriesWikiViewGenrePlatformMarketshare.fulfilled,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        }
      )
      .addCase(
        fetchTarSeriesWikiViewGenrePlatformMarketshare.rejected,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        }
      );
  },
});

const TarSeriesWikiViewGenrePlatformMarketshareReducer =
  tarSeriesWikiViewGenrePlatformResposeSlice.reducer;

export default TarSeriesWikiViewGenrePlatformMarketshareReducer;

export const tarSeriesWikiViewGenrePlatformMarketshareResposeAction =
  tarSeriesWikiViewGenrePlatformResposeSlice.actions;
