import {
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Pagination,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import React, { useEffect, useState } from "react";
import { OptionLayer } from "../../../components/option-layer/OptionLayer";
import {
  getWeeksInMonth,
  getYearList,
  monthList,
} from "../../../utils/dateFormatter";
import { isNone } from "../../../utils/sugarUtils";
import {
  MoviesType,
  useGetPantheonRankingData,
} from "./api/hooks/useGetPantheonRankingData";
import { PantheonRankingResponseItem } from "./api/request";
import { OptionsFilteringType } from "@cloudscape-design/components/internal/components/dropdown/interfaces";
import { getOptions } from "./utils";
import CustomLoadingOverlay from "../../../components/PantheonLoading";

const LIMIT = 100;

export const GRPantheonRanking = () => {
  const [selectedOptionGenre, setSelectedOptionGenre] = useState(null);
  const [selectedOptionVertical, setSelectedOptionVertical] =
    useState<OptionDefinition>({
      label: "Movies",
      value: "movies",
    });
  const [sortOption, setSortOption] = useState<OptionDefinition>();
  const [selectedDistributor, setSelectedDistributor] =
    useState<OptionDefinition>();
  const [selectedProduction, setSelectedProduction] =
    useState<OptionDefinition>();
  const [selectedYear, setSelectedYear] = useState<OptionDefinition>();
  const [selectedMonth, setSelectedMonth] = useState<OptionDefinition>();
  const [selectedWeek, setSelectedWeek] = useState<OptionDefinition>();
  const [selectedIps, setSelectedIps] = useState([]);
  const [comparedIp, setComparedIp] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [weekOptions, setWeekOptions] = useState([]);

  const dropdownElements = [
    {
      key: "category",
      label: "Category",
      placeholder: "Category",
      option: selectedOptionVertical,
      setOption: setSelectedOptionVertical,
    },
    {
      key: "sort",
      label: "Sort by",
      placeholder: "Sort by",
      option: sortOption,
      setOption: setSortOption,
    },
  ];

  const uiFilterDropdownElements = [
    {
      key: "genre",
      label: "Genre",
      placeholder: "Genre",
      option: selectedOptionGenre,
      setOption: setSelectedOptionGenre,
    },
    {
      key: "distributors",
      label: "Distributor",
      placeholder: "Distributor",
      option: selectedDistributor,
      setOption: setSelectedDistributor,
    },
    {
      key: "production",
      label: "Production",
      placeholder: "Production",
      option: selectedProduction,
      setOption: setSelectedProduction,
    },
  ];

  const dateDropdownElements = [
    {
      key: "year",
      label: "Year",
      placeholder: "Year",
      option: selectedYear,
      filteringType: "auto",
      setOption: setSelectedYear,
    },
    {
      key: "month",
      label: "Month",
      placeholder: "Month",
      option: selectedMonth,
      filteringType: "none",
      setOption: setSelectedMonth,
    },
  ];

  useEffect(() => {
    if (isNone(selectedYear?.value) || isNone(selectedMonth?.value)) return;

    const weeks = getWeeksInMonth(
      Number(selectedYear.value),
      selectedMonth.value,
    );
    const options = weeks.map((week) => ({ label: week, value: week }));
    setWeekOptions(options);
  }, [selectedYear?.value, selectedMonth?.value]);

  const {
    data: pantheonRankingData,
    isLoading,
    error,
  } = useGetPantheonRankingData({
    vertical: selectedOptionVertical.value as MoviesType,
    limit: LIMIT,
    offset: currentPageIndex - 1,
    sort: sortOption?.value,
    timeSpan: selectedWeek?.value,
  });

  const isValidOption = (
    item: PantheonRankingResponseItem,
    key: string,
    value: string,
  ) => {
    if (isNone(value) || value === "All") return true;

    return item[key]?.includes(value);
  };

  useEffect(() => {
    const newData = pantheonRankingData?.data?.filter(
      (item: PantheonRankingResponseItem) => {
        return (
          isValidOption(item, "year", selectedYear?.value) &&
          isValidOption(item, "genre", selectedOptionGenre?.value) &&
          isValidOption(item, "distributors", selectedDistributor?.value) &&
          isValidOption(item, "production", selectedProduction?.value)
        );
      },
    );

    setFilteredData(newData);
  }, [
    pantheonRankingData,
    selectedYear?.value,
    selectedOptionGenre?.value,
    selectedDistributor?.value,
    selectedProduction?.value,
    setFilteredData,
  ]);

  if (isLoading) {
    return (
        <div className="flex flex-row justify-center h-200">
          <CustomLoadingOverlay />
        </div>
    );
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <ContentLayout
      header={
        <Header
          variant="h3"
          actions={
              <SpaceBetween direction="horizontal" size="xs">
                <FormField label="Filter IPs">
                  <Input
                    type="search"
                    placeholder="Search IPs..."
                    onChange={({ detail }) => setSearchFilter(detail.value)}
                    value={searchFilter}
                  />
                </FormField>
                <div className="flex flex-col gap-1">
                  <div className="font-bold">Date Range</div>
                  <div className="flex gap-2">
                    {dateDropdownElements.map(
                      ({
                        key,
                        placeholder,
                        option,
                        filteringType,
                        setOption,
                      }) => (
                        <Select
                          key={key}
                          placeholder={placeholder}
                          selectedOption={option}
                          onChange={({ detail }) =>
                            setOption(detail.selectedOption)
                          }
                          options={getOptions(key, pantheonRankingData)}
                          filteringType={filteringType as OptionsFilteringType}
                        />
                      ),
                    )}
                    {weekOptions && (
                      <Select
                        placeholder="Week"
                        selectedOption={selectedWeek}
                        onChange={({ detail }) =>
                          setSelectedWeek(detail.selectedOption)
                        }
                        options={weekOptions}
                      />
                    )}
                  </div>
                </div>
              </SpaceBetween>
          }
        >
          Pantheon Ranking
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="xs">
        <Container 
        disableContentPaddings={true}
        header={
          <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
            {uiFilterDropdownElements.map(
              ({ key, label, placeholder, option, setOption }) => (
                <FormField label={label} key={label}>
                  <Select
                    placeholder={placeholder}
                    selectedOption={option}
                    onChange={({ detail }) =>
                      setOption(detail.selectedOption)
                    }
                    options={getOptions(key, pantheonRankingData)}
                    filteringType="auto"
                  />
                </FormField>
              ),
            )}
            {dropdownElements.map(
              ({ key, label, placeholder, option, setOption }) => (
                <FormField label={label} key={label}>
                  <Select
                    placeholder={placeholder}
                    selectedOption={option}
                    onChange={({ detail }) =>
                      setOption(detail.selectedOption)
                    }
                    options={getOptions(key, pantheonRankingData)}
                    filteringType="auto"
                  />
                </FormField>
              ),
            )}
            </SpaceBetween>
          }/>
        }
        />
        <OptionLayer
          optionData={pantheonRankingData.data}
          onItemSelect={() => { }}
          selectedIps={selectedIps}
          searchFilter={searchFilter}
        />
        <div className="flex justify-end">
          <Pagination
            currentPageIndex={currentPageIndex}
            onChange={({ detail }) =>
              setCurrentPageIndex(detail.currentPageIndex)
            }
            pagesCount={Math.ceil(pantheonRankingData.count / LIMIT)}
          />
        </div>
      </SpaceBetween>
    </ContentLayout>
  );
};
