import { Box, Link, SpaceBetween } from "@cloudscape-design/components";
import moment from "moment";
import React from "react";
import shortenInteger from "../../../utils/shortenInteger";
import {
  getSentimentCategory,
  getSentimentColor,
} from "../channelPage/components/VideoList/utils";
import { ResponseDataItemType } from "./api/type";
import { AutoSuggestEditor } from "./components/AutoSuggestEditor";

const columnDefinitions = [
  {
    id: "image",
    width: 120,
    cell: (e: ResponseDataItemType) => {
      return (
        e.videoId && (
          <a href={`/mediacatalog/youtube/videos/${e.videoId}`}>
            <img
              src={`https://i.ytimg.com/vi/${e.videoId}/hqdefault.jpg`}
              alt={e.title}
              className="rounded"
              loading="lazy"
            />
          </a>
        )
      );
    },
  },
  {
    id: "title",
    header: "Title",
    width: 350,
    cell: (e: ResponseDataItemType) => {
      return (
        <div>
          <Link
            href={`/mediacatalog/youtube/videos/${e.videoId}`}
            fontSize="heading-xs"
            variant="secondary"
          >
            {e.title}
          </Link>
          <SpaceBetween direction="horizontal" size="xxs">
            {e.ip_id && (
              <Link href={`/item/${e.ip_id}`}>
                <span className="mt-1 inline-flex items-center rounded-md bg-amber-500/10 px-1 py-0.5 text-xs  text-amber-500 ring-1 ring-inset ring-amber-500/30  hover:bg-amber-500  hover:text-black">
                  {e.ip}
                </span>
              </Link>
            )}
            <Link href={`/mediacatalog/youtube/channels/${e.channelId}`}>
              <span className="mt-1 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-xs  text-green-400 ring-1 ring-inset ring-green-400/30  hover:bg-green-500  hover:text-black">
                {e.channel}
              </span>
            </Link>
          </SpaceBetween>
        </div>
      );
    },
  },
  {
    id: "publishedAt",
    header: "Published On",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>
        {moment(new Date(e.publishedAt * 1000)).format("MMMM D, YYYY")}
      </span>
    ),
    sortingField: "publishedAt",
  },
  {
    id: "views",
    header: "Views",
    width: 180,
    cell: (e: ResponseDataItemType) => <span>{shortenInteger(e.views)}</span>,
    sortingField: "views",
  },
  {
    id: "likes",
    header: "Likes",
    width: 180,
    cell: (e: ResponseDataItemType) => <span>{shortenInteger(e.likes)}</span>,
    sortingField: "likes",
  },
  {
    id: "comments",
    header: "Comments",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.comments)}</span>
    ),
    sortingField: "comments",
  },
  {
    id: "sentimentScore",
    header: "User Sentiment",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <Box
        color={
          e.sentimentScore != null
            ? getSentimentColor(e.sentimentScore)
            : "text-status-inactive"
        }
      >
        {e.sentimentScore != null
          ? `${getSentimentCategory(
              e.sentimentScore,
            )} (${e.sentimentScore.toFixed(2)})`
          : "N/A"}
      </Box>
    ),
    sortingField: "sentimentScore",
  },
  {
    id: "todayViews",
    header: "Today's Views",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.todayViews)}</span>
    ),
    sortingField: "todayViews",
  },
  {
    id: "todayLikes",
    header: "Today's Likes",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.todayLikes)}</span>
    ),
    sortingField: "todayLikes",
  },
  {
    id: "todayComments",
    header: "Today's Comments",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.todayComments)}</span>
    ),
    sortingField: "todayComments",
  },
  {
    id: "lastUpdated",
    header: "Last Updated",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>
        {moment(new Date(e.lastUpdated * 1000)).format("MMMM D, YYYY")}
      </span>
    ),
    sortingField: "lastUpdated",
  },
];

export const getColumnDefinitions = (type: string) => {
  let additionalColumns = [];

  if (type === "unmapped") {
    additionalColumns.push({
      id: "linkIP",
      header: "Link IP",
      width: 300,
      cell: (e: ResponseDataItemType) => (
        <AutoSuggestEditor videoId={e.videoId} />
      ),
    });
  }

  return [...columnDefinitions, ...additionalColumns];
};

export const getVisibleContentPreference = (type: string) => {
  const preferences = [
    {
      id: "image",
      label: "Image",
      visible: true,
    },
    {
      id: "title",
      label: "Title",
      visible: true,
      alwaysVisible: true,
    },
    {
      id: "publishedAt",
      label: "Published On",
      visible: true,
    },
    {
      id: "views",
      label: "Views",
      visible: true,
    },
    {
      id: "likes",
      label: "Likes",
      visible: true,
    },
    {
      id: "comments",
      label: "Comments",
      visible: true,
    },
    {
      id: "sentimentScore",
      label: "User Sentiment",
      visible: true,
    },
    {
      id: "todayViews",
      label: "Today's Views",
      visible: true,
    },
    {
      id: "todayLikes",
      label: "Today's Likes",
      visible: true,
    },
    {
      id: "todayComments",
      label: "Today's Comments",
      visible: true,
    },
    {
      id: "lastUpdated",
      label: "Last Updated",
      visible: false,
    },
  ];

  const unmappedPreference = {
    id: "linkIP",
    label: "Link IP",
    visible: true,
  };

  if (type === "unmapped") preferences.push(unmappedPreference);

  return preferences;
};
