import {
  Cards,
  Checkbox,
  ExpandableSection,
  StatusIndicator,
} from "@cloudscape-design/components";
import _ from "lodash";
import { Link } from "react-router-dom";
function getHeaderText(type, items) {
  return (
    <div className="text-xl">
      {type} ({items.length})
    </div>
  );
}

const formatDate = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};
export function MediaSection({ type, items, selectedCards, addOrRemoveCards }) {
  let url;

  switch (type) {
    case "Games":
      url = "https://images.igdb.com/igdb/image/upload/t_cover_big";
      break;
    case "Film":
    case "Television Series":
    default:
      url = "https://image.tmdb.org/t/p/w780";
      break;
  }

  return (
    <ExpandableSection
      defaultExpanded
      variant="footer"
      headerText={getHeaderText(type, items)}
    >
      <Cards
        cardDefinition={{
          header: (item) => {
            return (
              <div className="flex flex-row">
                <div className="flex-1">
                  <Link to={`/item/${item.ip_id}`}
                    className={`heading-s ${item.tracked ? 'text-green-400' : 'text-slate-400'}`}
                  >
                    {item.name}
                  </Link>
                </div>
                <Checkbox
                  checked={_.includes(selectedCards, item.ip_id)}
                  onChange={({ detail }) =>
                    addOrRemoveCards(detail.checked, item.ip_id)
                  }
                />
              </div>
            );
          },
          sections: [
            {
              id: "image-summary",
              content: (item) => (
                <div className="flex">
                  <img
                    style={{
                      width: "150px",
                      height: "200px",
                      marginRight: "20px",
                    }}
                    className="rounded-md"
                    src={
                      type === "Games"
                        ? `${url}/${item.image_url}.png`
                        : `${url}/${item.image_url}`
                    }
                    alt={item.name}
                  />
                  <div>
                    {item.release_date && (
                      <div className="inline-flex mb-2 items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
                        {formatDate(new Date(Date.parse(item.release_date)))}
                      </div>
                    )}
                    <div className="grow">
                      {item.summary && item.summary.length > 150
                        ? `${item.summary.substring(0, 150)}...`
                        : item.summary}
                    </div>
                  </div>
                </div>
              ),
            },
          ],
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 800, cards: 2 },
          { minWidth: 1000, cards: 3 },
        ]}
        items={items}
        loadingText="Loading..."
      />
    </ExpandableSection>
  );
}
