import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { SnackBarContextProvider } from './hooks/notify/snack-bar/snackbar-context';
import "@cloudscape-design/global-styles/index.css"
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import { LicenseManager } from "ag-grid-enterprise";
import { AgCharts } from 'ag-charts-enterprise';

// LicenseManager.setLicenseKey("CompanyName=SpectacorGaming,LicensedGroup=SpectacorGaming,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037347,SupportServicesEnd=24_January_2024_[v2]_MTcwNjA1NDQwMDAwMA==dd9e80a832e4fac98561d22abb2a2def");
LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-053075}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SpectacorGaming}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Charts_and_AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{23_January_2025}____[v3]_[0102]_MTczNzU5MDQwMDAwMA==58347d6711299f22e24c18caedfb31e5")
AgCharts.setLicenseKey('Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-053075}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SpectacorGaming}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Charts_and_AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{23_January_2025}____[v3]_[0102]_MTczNzU5MDQwMDAwMA==58347d6711299f22e24c18caedfb31e5');
const container = document.getElementById('root');
const root = createRoot(container);
applyMode(Mode.Dark);
root.render(
  // <React.StrictMode>
    <SnackBarContextProvider>
      <App />
    </SnackBarContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
