const RatingPercent = ({ percent = 75, title, subtitle, className }) => {
    let color = "green";
    if (percent <= 33) {
        color = "red";
    } else if (percent <= 66) {
        color = "yellow";
    }
    return (

        <div className="flex justify-center space-x-2">
            <div className="flex space-x-2 items-center">
                <div className="circle-wrap">
                    <div className="circle">
                        <div className="mask full" style={{ transform: `rotate(${Math.round(percent / 100.0 * 180.0)}deg)` }}>
                            <div className="fill" style={{ transform: `rotate(${Math.round(percent / 100.0 * 180.0)}deg)`, backgroundColor: color }}></div>
                        </div>
                        <div className="mask half">
                            <div className="fill" style={{ transform: `rotate(${Math.round(percent / 100.0 * 180.0)}deg)`, backgroundColor: color }}></div>
                        </div>
                        <div className="inside-circle font-bold text-lg" style={{ backgroundColor: '#0F1B2A' }}>
                            {Math.round(percent) / 10}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="text-sm font-bold">{title}</div>
                <div className="text-xs italic text-slate-400">
                    {subtitle}
                </div>
            </div>
        </div>
        
    );
};

export default RatingPercent;