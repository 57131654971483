import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../../../components/forms/TextField";
import FormProvider from "../../../../components/forms/FormProvider";
import PasswordTextField from "../../../../components/forms/PasswordTextField";
import SelectBox from "../../../../components/forms/SelectBox";
import LoadingButton from "../../../../components/forms/LoadingButton";
import { useSelector } from "react-redux";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import SwitchButton from "../../../../components/forms/SwitchButton";
import ReactHookFormChips from "../../../../components/forms/ReactHookFormChips";
import {
  clearCreateRoleFn,
  clearEditRoleFn,
  createRoleFn,
  editRoleFn,
} from "../redux/actions/roles-action";
import { MultiSelectBox } from "../../../../components/forms/MultiSelectBox";
import { Alert, Button, SpaceBetween } from "@cloudscape-design/components";
export default function EditRoleForm({
  setCancel,
  setCompleted,
  permissionsList,
  defaults,
  ...others
}) {
  const snackbarCtx = useContext(SnackbarContext);
  const getAllPermission = (items) => {
    return permissionsList.filter((d) => items.includes(d.permission_name));
  };

  const editRoleDefaultValue = {
    roleName: "",
    permissions: "",
  };

  const editRole = useSelector((state) => state.editRole);
  const onSubmit = (data) => {
    editRoleFn({ roleId: defaults.id, ...data });
  };

  const editRoleSchema = Yup.object().shape({
    roleName: Yup.string().required("Role name is required"),
    permissions: Yup.array().min(1, "Permission is required"),
  });

  const methods = useForm({
    defaultValues: editRoleDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(editRoleSchema),
  });

  const {
    setValue,
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    return () => {
      clearEditRoleFn();
    };
  }, []);
  useEffect(() => {
    if (defaults) {
      setValue("roleName", defaults.roleName);
      setValue(
        "permissions",
        getAllPermission(defaults.permissions).map((d) => ({
          value: d.permission_name,
          label: d.permission_name,
        }))
      );
    }
  }, [defaults]);

  useEffect(() => {
    if (!editRole.isLoading && editRole.isCompleted && !editRole.isError) {
      setCompleted();
      setCancel(true);
    }
    return () => {
      clearEditRoleFn();
    };
  }, [editRole]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      id="edit_role_form"
    >
      <div className="py-5">
        {!editRole.isLoading && editRole.isCompleted && editRole.isError && (
          <Alert type="error" dismissible={true}>
            {editRole.message}
          </Alert>
        )}
        {!editRole.isLoading && editRole.isCompleted && !editRole.isError && (
          <Alert type="success" dismissible={true}>
            {editRole.message}
          </Alert>
        )}
        <div className="grid grid-cols-1 gap-6  ">
          <span>
            <TextField
              name={"roleName"}
              label="Role Name"
              placeholder={"Role Name"}
              type={"text"}
            />
          </span>
          <span>
            <MultiSelectBox
              name={"permissions"}
              label="Permissions"
              options={permissionsList.map((d) => ({
                value: d.permission_name,
                label: d.permission_name,
              }))}
            />
          </span>
    
        </div>
      </div>
    </FormProvider>
  );
}
