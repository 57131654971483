import React, { useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Header,
  SideNavigation,
  Box,
  Table,
  Link,
  Flashbar,
  Pagination,
} from "@cloudscape-design/components";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import { getFilteredCompaniesData } from "./redux/companies_action";
import Preferences from "../components/Preferences";
import Filters from "../components/Filters";
import { addMessageToFlash, cleanAllFlashMessage } from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };
const breadcrumbs = [{text: "Media Catalog"},{ text: "Explore", href: "/explore" }, { text: "Companies" }];
const noImage = require("../../../assets/images/icons/no-image_300.jpg");
const TableColumns = [
  // {
  //   id: "poster",
  //   header: "Poster",
  //   width: 120,
  //   cell: (e) => {
  //     return (
  //       <div>
  //         {e.poster_path ? (
  //           <img
  //             src={`https://www.themoviedb.org/t/p/h50_filter(negate,000,666)/${e.poster_path}`}
  //             alt={e.name}
  //           />
  //         ) : (
  //           <img
  //               src={noImage}
  //             alt="No Image"
  //           />
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    id: "name",
    header: "Company Name",
    width: 700,
    minWidth: 300,
    cell: (e) => {
      return (
        <div>
          <Link
            href={`${window.location.origin}/item/companies-${e.id}`}
            fontSize="heading-s"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            {e.name}
          </Link>
        </div>
      );
    },

    sortingField: "name",
  },
];

const idMap = new Map([
  ["name", "ip.keyword"],
  ["status", "status"],
  // ["release_date", "release_date"],
]);

export default function ExploreCompanies() {
  const { user } = useAuthContext();
  const { data, total, status, error } = useSelector(
    (state) => state.companiesData
  );

 
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: ["name", "poster"],
  });
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          explore: {
            companies: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message:"Preference saved!",
          })
        );

        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e?.response?.data?.message || "Failed to save preference ",
          })
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.explore?.companies?.preferences) {
          setPreferences(d.explore?.companies?.preferences);
        }
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  const formatFilter = (token, operator) => {
    if (!token.propertyKey) {
      return {
        query_string: {
          default_field: "name",
          query: `*${token.value}*`,
        },
      };
    }

    switch (token.propertyKey) {
      case "name":
        return operator === "contain"
          ? {
              query_string: {
                default_field: "name",
                query: `*${token.value}*`,
              },
            }
          : {
              match: {
                ip: {
                  query: token.value,
                  auto_generate_synonyms_phrase_query: false,
                },
              },
            };

      case "status":
        return {
          match: { status: token.value },
        };

      default:
        return null;
    }
  };

  useEffect(() => {
    const from = (currentPage - 1) * preferences.pageSize;

    const sort = sortingColumn
      ? [
          {
            [`${idMap.get(sortingColumn.id)}`]: sortingDescending
              ? "desc"
              : "asc",
          },
        ]
      : [];
    const include = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "=") {
          return formatFilter(t);
        }
        if (t.operator === ":") {
          return formatFilter(t, "contain");
        }
        return null;
      })
      .filter((e) => e);
    const exclude = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "!=") {
          return formatFilter(t);
        }
        return null;
      })
      .filter((e) => e);
    const body = {
      sort,
      include,
      exclude,
      include_condition: filteringQuery.operation,
    };
    const range = () => {
      if (!filteringQuery.tokens.map((e) => e.propertyKey)) {
        return null;
      }
    };

    getFilteredCompaniesData(from, preferences.pageSize, range(), body);
  }, [
    currentPage,
    preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
  ]);

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage()
  }, []);

  useEffect(() => {
    if (status === "failed" && error) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        })
      );
    }
  }, [status, error]);

  return (
    <>
      <Helmet>
        <title>Explore Companies</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
            <Table
              visibleColumns={preferences.visibleContent}
              columnDefinitions={TableColumns}
              sortingColumn={sortingColumn}
              sortingDescending={sortingDescending}
              onSortingChange={(event) => {
                setSortingDescending(event.detail.isDescending);
                setSortingColumn(event.detail.sortingColumn);
              }}
              loading={status === "loading"}
              items={data}
              loadingText="Loading resources"
              wrapLines
              stripedRows
              stickyHeader
              resizableColumns={true}
              variant="full-page"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No items</b>
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    No items to display.
                  </Box>
                </Box>
              }
              header={
                <>
                  <CustomFlashBar />
                  <Header
                  variant="h3"
                  counter={<span>({total.toLocaleString()})</span>}>
                  Companies
                  </Header>
                </>
              }
              preferences={
                <Preferences
                  preferences={preferences}
                  handleChange={handlePreferencesChange}
                />
              }
              pagination={
                <Pagination
                  currentPageIndex={currentPage}
                  pagesCount={Math.ceil(
                    (total > 10000 ? 10000 : total) / preferences.pageSize
                  )}
                  onChange={(page) => {
                    setCurrentPage(page.detail.currentPageIndex);
                  }}
                />
              }
              filter={
                <Filters
                  data={data}
                  preferences={preferences}
                  query={filteringQuery}
                  handleChange={(detail) => {
                    setFilteringQuery(detail);
                    setCurrentPage(1);
                  }}
                />
              }
            />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}
