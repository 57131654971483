import {
  Box,
  Button,
  ButtonDropdown,
  Checkbox,
  ColumnLayout,
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  SplitPanel,
  Toggle,
} from "@cloudscape-design/components";
import AppLayout from "@cloudscape-design/components/app-layout";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { yupResolver } from "@hookform/resolvers/yup";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { isString } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import "../../ag-styles.css";
import { useAuthContext } from "../../auth/useAuthContext";
import { useContextMenuItemsConfig } from "../../components/ContextMenuItemsConfig";
import {
  chartThemes,
  customChartThemes,
} from "../../components/CustomChartTheme";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import { useSideBarConfig } from "../../components/SideBarConfig";
import FormProvider from "../../components/forms/FormProvider";
import { granularityItems } from "../../components/forms/GranularitySelect";
import { navItems } from "../../layouts/common/menu/side-menu";
import { useGetGenericPreference } from "../../services/generic/hooks/useGetGenericPreference";
import { useGetWikipediaWeightage } from "../../services/platformWeightage/hooks/useGetWikipediaWeightage";
import { usePostAndGetGenericPreference } from "../../services/generic/hooks/usePostAndGetGenericPreference";
import axiosInstance from "../../utils/axios";
import createFlashMessage from "../../utils/createFlashMessage";
import CustomFlashBar from "../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../common/redux/flash-action";
import { getWikiRawData } from "./redux/actions";

const useSplitPanel = (selectedItems) => {
  const [splitPanelSize, setSplitPanelSize] = useState(300);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);

  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  const onSplitPanelToggle = ({ detail: { open } }) => {
    setSplitPanelOpen(open);

    if (!open) {
      setHasManuallyClosedOnce(true);
    }
  };

  useEffect(() => {
    if (selectedItems.length && !hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  }, [selectedItems.length, hasManuallyClosedOnce]);

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
  };
};

export const RawDataPage = () => {
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const wikiRawData = useSelector((state) => state.wikiRawData);
  const [granularity, setGranularity] = useState({
    label: "None",
    value: "none",
  });
  const [dateRange, setDateRange] = useState(null);
  const [optionTitle, setOptionTitle] = useState("Weight Settings");
  const [checked, setChecked] = React.useState(false);
  const [gridApi, setGridApi] = React.useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [showWeightageCreateForm, setShowWeightageCreateForm] = useState(false);
  const { user } = useAuthContext();
  const [optionPath, setOptionPath] = useState("");

  const { data: genericPreference } = useGetGenericPreference();
  const { data: optionWeightage } = useGetWikipediaWeightage();
  const { postAndGetGenericPreference } = usePostAndGetGenericPreference();

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    setValue("isDefault", checked);
  }, [checked]);

  useEffect(() => {
    const isWeightageApplied =
      genericPreference?.data?.wikipedia?.rawdata?.applyWeightage === "true";
    const isFullWidth =
      genericPreference?.data?.wikipedia?.rawdata?.fullWidth === "true";

    setIsWeightageApplied(isWeightageApplied);
    setIsFullWidth(isFullWidth);
  }, [genericPreference]);

  function findPersonalDefaultData(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].type === "personal" &&
        arr[i].filename.includes("default.json") &&
        arr[i].platform === "wikipedia" &&
        arr[i].service === "weightage"
      ) {
        return { ...arr[i], type: { label: "Personal", value: "personal" } };
      }
    }
    return null;
  }

  function findOrgDefaultData(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].type === "organization" &&
        arr[i].filename.includes("default.json") &&
        arr[i].platform === "wikipedia" &&
        arr[i].service === "weightage"
      ) {
        return {
          ...arr[i],
          type: { label: "Organization", value: "organization" },
        };
      }
    }
    return null;
  }

  useEffect(() => {
    if (!optionWeightage) return;

    if (user.role === "superAdmin" || user.role === "admin") {
      if (findPersonalDefaultData(optionWeightage.data)) {
        getSelectWeight(findPersonalDefaultData(optionWeightage.data));
      } else if (findOrgDefaultData(optionWeightage?.data)) {
        getSelectWeight(findOrgDefaultData(optionWeightage.data));
      }
    }
  }, [optionWeightage]);

  const weightDefaultValue = {
    defaultDesktop: 0,
    defaultMobileWeb: 0,
    defaultMobileApp: 0,
    type: { label: "Personal", value: "personal" },
    name: "",
    method: "",
    isDefault: false,
  };

  const weightSchema = Yup.object().shape({
    defaultDesktop: Yup.string().required("Desktop weight is required"),
    defaultMobileWeb: Yup.string().required("Mobile web weight is required"),
    defaultMobileApp: Yup.string().required("Mobile app weight is required"),
    type: Yup.object().required("Type is required"),
    name: Yup.string().when("type", (type, schema) => {
      return type.value === "personal"
        ? schema.required("Name is required")
        : schema.nullable();
    }),
    method: Yup.string().nullable(),
    isDefault: Yup.string().nullable(),
  });

  const methods = useForm({
    defaultValues: weightDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(weightSchema),
  });

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const currentDate = moment().format("YYYYMMDD");
  const currentDateMinus15Days = moment()
    .subtract(15, "days")
    .format("YYYYMMDD");

  useEffect(() => {
    getWikiRawData({
      lte: dateRange
        ? moment(dateRange.endDate).format("YYYYMMDD")
        : currentDate,
      gte: dateRange
        ? moment(dateRange.startDate).format("YYYYMMDD")
        : currentDateMinus15Days,
      interval: granularity.value,
    });
  }, [granularity, dateRange]);

  useEffect(() => {
    if (wikiRawData?.completed && wikiRawData?.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: wikiRawData.message,
        }),
      );
    }
  }, [wikiRawData]);

  function CommaSeparatedNumberCellRenderer(props) {
    if (props.value) {
      return <div>{props.value.toLocaleString()}</div>;
    }
    return null;
  }

  const groupDisplayType = "groupRows";

  const gridRef = useRef();

  const clearFilters = useCallback(() => {
    if (localStorage.getItem("nbcu_dashboard_raw_data")) {
      localStorage.removeItem("nbcu_dashboard_raw_data");
    }
    gridRef.current.api.setFilterModel(null);
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "IP Name",
      field: "ip",
    },
    {
      headerName: "Vertical",
      field: "vertical",
      pinned: "left",
      filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
      enableRowGroup: true,
    },
    {
      headerName: "Company",
      field: "parentCompany",
      pinned: "left",
      filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
      enableRowGroup: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      filter: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
    };
  }, []);

  const sideBar = useSideBarConfig();
  const getContextMenuItems = useContextMenuItemsConfig();

  const groupDefaultExpanded = -1;

  const createColumnDef = (field, headerName) => {
    return {
      headerName,
      field,
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "right" },
      cellRenderer: "CommaSeparatedNumberCellRenderer",
    };
  };

  useEffect(() => {
    if (wikiRawData?.data?.data?.length > 0) {
      const sampleData = wikiRawData.data.data[0];
      const keys = Object.keys(sampleData).filter(
        (key) =>
          key !== "ip" &&
          key !== "ip_id" &&
          key !== "vertical" &&
          key !== "parentCompany",
      );

      const updatedColumnDefs = [
        {
          headerName: "IP Name",
          field: "ip",
          pinned: "left",
        },
        {
          headerName: "Vertical",
          field: "vertical",
          pinned: "left",
          filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
          enableRowGroup: true,
        },
        {
          headerName: "Company",
          field: "parentCompany",
          pinned: "left",
          filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
          enableRowGroup: true,
        },
        ...(keys
          ? keys.map((key) => {
              return {
                headerName: key,
                marryChildren: true,
                children: [
                  createColumnDef(`${key}.Total Views`, "Views"),
                  createColumnDef(`${key}.Desktop`, "Desktop"),
                  createColumnDef(`${key}.Mobile Web`, "Mobile Web"),
                  createColumnDef(`${key}.Mobile App`, "Mobile App"),
                ],
              };
            })
          : []),
      ];

      setColumnDefs(updatedColumnDefs);
    }
  }, [wikiRawData]);

  const breadcrumbs = [
    {
      text: "Platforms",
    },
    {
      text: "Wikipedia",
    },
    {
      text: "Raw Data",
      href: "/platforms/wikipedia/raw-data",
    },
  ];

  const {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
  } = useSplitPanel([]);

  useEffect(() => {
    return () => {
      if (localStorage.getItem("platforms_wikipedia_raw_data")) {
        localStorage.removeItem("platforms_wikipedia_raw_data");
      }
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (localStorage.getItem("platforms_wikipedia_raw_data")) {
        let rawData = JSON.parse(
          localStorage.getItem("platforms_wikipedia_raw_data"),
        );
        gridRef.current.api.setFilterModel(rawData);
      }
      const handleFilterChanged = () => {
        localStorage.setItem(
          "platforms_wikipedia_raw_data",
          JSON.stringify(gridRef.current.api.getFilterModel()),
        );
      };

      gridRef.current.api.addEventListener(
        "filterChanged",
        handleFilterChanged,
      );

      return () => {
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.removeEventListener(
            "filterChanged",
            handleFilterChanged,
          );
        }
      };
    }
  }, [gridRef.current]);

  const onSubmit = (data) => {
    curdOption(data);
  };
  const values = watch();

  const [isWeightageApplied, setIsWeightageApplied] = useState(false);
  const toggleWeightage = ({ detail }) => {
    changePreference(detail.checked);
  };

  const curdOption = (data) => {
    let url = "";
    if (data.type.value === "organization") {
      url = `/preferences/organization/${user["custom:organization"]}/wikipedia/weightage`;
    } else {
      url = `/preferences/personal/${user.username}/wikipedia/weightage/${data.name}.json`;
    }

    axiosInstance
      .request({
        url: url,
        method: "POST",
        params: {
          default: data.isDefault,
        },
        data: {
          desktop: data.defaultDesktop,
          mobileWeb: data.defaultMobileWeb,
          mobileApp: data.defaultMobileApp,
        },
      })
      .then((result) => {
        setReadOnly(true);
        setChecked(false);
        reset();
        setShowWeightageCreateForm(false);
      })
      .catch((e) => console.log(e));
  };

  const getSelectWeight = (d) => {
    setOptionPath(
      `${d.type.value ? d.type.value : d.type} > ` +
        d.filename.replace(".json", ""),
    );
    setOptionTitle(d.filename.replace(".json", "") + " Weight Settings");
    let url = "";
    if (d.type.value === "organization") {
      url = `/preferences/organization/${user["custom:organization"]}/${d.platform}/${d.service}/${d.filename}`;
    } else {
      url = `/preferences/personal/${user.username}/${d.platform}/${d.service}/${d.filename}`;
    }
    axiosInstance
      .request({
        url: url,
        method: "GET",
      })
      .then((result) => {
        setValue("defaultDesktop", result.data.desktop);
        setValue("defaultMobileApp", result.data.mobileApp);
        setValue("defaultMobileWeb", result.data.mobileWeb);

        if (isString(d.type)) {
          setValue(
            "type",
            [
              { label: "Personal", value: "personal" },
              {
                label: "Organization",
                value: "organization",
              },
            ].find((f) => f.value === d.type),
          );
        }
        if (d.type.value !== "organization") {
          setValue("name", d.filename.replace(".json", ""));
        }
        if (d.filename === "default.json") {
          setValue("isDefault", true);
          setChecked(true);
        } else {
          setValue("isDefault", false);
          setChecked(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const changePreference = (status) => {
    const json = {
      wikipedia: {
        rawdata: {
          applyWeightage: String(!!status),
        },
      },
    };
    postAndGetGenericPreference(json);
  };

  const changeFullWidth = (status) => {
    const json = {
      wikipedia: {
        rawdata: {
          fullWidth: String(!!status),
        },
      },
    };
    postAndGetGenericPreference(json);
  };

  function applyWeightage({ columnDefs, values, data }) {
    if (
      data?.data?.wikipedia?.rawdata?.applyWeightage === "true" &&
      values.defaultDesktop &&
      columnDefs &&
      columnDefs.length > 0
    ) {
      const sampleData = wikiRawData.data.data[0];
      const keys = Object.keys(sampleData).filter(
        (key) =>
          key !== "ip" &&
          key !== "ip_id" &&
          key !== "vertical" &&
          key !== "parentCompany",
      );
      const updated = columnDefs.map((obj) => ({
        ...obj,
        [keys[0]]: {
          ...obj[keys[0]],
          Desktop: obj[keys[0]]["Desktop"] * values.defaultDesktop,
          "Mobile App": obj[keys[0]]["Mobile App"] * values.defaultMobileApp,
          "Mobile Web": obj[keys[0]]["Mobile Web"] * values.defaultMobileWeb,
        },
      }));
      return updated;
    } else {
      return columnDefs;
    }
  }

  const appliedcolumnDefs = applyWeightage({
    columnDefs:
      wikiRawData && wikiRawData.data && wikiRawData.data.data
        ? wikiRawData.data.data
        : [],
    values,
    data: genericPreference || {},
  });

  const Content = (
    <>
      <div
        style={{
          height: "86vh",
          paddingTop: 10,
        }}
        className={"ag-theme-quartz-dark"}
      >
        {wikiRawData &&
          wikiRawData.data &&
          wikiRawData.data.data &&
          wikiRawData.data.data.length > 0 && (
            <AgGridReact
              onGridReady={(p) => {
                setGridApi(p.api);
              }}
              rowData={appliedcolumnDefs}
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              enableRangeSelection={true}
              allowContextMenuWithControlKey={true}
              getContextMenuItems={getContextMenuItems}
              groupDisplayType={groupDisplayType}
              rowHeight={35}
              animateRows={true}
              rowGroupPanelShow={"always"}
              tooltipShowDelay={500}
              groupRowsSticky={true}
              groupDefaultExpanded={groupDefaultExpanded}
              customChartThemes={customChartThemes}
              chartThemes={chartThemes}
              components={{ CommaSeparatedNumberCellRenderer }}
            ></AgGridReact>
          )}
      </div>
    </>
  );

  return (
    <>
      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        splitPanelOpen={splitPanelOpen}
        onSplitPanelResize={onSplitPanelResize}
        splitPanelSize={splitPanelSize}
        onSplitPanelToggle={onSplitPanelToggle}
        splitPanel={
          <SplitPanel
            header={
              <span style={{ textTransform: "capitalize" }}>{optionTitle}</span>
            }
            i18nStrings={{
              preferencesTitle: "Split panel preferences",
              preferencesPositionLabel: "Split panel position",
              preferencesPositionDescription:
                "Choose the default split panel position for the service.",
              preferencesPositionSide: "Side",
              preferencesPositionBottom: "Bottom",
              preferencesConfirm: "Confirm",
              preferencesCancel: "Cancel",
              closeButtonAriaLabel: "Close panel",
              openButtonAriaLabel: "Open panel",
              resizeHandleAriaLabel: "Resize split panel",
            }}
            closeBehavior="collapse"
            defaultPosition="bottom"
            size={splitPanelSize}
          >
            <ColumnLayout columns={2}>
              <div>
                <Header variant="h5">{optionPath}</Header>
              </div>
              <div>
                <div className=" flex flex-row justify-end items-stretch content-center flex-nowrap gap-4 ">
                  <SpaceBetween direction="horizontal" size="l">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setValue("method", "create");
                        setShowWeightageCreateForm(true);
                      }}
                    >
                      Create New
                    </Button>
                    <ButtonDropdown
                      onItemClick={(e) => {
                        const itemData = optionWeightage.data.filter(
                          (d) =>
                            d.type === e.detail.id.split("@")[1] &&
                            d.filename === e.detail.id.split("@")[0] + ".json",
                        );
                        if (itemData && itemData.length > 0) {
                          getSelectWeight(itemData[0]);
                        }
                      }}
                      items={[
                        {
                          id: "personal",
                          text: "Personal",
                          items: optionWeightage?.data
                            .filter(
                              (d) =>
                                d.platform === "wikipedia" &&
                                d.type === "personal",
                            )
                            .map((d) => ({
                              id:
                                d.filename.replace(".json", "") + "@" + d.type,
                              text: d.filename.replace(".json", ""),
                            })),
                        },
                        {
                          id: "organization",
                          text: "Organization",
                          items: optionWeightage?.data
                            .filter(
                              (d) =>
                                d.platform === "wikipedia" &&
                                d.type === "organization",
                            )
                            .map((d) => ({
                              id:
                                d.filename.replace(".json", "") + "@" + d.type,
                              text: d.filename.replace(".json", ""),
                            })),
                        },
                      ]}
                      expandableGroups
                    >
                      Load
                    </ButtonDropdown>
                  </SpaceBetween>
                </div>
              </div>
            </ColumnLayout>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <ColumnLayout columns={4}>
                <Controller
                  name={`defaultDesktop`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormField
                        label="Desktop"
                        stretch={true}
                        errorText={errors?.defaultDesktop?.message ?? ""}
                      >
                        <Input
                          {...field}
                          onChange={(e) => field.onChange(e.detail.value)}
                          readOnly={readOnly}
                        />
                      </FormField>
                    );
                  }}
                />
                <Controller
                  name={`defaultMobileWeb`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormField
                        label="Mobile Web"
                        stretch={true}
                        errorText={errors?.defaultMobileWeb?.message ?? ""}
                      >
                        <Input
                          {...field}
                          onChange={(e) => field.onChange(e.detail.value)}
                          readOnly={readOnly}
                        />
                      </FormField>
                    );
                  }}
                />
                <Controller
                  name={`defaultMobileApp`}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormField
                        label="Web App"
                        stretch={true}
                        errorText={errors?.defaultMobileApp?.message ?? ""}
                      >
                        <Input
                          {...field}
                          onChange={(e) => field.onChange(e.detail.value)}
                          readOnly={readOnly}
                        />
                      </FormField>
                    );
                  }}
                />
              </ColumnLayout>
              <div className=" flex flex-row justify-end items-stretch content-center flex-nowrap gap-4 ">
                <SpaceBetween direction="horizontal" size="l">
                  {readOnly ? (
                    <Button
                      formAction="none"
                      onClick={() => {
                        setReadOnly(false);
                        setValue("method", "edit");
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          onChange={({ detail }) => setChecked(detail.checked)}
                          checked={checked}
                        >
                          Set as default
                        </Checkbox>
                      </div>
                      <Button formAction="submit">Save</Button>
                      <Button
                        formAction="none"
                        variant="secondary"
                        onClick={() => setReadOnly(true)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </SpaceBetween>
              </div>
            </FormProvider>
          </SplitPanel>
        }
        content={
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <ContentLayout
              header={
                <>
                  <Header
                    variant="h3"
                    actions={
                      <>
                        <SpaceBetween direction="horizontal" size="xs">
                          <Toggle
                            onChange={toggleWeightage}
                            checked={isWeightageApplied}
                          >
                            Apply Weightage
                          </Toggle>
                          <Button onClick={clearFilters}>
                            Clear All Filters
                          </Button>
                          <Select
                            selectedOption={granularity}
                            onChange={({ detail }) =>
                              setGranularity(detail.selectedOption)
                            }
                            selectedAriaLabel="Selected"
                            options={granularityItems}
                          />

                          <RangeDateSelector
                            defaults={
                              dateRange
                                ? dateRange?.type === "relative"
                                  ? {
                                      ...dateRange,
                                      startDate: moment()
                                        .subtract(15, "days")
                                        .startOf("day")
                                        .format("YYYY-MM-DD"),
                                      endDate: moment(dateRange.endDate),
                                    }
                                  : dateRange
                                : {
                                    type: "absolute",
                                    startDate: moment()
                                      .subtract(15, "days")
                                      .startOf("day")
                                      .format("YYYY-MM-DD"),
                                    endDate: moment().format("YYYY-MM-DD"),
                                  }
                            }
                            onChange={(e) => {
                              if (e.type === "relative") {
                                setDateRange({
                                  ...e,
                                  startDate: moment(e.startDate).format(
                                    "YYYY-MM-DD",
                                  ),
                                  endDate: moment(e.endDate).format(
                                    "YYYY-MM-DD",
                                  ),
                                });
                              } else {
                                setDateRange(e);
                              }
                            }}
                          />
                        </SpaceBetween>
                      </>
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="px-5">Raw Data</span>
                      <Toggle
                        checked={isFullWidth}
                        onChange={() => {
                          changeFullWidth(!isFullWidth);
                        }}
                      >
                        Full width
                      </Toggle>
                    </div>
                  </Header>

                  <div className="py-5">
                    <CustomFlashBar />
                  </div>
                </>
              }
            >
              <Container fitHeight>{Content}</Container>
            </ContentLayout>
            <Modal
              size="large"
              onDismiss={() => setShowWeightageCreateForm(false)}
              visible={showWeightageCreateForm}
              closeAriaLabel="Close modal"
              header="Create weight settings"
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Controller
                    name={`type`}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormField
                          label="Type"
                          stretch={true}
                          errorText={errors?.type?.message ?? ""}
                        >
                          <Select
                            {...field}
                            selectedOption={field.value}
                            onChange={({ detail }) =>
                              field.onChange(detail.selectedOption)
                            }
                            options={
                              user.role === "superAdmin" ||
                              user.role === "admin"
                                ? [
                                    { label: "Personal", value: "personal" },
                                    {
                                      label: "Organization",
                                      value: "organization",
                                    },
                                  ]
                                : [{ label: "Personal", value: "personal" }]
                            }
                            selectedAriaLabel="Selected"
                          />
                        </FormField>
                      );
                    }}
                  />
                </div>
                {values.type.value === "personal" ? (
                  <div style={{ paddingTop: 10, paddingBottom: 5 }}>
                    <Controller
                      name={`name`}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormField
                            label="Name"
                            description="Title of the weight settings"
                            stretch={true}
                            errorText={errors?.name?.message ?? ""}
                          >
                            <Input
                              {...field}
                              onChange={(e) => field.onChange(e.detail.value)}
                            />
                          </FormField>
                        );
                      }}
                    />
                  </div>
                ) : null}
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Controller
                    name={`defaultDesktop`}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormField
                          label="Desktop"
                          stretch={true}
                          errorText={errors?.defaultDesktop?.message ?? ""}
                        >
                          <Input
                            {...field}
                            onChange={(e) => field.onChange(e.detail.value)}
                          />
                        </FormField>
                      );
                    }}
                  />
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Controller
                    name={`defaultMobileWeb`}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormField
                          label="Mobile Web"
                          stretch={true}
                          errorText={errors?.defaultMobileWeb?.message ?? ""}
                        >
                          <Input
                            {...field}
                            onChange={(e) => field.onChange(e.detail.value)}
                          />
                        </FormField>
                      );
                    }}
                  />
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Controller
                    name={`defaultMobileApp`}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormField
                          label="Web App"
                          stretch={true}
                          errorText={errors?.defaultMobileApp?.message ?? ""}
                        >
                          <Input
                            {...field}
                            onChange={(e) => field.onChange(e.detail.value)}
                          />
                        </FormField>
                      );
                    }}
                  />
                </div>
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <Controller
                    name={`isDefault`}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormField
                          label="Set as default"
                          stretch={true}
                          errorText={errors?.defaultMobileApp?.message ?? ""}
                        >
                          <Checkbox
                            onChange={({ detail }) =>
                              field.onChange(detail.checked)
                            }
                            checked={field.value}
                          >
                            Set as default
                          </Checkbox>
                        </FormField>
                      );
                    }}
                  />
                </div>
                <div className={"pb-10 pt-5"}>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        type="button"
                        variant="link"
                        onClick={() => setShowWeightageCreateForm(false)}
                      >
                        Cancel
                      </Button>
                      <Button formAction="submit" variant="primary">
                        Create
                      </Button>
                    </SpaceBetween>
                  </Box>
                </div>
              </FormProvider>
            </Modal>
          </div>
        }
      />
    </>
  );
};
