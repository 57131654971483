export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || "";
export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const IP_LIST = [
  { label: "Pantheon", value: "Pantheon" },
  { label: "NBCU", value: "nbcu" },
];

export const TAGS_LIST = [
  "wikipedia",
  "trends",
  "youtube",
  "a03",
  "instagram",
  "wattpad",
  "subreddit",
  "parentCompany",
  "twitter",
  "tiktok",
  "fandom",
  "tmsId",
  "emsId",
  "rtUrl",
  "twitch",
  "discord",
  "steam",
];

export const COMMON_BOX_OFFICE_REGIONS = {
  XDOM: "Domestic",
  XWW: "WorldWide",
  XNDOM: "International",
  ES: "Spain",
  GB: "United Kingdom",
  AU: "Australia",
  DE: "Germany",
  IT: "Italy",
  FR: "France",
  MX: "Mexico",
  KR: "South Korea",
  BR: "Brazil",
  JP: "Japan",
  TH: "Thailand",
  IN: "India",
  CA: "Canada",
};

export const regionGroups = {
  Regions: ["XDOM", "XWW", "XNDOM"],
  Countries: [
    "ES",
    "GB",
    "AU",
    "DE",
    "IT",
    "FR",
    "MX",
    "KR",
    "BR",
    "JP",
    "TH",
    "IN",
    "CA",
  ],
};

export const formattedBoxOfficeRegions = Object.entries(regionGroups).map(
  ([groupLabel, regionKeys]) => {
    const options = regionKeys.map((key) => ({
      label: COMMON_BOX_OFFICE_REGIONS[key],
      value: key,
      tags: key === "XDOM" ? ["US & Canada"] : [],
    }));
    return { label: groupLabel, options };
  },
);

export const occassion = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Weekend", value: "weekend" },
];

export const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };
