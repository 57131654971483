import axiosInstance from "../../../../utils/axios";

export type GetChannelsListDataProps = {
  from: number;
  size: number;
  include: Array<any>;
  exclude: Array<any>;
  condition: string;
  sort: Array<any>;
};

export const DEFAULT_API_PARAMS = {
  from: 0,
  size: 50,
  include: [],
  exclude: [],
  condition: "and",
  sort: [],
};

export const getChannelsListData = async ({
  from = DEFAULT_API_PARAMS.from,
  size = DEFAULT_API_PARAMS.size,
  include = DEFAULT_API_PARAMS.include,
  exclude = DEFAULT_API_PARAMS.exclude,
  sort = DEFAULT_API_PARAMS.sort,
  condition = "and",
}: GetChannelsListDataProps) => {
  const requestBody = {
    include,
    exclude,
    sort,
    condition,
  };

  return axiosInstance.request({
    url: `youtube/v1/channels/list?from=${from}&size=${size}`,
    method: "POST",
    data: requestBody,
  });
};

export const getChannelsSearchData = (queryString: string) => {
  const MAX_RESULTS = 6;

  return axiosInstance.request({
    url: `youtube/v1/channels/search?q=${queryString}&maxResults=${MAX_RESULTS}`,
    method: "GET",
  });
};

export type PostCreateChannelType = {
  channel_id: string;
  type: string;
  todaysViews: number;
};

export const postCreateChannel = (requestBody: PostCreateChannelType) => {
  return axiosInstance.request({
    url: `youtube/v1/channels`,
    method: "POST",
    data: { ...requestBody },
  });
};
