import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router";
import  navigation  from "./router";

export default function Navigator() {
  const settings = useSelector((state) => state.settings);
  useEffect(() => {
    if (settings.theme === "light") {
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
    }
  }, [settings]);

  return <RouterProvider router={navigation} />;
}
