import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import PiracyCharts from "./PiracyCharts";

const title = "Piracy Rankings";

function PiracyChartsIndexPage() {
  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Viewer's Pulse", href: "/" },
    { text: title },
  ];
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  description="The Piracy Rankings page provides insights into the most pirated movies and series by Ranking them using weekly downloads.the data is updated every monday for the last week"
                  variant="h2"
                >
                  {title}
                </Header>
                <CustomFlashBar />
              </>
            }
          >
            <PiracyCharts />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default PiracyChartsIndexPage;
