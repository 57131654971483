import {
    AppLayout,
    BreadcrumbGroup,
    ContentLayout,
    Header,
    Container,
    SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../../layouts/common/menu/side-menu"
import CustomFlashBar from "../../../common/CustomFlashBar";
import SeriesNonOTTComponent from "./series-non-ott";

function SeriesNonOTTIndex() {
    const breadcrumbs = [
        { text: "Dashboard" },
        { text: "Peacock Dashboard", href: "/peacock-dashboard" },
        { text: "Series: Beyond OTT" },
    ];
    return (
        <>
            <Helmet>
                <title>Series: Beyond OTT</title>
            </Helmet>
            <AppLayout
                stickyNotifications
                toolsHide
                contentType="cards"
                ariaLabels={{ navigationClose: "close" }}
                navigation={
                    <SideNavigation
                        activeHref={window.location.pathname}
                        items={navItems}
                    />
                }
                content={
                    <ContentLayout
                        header={
                            <>
                                <Header variant="h2" description="Explore a unique collection of series beyond the mainstream reach. Uncover hidden gems and critically acclaimed productions absent from standard OTT platforms. From underappreciated classics to regional specialties, this resource offers valuable insights and identifies potential titles for a distinctive and enriching viewing experience.">Series: Beyond OTT</Header>
                                <CustomFlashBar />
                            </>
                        }
                    >

                            <SeriesNonOTTComponent />

                    </ContentLayout>
                }
                breadcrumbs={
                    <BreadcrumbGroup
                        items={breadcrumbs}
                        expandAriaLabel="Show path"
                        ariaLabel="Breadcrumbs"
                    />
                }
            />
        </>
    );
}

export default SeriesNonOTTIndex;
