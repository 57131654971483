import { dispatch } from "../../../../app/store";
import { fetchCompaniesData, fetchFilteredCompaniesData } from "./companies_slice";

export async function getCompaniesData(from, size, range) {
  await dispatch(fetchCompaniesData({ from, size, range }));
}

export async function getFilteredCompaniesData(from, size, range, body) {
  
  await dispatch(fetchFilteredCompaniesData({ from, size, range, body }));
}
