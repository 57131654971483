import { time } from "ag-charts-community";
import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import shortenInteger from "../../../utils/shortenInteger";
import { useGetBOData } from "../api/hooks/useGetBOData";

const tooltip = {
    renderer: ({ datum }) => {
        return {
            title: `<span style="font-size: 16px; color: black">${datum.year}</span>`,
            content: `
                <b># of Movies:</b> ${datum.movieCount} <br />
                <b>Domestic Collection:</b> ${shortenInteger(datum.domestic)}<br/>
                <b>International Collection:</b> ${shortenInteger(datum.international)}<br/>
                <b>Worldwide Collection:</b> ${shortenInteger(datum.worldWide)}<br/>
            `,
        };
    },
};

const pantheonTheme = {
    baseTheme: "ag-vivid-dark",
    overrides: {
        common: {
            background: { fill: "#0F1B2A" },
        },
    },
};

export const BOChart = () => {
    const { isLoading, data, error } = useGetBOData();
    const [options, setOptions] = useState(null);

    useEffect(() => {
        if (data && data.length > 0) {
            const processedData = data.map(item => ({
                ...item,
                yearInEpoch: new Date(item.year).getTime(),
                movieCount: parseInt(item.movieCount),
                worldWide: parseInt(item.worldWide),
                international: parseInt(item.international),
                domestic: parseInt(item.domestic)
            }));

            const newOptions = {
                theme: pantheonTheme,
                legend: { enabled: true },
                data: processedData,
                animation: {
                    enabled: true,
                },
                zoom: {
                    enabled: true,
                },
                title: {
                    text: `BoxOffice collections over-time`,
                },
                navigator: {
                    enabled: true,
                    height: 20,
                    margin: '20px',
                },
                padding: { bottom: 0 },
                series: [          
                    {
                        type: 'bar',
                        xKey: 'yearInEpoch',
                        xName: 'Year',
                        yKey: 'domestic',
                        yName: 'Domestic BoxOffice',
                        stackGroup: 'BO',
                        fill: '#8069D8',
                        tooltip
                    },       
                    {
                        type: 'bar',
                        xKey: 'yearInEpoch',
                        xName: 'Year',
                        yKey: 'international',
                        yName: 'International BoxOffice',
                        stackGroup: 'BO',
                        fill: '#C54F78',
                        tooltip
                    },
                    {
                        type: 'line',
                        xKey: 'yearInEpoch',
                        xName: 'Year',
                        strokeWidth: 2,
                        yKey: 'movieCount',
                        yName: '# of Movies',
                        lineDash: [4, 2],
                        stroke: '#F2A602',
                        marker: {
                            enabled: false,
                        },
                        tooltip,
                    }
                ],
                axes: [
                    {
                        position: 'bottom',
                        type: 'time',
                        keys: ['yearInEpoch'],
                        label: {
                            rotation: 300,
                            avoidCollisions: true,
                            format: "%Y",
                        },
                        nice: true,
                        tick: {
                            format: "%Y",
                            interval: time.year,
                        },
                        gridLine: {
                            enabled: false,
                        },
                        line: {
                            enabled: false,
                        },
                        crossLines: [
                            {
                                type: "range",
                                range: [new Date(2020, 0, 1), new Date(2023, 0, 1)],
                                strokeWidth: 0,
                                fill: "#7290C4",
                                fillOpacity: 0.4,
                                label: {
                                    text: "COVID-19",
                                    padding: 10,
                                    position: "top",
                                },
                            },
                        ],
                    },
                    {
                        position: 'left',
                        type: 'number',
                        keys: ['worldWide', 'international', 'domestic'],
                        label: {
                            formatter: function (params) {
                                const value = params.value;
                                return `${(value / 1_000_000_000).toFixed(0)}`;
                            }
                        },
                        line: {
                            width: .5,
                        },
                        gridLine: {
                            enabled: false,
                        },
                        title: {
                            text: '$ in Billions',
                        }
                    },
                    {
                        position: 'right',
                        type: 'log',
                        keys: ['movieCount'],
                        line: {
                            width: .5,
                        },
                        gridLine: {
                            enabled: false,
                        },
                        title: {
                            text: 'Movie Count ',
                        }
                    },
                ],
            };
            setOptions(newOptions);
        }
    }, [data]);

    if (isLoading) {
        return (
            <div className="flex flex-row justify-center h-120">
                <CustomLoadingOverlay />
            </div>
        );
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    if (!options) {
        return <div>No data to display</div>;
    }

    return (
        <div className="h-120">
            <AgChartsReact options={options} />
        </div>
    );
}