import {
  Container,
  FormField,
  Header,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { AdvancedFilters } from "../../../components/filters/AdvancedFilters";
import { FiltersDropdown } from "../../../components/filters/FiltersDropdown";
import { isNone, isSomeOrElse } from "../../../utils/sugarUtils";
import TableLegend from "../components/TableLegend";
import { useGetTwitchTrendsData } from "./api/hooks/useGetTwitchTrendsData";
import { useGridConfig } from "./hooks/useGridConfig";

function TwitchTrendsCharts() {
  const gridRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [TwitchTC, setTwitchTC] = useState({
    data: [],
    status: "idle",
  });
  const [filter, setFilter] = useState(null);
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const { defaultColDef, columnDefs, onGridReady } = useGridConfig();

  const { data: twitchTrendsData, isLoading, error } = useGetTwitchTrendsData();

  useEffect(() => {
    if (isNone(twitchTrendsData)) return;

    let data = twitchTrendsData.data?.data?.map((item) => {
      return {
        ...item,
      };
    });
    const latestUpdatedAt = moment(
      twitchTrendsData.data.data.timestamp * 1000,
    ).format("DD MMM Y");

    setTwitchTC({ data, status: "loaded", latestUpdatedAt });
  }, [twitchTrendsData]);

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const getExistingTwitchPreferences = (currentFilters) => ({
    ...globalPref,
    dashboards: {
      ...globalPref?.dashboards,
      viewersPulse: {
        ...globalPref?.dashboards?.viewersPulse,
        twitch: {
          filters: { ...currentFilters },
        },
      },
    },
  });

  const getPreferencesAfterDelete = (name) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.twitch?.filters,
        {},
      ),
    };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingTwitchPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.viewersPulse?.twitch?.filters,
        {},
      ),
    };
    currentFilters[name] = { ...filter };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingTwitchPreferences(currentFilters);
  };

  if (error) {
    return <div>Error: {error?.message}</div>;
  }

  if (isLoading) {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  const handleClearFilter = () =>
    gridRef.current.api.setAdvancedFilterModel(null);

  const handleFilterItemClick = (id, filters) => {
    const filter = filters[id];

    gridRef.current.api.setAdvancedFilterModel(filter);
    setSelectedFilterName(id);
  };

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <FiltersDropdown
                  handleFilterItemClick={handleFilterItemClick}
                  path="dashboards.viewersPulse.twitch.filters"
                />
                <AdvancedFilters
                  handleClearFilter={handleClearFilter}
                  selectedFilterName={selectedFilterName}
                  filter={filter}
                  getPreferencesAfterDelete={getPreferencesAfterDelete}
                  getPreferencesAfterSaveOrUpdate={
                    getPreferencesAfterSaveOrUpdate
                  }
                />
              </SpaceBetween>
            }
          >
            <FormField description="Filter titles from the table">
              <TextFilter
                filteringText={filterText}
                filteringPlaceholder="Search title"
                filteringAriaLabel="Filter title"
                onChange={({ detail }) => setFilterText(detail.filteringText)}
              />
            </FormField>
          </Header>
        }
      >
        <div id="advancedFilterParent"></div>
      </Container>

      <SpaceBetween direction="vertical" size="m">
        <div style={{ height: "70vh" }} className="ag-theme-quartz-dark">
          <AgGridReact
            ref={gridRef}
            quickFilterText={filterText}
            rowData={TwitchTC.data}
            paginationPageSize={100}
            pagination={true}
            defaultColDef={defaultColDef}
            loadingOverlayComponent={CustomLoadingOverlay}
            noRowsOverlayComponent={CustomLoadingOverlay}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={false}
            enableAdvancedFilter={true}
            tooltipShowDelay={100}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onFilterChanged={(params) => {
              const f = params?.api?.getAdvancedFilterModel();
              setFilter(f);
            }}
          />
        </div>
        <TableLegend />
      </SpaceBetween>
    </SpaceBetween>
  );
}

export default TwitchTrendsCharts;
