import axiosInstance from "../../../../utils/axios";

type GetGlobalRankingParamsType = {
  vertical: string;
  startdate: number;
  enddate: number;
  partition: number;
  size: number;
  noofpartition: number;
};

export const getGlobalRanking = (params: GetGlobalRankingParamsType) => {
  return axiosInstance.request({
    url: "/pantheon/globalranking/v1",
    method: "GET",
    params,
  });
};
