import Link from "@cloudscape-design/components/link";
import React, { useMemo } from "react";

const RenderItem = ({ item, index, isGrouped, selectedIps, onItemSelect }) => {
  let imageUrl = "-";
  if (item.ip_id.startsWith("film-") || item.ip_id.startsWith("series-")) {
    imageUrl = `https://image.tmdb.org/t/p/w185/${item.image_url}`;
  } else if (item.ip_id.startsWith("game-")) {
    imageUrl = `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
  }

  return (
    <div key={item.IP} className="inline-block relative group">
      <div
        className={`w-44 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out  m-2`}
      >
        <div className="flex justify-center items-center">
          {imageUrl === "-" ? (
            <div
              className={`"w-44 h-64 bg-slate-600 text-white flex justify-center items-center rounded-md`}
            >
              <div className="text-sm text-center">{item.IP}</div>
            </div>
          ) : (
            <Link
              href={
                item.ip_id
                  ? `${window.location.origin}/item/${item.ip_id}`
                  : undefined
              }
            >
              <img
                src={imageUrl}
                alt={item.IP}
                className={`w-44 rounded-md`}
                loading="lazy"
              />
              <div className="absolute inset-0 rounded-md items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity border-2 border-slate-600 bg-black bg-opacity-90 p-4">
                <span className="text-white">{item.IP}</span>
              </div>
            </Link>
          )}
        </div>
        {!isGrouped && item.vertical && (
          <div className="absolute top-2 right-2 p-3 h-2 w-2 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-yellow-500 font-bold text-md">
            {item.vertical.charAt(0)}
          </div>
        )}
        <div
          className={`absolute bottom-2 left-2 bg-sidebarLow border-2 border-slate-600 rounded-full px-2.5 py-1`}
        >
          <span className="text-amber-500 font-bold flex flex-row items-center">
            <p>#</p> {index + 1}
          </span>
        </div>
        <button
          type="button"
          onClick={() => onItemSelect(item)}
          className={`absolute bottom-2 right-2 rounded-full px-3 py-1.5 text-sm font-semibold border-2 border-slate-600 shadow-sm ${
            selectedIps.filter((ip) => ip.ip_id === item.ip_id).length > 0
              ? "bg-blue-500 text-white"
              : "bg-sidebarLow text-white"
          } hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
        >
          {parseFloat(item.score).toFixed(3)}
        </button>
      </div>
    </div>
  );
};

export const OptionLayer = ({
  optionData = [],
  selectedIps,
  onItemSelect,
  searchFilter,
}) => {
  const filteredData = useMemo(() => {
    return optionData.filter((item) =>
      item.IP.toLowerCase().includes(searchFilter.toLowerCase()),
    );
  }, [searchFilter, optionData]);

  return (
    <>
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-wrap justify-center overflow-y-auto">
          {!Array.isArray(filteredData) || filteredData.length <= 0 ? (
            <div>No results</div>
          ) : (
            filteredData.map((item, index) => (
              <RenderItem
                key={item.ip_id}
                item={item}
                index={index}
                selectedIps={selectedIps}
                onItemSelect={onItemSelect}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
