import React, { useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup, Button, Header, Toggle,
  SideNavigation, Box, PropertyFilter, Table,
  Flashbar,
  Pagination
} from "@cloudscape-design/components";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { useItemPaginationCallback } from "./useItemPaginationCallback";
import { TableColumns } from './TableColumns';
import { PreferencesComponent } from "./PreferencesComponent";
import ItemFiltering from "./ItemFilteringComponent";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const ItemSearch = () => {
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const breadcrumbs = [{ text: "Items" }, { text: "Search" }];
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPageIndex, setCurrentPageIndex] = useState(() => {
    const pageIndex = searchParams.get("page");
    if (!pageIndex) return 1;
    return parseInt(pageIndex);
  });
  const [filteringQuery, setFilteringQuery] = useState(() => {
    const tokens = JSON.parse(searchParams.get("q"));
    const operation = searchParams.get("operation");
    if (!tokens) return DEFAULT_FILTERING_QUERY;
    return {
      tokens: tokens,
      operation: operation,
    };
  });
  const [sortingColumn, setSortingColumn] = useState(() => {
    const sort = searchParams.get("sort");
    if (!sort) return {};
    return {
      sortingField: sort
    };
  });
  const [sortingDescending, setSortingDescending] = useState(() => {
    const desc = searchParams.get("desc");
    if (!desc) return true;
    return desc === "true";
  });
  

  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: 'comfortable',
    pageSize: 50,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
    ],
  });

  const handlePreferencesChange = (newPreferences) => {
    setPreferences(newPreferences);
  };


  const { pageSize } = preferences;
  const defaultParams = {
    pagination: {
      currentPageIndex,
      pageSize,
    },
    filtering: {
      filteringTokens: filteringQuery.tokens,
      filteringOperation: filteringQuery.operation,
    },
    sorting: {
      sortingDescending, 
      sortingColumn
    },
    loaded: (!!filteringQuery && !!sortingColumn && !!currentPageIndex)
  };
  
  const {
    items,
    loading,
    totalCount,
    pagesCount,
    apiCount,
    flashbarItems,
  } = useItemPaginationCallback(defaultParams);

  const handlePropertyFilteringChange = ({ detail }) => {
    setFilteringQuery(detail);
    setCurrentPageIndex(1);
  };

  return (
    <>
      <Helmet>
        <title>Item Search</title>
      </Helmet>

      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <Table
            loading={loading}
            columnDefinitions={TableColumns}
            filter={
              <ItemFiltering
                filteringQuery={filteringQuery}
                handlePropertyFilteringChange={handlePropertyFilteringChange}
                countText={`${apiCount} matches`}
              />
            }
            items={items}
            loadingText="Loading resources"
            wrapLines
            stickyHeader
            variant="full-page"
            visibleColumns={[
              "name",
              //"id",
              "type",
              "poster",
              "release_date"
            ]}
            empty={
              <Box textAlign="center" color="inherit">
                <b>No items</b>
                <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                  No items to display.
                </Box>
                
              </Box>
            }
            header={
              <>
              <Flashbar items={flashbarItems} />
              <Header variant="h3">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "20px" }}>Item Search</span>
                  {/*<Toggle checked={isFullWidth} onChange={() => setIsFullWidth(!isFullWidth)} >
                  Full width
                  </Toggle>*/}
                </div>
              </Header>
              </>
            }
            pagination={
              <Pagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.min(200, pagesCount)}
                disabled={loading}
                ariaLabels={{
                  nextPageLabel: "Next page",
                  previousPageLabel: "Previous page",
                  pageLabel: (pageNumber) =>
                    `Page ${pageNumber} of all pages`,
                }}
                onChange={(event) =>
                  setCurrentPageIndex(event.detail.currentPageIndex)
                }
              />
            }
            sortingColumn={sortingColumn}
            sortingDescending={sortingDescending}
            onSortingChange={(event) => {
              setSortingColumn(event.detail.sortingColumn);
              setSortingDescending(event.detail.isDescending);
            }}
            preferences={
            <PreferencesComponent
              initialPreferences={preferences}
              onPreferencesChange={handlePreferencesChange}
            />
            }
          />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default ItemSearch;
