import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../layouts/common/menu/side-menu";
import IndexPageGrid from "../../components/IndexPageGrid";

function PeacockDashboardCards() {
  const items = [
    {
      link: "/peacock-dashboard/non-ott-titles/series",
      image: "/images/exclusiveSeries.png",
      name: "Series: Beyond OTT",
      path: "Peacock Dashboard",
      description: "Explore a unique collection of series beyond the mainstream reach. Uncover hidden gems and critically acclaimed productions absent from standard OTT platforms. From underappreciated classics to regional specialties, this resource offers valuable insights and identifies potential titles for a distinctive and enriching viewing experience."
    },
    {
      link: "/peacock-dashboard/non-ott-titles/movies",
      image: "/images/nonOTTMovies.png",
      name: "Movies: Unstreamed Picks",
      path: "Peacock Dashboard",
      description: "Our collection brings to light exceptional films, from overlooked masterpieces to regional cinematic wonders. This platform serves as a gateway to uncover and delve into the depths of rare and captivating movie experiences, offering insights and identifying opportunities for diverse and enriching cinematic exploration."
    },
    {
      link: "/peacock-dashboard/series/wiki/genre",
      image: "/images/peacock-wiki-genre.png",
      name: "Genre Insights",
      path: "Peacock Dashboard > Series Analysis",
      description:
        "Showcasing genre popularity by title count and Wikipedia Pageviews. Track trends, identify what captivates viewers, and strategize content creation based on real-time, data-driven insights.",
    },
    {
      link: "/peacock-dashboard/series/wiki/genre-platform",
      image: "/images/peacock-wiki-platform.png",
      name: "Platform Influence",
      path: "Peacock Dashboard > Series Analysis",
      description:
        "Unveil the landscape of Genre across platforms with our dashboard. It details viewership and title counts, offering a colorful breakdown by service. Pinpoint market leaders, analyze viewer engagement, and leverage the visualized data to drive your strategic decisions.",
    },
    {
      link: "/peacock-dashboard/series/wiki/genre-marketshare",
      image: "/images/peacock-wiki-marketshare.png",
      name: "Platform Ratio by Genre",
      path: "Peacock Dashboard > Series Analysis",
      description:
        "Navigate the dynamic sphere of Genre with our vivid bubble chart, comparing platform shares and viewership numbers. Each bubble's size and color reflect title counts and platform popularity, offering a unique visual perspective on entertainment trends.",
    },
  ];

  const gridDefinition = items.map(() => ({ colspan: { xxs: 12, xs: 6, m: 3, xl: 2 } }));

  return (
    <IndexPageGrid items={items} gridDefinition={gridDefinition} />
  );
}
function PeacockDashboardHomePage() {
  const breadcrumbs = [{ text: "Dashboard" }, { text: "Peacock" }];
  return (
    <>
      <Helmet>
        <title>Peacock Dashboard</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="cards"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                variant="h2"
                description="Custom-built dashboards for Peacock"
              >
                Peacock Dashboards
              </Header>
            }
          >
            <PeacockDashboardCards />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default PeacockDashboardHomePage;
