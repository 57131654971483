import React, { useState, useEffect, useRef } from 'react';
import { PropertyFilter } from "@cloudscape-design/components";
import axiosInstance from '../../../utils/axios';

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const ItemFiltering = ({ filteringQuery, handlePropertyFilteringChange, countText }) => {
    const [filteringOptions, setFilteringOptions] = useState([]);
    const [typeFilteringOptions, setTypeFilteringOptions] = useState([]);
    const [status, setStatus] = useState('pending');
    const request = useRef();

    useEffect(() => {
        fetchTypeFilteringOptions();
    }, []);

    const fetchTypeFilteringOptions = () => {
        if (typeFilteringOptions.length > 0) {
            setFilteringOptions(typeFilteringOptions);
        } else {
            axiosInstance.get('/ipmanagement/search?type=Thing&fields=id,name')
                .then((response) => {
                    const data = response.data;
                    const newFilteringOptions = [];

                    data.forEach(value => {
                        newFilteringOptions.push({ propertyKey: "type.name", value: value.name, label: value.name });
                    });

                    setTypeFilteringOptions(newFilteringOptions);
                    setFilteringOptions(newFilteringOptions);
                    setStatus('finished');
                });
        }
    };

    const fetchSuggestions = (filteringText, filteringProperty) => {
        if (!filteringText || filteringText.length == 0) return;
        axiosInstance.get(`/ipmanagement/search?autocomplete=${filteringText}`)
            .then((response) => {
                if (!request.current || request.current.filteringText !== filteringText || request.current.filteringProperty !== filteringProperty) {
                    return;
                }
                const data = response.data;
                const newFilteringOptions = [];

                let tracked = [];
                data.forEach(value => {
                    if (tracked.includes(value.name)) return;
                    newFilteringOptions.push({ propertyKey: "name", value: value.name });
                    tracked.push(value.name);
                });

                setFilteringOptions(newFilteringOptions);
                setStatus('finished');
            });
    };

    const handleLoadItems = ({ detail: { filteringProperty, filteringText, firstPage, samePage } }) => {
        const label = filteringText || filteringProperty?.propertyLabel;
        if (label === "Type") {
            setStatus('finished');
            fetchTypeFilteringOptions();
        } else {
            setStatus('loading');
            request.current = {
                filteringProperty,
                filteringText,
            };
            fetchSuggestions(filteringText, filteringProperty);
        }
    };

    return (
        <PropertyFilter
            i18nStrings={{
                filteringAriaLabel: "your choice",
                dismissAriaLabel: "Dismiss",
                clearAriaLabel: "Clear",

                filteringPlaceholder: "Filter by text, property or value",
                groupValuesText: "Values",
                groupPropertiesText: "Properties",
                operatorsText: "Operators",

                operationAndText: "and",
                operationOrText: "or",

                operatorLessText: "Less than",
                operatorLessOrEqualText: "Less than or equal",
                operatorGreaterText: "Greater than",
                operatorGreaterOrEqualText: "Greater than or equal",
                operatorContainsText: "Contains",
                operatorDoesNotContainText: "Does not contain",
                operatorEqualsText: "Equals",
                operatorDoesNotEqualText: "Does not equal",

                editTokenHeader: "Edit filter",
                propertyText: "Property",
                operatorText: "Operator",
                valueText: "Value",
                cancelActionText: "Cancel",
                applyActionText: "Apply",
                allPropertiesLabel: "All properties",

                tokenLimitShowMore: "Show more",
                tokenLimitShowFewer: "Show fewer",
                clearFiltersText: "Clear filters",
                removeTokenButtonAriaLabel: (token) =>
                    `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            filteringProperties={[
                {
                    propertyLabel: "Name",
                    key: "name",
                    groupValuesLabel: "Name values",
                    operators: [":", "="],
                },
                {
                    propertyLabel: "Type",
                    key: "type.name",
                    groupValuesLabel: "Type values",
                    operators: ["!=", "="],
                },
            ].sort((a, b) =>
                a.propertyLabel.localeCompare(b.propertyLabel)
            )}
            onChange={handlePropertyFilteringChange}
            query={filteringQuery}
            filteringOptions={filteringOptions}
            filteringStatusType={status}
            countText={countText}
            onLoadItems={handleLoadItems}
        />
    );
}

export default ItemFiltering;