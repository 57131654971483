import {
  Container,
  Header,
  Spinner,
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import {  time } from "ag-charts-community";
import React, { useState, useEffect } from "react";
import { fetchItemStreamingWindowData } from "../redux/actions/item-actions";
import { useSelector } from "react-redux";


const StreamingWindowComponent = ({ titleId , vertical }) => {
  const [streamingWindowData, setStreamingWindowData] = useState(null);
  const [error, setError] = useState(null);
  const itemStreamingWindowData = useSelector(
    (state) => state?.itemStreamingWindowData?.data
  );
  const isLoading = useSelector((state) => state?.itemStreamingWindowData?.loading);

  useEffect(() => {
    setStreamingWindowData(itemStreamingWindowData);
  }, [itemStreamingWindowData]);

  useEffect(() => {
    if (itemStreamingWindowData === null) {
      fetchItemStreamingWindowData({ id: titleId, vertical });
    }
  }, []);

  if (isLoading)
    return (
      <div className="justify-center flex">
        <Spinner size="large" />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  if (!streamingWindowData || Object.keys(streamingWindowData).length === 0) {
    return (
     ""
    );
  }

  const fills = [
    '#5C2983',
    '#0076C5',
    '#21B372',
    '#FDDE02',
    '#F76700',
    '#D30018',
  ];

  const myTheme = {
    baseTheme: 'ag-default-dark',
    overrides: {
      common: {
        background: { fill: "#0F1B2A" },
      },
      'range-bar': {
        series: {
          highlightStyle: {
            item: {
              stroke: "gold",
              strokeWidth: 100,
            },

          },
        },
      },
    },
  };

  const transformedData = streamingWindowData.map((item,index) => ({
    ...item,
    unique_key: `${item.service_name}_${index}`,
    service_name: item.service_name, 
    start_date: new Date(item.start_date),
    end_date: new Date(item.end_date),
    days: parseInt(item.days, 10), 
  }));

  const serviceToColor = transformedData.reduce((acc, item) => {
    acc[item.service_name] =
      acc[item.service_name] || fills[Math.floor(Math.random() * fills.length)];
    return acc;
  }, {});
  const tooltip = {
    renderer: ({ datum, xKey, yLowKey, yHighKey }) => {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()} ${date.toLocaleString('en-US', {
          month: 'long',
        })} ${date.getFullYear()}`;
      };
      return {
        title: datum.service_name,
        content: `<b>Days:</b> ${datum.days
          } days <br/><b>Start Date: </b>${formatDate(
            datum[yLowKey]
          )}<br/><b>End Date: </b>${formatDate(datum[yHighKey])}`,
      };
    },
  };
  const options = {
    theme: myTheme,
    data: transformedData,
    series: [
      {
        type: 'range-bar',
        direction: 'horizontal',
        xKey: 'service_name',
        yLowKey: 'start_date',
        yHighKey: 'end_date',
        label: {
          placement: 'outside',
          color: 'white',
          fontSize: 10,
          padding: 5,
          formatter: ({ datum, itemId }) => {
            if (itemId === 'high') {
              return `${datum.days} days`;
            }
            return '';
          },
        },
        tooltip,
        formatter: (params) => ({
          fill: serviceToColor[params.datum.service_name],
          fillOpacity: 1,
          labelKey: 'service_name',
        }),
      },
    ],
    axes: [
      {
        type: 'category',
        position: 'left',
        crosshair: { enabled: true },
      },
      {
        type: 'time',
        position: 'bottom',
        // title: { text: "Timestamp" },
        tick: { interval: time.month.every(2) },
        gridLine: {
          style: [
            {
              stroke: '#383838',
              lineDash: [2, 2],
            }
          ],
        },
        crosshair: { enabled: false },
        label: {
          avoidCollisions: true,
          autoRotate: true,
          minSpacing: 20,
        },
        // crossLines: transformedData.map((item) => ({
        //   type: 'range',
        //   range: [item.start_date, item.end_date],
        //   fillOpacity: 0.1,
        //   fill: 'grey',
        // })),
      },
    ],
  };

  return (
    <Container
    header={
      <Header variant="h3">Service Platform Streaming Window</Header>
    }>
    <AgChartsReact options={options} />
    </Container>
  );
};

export default StreamingWindowComponent;
