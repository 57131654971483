import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchPiracyGenericTelevisionSummary = createAsyncThunk(
  "piracyGenericTelevisionSummary/fetchPiracyGenericTelevisionSummary",

  async ({ gte, lte, option, size, vertical, country }) => {
    const response = await axiosInstance.request({
      url: `/piracy/generic/us/summary`,
      method: `GET`,
      params: {
        gte,
        lte,
        option,
        size,
        vertical: vertical.value,
        country: country,
      },
    });
    return response;
  }
);

const piracyGenericTelevisionSummarySlice = createSlice({
  name: "piracyGenericTelevisionSummary",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPiracyGenericTelevisionSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPiracyGenericTelevisionSummary.fulfilled,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        }
      )
      .addCase(
        fetchPiracyGenericTelevisionSummary.rejected,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        }
      );
  },
});

const piracyGenericTelevisionSummaryReducer =
  piracyGenericTelevisionSummarySlice.reducer;

export default piracyGenericTelevisionSummaryReducer;

export const piracyGenericTelevisonSummaryActions =
  piracyGenericTelevisionSummarySlice.actions;
