import { Spinner ,Link, Icon } from "@cloudscape-design/components"

const SocialTrends = ({ itemData, instagramHashtagsData }) => {
    return (
        <>
            <div className="text-lg font-bold pb-2">
                Instagram
            </div>
            <dl className=" grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                {itemData &&
                    itemData.data &&
                    itemData.data.instagram &&
                    instagramHashtagsData &&
                    instagramHashtagsData.data ? (
                    itemData.data.instagram.map((tag, index) => {
                        const tagData =
                            instagramHashtagsData.data.find(
                                (h) => h.hashtag === tag
                            );
                        return (
                            <div
                                key={index}
                                className="overflow-hidden rounded-2xl bg-darkerBg shadow-lg px-4 py-2 sm:px-4"
                            >
                                <dt className="truncate text-base font-medium">
                                    <Link
                                        href={`https://instagram.com/explore/tags/${tag}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        #{tag} <Icon name="external" />
                                    </Link>
                                </dt>
                                <dd className="flex items-baseline justify-between">
                                    <span className="text-xs italic">
                                        {instagramHashtagsData
                                            ?.loading ? (
                                            <div className="text-center">
                                                <Spinner />
                                            </div>
                                        ) : tagData ? (
                                            `${tagData.num_posts.toLocaleString()} posts`
                                        ) : (
                                            "N/A"
                                        )}
                                    </span>
                                </dd>
                            </div>
                        );
                    })
                ) : (
                    <div className="text-center text-gray-500">
                        No tags available
                    </div>
                )}
            </dl>
        </>
    )
}

export default SocialTrends