import "ag-charts-enterprise";
import { AgChartsReact } from "ag-charts-react";
import React from "react";

const pantheonTheme = {
  baseTheme: "ag-vivid-dark",
  palette: {
    fills: ["#5C2983", "#0076C5", "#21B372", "#FDDE02", "#F76700", "#D30018"],
    strokes: ["#5C2983", "#0076C5", "#21B372", "#FDDE02", "#F76700", "#D30018"],
  },
  overrides: {
    common: {
      background: { fill: "#0F172B" },
    },
  },
};

export const ItemsComparisonChart = ({ data }) => {
  if (!data) return <div>Loading...</div>;
  const options = {
    theme: pantheonTheme,
    series: data.map((movie) => ({
      type: "radar-area",
      name: movie?.name || 'Unknown',
      data: movie?.scores || [],
      angleKey: "platform",
      radiusKey: "score",
      radiusName: movie?.name,
      strokeWidth: 2,
      fillOpacity: 0.1,
    })),

    axes: [
      {
        type: "angle-category",
        shape: "circle",
        gridLine: { enabled: true },
        line: { enabled: true, color: "#36454F" },
      },
      {
        type: "radius-number",
        shape: "circle",
        label: { enabled: false },
        gridLine: { enabled: true },
        line: { enabled: false },
      },
    ],
  };

  return <AgChartsReact options={options} />;
};
