import { useQuery } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { QUERY_KEYS } from "../constants";
import { getGenericPreference } from "../requests";

export const useGetGenericPreference = () => {
  const { user } = useAuthContext();
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_GENERAL_PREFERENCE,
    () => getGenericPreference(user),
  );

  return {
    data,
    isLoading,
    error,
  };
};
