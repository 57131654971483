import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import renderTrend from "../../../../utils/trendIcon";

export const useGridConfig = () => {
  const currentYear = new Date().getFullYear();
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "vertical_rank_7days",
        headerName: "Rank ",
        cellDataType: "number",
        maxWidth: 80,
        pinned: "left",
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        cellRenderer: (params) => {
          const ipId = params.data.ip_id;
          const destination = ipId ? `/item/${ipId}` : '#';
          return ipId ? (
            <Link variant="primary" href={destination}>
              {params.value}
            </Link>
          ) : (
            <span>{params.value}</span>
          );
        },
      },
      {
        headerName: "US Rank",
        marryChildren: true,
        headerClass: "global-ranking-column",
        children: [
          {
            field: "us_rank_7days",
            headerName: "Weekly",
            minWidth: 100,
            cellDataType: "number",
            headerClass: "global-ranking-column",
            cellStyle: { 'backgroundColor': '#33CC3344', 'textAlign': 'center' },
          },
          {
            field: "us_rank_current",
            headerName: currentYear,
            minWidth: 100,
            cellDataType: "number",
            headerClass: "global-ranking-column",
            cellStyle: { 'backgroundColor': '#33CC3344', 'textAlign': 'center' },
          },
          {
            field: "us_rank_lifetime",
            headerName: "All-time",
            minWidth: 100,
            headerClass: "global-ranking-column",
            cellStyle: { 'backgroundColor': '#33CC3344', 'textAlign': 'center' },
            cellDataType: "number",
          }],
      },
      {
        headerName: `Vertical Rank`,
        marryChildren: true,
        headerClass: "global-platform-column",
        children: [
          {
            field: "vertical_rank_7days",
            headerName: "Weekly",
            minWidth: 100,
            cellDataType: "number",
            cellStyle: { 'backgroundColor': '#338fcc44', 'textAlign': 'center' },
            headerClass: "global-platform-column",
          },
          {
            field: "vertical_rank_current",
            headerName: currentYear,
            minWidth: 100,
            cellDataType: "number",
            cellStyle: { 'backgroundColor': '#338fcc44', 'textAlign': 'center' },
            headerClass: "global-platform-column",
          },
          {
            field: "vertical_rank_lifetime",
            headerName: "All-time",
            minWidth: 100,
            cellStyle: { 'backgroundColor': '#338fcc44', 'textAlign': 'center' },
            headerClass: "global-platform-column",
            cellDataType: "number",
          }],
      },
      {
        headerName: `Worlwide Rank`,
        marryChildren: true,
        headerClass: "global-weighted-column",
        children: [
          {
            field: "rank_7days",
            headerName: "Weekly",
            minWidth: 100,
            cellDataType: "number",
            cellStyle: { 'backgroundColor': '#cc333344', 'textAlign': 'center' },
            headerClass: "global-weighted-column",
                    },
          {
            field: "rank_current",
            headerName: currentYear,
            minWidth: 100,
            cellDataType: "number",
            cellStyle: { 'backgroundColor': '#cc333344', 'textAlign': 'center' },
            headerClass: "global-weighted-column",
          },
          {
            field: "rank_lifetime",
            headerName: "All-time",
            minWidth: 100,
            cellStyle: { 'backgroundColor': '#cc333344', 'textAlign': 'center' },
            headerClass: "global-weighted-column",
            cellDataType: "number",
          }],
      },
    ];
    return allColumns;
  }, []);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  const gridOptions = {
    getContextMenuItems: (params) => {
      if (params.column.colId === "ip") {
        return null;
      }
      return params.defaultItems;
    },
  };

  return {
    defaultColDef,
    columnDefs,
    onGridReady,
    gridOptions,
  };
};
