import React, { useEffect } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../ag-styles.css";

import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import {
  granularityItems,
} from "../../components/forms/GranularitySelect";
import moment from "moment";
import AppLayout from "@cloudscape-design/components/app-layout";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { navItems } from "../../layouts/common/menu/side-menu";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import {
  Button,
  Container,
  ContentLayout, Spinner,
  Header,
  Toggle,
  Select,
  SpaceBetween
} from "@cloudscape-design/components";
import { getTiktokRawData } from "./redux/actions/tiktok-raw-actions";
import createFlashMessage from "../../utils/createFlashMessage";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../common/redux/flash-action";
import CustomFlashBar from "../common/CustomFlashBar";

export const TiktokRawDataPage = () => {
  const columnHoverHighlight = true;
  const settings = useSelector((state) => state.settings);
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const tiktokRawData = useSelector((state) => state.tiktokRawData);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState([]);
  const [data, setData] = useState([]);
  const [granularity, setGranularity] = useState({ label: "Day", value: "1d" });
  const [dateRange, setDateRange] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const handleOnChange = (event) => {
    setStartIndex(event.startIndex);
    setEndIndex(event.endIndex);
  };

  // useEffect(() => {
  //   getTiktokRawData({
  //     lte: "now/d",
  //     gte: "now-15d/d",
  //     interval: granularity.value,
  //   });
  // }, []);
  useEffect(() => {
    setIsLoading(true);
    getTiktokRawData({
      lte: dateRange ? moment(dateRange.endDate).valueOf() / 1000 : "now/d",
      gte: dateRange
        ? moment(dateRange.startDate).valueOf() / 1000
        : "now-15d/d",
      interval: granularity.value,
    }).finally(() => setIsLoading(false));
  }, [granularity, dateRange]);
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (tiktokRawData.completed && tiktokRawData.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: tiktokRawData.message,
        })
      );
    }
  }, [tiktokRawData]);

  function CommaSeparatedNumberCellRenderer(props) {
    if (props.value) {
      return <div>{props.value.toLocaleString()}</div>;
    }
    return null;
  }

  function DecimalPlacesCellRenderer(params) {
    if (params.value) {
      return parseFloat(params.value).toFixed(2);
    }
    return null;
  }

  const groupDisplayType = "groupRows";

  const customChartThemes = {
    myCustomTheme: {
      baseTheme: "ag-default-dark",
      palette: {
        fills: [
          "#2196F3",
          "#4CAF50",
          "#F44336",
          "#9C27B0",
          "#3F51B5",
          "#FF9800",
          "#009688",
          "#8BC34A",
          "#CDDC39",
          "#00BCD4",
        ],
        strokes: ["#874349", "#718661", "#a48f5f"],
      },
      overrides: {
        common: {
          background: { fill: "#0F172B" },
          title: { fontSize: 22, fontFamily: "Arial, sans-serif" },
          axes: {
            number: { tick: { width: 0.5} },
            category: { tick: { width: 0.5 } },
          },
        },
      },
    },
  };
  const gridRef = useRef();

  useEffect(() => {
    return () => {
      if (
        localStorage.getItem("platforms_content_consumption_tiktok_raw_data")
      ) {
        localStorage.removeItem(
          "platforms_content_consumption_tiktok_raw_data"
        );
      }
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (
        localStorage.getItem("platforms_content_consumption_tiktok_raw_data")
      ) {
        let rawData = JSON.parse(
          localStorage.getItem("platforms_content_consumption_tiktok_raw_data")
        );
        gridRef.current.api.setFilterModel(rawData);
      }
      const handleFilterChanged = () => {
        localStorage.setItem(
          "platforms_content_consumption_tiktok_raw_data",
          JSON.stringify(gridRef.current.api.getFilterModel())
        );
      };

      gridRef.current.api.addEventListener(
        "filterChanged",
        handleFilterChanged
      );

      return () => {
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.removeEventListener(
            "filterChanged",
            handleFilterChanged
          );
        }
      };
    }
  }, [gridRef.current]);

  const clearFilters = useCallback(() => {
    if (localStorage.getItem("platforms_content_consumption_tiktok_raw_data")) {
      localStorage.removeItem("platforms_content_consumption_tiktok_raw_data");
    }
    gridRef.current.api.setFilterModel(null);
  }, []);
  const chartThemes = ["myCustomTheme"];
  const [columnDefs, setColumnDefs] = useState();

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      filter: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 400,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 225,
          maxWidth: 400,
          width: 225,
        },
      ],
      defaultToolPanel: "",
    };
  }, []);
  const getContextMenuItems = useCallback((params) => {
    const result = [
      "autoSizeAll",
      "resetColumns",
      "separator",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "separator",
      "chartRange",
      "separator",
      "export",
    ];
    return result;
  }, []);

  const groupDefaultExpanded = 1;

  function DecimalPlacesCellRenderer(params) {
    if (params.value) {
      return parseFloat(params.value).toFixed(2);
    }
    return null;
  }

  useEffect(() => {
    if (
      tiktokRawData.data &&
      tiktokRawData.data.data &&
      tiktokRawData.data.data.length > 0
    ) {
      const sampleData = tiktokRawData.data.data[0];
      const keys = Object.keys(sampleData).filter(
        (key) => key !== "ip" && key !== "ip_id" && key !== "vertical"
      );
      function getScoreValueGetter(key) {
        return (params) => {
          const fields = ["num_views", "num_posts"];
          const weights = {
            num_views: 0.000000002,
            num_posts: 0.0002,
          };

          const total = fields.reduce((sum, field) => {
            // Check if the field key exists and is not null
            const fieldValue =
              params.data[key] && params.data[key][field]
                ? params.data[key][field]
                : 0;
            return sum + fieldValue * weights[field];
          }, 0);

          return total;
        };
      }

      setColumnDefs([
        { headerName: "IP Name", field: "ip", pinned: "left" },
        {
          headerName: "Vertical",
          field: "vertical",
          pinned: "left",
          filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
          // rowGroup: true,
          // enableRowGroup: true,
          // hide: true,
        },
        ...keys.map((key) => {
          return {
            headerName: key,
            marryChildren: true,
            children: [
              {
                headerName: "Score",
                field: `${key}.total`,
                // columnGroupShow: "open",
                filter: "agNumberColumnFilter",
                cellStyle: { textAlign: "right" },
                cellRenderer: "CommaSeparatedNumberCellRenderer",
                valueGetter: getScoreValueGetter(key),
                valueFormatter: (params) => parseFloat(params.value).toFixed(2),
              },
              {
                headerName: "Views",
                field: `${key}.num_views`,
                columnGroupShow: "open",
                filter: "agNumberColumnFilter",
                cellStyle: { textAlign: "right" },
                cellRenderer: "CommaSeparatedNumberCellRenderer",
              },
              {
                headerName: "Posts",
                field: `${key}.num_posts`,
                columnGroupShow: "open",
                filter: "agNumberColumnFilter",
                cellStyle: { textAlign: "right" },
                cellRenderer: "CommaSeparatedNumberCellRenderer",
              },
            ],
          };
        }),
      ]);
    }
  }, [tiktokRawData]);

  const Content = (
    <>
      {isLoading && (
        <div className="flex flex-row justify-center items-center">
          <Spinner size="large" />
        </div>
      )}
      <div
        style={{
          height: "86vh",
          paddingRight: 20,
          paddingLeft: 20,
          paddingTop: 10,
        }}
        className={"ag-theme-quartz-dark"}
      >
        {tiktokRawData.data &&
          tiktokRawData.data.data &&
          tiktokRawData.data.data.length > 0 && (
            <AgGridReact
              // domLayout="autoHeight"
              rowData={tiktokRawData.data.data}
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              enableRangeSelection={true}
              allowContextMenuWithControlKey={true}
              getContextMenuItems={getContextMenuItems}
              groupDisplayType={groupDisplayType}
              rowHeight={35}
              animateRows={true}
              // rowGroupPanelShow={"always"}
              tooltipShowDelay={500}
              groupRowsSticky={true}
              groupDefaultExpanded={groupDefaultExpanded}
              customChartThemes={customChartThemes}
              chartThemes={chartThemes}
              components={{ CommaSeparatedNumberCellRenderer }}
              columnHoverHighlight={columnHoverHighlight}
            ></AgGridReact>
          )}
      </div>
    </>
  );
  const breadcrumbs = [
    {
      text: "Platforms",
      // href: "",
    },
    {
      text: "TikTok",
      // href: "/platforms/youtubetrends",
    },
    {
      text: "Raw Data",
      href: "/platforms/consumer-products/tiktok/raw-data",
    },
  ];

  return (
    <>
      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        content={
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <ContentLayout
              header={
                <>
                  <Header
                    variant="h3"
                    actions={
                      <>
                        <SpaceBetween direction="horizontal" size="xs">
                          <Button onClick={clearFilters}>
                            Clear All Filters
                          </Button>
                          <Select
                            selectedOption={granularity}
                            onChange={({ detail }) =>
                              setGranularity(detail.selectedOption)
                            }
                            selectedAriaLabel="Selected"
                            options={granularityItems}
                          />
                          <RangeDateSelector
                            defaults={
                              dateRange
                                ? dateRange?.type === "relative"
                                  ? {
                                      ...dateRange,
                                      startDate: moment()
                                        .subtract(15, "days")
                                        .startOf("day")
                                        .format("YYYY-MM-DD"),
                                      endDate: moment(dateRange.endDate),
                                    }
                                  : dateRange
                                : {
                                    type: "absolute",
                                    startDate: moment()
                                      .subtract(15, "days")
                                      .startOf("day")
                                      .format("YYYY-MM-DD"),
                                    endDate: moment().format("YYYY-MM-DD"),
                                  }
                            }
                            onChange={(e) => {
                              if (e.type === "relative") {
                                setDateRange({
                                  ...e,
                                  startDate: moment(e.startDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                  endDate: moment(e.endDate).format(
                                    "YYYY-MM-DD"
                                  ),
                                });
                              } else {
                                setDateRange(e);
                              }
                            }}
                          />
                        </SpaceBetween>
                      </>
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="px-5">Raw Data</span>
                      <Toggle
                        checked={isFullWidth}
                        onChange={() => setIsFullWidth(!isFullWidth)}
                      >
                        Full width
                      </Toggle>
                    </div>
                  </Header>

                  <div className="py-5">
                    <CustomFlashBar />
                  </div>
                </>
              }
            >
              <Container fitHeight>{Content}</Container>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
};
