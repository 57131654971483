import React, { useMemo } from "react";
import { PropertyFilter } from "@cloudscape-design/components";
import { DateTimeForm, formatDateTime } from "./DateFormTime";

const propertyLabel = new Map([
  ["name", "Name"],
  ["status", "Status"],
  ["release_date", "Release Date"],
  ["tracked", "Tracked"]
]);

export default function Filters({ query, handleChange, data, preferences }) {
  const filteringOptions = useMemo(() => {
    const queryVals = query?.tokens?.map((f) => f.value);

    return [
      ...[...new Set(data.map((d) => d.status))]
        .filter((e) => e && !queryVals.includes(e))
        .sort()
        .map((f) => ({ propertyKey: "status", value: f })),
      { propertyKey: "tracked", value: "true" },
      { propertyKey: "tracked", value: "false" },
    ];
  }, [data, query]);


  const filteringProperties = useMemo(() => {
    return [
      ...preferences.visibleContent
        .map((p) => {
          const prop = propertyLabel.get(p);
          if (prop == null) return null;

          if (p === "tracked") {
            return {
              key: p,
              propertyLabel: prop,
              groupValuesLabel: `${prop} values`,
              operators: ["=", "!="],
              values: ["true", "false"],
            };
          }

          if (p === "release_date") {
            return {
              key: p,
              propertyLabel: prop,
              groupValuesLabel: `${prop} values`,
              defaultOperator: ">",
              operators: ["<", "<=", ">", ">="].map((operator) => ({
                operator,
                form: DateTimeForm,
                format: formatDateTime,
                match: "datetime",
              })),
            };
          }

          return {
            key: p,
            propertyLabel: prop,
            groupValuesLabel: `${prop} values`,
            operators: p === "name" ? ["=", ":"] : ["=", "!="],
          };
        })
        .filter((p) => p),
    ];
  }, [preferences.visibleContent]);

  return (
    <PropertyFilter
      onChange={({ detail }) => {
        handleChange?.(detail);
      }}
      query={query}
      i18nStrings={{
        filteringAriaLabel: "Find resources",
        filteringPlaceholder: "Find resources",
        groupValuesText: "Values",
        valueText: "Value",
        groupPropertiesText: "Properties",
        propertyText: "Property",
        operatorsText: "Operators",
        operatorText: "Operator",
        operationAndText: "and",
        operationOrText: "or",
        allPropertiesLabel: "All properties",
        cancelActionText: "Cancel",
        applyActionText: "Apply",
        tokenLimitShowMore: "Show more",
        tokenLimitShowFewer: "Show fewer",
        clearFiltersText: "Clear filters",
        removeTokenButtonAriaLabel: (token) =>
          `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: "${text}"`,
      }}
      expandToViewport
      filteringOptions={filteringOptions}
      filteringProperties={filteringProperties}
    />
  );
}
