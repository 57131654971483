import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Grid,
  Link,
  Select,
  SpaceBetween,
  Spinner
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import shortenInteger from "../../../../../utils/shortenInteger";
import { getChannelVideos } from "../../api/request";
import { getSentimentCategory, getSentimentColor } from "./utils";

const VideoList = ({ channelId }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(0);
  const hasMore = useRef(true);
  const loader = useRef(null);
  const [counter, setCounter] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [sortOrder, setSortOrder] = useState({ label: "desc", value: "desc" });
  const [selectedField, setSelectedField] = useState({
    label: "Published On",
    value: "publishedAt",
  });
  const [sort, setSort] = useState({ publishedAt: "desc" });

  const handleObserver = useCallback(
    (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore.current && !loading) {
        setFetchTrigger((prev) => prev + 1);
      }
    },
    [loading],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    });

    const currentLoader = loader.current;

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    const fetchVideos = async () => {
      if (loading || !hasMore.current) {
        return;
      }
      setLoading(true);
      try {
        const response = await getChannelVideos({ channelId, from, sort });
        setVideos((prev) => [...prev, ...response.data.data]);
        setCounter(response.data.count);
        if (response.data.data.length < 4) {
          hasMore.current = false;
        } else {
          setFrom((prevFrom) => prevFrom + response.data.data.length);
        }
      } catch (error) {
        console.error("Failed to fetch videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [channelId, fetchTrigger, sort]);

  const gridDefinition = Array.from({ length: counter }, () => ({
    colspan: { default: 12, xs: 6, l: 4 },
  }));

  return (
    // if videos are not available, then dont display the ExpandalbeSection
    (videos.length > 0 && (<ExpandableSection
      defaultExpanded
      variant="container"
      headerText="Videos"
      headerCounter={`(${counter})`}
      headerActions={
        <SpaceBetween direction="horizontal" size="xs">
          <Select
            selectedOption={selectedField}
            onChange={({ detail }) =>
              setSelectedField(detail.selectedOption)
            }
            expandToViewport
            controlId="select-field"
            placeholder="Field"
            options={[
              { label: "Published On", value: "publishedAt" },
              { label: "Views", value: "views" },
              { label: "Likes", value: "likes" },
              { label: "Comments", value: "comments" },
              { label: "Score", value: "sentimentScore" },
            ]}
          />
          <Select
            selectedOption={sortOrder}
            expandToViewport
            onChange={({ detail }) => setSortOrder(detail.selectedOption)}
            controlId="select-order"
            placeholder="Sort order"
            options={[
              { label: "asc", value: "asc" },
              { label: "desc", value: "desc" },
            ]}
          />
          <Button
            variant="primary"
            onClick={() => {
              hasMore.current = true;
              setFrom(0);
              setVideos([]);
              setSort({ [selectedField.value]: sortOrder.value });
            }}
          >
            Apply
          </Button>
        </SpaceBetween>
      }
    >
      <Grid gridDefinition={gridDefinition}>
        {videos.map((video) => (
          <Container
            fitHeight
            key={video.videoId}
            media={{
              content: (
                <iframe
                  src={`https://www.youtube.com/embed/${video.videoId}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ),
              height: 300,
            }}
            footer={
              <ColumnLayout
                minColumnWidth="100"
                columns={4}
                variant="text-grid"
              >
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Views
                  </Box>
                  <Box textAlign="center">{shortenInteger(video?.views)}</Box>
                </div>
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Likes
                  </Box>
                  <Box textAlign="center">{shortenInteger(video?.likes)}</Box>
                </div>
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Comments
                  </Box>
                  <Box textAlign="center">
                    {shortenInteger(video?.comments)}
                  </Box>
                </div>
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Score
                  </Box>
                  <Box textAlign="center">
                    {video?.sentimentScore?.toFixed(3)}
                  </Box>
                </div>
              </ColumnLayout>
            }
          >
            {video.sentimentScore !== null && video.sentimentScore !== undefined && (
              <Box
                variant="small"
                color={getSentimentColor(video.sentimentScore)}
              >
                {getSentimentCategory(video.sentimentScore)} Sentiment
              </Box>
            )}
            <Box variant="h2">
              <Link
                fontSize="heading-m"
                href={`/mediacatalog/youtube/videos/${video.videoId}`}
                target="_blank"
              > {video.title}
              </Link>
              {video?.madeForKids ? (
                <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
                  Kids
                </span>
              ) : (
                ""
              )}
            </Box>
            <Box variant="small">
              <Link href={`/item/${video.ip_id}`}>
                {video.ip}
              </Link>
              <span className="font-bold">
                {video.ip_id?.startsWith('film') && ' | Film'}
                {video.ip_id?.startsWith('series') && ' | TV'}
                {video.ip_id?.startsWith('game') && ' | Game'}
              </span> |
              Published On:
              {new Date(video.publishedAt * 1000).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </Box>

          </Container>
        ))}
      </Grid>
      {loading && (
        <div className="flex justify-center">
          <Spinner size="large" />
        </div>
      )}
      <div ref={loader} />
    </ExpandableSection>))
  );
};

export default VideoList;
