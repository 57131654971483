import { AgChartsReact } from "ag-charts-react";
import React from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import shortenInteger from "../../../../utils/shortenInteger";
import { useBODataProcessing } from "../api/hooks/useBODataProcessing";

const tooltip = {
    renderer: ({ datum, angleKey, calloutLabelKey = 'distributor' }) => ({
        title: `${datum[calloutLabelKey]}`,
        content: `${datum[angleKey].toFixed(2)}%`,
    }),
};

const pantheonTheme = {
    baseTheme: "ag-polychroma-dark",
    overrides: {
        common: {
            background: { fill: "#0F1B2A" },
        },
    },
};

const numFormatter = new Intl.NumberFormat('en-US');

export const BoDomesticShareChart = () => {
    const { isLoading, processedData, error } = useBODataProcessing();
    const options = processedData
        ? {
            theme: pantheonTheme,
            legend: { enabled: true },
            data: processedData,
            animation: { enabled: true },
            zoom: {
                enableAxisDragging: false,
                enablePanning: false,
                enableScrolling: false,
                enableSelecting: true,
            },
            title: {
                text: 'Domestic Box Office Share',
            },
            series: [
                {
                    type: 'pie',
                    calloutLabelKey: 'distributor',
                    sectorLabelKey: 'domesticMarketShare',
                    angleKey: 'domesticMarketShare',
                    calloutLabel: {
                        offset: 10,
                    },
                    sectorLabel: {
                        formatter: ({ datum, sectorLabelKey = 'domesticMarketShare' }) => {
                            return `${numFormatter.format(datum[sectorLabelKey])}%`;
                        },
                    },
                    tooltip
                },
            ],
            legend: {
                enabled: false,
            }
        }
        : null;

    if (isLoading) {
        return (
            <div className="flex flex-row justify-center h-160">
                <CustomLoadingOverlay />
            </div>
        );
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    if (!options) {
        return <div>No data to display</div>;
    }

    return (
        <div className="h-160">
            <AgChartsReact options={options} />
        </div>
    );
};
