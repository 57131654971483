import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  SideNavigation,
} from "@cloudscape-design/components";
import React from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../layouts/common/menu/side-menu";
import IndexPageGrid from "../../components/IndexPageGrid";

function HomePageCards() {
  const items = [
    {
      link: "/dashboards/wizard",
      image: "/images/personalDash.png",
      name: "Personal Dashboard",
      path: "Viewer's Pulse",
      description:
        "A customizable dashboard that can be personalized using available widgets",
    },
    {
      link: "/dashboards/jwstreaming",
      image: "/images/jwstreaming.png",
      name: "OTT Streaming Charts",
      path: "Viewer's Pulse",
      description:
        "Streaming metrics sourced from JustWatch",
    },
    {
      link: "/dashboards/imdbtrends",
      image: "/images/IMDbUserEngagement.png",
      name: "IMDb User Engagement",
      path: "Viewer's Pulse",
      description:
        "IMDb user engagement dashboard based on votes and ratings",
    },
    {
      link: "/dashboards/rttrends", 
      image: "/images/RTUserEngagement.png",
      name: "Rotten Tomatoes User Engagement",
      path: "Viewer's Pulse",
      description:
        "Rotten Tomatoes user engagement based on user activity",
    },
    {
      link: "/dashboards/wikipediatrends",
      image: "/images/wikipediaUserSearch.png",
      name: "Wikipedia User Search",
      path: "Viewer's Pulse",
      description:
        "Wikipedia user search is based on the pageviews of Wikipedia articles",
    },
    {
      link: "/dashboards/twitchtrends",
      image: "/images/twitch.png",
      name: "Twitch Stats",
      path: "Viewer's Pulse",
      description:
        "Twitch stats based on viewership and broadcasting",
    },
    {
      link: "/dashboards/youtubetrends",
      image: "/images/youtube.png",
      name: "YouTube Stats",
      path: "Viewer's Pulse",
      description:
        "YouTube stats based on viewership and engagement",
    },
   {
      link: "/dashboards/piracy",
      image: "/images/p2p-downloads.png",
      name: "Piracy Rankings",
      path: "Viewer's Pulse",
      description:
        "Piracy rankings based on P2P downloads",
   }
  ];
  const gridDefinition = items.map(() => ({ colspan: { xxs: 6, xs: 6, s:4, m: 3, xl: 2 } }));

  return (
    <IndexPageGrid items={items} gridDefinition={gridDefinition} />
  );
}
function PantheonHomePage() {
  const breadcrumbs = [{ text: "Dashboard" }, { text: "Viewer's Pulse" }];
  return (
    <>
      <Helmet>
        <title>Viewer's Pulse</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="cards"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            disableOverlap
            header={
              <Header
                variant="h2"
                description="A collection of dashboards that provide insights into viewer engagement and platform performance."
              >
                Viewer's Pulse
              </Header>
            }
          >
            <HomePageCards />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default PantheonHomePage;
