import { ButtonDropdown } from "@cloudscape-design/components";
import React, { FC, useEffect, useState } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { mapToPlatformWeightage } from "../modules/home/pantheon-ranking-raw-data/domain/mapToPlatformWeightage";
import { PLATFORMS, UserTypes } from "../services/platformPreference/constants";
import { useGetPlatformWeightages } from "../services/platformPreference/hooks/useGetPlatformWeightages";
import { PlatformWeightageItems } from "../services/platformPreference/types";

const items = [
  {
    id: UserTypes.PERSONAL,
    text: "Personal",
  },
  {
    id: UserTypes.ORGANIZATION,
    text: "Organization",
  },
];

type PlatformWeightageDropdownProps = {
  platform: string;
  data: PlatformWeightageItems;
  updateFormValues: (weightage) => void;
  setSelectedFileName: (name: string) => void;
};

export const PlatformWeightageDropdown: FC<PlatformWeightageDropdownProps> = ({
  platform,
  data,
  updateFormValues,
  setSelectedFileName,
}) => {
  const { user } = useAuthContext();
  const [weight, setWeight] = useState(null);

  const { data: platformWeightaqe, isLoading } =
    useGetPlatformWeightages(weight);

  useEffect(() => {
    if (!isLoading && platformWeightaqe) {
      updateFormValues(platformWeightaqe);
    }
  }, [isLoading, platformWeightaqe, updateFormValues, weight]);

  return (
    <ButtonDropdown
      onItemClick={(e) => {
        const itemData = data.filter(
          (d) =>
            d.type === e.detail.id.split("@")[1] &&
            d.filename === e.detail.id.split("@")[0] + ".json",
        );

        if (itemData && itemData.length > 0) {
          setWeight(mapToPlatformWeightage({ ...itemData[0], user, platform }));
          setSelectedFileName(e.detail.id.split("@")[0]);
        }
      }}
      items={items.map(({ id, text }) => ({
        id,
        text,
        items: data
          .filter((d) => d.platform === platform && d.type === id)
          .map((d) => ({
            id: d.filename.replace(".json", "") + "@" + d.type,
            text: d.filename.replace(".json", ""),
          })),
      }))}
      expandableGroups
      expandToViewport={true}
    >
      Load
    </ButtonDropdown>
  );
};
