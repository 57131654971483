import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthGuard from "../auth/AuthGuard";
import { ErrorLayout } from "../components/ErrorLayout";
import CommonLayout from "../layouts/common/CommonLayout";
import OrganizationsListPage from "../modules/account-manaement/organizations";
import PermissionsListPage from "../modules/account-manaement/permissions";
import RoleListPage from "../modules/account-manaement/roles";
import UserListPage from "../modules/account-manaement/users";
import Ao3RawDataPage from "../modules/ao3/ao3-raw-page";
import AuditHistory from "../modules/audit-history";
import { BoxOfficeHomePage } from "../modules/box-office";
import { BoByStudioPage } from "../modules/box-office/bo-by-studio";
import { BoxOfficeGrossTimeseriesPage } from "../modules/box-office/grosstimeseries";
import { BoxOfficeRankingPage } from "../modules/box-office/ranking";
import IssueList from "../modules/bug-reporting";
import ViewIssuePage from "../modules/bug-reporting/view";
import { AmazonVendorCentralPage } from "../modules/consumer-products/amazon-vendor-central";
import IdDataMappingIndex from "../modules/data-mapping/id-mapping";
import ExploreHomePage from "../modules/explore";
import ExploreCompanies from "../modules/explore/companies";
import ExploreFranchise from "../modules/explore/franchise";
import ExploreGames from "../modules/explore/games";
import ExploreMovies from "../modules/explore/movies";
import ExploreTelevision from "../modules/explore/television";
import { FandomRawDataPage } from "../modules/fandom/fandom-raw-page";
import { ForgotPasswordPage } from "../modules/forgot-password";
import GRHomePage from "../modules/global-ranking";
import { GRPantheonRankingIndex } from "../modules/global-ranking/pantheon-ranking";
import GRRawDataIndexPage from "../modules/global-ranking/raw-data";
import GoogleRealtimeSearch from "../modules/google-realtime";
import GoogleRealtimeSearchReport from "../modules/google-realtime/report";
import { GoogleTrendsRawDataPage } from "../modules/google-trends/google-trends-raw-data-page";
import GooglePage from "../modules/googletrendsv2";
import PantheonHomePage from "../modules/home";
import IMDBTrendsChartsIndexPage from "../modules/home/imdb-trend-charts";
import JWStreamingChartsIndexPage from "../modules/home/jwstreamingcharts";
import { PantheonRanking } from "../modules/home/pantheon-ranking";
import { RankingRawDataPage } from "../modules/home/pantheon-ranking-raw-data/PantheonRankingRawData";
import PiracyChartsIndexPage from "../modules/home/piracy-stats";
import RTTrendsChartsIndexPage from "../modules/home/rt-trend-charts";
import TwitchTrendsChartsIndexPage from "../modules/home/twitch-stats";
import WikipediaTrendsChartsIndexPage from "../modules/home/wiki-pageviews";
import DashboardWizard from "../modules/home/wizard/DashboardWizard";
import YoutubeTrendsChartsIndexPage from "../modules/home/youtube-stats";
import { IMDbDashboardPage } from "../modules/imdb";
import { InstagramRawDataPage } from "../modules/instagram/instagram-raw-page";
import IpManagement from "../modules/ip_management";
import PantheonManagement from "../modules/ip_management/pantheonList";
import Item from "../modules/item/item";
import ItemSearch from "../modules/item/search/index";
import { LoginPage } from "../modules/login";
import PlatformJobState from "../modules/monitoring/job-states";
import PeacockDashboardHomePage from "../modules/peacock-dashboard";
import MoviesNonOTTIndex from "../modules/peacock-dashboard/non-ott-titles/movies";
import SeriesNonOTTIndex from "../modules/peacock-dashboard/non-ott-titles/series";
import TarSeriesWikiViewGenrePlatformMarketsharePage from "../modules/peacock-dashboard/series-genre-marketshare";
import TarSeriesWikiViewGenrePlatformPage from "../modules/peacock-dashboard/series-genre-platform";
import { PiracyGenericSummaryPage } from "../modules/piracy/generic/piracy-generic-summary-page";
import { RedditRawDataPage } from "../modules/reddit/reddit-raw-page";
import { ResetPasswordPage } from "../modules/reset-password";
import { OptionsEditPage } from "../modules/settings/option-edit-page";
import { RankingPage } from "../modules/settings/ranking-page";
import CreateRankPage from "../modules/settings/wizard/create-rank-page";
import TitleAvailibility from "../modules/tar/movie";
import TarSeriesWikiViewGenrePage from "../modules/tar/series/wiki";
import TitleSeriesAvailibility from "../modules/tar/television";
import { TiktokRawDataPage } from "../modules/tiktok/tiktok-raw-page";
import TopicModelingPantheon from "../modules/topic-modeling/pantheon";
import TopicModelingPantheonTopicPage from "../modules/topic-modeling/pantheon/topic-page";
import TopicModelingTrueCrimeV2 from "../modules/topic-modeling/true-crime-v2";
import TopicModelingTrueCrimeNamePageV2 from "../modules/topic-modeling/true-crime-v2/name-page";
import TopicModelingTrueCrimeTopicPageV2 from "../modules/topic-modeling/true-crime-v2/topic-page";
import TrackedHomePage from "../modules/tracked-titles";
import TwitterQueryTool from "../modules/twitter/query-tool";
import TwitterQueryToolResults from "../modules/twitter/query-tool/results";
import { TwitterRawDataPage } from "../modules/twitter/twitter-raw-page";
import { WattpadRawDataPage } from "../modules/wattpad/wattpad-raw-page";
import WikipediaPage from "../modules/wikipedia";
import { RawDataPage } from "../modules/wikipedia/raw-data";
import YoutubeChannelItemPage from "../modules/youtube/channelPage";
import { YoutubeChannels } from "../modules/youtube/channelsList";
import { YoutubeHomePage } from "../modules/youtube/home";
import YoutubeVideoItemPage from "../modules/youtube/videoPages";
import { YoutubeVideos } from "../modules/youtube/videos";

const navigation = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorLayout />,
    element: (
      <AuthGuard>
        <CommonLayout title="Dashboard" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <PantheonHomePage />,
      },
      {
        path: "/dashboards/trackedtitles",
        element: <TrackedHomePage />,
      },
      {
        path: "/dashboards/globalranking",
        element: <GRHomePage />,
      },
      {
        path: "/dashboards/globalranking/raw-data",
        element: <GRRawDataIndexPage />,
      },
      {
        path: "/dashboards/globalranking/pantheonranking",
        element: <GRPantheonRankingIndex />,
      },
      {
        path: "/dashboards/wizard",
        element: <DashboardWizard />,
      },
      {
        path: "/dashboards/pantheonranking",
        element: <PantheonRanking />,
      },
      {
        path: "/dashboards/raw-data",

        element: <RankingRawDataPage />,
      },
      {
        path: "/dashboards/jwstreaming",
        element: <JWStreamingChartsIndexPage />,
      },
      {
        path: "/dashboards/imdbtrends",
        element: <IMDBTrendsChartsIndexPage />,
      },
      {
        path: "/dashboards/globalranking",
        element: <GRRawDataIndexPage />,
      },
      {
        path: "/dashboards/rttrends",
        element: <RTTrendsChartsIndexPage />,
      },
      {
        path: "/dashboards/wikipediatrends",
        element: <WikipediaTrendsChartsIndexPage />,
      },
      {
        path: "/dashboards/twitchtrends",
        element: <TwitchTrendsChartsIndexPage />,
      },
      {
        path: "/dashboards/youtubetrends",
        element: <YoutubeTrendsChartsIndexPage />,
      },
      {
        path: "/dashboards/piracy",
        element: <PiracyChartsIndexPage />,
      },
    ],
  },
  {
    path: "/settings/nbcu-weightage/ranking",
    element: (
      <AuthGuard>
        <CommonLayout title="Dashboard" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/settings/nbcu-weightage/ranking",
        element: <RankingPage />,
      },
      {
        path: "/settings/nbcu-weightage/ranking/create",
        element: <CreateRankPage />,
      },
      {
        path: "/settings/nbcu-weightage/ranking/:id",
        element: <OptionsEditPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },

  {
    path: "users",
    element: (
      <AuthGuard>
        <CommonLayout title="user management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/users/",
        element: <UserListPage />,
      },
    ],
  },
  {
    path: "permissions",
    element: (
      <AuthGuard>
        <CommonLayout title="Permissions management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/permissions/",
        element: <PermissionsListPage />,
      },
    ],
  },
  {
    path: "roles",
    element: (
      <AuthGuard>
        <CommonLayout title="role management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/roles/",
        element: <RoleListPage />,
      },
    ],
  },
  {
    path: "teams",
    element: (
      <AuthGuard>
        <CommonLayout title="Teams management" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/teams/",
        element: <OrganizationsListPage />,
      },
    ],
  },
  {
    path: "platforms",
    element: (
      <AuthGuard>
        <CommonLayout title="Wikipedia" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/platforms/imdb",
        element: <IMDbDashboardPage />,
      },
      {
        path: "/platforms/wikipedia",
        element: <WikipediaPage />,
      },
      {
        path: "/platforms/wikipedia/summary",
        element: <WikipediaPage />,
      },
      {
        path: "/platforms/wikipedia/raw-data",
        element: <RawDataPage />,
      },
      {
        path: "/platforms/googletrends",
        element: <GooglePage />,
      },
      {
        path: "/platforms/google/raw-data",
        element: <GoogleTrendsRawDataPage />,
      },
      {
        path: "/platforms/googletrends/realtime",
        element: <GoogleRealtimeSearch />,
      },
      {
        path: "/platforms/googletrends/realtime/searches/:id",
        element: <GoogleRealtimeSearchReport />,
      },
      {
        path: "/platforms/fan-sites/fandom/raw-data",
        element: <FandomRawDataPage />,
      },
      {
        path: "/platforms/fan-sites/ao3/raw-data",
        element: <Ao3RawDataPage />,
      },
      {
        path: "/platforms/fan-sites/reddit/raw-data",
        element: <RedditRawDataPage />,
      },
      {
        path: "/platforms/fan-sites/wattpad/raw-data",
        element: <WattpadRawDataPage />,
      },
      {
        path: "/platforms/content-consumption/twitter/raw-data",
        element: <TwitterRawDataPage />,
      },
      {
        path: "/platforms/content-consumption/twitter/query-tool",
        element: <TwitterQueryTool />,
      },
      {
        path: "/platforms/content-consumption/twitter/query-tool/searches/:id",
        element: <TwitterQueryToolResults />,
      },
      {
        path: "/platforms/content-consumption/tiktok/raw-data",
        element: <TiktokRawDataPage />,
      },
      {
        path: "/platforms/content-consumption/instagram/raw-data",
        element: <InstagramRawDataPage />,
      },
      {
        path: "/platforms/piracy/generic/:country/summary",
        element: <PiracyGenericSummaryPage />,
      },
      {
        path: "/platforms/consumer-products/amazon-search",
        element: <AmazonVendorCentralPage />,
      },
    ],
  },
  {
    path: "ipmanagement",
    element: (
      <AuthGuard>
        <CommonLayout title="IP Management" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/ipmanagement/",
        element: <IpManagement />,
      },
      {
        path: "/ipmanagement/pantheon",
        element: <PantheonManagement />,
      },
    ],
  },
  {
    path: "datamapping",
    element: (
      <AuthGuard>
        <CommonLayout title="Data Mapping" />
      </AuthGuard>
    ),

    children: [
      {
        path: "/datamapping/idmanagement",
        element: <IdDataMappingIndex />,
      },
    ],
  },
  {
    path: "platformjobstate",
    element: (
      <AuthGuard>
        <CommonLayout title="Platform Job State" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/platformjobstate/",
        element: <PlatformJobState />,
      },
    ],
  },
  {
    path: "issues",
    element: (
      <AuthGuard>
        <CommonLayout title="Issues" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/issues/",
        element: <IssueList />, // Component to list all issues
      },
      {
        path: "/issues/:id",
        element: <ViewIssuePage />, // Component to list all issues
      },
    ],
  },
  {
    path: "tar",
    element: (
      <AuthGuard>
        <CommonLayout title="Explore" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/tar/movie/titleavailability/",
        element: <TitleAvailibility />,
      },
      {
        path: "/tar/television/titleavailability/",
        element: <TitleSeriesAvailibility />,
      },
      {
        path: "/tar/series/wiki/view/genre",
        element: <TarSeriesWikiViewGenrePage />,
      },
      {
        path: "/tar/series/wiki/view/genre/platform",
        element: <TarSeriesWikiViewGenrePlatformPage />,
      },
      {
        path: "/tar/series/wiki/view/genre/platform/marketshare",
        element: <TarSeriesWikiViewGenrePlatformMarketsharePage />,
      },
    ],
  },
  {
    path: "peacock-dashboard",
    element: (
      <AuthGuard>
        <CommonLayout title="Peacock Dashboard" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/peacock-dashboard/",
        element: <PeacockDashboardHomePage />,
      },
      {
        path: "/peacock-dashboard/series/wiki/genre",
        element: <TarSeriesWikiViewGenrePage />,
      },
      {
        path: "/peacock-dashboard/series/wiki/genre-platform",
        element: <TarSeriesWikiViewGenrePlatformPage />,
      },
      {
        path: "/peacock-dashboard/series/wiki/genre-marketshare",
        element: <TarSeriesWikiViewGenrePlatformMarketsharePage />,
      },
      {
        path: "/peacock-dashboard/non-ott-titles/movies",
        element: <MoviesNonOTTIndex />,
      },
      {
        path: "/peacock-dashboard/non-ott-titles/series",
        element: <SeriesNonOTTIndex />,
      },
    ],
  },
  {
    path: "item",
    element: (
      <AuthGuard>
        <CommonLayout title="Item" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/item/search",
        element: <ItemSearch />,
      },
      {
        path: "/item/:id",
        element: <Item />,
      },
    ],
  },
  {
    path: "explore",
    element: (
      <AuthGuard>
        <CommonLayout title="Explore" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/explore/",
        element: <ExploreHomePage />,
      },
      {
        path: "/explore/movies",
        element: <ExploreMovies />,
      },
      {
        path: "/explore/television",
        element: <ExploreTelevision />,
      },
      {
        path: "/explore/games",
        element: <ExploreGames />,
      },
      {
        path: "/explore/franchise",
        element: <ExploreFranchise />,
      },
      {
        path: "/explore/companies",
        element: <ExploreCompanies />,
      },
    ],
  },
  {
    path: "mediacatalog/youtube",
    element: (
      <AuthGuard>
        <CommonLayout title="YouTube" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/mediacatalog/youtube/",
        element: <YoutubeHomePage />,
      },
      {
        path: "/mediacatalog/youtube/channels",
        element: <YoutubeChannels />,
      },
      {
        path: "/mediacatalog/youtube/channels/:id",
        element: <YoutubeChannelItemPage />,
      },
      {
        path: "/mediacatalog/youtube/videos/:id",
        element: <YoutubeVideoItemPage />,
      },
      {
        path: "/mediacatalog/youtube/movietrailers",
        element: <YoutubeVideos type="film" />,
      },
      {
        path: "/mediacatalog/youtube/seriestrailers",
        element: <YoutubeVideos type="series" />,
      },
      {
        path: "/mediacatalog/youtube/gamestrailers",
        element: <YoutubeVideos type="game" />,
      },
      {
        path: "/mediacatalog/youtube/unmappedtrailers",
        element: <YoutubeVideos type="unmapped" />,
      },
      {
        path: "/mediacatalog/youtube/unofficialtrailers",
        element: <YoutubeVideos type="unofficial" />,
      },
      {
        path: "/mediacatalog/youtube/kidstrailers",
        element: <YoutubeVideos type="kids" />,
      },
      {
        path: "/mediacatalog/youtube/alltrailers",
        element: <YoutubeVideos type="all" />,
      },
    ],
  },
  {
    path: "audit",
    element: (
      <AuthGuard>
        <CommonLayout title="Audit Logs" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/audit/",
        element: <AuditHistory />,
      },
    ],
  },
  {
    path: "box-office",
    element: (
      <AuthGuard>
        <CommonLayout title="Box Office" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/box-office",
        element: <BoxOfficeHomePage />,
      },
      {
        path: "/box-office/grosstimeseries",
        element: <BoxOfficeGrossTimeseriesPage />,
      },
      {
        path: "/box-office/ranking",
        element: <BoxOfficeRankingPage />,
      },
      {
        path: "/box-office/bo-by-studio",
        element: <BoByStudioPage />,
      },
    ],
  },
  {
    path: "topic-modeling",
    element: (
      <AuthGuard>
        <CommonLayout title="Topic Modeling" />
      </AuthGuard>
    ),
    children: [
      {
        path: "/topic-modeling/true-crime",
        element: <TopicModelingTrueCrimeV2 />,
      },
      {
        path: "/topic-modeling/true-crime/person/:name",
        element: <TopicModelingTrueCrimeNamePageV2 />,
      },
      {
        path: "/topic-modeling/true-crime/topic/:topicId",
        element: <TopicModelingTrueCrimeTopicPageV2 />,
      },
      {
        path: "/topic-modeling/pantheon",
        element: <TopicModelingPantheon />,
      },
      {
        path: "/topic-modeling/pantheon/topic/:topicId",
        element: <TopicModelingPantheonTopicPage />,
      },
    ],
  },
]);

export default navigation;
