import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getYoutubeTrendsData } from "../request";

export const useGetYoutubeTrendsData = (vertical: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_YOUTUBE_TRENDS_DATA,vertical],
    () => getYoutubeTrendsData(vertical),
  );

  return {
    data,
    isLoading,
    error,
  };
};
