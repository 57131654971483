import React from "react";

export const MovieCard = ({ data }) => {
  return (
    <article className="grid grid-cols-2 justify-center items-center">
      <div className="w-48 xl:w-64 h-auto">
        <img
          src={"https://image.tmdb.org/t/p/w92" + data.url}
          alt={data.ip}
          className="rounded-lg w-40 h-48 m-8"
          loading="lazy"
          sizes="20"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-l text-blue-400 font-bold inline-block">
          IP: {data.ip}
        </div>
        <div>
          Release date:
          <span className="text-blue-400 pl-2">{data.releaseDate}</span>
        </div>
        <p>{data.summary}</p>
      </div>
    </article>
  );
};
