import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMovies } from "./movies-non-ott-slice";
import { CustomPagination } from "../../../../components/CustomPagination";
import {
    Grid,
    Container,
    Header,
    PropertyFilter,
    FormField,
    Box,
    Spinner,
    Link,
    SpaceBetween,
} from "@cloudscape-design/components";
import RottenTomatesCriticScore from "../../../item/components/RTCriticScore";
import RottenTomatesAudienceScore from "../../../item/components/RTAudienceScore";
import IMDbRating from "../../../item/components/IMDbRating";
import Genre from "../../../item/components/genre";
import ProductionCompanies from "../../../item/components/ProductionCompanies";

function MoviesNonOTTComponent() {
    const dispatch = useDispatch();
    const { movieNonOtt = [], loading, error, } = useSelector((state) => state.movieNonOtt);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [query, setQuery] = useState({ operation: 'and', tokens: [] });
    const [filteringOptions, setFilteringOptions] = useState([]);
    const [filteredSeries, setFilteredSeries] = useState([]);

    useEffect(() => {
        dispatch(
            fetchMovies({
                sort: [{ release_date: "desc" }],
                include: [{ range: { release_date: { lt: "now-1y/d" } } }],
                exclude: [
                    { range: { imdb_vote_average: { lt: 7 } } },
                    { range: { "rtFanSummary.score": { lt: 75 } } },
                    { range: { "rtCriticSummary.score": { lt: 75 } } },
                ],
                includeCondition: "and",
            })
        );
    }, [dispatch]);

    useEffect(() => {
        const genreOptions = movieNonOtt
            ?.flatMap(movie => movie?.genre ?? [])
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(genre => ({ propertyKey: 'genre', value: genre, label: genre }));

        setFilteringOptions(genreOptions ?? []);
    }, [movieNonOtt]);

    const filteringProperties = [
        {
            key: 'genre',
            propertyLabel: 'Genre',
            operators: ["=", "!="],
        },
        {
            key: "production_company",
            propertyLabel: "Production Company",
            operators: ["=", "!="],
        },
        {
            key: "imdb_vote_average",
            propertyLabel: "IMDb Score",
            operators: [">", "<"],
        },
        {
            key: "rtCriticSummary.score",
            propertyLabel: "RT Critic Score",
            operators: [">", "<"],
        },
        {
            key: "rtFanSummary.score",
            propertyLabel: "RT Audience Score",
            operators: [">", "<"],
        },
        {
            key: "release_date",
            propertyLabel: "Release Date",
            operators: [">", "<"],
        },
    ];
    const handleLoadItems = async (detail) => {

        return filteringOptions;
    };

    const handleQueryChange = (event) => {
        const newQuery = event.detail;

        if (!newQuery.tokens) {
            console.error('New query does not have tokens:', newQuery);
            setQuery({ ...newQuery, tokens: [] });
        } else {
            setQuery(newQuery);
        }
    };

    useEffect(() => {
        const applyFilters = () => {
            const filtered = movieNonOtt.filter((movie) => {
                return query.tokens.every((token) => {
                    const keys = token.propertyKey.split('.');
                    let movieValue = movie;

                    for (const key of keys) {
                        if (movieValue[key] === undefined) {
                            return false;
                        } else {
                            movieValue = movieValue[key];
                        }
                    }

                    if (['rtCriticSummary.score', 'rtFanSummary.score'].includes(token.propertyKey)) {
                        movieValue = parseInt(movieValue, 10);
                    }
                    if (movieValue === undefined) {
                        return false;
                    }

                    if (token.propertyKey === "production_company") {
                        return movieValue.some(
                            (company) => company.name === token.value.replace(/"/g, "")
                        );
                    } else if (token.propertyKey.includes("date")) {
                        const dateMovieValue = new Date(movieValue);
                        const dateTokenValue = new Date(token.value);

                        switch (token.operator) {
                            case ">":
                                return dateMovieValue > dateTokenValue;
                            case "<":
                                return dateMovieValue < dateTokenValue;
                            case "=":
                                return dateMovieValue.getTime() === dateTokenValue.getTime();
                            case "!=":
                                return dateMovieValue.getTime() !== dateTokenValue.getTime();
                            default:
                                return true;
                        }
                    } else if (Array.isArray(movieValue)) {
                        if (token.operator === "=") {
                            return movieValue.includes(token.value);
                        } else if (token.operator === "!=") {
                            return !movieValue.includes(token.value);
                        }
                    } else {
                        switch (token.operator) {
                            case "=":
                                return movieValue === token.value;
                            case "!=":
                                return movieValue !== token.value;
                            case ">":
                                return movieValue > token.value;
                            case "<":
                                return movieValue < token.value;
                            default:
                                return true;
                        }
                    }
                });
            });
            setFilteredSeries(filtered);
        };
        applyFilters();
    }, [query, movieNonOtt]);

    if (loading) return <Container><Spinner size="large" /></Container>;
    if (error) return <div>Error: {error}</div>;

    const pageCount = Math.ceil(filteredSeries.length / itemsPerPage);
    const currentData = filteredSeries.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SpaceBetween direction="vertical" size="m">
            <Container>
                <FormField description="Advanced filter">
                    <PropertyFilter
                        i18nStrings={{
                            applyActionText: "Apply",
                            clearFiltersText: "Clear filters",
                            groupPropertiesText: "Properties",
                            filteringAriaLabel: "Query builder",
                            filteringPlaceholder: "Query builder",
                            operationAndText: "and",
                            operationOrText: "or",
                            operatorContainsText: "contains",
                            operatorDoesNotContainText: "does not contain",
                            operatorDoesNotEqualText: "does not equal",
                            operatorEqualsText: "equals",
                            operatorGreaterOrEqualText: "greater than or equal to",
                            operatorGreaterText: "greater than",
                            operatorLessOrEqualText: "less than or equal to",
                            operatorLessText: "less than",
                            operatorStartsWithText: "starts with",
                        }}
                        expandToViewport
                        filteringProperties={filteringProperties}
                        filteringOptions={filteringOptions}
                        query={query}
                        onChange={handleQueryChange}
                        onLoadItems={handleLoadItems}
                        countText={`${filteredSeries.length} Matches`}
                    />

                </FormField>
            </Container>
       
            <Grid gridDefinition={currentData.map(() => ({ colspan: { xxs: 12, s: 6, l: 4, xl: 3 } }))}>
                {currentData.map((movie, index) => (
                    <Container fitHeight key={index} footer={
                            <div className="flex justify-center items-start gap-5">
                                {movie.rtCriticSummary && ( <RottenTomatesCriticScore rtCriticSummary={movie.rtCriticSummary} /> )}
                                {movie.rtFanSummary && ( <RottenTomatesAudienceScore rtFanSummary={movie.rtFanSummary} /> )}
                                {!!movie.imdb_vote_average && ( <IMDbRating percent={movie.imdb_vote_average} title={"IMDb SCORE"} subtitle={`${movie.imdb_vote_count.toLocaleString()} votes`} /> )}
                            </div>
                        }
                    >
                        <div className="text-slate-500 text-xs">
                            Released:{" "}{new Date(movie.release_date).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric", })}
                        </div>
                        <Box margin={{ bottom: "xxs" }}>
                            <div className="text-base font-bold">
                                <Link color="inverted" fontSize="inherit" href={`/item/${movie.ip_id}`}>{movie.name}</Link>
                            </div>
                        </Box>
                        <Box margin={{ bottom: "xxs" }}>
                            <Genre item={movie.genre} />
                        </Box>
                        <Box margin={{ bottom: "xxs" }}>
                            <ProductionCompanies item={movie.production_company} />
                        </Box>
                        {movie.homepage && (
                            <Box>
                                <Link color="inverted" variant="info" href={movie.homepage} external > Homepage </Link>
                            </Box>
                        )}
                    </Container>
                ))}
            </Grid>
            <CustomPagination
                currentPage={currentPage}
                totalPages={pageCount}
                onNext={() => setCurrentPage(currentPage + 1)}
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onPageClick={setCurrentPage}
            />
       
        </SpaceBetween>
    );
}

export default MoviesNonOTTComponent;
