/* PLEASE DO NOT REFORMAT THIS FILE (breaks spacing in certain areas) */

import { 
  AppLayout,
  ContentLayout,
  Container,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Icon,
  DatePicker,
  Button,
  Table,
  Modal,
  SpaceBetween,
  Box,
  Input,
  Autosuggest,
  TokenGroup,
  Select,
  FormField,
  ButtonDropdown,
  StatusIndicator,
  Tabs,
  TextFilter,
  Pagination,
  ColumnLayout,
  Toggle,
  Spinner,
  SplitPanel,
  Multiselect,
  Popover,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import 'ag-grid-enterprise';
import { AgChartsReact } from "ag-charts-react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { lineTheme } from "./chartConfig";
import TopicSearchBar from "../components/TopicSearchBar";
import { addToFavorites, removeFromFavorites, callPreferences } from "./preferences";
import { slugify } from "../utils/name-utils";

const TopicModelingPantheonTopicPage = () => {

  const flashbarRef = useRef(null);

  const dl_overviewGridRef = useRef(null);
  const dl_postsOverTimeGridRef = useRef(null);
  const dl_postsGridRef = useRef(null);

  const { topicId } = useParams();

  const domain = "pantheon-reddit";
  const [ breadcrumbs, setBreadcrumbs ] = useState([{ text: "Topic Modeling", }, { text: "General", href: "/topic-modeling/pantheon" }, { text: "Topic", href: "/" },]);
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment().subtract(2, 'weeks').format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const granularityOptions = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ];
  const postsPageSize = 50;


  const [ topicsOverTimeResponse, setTopicsOverTimeResponse ] = useState(defaultResponseState);
  const [ postsByTopicResponse, setPostsByTopicResponse ] = useState(defaultResponseState);

  const [ topicLabel, setTopicLabel ] = useState("");
  const [ topicSeriesData, setTopicSeriesData ] = useState([]);
  const [ topicWordsSeriesData, setTopicWordsSeriesData ] = useState([]);
  const [ topicTotalPosts, setTopicTotalPosts ] = useState(0);
  const [ topicIps, setTopicIps ] = useState([]);
  const [ showMarkers, setShowMarkers ] = useState(false);
  const [ granularity, setGranularity ] = useState(granularityOptions[1]);

  const [ postsPageIndex, setPostsPageIndex ] = useState(1);
  const [ postsTargetDate, setPostsTargetDate ] = useState(defaultDateRange);
  const [ postsCache, setPostsCache ] = useState({});
  const [ currentPosts, setCurrentPosts ] = useState([]);

  const [ isFavorite, setIsFavorite ] = useState(null);


  const handleTopicsOverTimeResponse = (response) => {
    const label = response.topic_labels[topicId];
    const words = response.topic_words[topicId];
    const ips = response.topic_ips;
    const timeseriesData = response.data;

    const seriesData = timeseriesData.map((item) => (
      {
        timestamp: moment(item.timestamp).toDate(),
        value: item.data[0].count,
        num_upvotes: item.data[0].num_upvotes,
        num_comments: item.data[0].num_comments,
      }
    ));

    const wordsSeriesData = words.map((item) => (
      {
        word: item.word,
        score: item.score,
      }
    ));

    setTopicLabel(label);
    setTopicSeriesData(seriesData);
    setTopicWordsSeriesData(wordsSeriesData);
    setTopicIps(ips);
    setTopicTotalPosts(timeseriesData.reduce((acc, item) => acc + item.data[0].count, 0));
    setBreadcrumbs([{ text: "Topic Modeling", }, { text: "General", href: "/topic-modeling/pantheon" }, { text: "Topic: " + label, href: `/topic-modeling/pantheon/topic/${topicId}` },]);
  };

  const groupSeries = (seriesData, groupBy) => {
    const grouped = {};
  
    seriesData.forEach((item) => {
      const startOfGroup = moment(item.timestamp).startOf(groupBy).toDate();
      const key = moment(startOfGroup).format('YYYY-MM-DD');
  
      if (!grouped[key]) {
        grouped[key] = { timestamp: startOfGroup, value: 0 };
      }
      grouped[key].value += item.value;
    });

    const newSeriesData = Object.values(grouped).sort((a, b) => a.timestamp - b.timestamp);
  
    return newSeriesData;
  };

  const setPostsPage = (dateRange, page) => {
    const key = `${dateRange.startDate}_${dateRange.endDate}_${topicId}_${page}`;
    const newPostsCache = { ...postsCache };
    if (!(key in newPostsCache)) {
      let params = {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        topic_ids: topicId,
        min_prob: 0.5,
        size: postsPageSize,
        page: page - 1,
      };
      const existingQueryKey = Object.keys(newPostsCache).find(key => key.startsWith(`${dateRange.startDate}_${dateRange.endDate}_`));
      if (existingQueryKey) {
        const executionId = newPostsCache[existingQueryKey].execution_id;
        console.log(executionId)
        params.execution_id = executionId;
      }
      getPostsByTopic(params).then(data => {
        newPostsCache[key] = data;
        setPostsCache(newPostsCache);
        setCurrentPosts(data);
      });
    } else {
      setCurrentPosts(newPostsCache[key]);
    }
  };

  const downloadPage = () => {
    const spreadsheets = [
      dl_overviewGridRef.current.api.getSheetDataForExcel({ sheetName: "Topic Overview" }),
      dl_postsOverTimeGridRef.current.api.getSheetDataForExcel({ sheetName: "Posts Over Time" }),
      dl_postsGridRef.current.api.getSheetDataForExcel({ sheetName: "Reddit Posts" }),
    ];
    dl_overviewGridRef.current.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `topic_${slugify(topicLabel).slice(0, 32)}_${moment().format("YYYY-MM-DD")}.xlsx`,
    });
  };



  const getTopicsOverTime = (params) => {
    setTopicsOverTimeResponse({ ...topicsOverTimeResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/topicsovertime",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setTopicsOverTimeResponse({ ...topicsOverTimeResponse, data: response.data, loading: false, completed: true, error: null });
      handleTopicsOverTimeResponse(response.data);
    }).catch(error => {
      setTopicsOverTimeResponse({ ...topicsOverTimeResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getPostsByTopic = (params) => {
    setPostsByTopicResponse({ ...postsByTopicResponse, loading: true, completed: false, error: null });

    return axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/postsbytopic",
      params: {
        domain: domain,
        ...params,
      },
    }).then(response => {
      setPostsByTopicResponse({ ...postsByTopicResponse, data: response.data, loading: false, completed: true, error: null });
      return response.data;
    }).catch(error => {
      setPostsByTopicResponse({ ...postsByTopicResponse, data: null, error: error, loading: false, completed: true });
      console.log(error);
    });
  };

  const getItemImage = (item) => {
    switch(item.vertical) {
      case "Gaming":
        return `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
      case "Movies":
      case "Television":
        return `https://image.tmdb.org/t/p/w92${item.image_url}`;
    }
  };


  useEffect(() => {
    if (!topicId) return;
    setPostsCache({});
    const getPreferences = async () => {
      const newPreferences = await callPreferences(domain, "get_preferences");
      const favoriteTopics = newPreferences?.favorites?.topics?.map(x => parseInt(x.value));
      setIsFavorite(favoriteTopics?.includes(parseInt(topicId)));
    };
    getPreferences();
    getTopicsOverTime({
      start_date: "2020-01-01", 
      end_date: moment().format("YYYY-MM-DD"), 
      granularity: "day",
      min_prob: 0.5,
      topic_ids: topicId,
    });
    setPostsPage(postsTargetDate, 1);
  }, [topicId]);

  return (
    <>
      <Helmet><title>{topicsOverTimeResponse?.data ? "Topic: " + topicsOverTimeResponse?.data?.topic_labels[topicId] : "Topic"}</title></Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <>
            <ContentLayout
              header={
                <>
                  <Header
                    actions={
                      <div className="flex space-x-2 items-center">
                        <Button
                          loading={topicsOverTimeResponse?.loading || postsByTopicResponse?.loading}
                          onClick={downloadPage}
                        >
                          Download
                        </Button>
                        <TopicSearchBar
                          domain={domain}
                          searchApiUrl="/topicmodeling/pantheon/search"
                          topicPageUrl="/topic-modeling/pantheon/topic"
                          limit={10}
                        />
                      </div>
                    }
                  >
                    <div className="flex items-center space-x-1">
                      {isFavorite === null ? (
                        <Spinner size="normal" />
                      ) : (
                        <Button 
                          ariaLabel={isFavorite ? "Remove from favorites" : "Add to favorites"}
                          iconName={isFavorite ? "star-filled" : "star"}
                          variant="inline-icon" 
                          onClick={() => {
                            if (isFavorite) {
                              removeFromFavorites(domain, { topics: topicId });
                            } else {
                              addToFavorites(domain, { topics: topicId });
                            }
                            setIsFavorite(!isFavorite);
                          }}
                        />
                      )}
                      <div>{topicsOverTimeResponse?.data ? "Topic: " + topicsOverTimeResponse?.data?.topic_labels[topicId] : "Topic"}</div>
                    </div>
                  </Header>
                  <PantheonFlashbar ref={flashbarRef} />
                </>
              }
            >
              <SpaceBetween size="m">
                <Container>
                  <ColumnLayout columns={2} borders="vertical">
                    <SpaceBetween size="s">
                      <FormField
                        label="Name"
                      >
                        {topicsOverTimeResponse?.loading ? <Spinner /> : topicLabel}
                      </FormField>
                      <FormField
                        label="ID"
                      >
                        {topicsOverTimeResponse?.loading ? <Spinner /> : topicId}
                      </FormField>
                      <FormField
                        label="Total posts"
                      >
                        {topicsOverTimeResponse?.loading ? <Spinner /> : <div>{topicTotalPosts.toLocaleString()}{" "}(from {moment("2020-01-01").format("l")} to {moment().format("l")})</div>}
                      </FormField>
                    </SpaceBetween>
                    <FormField
                      label="Top keywords"
                      description="Top ten keywords associated with this topic"
                    >
                      {topicsOverTimeResponse?.loading ? (
                        <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                          <Spinner size="large" />
                        </div> 
                      ) : (
                        <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                          <AgChartsReact 
                            options={{
                              data: topicWordsSeriesData,
                              series: [
                                {
                                  type: "bar",
                                  direction: "horizontal",
                                  xKey: "word",
                                  yKey: "score",
                                }
                              ],
                              axes: [
                                {
                                  type: "number",
                                  position: "bottom",
                                  label: { 
                                    enabled: false,
                                  },
                                  tick: {
                                    interval: 0.025,
                                    size: 0,
                                  },
                                  crosshair: {
                                    enabled: false,
                                  },
                                },
                                {
                                  type: "category",
                                  position: "left",
                                },
                              ],
                              tooltip: {
                                enabled: false,
                              },
                              theme: lineTheme,
                              padding: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              },
                            }}
                          />
                        </div>
                      )}
                    </FormField>
                  </ColumnLayout>
                </Container>
                <Container
                  header={
                    <Header
                      description="Number of posts matching this topic over time"
                      actions={
                        <div className="flex space-x-2 items-center">
                          <Select
                            options={granularityOptions}
                            selectedOption={granularity}
                            onChange={({ detail }) => {
                              setGranularity(detail.selectedOption);
                            }}
                          />
                          <Toggle
                            onChange={({ detail }) => {
                              setShowMarkers(detail.checked);
                            }}
                            checked={showMarkers}
                          >
                            Show markers
                          </Toggle>
                        </div>
                      }
                    >
                      Posts over time
                      {" "}
                      <Popover
                        position="right"
                        size="small"
                        triggerType="custom"
                        content={
                          <div>Click any point on the chart to see posts from that time period</div>
                        }
                      >
                        <Button iconName="status-info" variant="inline-icon" />
                      </Popover>
                    </Header>
                  }
                >
                  {topicsOverTimeResponse?.loading ? (
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div> 
                  ) : (
                    <div style={{ height: "400px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgChartsReact 
                        options={{
                          data: granularity.value === "day" ? topicSeriesData : groupSeries(topicSeriesData, granularity.value),
                          series: [
                            {
                              xKey: "timestamp",
                              yKey: "value",
                              title: "Post count",
                              tooltip: {
                                renderer: (params) => (
                                  {
                                    title: params.datum.title,
                                    content: (granularity.value === "day" ? 
                                      moment(params.datum.timestamp).format("ll")
                                      : granularity.value === "week" ?
                                      moment(params.datum.timestamp).format("ll") + " - " + moment(params.datum.timestamp).add(6, "days").format("ll")
                                      : moment(params.datum.timestamp).format("MMM YYYY")) + ": " + params.datum.value.toLocaleString() + " posts"
                                  }
                                ),
                              },
                              marker: {
                                fillOpacity: showMarkers ? 0.5 : 0,
                              },
                              connectMissingData: false,
                              listeners: {
                                nodeClick: (params) => {
                                  const clickedDate = moment(params.datum.timestamp).format("YYYY-MM-DD");
                                  const startDate = clickedDate;
                                  const endDate = granularity.value === "day" ? clickedDate : granularity.value === "week" ? moment(clickedDate).add(6, "days").format("YYYY-MM-DD") : moment(clickedDate).endOf("month").format("YYYY-MM-DD");
                                  const dateRange = {
                                    type: "absolute",
                                    startDate: startDate,
                                    endDate: endDate,
                                  };
                                  setPostsTargetDate(dateRange);
                                  setPostsPage(dateRange, 1);
                                },
                              },
                              nodeClickRange: "nearest",
                            },
                          ],
                          axes: [
                            {
                              type: 'time',
                              position: 'bottom',
                              title: "Date",
                              min: new Date(Date.parse("2020-01-01")),
                              max: new Date(),
                              label: {
                                format: '%b %d, %Y',
                              },
                              tick: {
                                minSpacing: 50,
                                maxSpacing: 200,
                              },
                            },
                            {
                              type: 'number',
                              position: 'left',
                              title: "Post count",
                              label: {
                                formatter: (params) => {
                                  const n = params.value;
                                  if (n < 1e3) return n;
                                  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                                  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                                  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                                  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                                }
                              },
                            },
                          ],
                          theme: lineTheme,
                          navigator: {
                            enabled: true
                          },
                        }}
                      />
                    </div>
                  )}
                </Container>
                <Container
                  header={
                    <Header
                      actions={
                        <Pagination
                          currentPageIndex={postsPageIndex}
                          onChange={({ detail }) => {
                            setPostsPageIndex(detail.currentPageIndex);
                            setPostsPage(postsTargetDate, detail.currentPageIndex);
                          }}
                          pagesCount={Math.ceil(currentPosts?.total_rows / postsPageSize)}
                        />
                      }
                      counter={!postsByTopicResponse?.loading && currentPosts?.total_rows ? `(${currentPosts?.total_rows.toLocaleString()})` : null}
                      description={(postsTargetDate.startDate !== defaultDateRange.startDate && postsTargetDate.endDate !== defaultDateRange.endDate) ? (postsTargetDate.startDate === postsTargetDate.endDate ? `Posts matching this topic on ${moment(postsTargetDate.startDate).format("ll")}` : `Posts matching this topic from ${moment(postsTargetDate.startDate).format("ll")} to ${moment(postsTargetDate.endDate).format("ll")}`) : "Latest posts matching this topic"}
                    >
                      Reddit posts
                    </Header>
                  }
                >
                  {postsByTopicResponse?.loading ? (
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div> 
                  ) : (
                    <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                      {currentPosts?.reddit?.length > 0 ? (
                        <ColumnLayout columns={1} borders="horizontal">
                          {currentPosts?.reddit?.map(post => (
                            <div className="grid">
                              <div className="flex justify-between">
                                <div className="flex flex-col flex-wrap h-full">
                                  <div className="grow">
                                    <div>
                                      <a className="text-sm font-bold" href={post.url} target="_blank">{post.title}</a>
                                    </div>
                                    <div>
                                      <a className="text-xs text-slate-400" href={`https://reddit.com/r/${post.subreddit}`} target="_blank">Posted by u/{post.author} to r/{post.subreddit}{" "}&bull;{" "}{moment(post.created_at * 1000).format("ll")}</a>
                                    </div>
                                    {post.body?.length > 0 && (
                                      <div 
                                        className="mt-2 text-xs" 
                                        style={{ 
                                          overflow: "hidden",
                                          maskImage: "linear-gradient(180deg, #000 60%, transparent)",
                                          maxHeight: "50px",
                                        }}
                                      >
                                        <div 
                                          style={{
                                            wordBreak: "break-word",
                                            overflow: "auto",
                                            paddingBottom: "5px",
                                          }}
                                        >
                                          {post.body}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <div className="text-sm mt-2"><Icon name="arrow-left" className="rotate-90 saturate-[10] hue-rotate-180 brightness-90" />{" "}{post.num_upvotes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{post.num_comments.toLocaleString()}</div>
                                  </div>
                                </div>
                                <div className="mx-4 min-w-24">
                                  {post.thumbnail && (
                                    <img 
                                      src={post.thumbnail} 
                                      alt="thumbnail" 
                                      className="w-24 h-24 object-cover rounded-md shadow-md" 
                                      onError={(e) => {
                                        e.target.style.display = "none";
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </ColumnLayout>
                      ) : (
                        <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                          <div>No posts found for this time period</div>
                        </div>
                      )}
                    </div>
                  )}
                </Container>
                <Container
                  header={
                    <Header
                      description="Assets that have been mentioned in posts matching this topic"
                    >
                      Related media
                    </Header>
                  }
                >
                  {topicsOverTimeResponse?.loading ? (
                    <div className="py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div> 
                  ) : Array.isArray(topicIps) && topicIps.length > 0 ? (
                    <div className="flex scrollbar-thin scrollbar-thumb-sky-600 scrollbar-track-slate-800 overflow-x-auto overflow-y-visible scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                      <div className="flex flex-nowrap space-x-4">
                        {Array.isArray(topicIps) && topicIps.map((item, index) => {
                          return (
                            <a key={item.name} className="inline-block relative" href={`/item/${item.id}`}>
                              <div title={item.name} className={"w-36 max-w-xs overflow-x-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out hover:bg-slate-700 border-2 border-slate-600 p-1.5 h-64"}>
                                <img
                                  src={getItemImage(item)}
                                  alt={item.name}
                                  className={" w-32 rounded-md object-cover object-center h-48"}
                                />
                                <div className={"absolute font-bold text-sm text-white px-0.5 text-left w-[8.25rem] top-52"}>
                                  <div className="line-clamp-2">{item.name}</div>
                                </div>
                                {/*<div className="absolute top-2 right-2 p-3 h-2 mt-1 mr-1 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-white font-bold text-md">
                                  {item.count.toLocaleString()} mentions
                                </div>*/}
                              </div>
                            </a>

                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <div>No related media found</div>
                    </div>
                  )}
                </Container>
              </SpaceBetween>
              <div className="hidden">
                {topicsOverTimeResponse?.loading === false && topicsOverTimeResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_overviewGridRef}
                        columnDefs={[
                          {
                            field: "name",
                            headerName: "Topic name",
                          },
                          {
                            field: "id",
                            headerName: "Topic ID",
                          },
                          {
                            field: "total_posts",
                            headerName: "Total posts",
                          },
                          {
                            field: "top_keywords",
                            headerName: "Top keywords",
                          },
                        ]}
                        rowData={[
                          {
                            name: topicLabel,
                            id: topicId,
                            total_posts: topicTotalPosts,
                            top_keywords: topicWordsSeriesData.map(item => item.word).join(", "),
                          },
                        ]}
                      />
                    </div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_postsOverTimeGridRef}
                        columnDefs={[
                          {
                            field: "timestamp_formatted",
                            headerName: "Date (formatted)",
                          },
                          {
                            field: "timestamp",
                            headerName: "Date",
                          },
                          {
                            field: "count",
                            headerName: "Post count",
                          },
                        ]}
                        rowData={topicSeriesData.map(item => (
                          { 
                            timestamp_formatted: moment(item.timestamp).format("ll"), 
                            timestamp: moment(item.timestamp).format("YYYY-MM-DD"), 
                            count: item.value,
                          }
                        ))}
                      />
                    </div>
                  </div>
                )}
                {postsByTopicResponse?.loading === false && postsByTopicResponse?.data && (
                  <div>
                    <div style={{ height: "250px", width: "100%" }} className="ag-theme-quartz-dark">
                      <AgGridReact
                        ref={dl_postsGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "author",
                            headerName: "Author",
                          },
                          {
                            field: "subreddit",
                            headerName: "Subreddit",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_upvotes",
                            headerName: "Upvotes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Post ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                          {
                            field: "body",
                            headerName: "Body",
                          },
                        ]}
                        rowData={currentPosts.reddit}
                      />
                    </div>
                  </div>
                )}
              </div>
            </ContentLayout>
          </>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TopicModelingPantheonTopicPage;
