import { dispatch } from "../../../../app/store";

import { wikipediaLeaderBoardApi } from "../index";

const getWikiLeaderBoardData = ({
  gte,
  lte,
  from,
  size,
  ip_list,
  vertical,
  keyword,
  genre,
}) => {
  dispatch(
    wikipediaLeaderBoardApi({
      gte,
      lte,
      from,
      size,
      ip_list,
      vertical,
      keyword,
      genre,
    })
  );
};

export { getWikiLeaderBoardData };
