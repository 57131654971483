import { isSomeOrElse } from "../../../../utils/sugarUtils";

type ScoresItemType = {
  platform: string;
  score: number | undefined;
};

type Scores = {
  wiki: number;
  imdb: number;
  rottentomatoes: number;
  boxofficeBudget: number;
  boxofficeXDOM: number;
  boxofficeXNDOM: number;
  piracy: number;
};

export type MappedRowType = {
  name: string;
  scores: Scores;
};

export type ChartContentType = {
  name: string;
  scores: Array<ScoresItemType>;
};

export type RawDataType = {
  [key: string]: MappedRowType;
};

const platformMap = {
  imdb: "IMDb Rating",
  rottentomatoes: "Rotten Tomatoes Audience Rating",
  wiki: "Wikipedia Page Views",
  piracy: "P2P Downloads",
  boxofficeBudget: "Production Budget",
  boxofficeXDOM: "Domestic BoxOffice",
  boxofficeXNDOM: "International BoxOffice",
};

export const mapData = (name: string, movie): MappedRowType => {
  return {
    name,
    scores: {
      wiki: isSomeOrElse(movie.wiki?.total_views, 0),
      imdb: isSomeOrElse(movie.imdb?.rating, 0),
      rottentomatoes: isSomeOrElse(movie?.rottentomatoes?.fan_rating, 0),
      boxofficeBudget: isSomeOrElse(movie.boxoffice?.budget?.amount, 0),
      boxofficeXDOM: isSomeOrElse( movie.boxoffice?.collection?.find((item) => item.area === "XDOM") ?.grossToDate, 0, ),
      boxofficeXNDOM: isSomeOrElse( movie.boxoffice?.collection?.find((item) => item.area === "XNDOM") ?.grossToDate, 0, ),
      piracy: isSomeOrElse(movie.piracy, 0),
    },
  };
};

export const getFormattedChartContent = (
  name: string,
  data: any,
  highestValue?: Scores,
): ChartContentType => ({
  name,
  scores: Object.keys(platformMap).map((key) => {
    return {
      platform: platformMap[key],
      score: !highestValue ? data[key] : (data[key] / highestValue[key]) * 100,
    };
  }),
});

const getHighestValue = (data): Scores => {
  return data.reduce(
    (acc, movie) => {
      acc.wiki = Math.max(acc.wiki, movie.scores.wiki);
      acc.imdb = Math.max(acc.imdb, movie.scores.imdb);
      acc.rottentomatoes = Math.max(
        acc.rottentomatoes,
        parseFloat(movie.scores.rottentomatoes),
      );

      acc.boxofficeBudget = Math.max(
        acc.boxofficeBudget,
        movie.scores.boxofficeBudget,
      );
      acc.boxofficeXDOM = Math.max(
        acc.boxofficeXDOM,
        movie.scores.boxofficeXDOM,
      );
      acc.boxofficeXNDOM = Math.max(
        acc.boxofficeXNDOM,
        movie.scores.boxofficeXNDOM,
      );
      acc.piracy = Math.max(acc.piracy, movie.scores.piracy);

      return acc;
    },
    {
      wiki: 0,
      imdb: 0,
      rottentomatoes: 0,
      boxofficeBudget: 0,
      boxofficeXDOM: 0,
      boxofficeXNDOM: 0,
      piracy: 0,
    },
  );
};

export const getChartContent = (
  rawData: RawDataType,
): Array<ChartContentType> => {
  const data = Object.values(rawData);

  if (data.length === 0) return [];

  if (data.length === 1) {
    const item = data[0];
    return Array(getFormattedChartContent(item.name, item.scores));
  }

  const highestValue = getHighestValue(data);

  const scaledMoviesData = data.map((movie) => {
    return getFormattedChartContent(movie.name, movie.scores, highestValue);
  });

  return scaledMoviesData;
};
