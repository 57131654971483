import { dispatch } from "../../../../../../app/store";
import {
  fetchTarSeriesWikiViewGenre,
  tarSeriesWikiViewGenreResposeAction,
} from "../slices/tar-series-wiki-view-genre-slice";

export async function getTarSeriesWikiViewGenreResponse({
  gte,
  lte,
  interval,
}) {
  await dispatch(fetchTarSeriesWikiViewGenre({ gte, lte, interval }));
}

export function clearTarSeriesWikiViewGenreResponse() {
  dispatch(tarSeriesWikiViewGenreResposeAction.clearData());
}
