import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTwitchTrendsData } from "../request";

export const useGetTwitchTrendsData = () => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TWITCH_TRENDS_DATA],
    () => getTwitchTrendsData(),
  );

  return {
    data,
    isLoading,
    error,
  };
};
