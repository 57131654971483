import {
  Box,
  Container,
  Header,
  TextContent
} from "@cloudscape-design/components";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { fetchItemWikiSummaryData } from "../redux/actions/item-actions";
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';

function WikiSummaryBox({ id }) {
  const itemWikiSummaryData = useSelector(
    (state) => state?.itemWikiSummaryData?.data || []
  );
  const isLoading = useSelector((state) => state?.itemWikiSummaryData?.loading);

  const [ hoveredWord , setHoveredWord ] = React.useState("sdlkfsdkfnskvcv");

  useEffect(() => {
      fetchItemWikiSummaryData({ id });
  }, [id]);

  console.log("itemWikiSummaryData", itemWikiSummaryData);
  return (
    <>
      {isLoading ? (
        <Box textAlign="center" padding="l">
          <CustomLoadingOverlay />
        </Box>
      ) : (
        <Container header={<Header>Summary</Header>}>
          {itemWikiSummaryData?.summary ? (
            <>
              <TextContent>
                {itemWikiSummaryData.summary.split('\n').map((paragraph, index) => {
                  const parts = paragraph.split(new RegExp(`(${hoveredWord})`, 'gi'));
                  return <p key={index}>{parts.map(part => part.toLowerCase() === hoveredWord.toLowerCase() ? <span style={{backgroundColor: "yellow", color: "black"}}>{part}</span> : part)}</p>;
                })}
              </TextContent>
              <ReactWordcloud
                words={itemWikiSummaryData.words_from_text}
                options={{
                  deterministic: true,
                  rotations: 1,
                  rotationAngles: [0],
                  fontSizes: [15, 60],
                  padding: 2,
                  enableTooltip: false,
                }}
                callbacks={{
                  onWordMouseOver: ({ text }) => {
                    setHoveredWord(text);
                  },
                  onWordMouseOut: () => {
                    setHoveredWord("sdlkfsdkfnskvcv");
                  },
                }}
              />
            </>
          ) : (
            <div className="py-8 flex flex-1 flex-row justify-center items-center">
              <b>Summary not available</b>
            </div>
          )}
        </Container>
      )}
    </>
  );
}

export default WikiSummaryBox;
