import { SpaceBetween } from "@cloudscape-design/components";

const Genre = ({ item }) => {
    return (
        <SpaceBetween direction="horizontal" size="xxxs">
            {item.map((genre) => (
                <span
                    key={genre}
                    className="inline-flex items-center rounded-md bg-purple-400/10 px-2 py-1 text-xs font-medium text-purple-400 ring-1 ring-inset ring-purple-400/30"
                >
                    {genre}
                </span>
            ))}
        </SpaceBetween>
    );
}
export default Genre;