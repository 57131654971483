import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { useDispatch as useAppDispatch } from "react-redux";
import SettingsReducer from "../modules/settings/redux/slices/SettingsReducer";
import UserListReducer from "../modules/account-manaement/users/redux/slices/userListSlice";
import OrganizationsReducer from "../modules/account-manaement/organizations/redux/slices/organizations-slice";
import RolesReducer from "../modules/account-manaement/roles/redux/slices/roles-slice";
import createUserReducer from "../modules/account-manaement/users/redux/slices/createUserSlice";
import createOrganizationReducer from "../modules/account-manaement/organizations/redux/slices/create-organization-slice";
import createRoleReducer from "../modules/account-manaement/roles/redux/slices/create-role-slice";
import permissionsReducer from "../modules/account-manaement/permissions/redux/slices/permissions-slice";
import routesListReducer from "../modules/account-manaement/permissions/redux/slices/permissions-route-list-slice";
import createPermissioinsRouteReducer from "../modules/account-manaement/permissions/redux/slices/create-permission-route-slice";
import ummappedRouteListReducer from "../modules/account-manaement/permissions/redux/slices/permissions-unmapped-routes-slice";
import editPermissioinsRouteReducer from "../modules/account-manaement/permissions/redux/slices/edit-permission-route-slice";
import editRoleReducer from "../modules/account-manaement/roles/redux/slices/edit-role-slice";
import editOrgReducer from "../modules/account-manaement/organizations/redux/slices/edit-organization-slice";
import editUserReducer from "../modules/account-manaement/users/redux/slices/editUserSlice";
import googleTrendsRawDataReducer from "../modules/google-trends/redux/slices/google-trends-rawdata-slice";
import {
  ipManagement,
  pantheonManagement,
} from "../modules/ip_management/redux/slices/ip-management-slice";
import LeaderboardDataReducer from "../modules/home/redux/slices/leaderboard-slice";
import SettingsNbcuDataReducer from "../modules/settings/redux/slices/settings-nbcu-slice";
import ListOptionsDataReducer from "../modules/home/redux/slices/list-options-slice";
import NbcuSettingDataReducer from "../modules/settings/redux/slices/get-nbcu-settings-slice";
import SaveNbcuSettingsDataReducer from "../modules/settings/redux/slices/save-nbcu-settings-slice";
import rankingRawDataReducer from "../modules/home/redux/slices/ranking-rawdata-slice";
import CreateNbcuSettingsDataReducer from "../modules/settings/redux/slices/create-nbcu-settings-slice";
import FandomRawDataReducer from "../modules/fandom/redux/slices/fandom-raw-slice";
import Ao3RawDataReducer from "../modules/ao3/redux/slices/ao3-raw-slice";
import RedditRawDataReducer from "../modules/reddit/redux/slices/reddit-raw-slice";
import WattpadRawDataReducer from "../modules/wattpad/redux/slices/wattpad-raw-slice";
import TiktokRawDataReducer from "../modules/tiktok/redux/slices/tiktok-raw-slice";
import TwitterRawDataReducer from "../modules/twitter/redux/slices/twitter-raw-slice";
import InstagramRawDataReducer from "../modules/instagram/redux/slices/instagram-raw-slice";
import GoogleRealTimeSearchSavedResponseReducer from "../modules/google-realtime/redux/slices/google-realtime-saved-response-slice";
import piracyGenericMoviesSummaryReducer from "../modules/piracy/generic/redux/slices/piracy-generic-movies-summary-slice";
import piracyGenericTelevisionSummaryReducer from "../modules/piracy/generic/redux/slices/piracy-generic-television-summary-slice";
import TarSeriesWikiViewGenreReducer from "../modules/tar/series/wiki/redux/slices/tar-series-wiki-view-genre-slice";
import TarSeriesWikiViewGenrePlatformReducer from "../modules/peacock-dashboard/series-genre-platform/redux/slices/tar-series-wiki-view-genre-platform-slice";
import TarSeriesWikiViewGenrePlatformMarketshareReducer from "../modules/peacock-dashboard/series-genre-marketshare/redux/slices/tar-series-wiki-view-genre-platform-marketshare-slice";
import ItemDataReducer from "../modules/item/redux/slices/item-slice";
import ItemChildrenDataReducer from "../modules/item/redux/slices/item-children-slice";
import HashtagsDataReducer from "../modules/item/redux/slices/tiktok-hashtags-slice";
import InstagramHashtagsDataReducer from "../modules/item/redux/slices/instagram-hashtags-slice";
import TrendsDataReducer from "../modules/item/redux/slices/general-trends-slice";
import moviesReducer from "../modules/explore/movies/redux/movies_slice";
import televisionReducer from "../modules/explore/television/redux/television_slice";
import tracedWikiDataReducer from "../modules/item/redux/slices/tracked-trends-slice";
import trackedFandomDataReducer from "../modules/item/redux/slices/tracked-fandom-trends-slice";
import trackedRedditDataReducer from "../modules/item/redux/slices/tracked-reddit-trends-slice";
import trackedInstagramDataReducer from "../modules/item/redux/slices/tracked-instagram-trends-slice";
import trackedGoogleTrendsReducer from "../modules/item/redux/slices/tracked-google-trends-slice";
import steamchartsDataReducer from "../modules/item/redux/slices/steamcharts-slice";
import twitchDataReducer from "../modules/item/redux/slices/twitch-slice";
import gamesReducer from "../modules/explore/games/redux/games_slice";
import franchiseReducer from "../modules/explore/franchise/redux/franchise_slice";
import companiesReducer from "../modules/explore/companies/redux/companies_slice";
import PiracyTrendsReducer from "../modules/item/redux/slices/piracy-trends-slice";
import issuesReducer from "../modules/bug-reporting/issues_slice";
import GeneralWikipediaTrendsReducer from "../modules/wikipedia/redux/slices/general-wikipedia-trends-slice";
import { WikipediaLeaderBoardReducer } from "../modules/wikipedia/redux";
import GeneralGoogleTrendsReducer from "../modules/googletrendsv2/redux/slices/general-googletrends-trends-slice";
import { GoogleLeaderBoardReducer } from "../modules/googletrendsv2/redux";
import wikiRawDataReducer from "../modules/wikipedia/redux/slices/raw-data-list-slice";
import AmazonVendorTimeseriesReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/get-timeseries-slice";
import AmazonVendorTopProductsReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/get-top-products-slice";
import AutoCompleteReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/autocomplete-slice";
import WikipediaTopicSearchReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/wikipedia-topic-search-slice";
import AmazonVendorTermSuggestionsReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/get-term-suggestions";
import AmazonVendorTermPoolListReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/get-term-pool-list-slice";
import AmazonVendorTopCategoriesReducer from "../modules/consumer-products/amazon-vendor-central/redux/slices/get-top-categories-slice";
import { flashMessagesReducer } from "../modules/common/redux/flash-slice";
import { globalPersonalPrefReducer } from "../modules/common/redux/global-pref-slice";
import boxOfficeTimeseriesReducer from "../modules/box-office/grosstimeseries/redux/slices/box-office-timeseries-slice";
import itemAuditDataReducer from "../modules/item/redux/slices/item-audit-slice";
import itemNewsDataReducer from "../modules/item/redux/slices/item-news-slice";
import itemWikiSummaryDataReducer from "../modules/item/redux/slices/item-wikisummary-slice";
import itemTrailerDataReducer from "../modules/item/redux/slices/item-trailer-slice";
import itemBoxOfficeDataReducer from "../modules/item/redux/slices/item-boxoffice-slice";
import itemStreamingWindowDataReducer from "../modules/item/redux/slices/item-streamingwindow-slice";
import { moviesNonOttReducer } from "../modules/peacock-dashboard/non-ott-titles/movies/movies-non-ott-slice";
import { seriesNonOttReducer } from "../modules/peacock-dashboard/non-ott-titles/series/series-non-ott-slice";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "pantheon-",
  whitelist: ["settings"],
};

const rootReducer = combineReducers({
  settings: SettingsReducer,
  userList: UserListReducer,
  organizationsList: OrganizationsReducer,
  rolesList: RolesReducer,
  createUser: createUserReducer,
  createOrg: createOrganizationReducer,
  createRole: createRoleReducer,
  permissionsList: permissionsReducer,
  routesList: routesListReducer,
  generalWikipediaTrendsData: GeneralWikipediaTrendsReducer,
  generalGoogleTrendsData: GeneralGoogleTrendsReducer,
  createPermissionRoute: createPermissioinsRouteReducer,
  ummappedRouteList: ummappedRouteListReducer,
  editPermissionRoute: editPermissioinsRouteReducer,
  editRole: editRoleReducer,
  editOrg: editOrgReducer,
  editUser: editUserReducer,
  googleTrendsRowData: googleTrendsRawDataReducer,
  ipManagement: ipManagement.reducer,
  pantheonManagement: pantheonManagement.reducer,
  leaderboardData: LeaderboardDataReducer,
  settingsNbcuData: SettingsNbcuDataReducer,
  listOptionsData: ListOptionsDataReducer,
  nbcuSetting: NbcuSettingDataReducer,
  saveSaveNbcuSettingsData: SaveNbcuSettingsDataReducer,
  rankingRowData: rankingRawDataReducer,
  createCreateNbcuSettingsData: CreateNbcuSettingsDataReducer,
  fandomRawData: FandomRawDataReducer,
  ao3RawData: Ao3RawDataReducer,
  redditRawData: RedditRawDataReducer,
  wattpadRawData: WattpadRawDataReducer,
  tiktokRawData: TiktokRawDataReducer,
  twitterRawData: TwitterRawDataReducer,
  instagramRawData: InstagramRawDataReducer,
  googleRealtimeSavedRespose: GoogleRealTimeSearchSavedResponseReducer,
  piracyGenericMoviesSummary: piracyGenericMoviesSummaryReducer,
  piracyGenericTelevisionSummary: piracyGenericTelevisionSummaryReducer,
  tarSeriesWikiViewGenre: TarSeriesWikiViewGenreReducer,
  tarSeriesWikiViewGenrePlatform: TarSeriesWikiViewGenrePlatformReducer,
  tarSeriesWikiViewGenrePlatformMarketshare:
    TarSeriesWikiViewGenrePlatformMarketshareReducer,
  itemData: ItemDataReducer,
  itemChildrenData: ItemChildrenDataReducer,
  tiktokHashtagsData: HashtagsDataReducer,
  instagramHashtagsData: InstagramHashtagsDataReducer,
  generalTrendsData: TrendsDataReducer,
  moviesData: moviesReducer,
  televisionData: televisionReducer,
  trackedWikiTrends: tracedWikiDataReducer,
  trackedFandomTrends: trackedFandomDataReducer,
  trackedRedditTrends: trackedRedditDataReducer,
  trackedInstagramTrends: trackedInstagramDataReducer,
  trackedGoogleTrends: trackedGoogleTrendsReducer,
  steamchartsData: steamchartsDataReducer,
  twitchData: twitchDataReducer,
  gamesData: gamesReducer,
  franchiseData: franchiseReducer,
  companiesData: companiesReducer,
  piracyTrends: PiracyTrendsReducer,
  issues: issuesReducer,
  wikipediaLeaderBoard: WikipediaLeaderBoardReducer,
  googletrendsLeaderBoard: GoogleLeaderBoardReducer,
  wikiRawData: wikiRawDataReducer,
  amazonVendorTimeseries: AmazonVendorTimeseriesReducer,
  amazonVendorTopProducts: AmazonVendorTopProductsReducer,
  amazonVendorTopCategories: AmazonVendorTopCategoriesReducer,
  amazonVendorAutoComplete: AutoCompleteReducer,
  wikipediaTopicSearch: WikipediaTopicSearchReducer,
  amazonVendorTermSuggestions: AmazonVendorTermSuggestionsReducer,
  amazonVendorTermPoolList: AmazonVendorTermPoolListReducer,
  flashMessages: flashMessagesReducer,
  globalPersonalPref: globalPersonalPrefReducer,
  boxOfficeTimeseries: boxOfficeTimeseriesReducer,
  itemAuditData: itemAuditDataReducer,
  itemNewsData: itemNewsDataReducer,
  itemWikiSummaryData: itemWikiSummaryDataReducer,
  itemTrailerData: itemTrailerDataReducer,
  itemBoxOfficeData: itemBoxOfficeDataReducer,
  itemStreamingWindowData: itemStreamingWindowDataReducer,
  movieNonOtt: moviesNonOttReducer,
  seriesNonOtt: seriesNonOttReducer,
});

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);

export const { dispatch } = store;
export const useDispatch = () => useAppDispatch();
