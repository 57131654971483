import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../../utils/axios";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTarSeriesWikiViewGenre = createAsyncThunk(
  "tarSeriesWikiViewGenre/fetchTarSeriesWikiViewGenre",
  async ({ gte, lte, interval }) => {
    const response = await axiosInstance.request({
      url: `/tar/wiki/views/genre`,
      method: `GET`,
      params: { gte, lte, interval },
    });
    return response;
  }
);

const tarSeriesWikiViewGenreResposeSlice = createSlice({
  name: "tarSeriesWikiViewGenre",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTarSeriesWikiViewGenre.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTarSeriesWikiViewGenre.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchTarSeriesWikiViewGenre.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const TarSeriesWikiViewGenreReducer =
  tarSeriesWikiViewGenreResposeSlice.reducer;

export default TarSeriesWikiViewGenreReducer;

export const tarSeriesWikiViewGenreResposeAction =
  tarSeriesWikiViewGenreResposeSlice.actions;
