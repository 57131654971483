import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Alert,
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  PropertyFilter,
  SideNavigation,
  SpaceBetween,
  Table
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { CommonPreference } from "../../../components/CommonPreference";
import FormModel from "../../../components/FormModel";
import SnackbarContext from "../../../hooks/notify/snack-bar/snackbar-context";
import { navItems } from "../../../layouts/common/menu/side-menu";
import TableEmptyState from "../../../utils/TableEmptyState";
import TableNoMatchState from "../../../utils/TableNoMatchState";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import createTableSortLabelFn from "../../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../../utils/getTextFilterCounterText";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CreateOrganizationForm from "./forms/create-organization-form";
import EditOrganizationForm from "./forms/edit-organization-form";
import { getOrganizationsList } from "./redux/actions/organizations-actions";
import {
  organizationFilteringProperties,
  organizationVisibleContentPreference,
} from "./table-properties/organization-table-properties";

export default function OrganizationsListPage() {
  const snackbarCtx = useContext(SnackbarContext);
  const organizationsList = useSelector((state) => state.organizationsList);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const createOrg = useSelector((state) => state.createOrg);
  useEffect(() => {
    if (!createOrg.isLoading && createOrg.isCompleted && !createOrg.isError) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: createOrg.message,
        })
      );
    } else if (
      !createOrg.isLoading &&
      createOrg.isCompleted &&
      createOrg.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: createOrg.message,
        })
      );
    }
  }, [createOrg]);
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  const editOrg = useSelector((state) => state.editOrg);
  useEffect(() => {
    if (!editOrg.isLoading && editOrg.isCompleted && !editOrg.isError) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: editOrg.message,
        })
      );
    } else if (!editOrg.isLoading && editOrg.isCompleted && editOrg.isError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: editOrg.message,
        })
      );
    }
  }, [editOrg]);

  const [preferences, setPreferences] = React.useState({
    pageSize: 20,
    wrapLines: false,
    visibleContent: ["name", "email", "org_status"],
  });

  useEffect(() => {
    getOrganizationsList();
  }, []);

  const deleteOrg = (orgIds) => {
    setOpenDeleteModel(false);
    axiosInstance
      .request({
        url: `/org`,
        method: "DELETE",
        data: { orgId: orgIds },
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: "Items deleted successfully",
          })
        );
        setSelectedItems([]);
        getOrganizationsList();
      })
      .catch((e) =>
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        )
      );
  };

  const columnDefinitions = [
    {
      header: "Name",
      id: "name",
      sortingField: "name",
      cell: (e) => <div>{e.name}</div>,
    },
    {
      header: "Email",
      id: "email",
      sortingField: "email",
      cell: (e) => <div>{e.email}</div>,
    },
    {
      header: "Status",
      id: "org_status",
      sortingField: "org_status",
      cell: (e) => <div>{e.org_status}</div>,
    },
    {
      header: "Created At",
      id: "created",
      sortingField: "created",
      cell: (e) => (
        <div>
          {e.created
            ? moment(e.created * 1000).format("MM/DD/yyyy, hh:mm A")
            : ""}
        </div>
      ),
    },
    {
      header: "Updated At",
      id: "last_updated",
      sortingField: "last_updated",
      cell: (e) => (
        <div>
          {e.last_updated
            ? moment(e.last_updated * 1000).format("MM/DD/yyyy, hh:mm A")
            : ""}
        </div>
      ),
    },
  ];

  const breadcrumbs = [
    {
      text: "Account Management",
      // href: '/users',
    },
    {
      text: "Teams",
      href: "/teams",
    },
  ];
  const deletingItemsSelected =
    selectedItems.filter((it) => it.state === "deleting").length > 0;
  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(organizationsList.organizationsList, {
    selection: {},
    propertyFiltering: {
      filteringProperties: organizationFilteringProperties,
      empty:
        organizationsList.isUserListLoading &&
        !organizationsList.isUserListLoadingCompleted ? (
          ""
        ) : (
          <TableEmptyState resourceName={"Teams"} />
        ),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });
  const Content = () => {
    return (
      <>
        <Table
          {...collectionProps}
          wrapLines={preferences.wrapLines}
          stripedRows={preferences.stripedRows}
          contentDensity={preferences.contentDensity}
          columnDisplay={preferences.contentDisplay}
          onSelectionChange={(d) => setSelectedItems(d.detail.selectedItems)}
          selectedItems={selectedItems}
          items={items}
          columnDefinitions={columnDefinitions}
          variant="full-page"
          selectionType="multi"
          resizableColumns={true}
          ariaLabels={{
            itemSelectionLabel: (_data, row) => `select ${row.id}`,
            allItemsSelectionLabel: () => "select all",
            selectionGroupLabel: `User selection`,
          }}
          preferences={
            <CommonPreference
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              contentDisplayOptions={organizationVisibleContentPreference}
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: "Next page",
                previousPageLabel: "Previous page",
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
              }}
            />
          }
          header={
            <>
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="l">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setOpenModel(true);
                      }}
                    >
                      Create
                    </Button>
                    <Button
                      disabled={selectedItems.length !== 1}
                      onClick={() => {
                        setOpenEditModel(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={
                        selectedItems.length === 0 || deletingItemsSelected
                      }
                      onClick={() => {
                        setOpenDeleteModel(true);
                      }}
                    >
                      Delete
                    </Button>
                  </SpaceBetween>
                }
                counter={
                  selectedItems.length
                    ? "(" +
                      selectedItems.length +
                      "/" +
                      organizationsList.organizationsList.length +
                      ")"
                    : "(" + organizationsList.organizationsList.length + ")"
                }
              >
                Teams
              </Header>

              <div className="py-5">
                <CustomFlashBar />
              </div>
            </>
          }
          filter={
            <PropertyFilter
              countText={getTextFilterCounterText(filteredItemsCount)}
              {...propertyFilterProps}
              i18nStrings={{
                filteringAriaLabel: "your choice",
                dismissAriaLabel: "Dismiss",
                clearAriaLabel: "Clear",

                filteringPlaceholder: "Filter users by text, property or value",
                groupValuesText: "Values",
                groupPropertiesText: "Properties",
                operatorsText: "Operators",

                operationAndText: "and",
                operationOrText: "or",

                operatorLessText: "Less than",
                operatorLessOrEqualText: "Less than or equal",
                operatorGreaterText: "Greater than",
                operatorGreaterOrEqualText: "Greater than or equal",
                operatorContainsText: "Contains",
                operatorDoesNotContainText: "Does not contain",
                operatorEqualsText: "Equals",
                operatorDoesNotEqualText: "Does not equal",

                editTokenHeader: "Edit filter",
                propertyText: "Property",
                operatorText: "Operator",
                valueText: "Value",
                cancelActionText: "Cancel",
                applyActionText: "Apply",
                allPropertiesLabel: "All properties",

                tokenLimitShowMore: "Show more",
                tokenLimitShowFewer: "Show fewer",
                clearFiltersText: "Clear filters",
                removeTokenButtonAriaLabel: (token) =>
                  `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                enteredTextLabel: (text) => `Use: "${text}"`,
              }}
              {...propertyFilterProps}
              expandToViewport={true}
            />
          }
        />
      </>
    );
  };

  //delete Model
  function DeleteModal({ organizations, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = organizations.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete organizations" : "Delete organization"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {organizations.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {organizations.length} organizations
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete organization
                <Box variant="span" fontWeight="bold">
                  {organizations[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              Proceeding with this action will delete the
              {isMultiple
                ? " organizations with all their content "
                : " role with all its content"}
              and can affect related items.
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <ColumnLayout columns={2}>
              <form onSubmit={handleDeleteSubmit}>
                <FormField
                  label={`To confirm this deletion, type "${deleteConsentText}".`}
                >
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </FormField>
              </form>
            </ColumnLayout>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  return (
    <>
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={<Content />}
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />

      <FormModel
        open={openModel}
        onClose={() => {
          setOpenModel(false);
        }}
        header="Create Team"
      >
          <CreateOrganizationForm
            setCompleted={() => {
              getOrganizationsList();
            }}
            setCancel={() => setOpenModel(false)}
          />
      </FormModel>
      <FormModel
        open={openEditModel}
        onClose={() => {
          setOpenEditModel(false);
        }}
        header="Edit Team"
      >
        <>
          <EditOrganizationForm
            defaults={selectedItems ? selectedItems[0] : null}
            setCompleted={() => {
              setSelectedItems([]);
              getOrganizationsList();
            }}
            setCancel={() => setOpenEditModel(false)}
          />
        </>
      </FormModel>
      <DeleteModal
        visible={openDeleteModel}
        organizations={selectedItems}
        onDelete={(e) => {
          if (selectedItems.length > 0) {
            deleteOrg(selectedItems.map((m) => m.id));
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenDeleteModel(false);
        }}
      />
    </>
  );
}
