import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getTopSearch } from "../request";

export const useTopSearchData = (keyword: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TOP_SEARCH_DATA, keyword],
    () => getTopSearch(keyword),
    { enabled: keyword.length > 0 },
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
  };
};
