import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaBug, FaLightbulb, FaRocket } from "react-icons/fa";
import {
  Pagination,
  BreadcrumbGroup,
  SideNavigation,
  AppLayout,
  Container,
  Box,
  Button,
  ButtonDropdown,
  CollectionPreferences,
  TextFilter,
  ContentLayout,
  Header,
  Toggle,
  SpaceBetween,
  Flashbar,
  Table,
  Modal,
  FormField,
  Input,
  Select,
  Textarea,
  Alert,
  ColumnLayout,
  Link,
  PropertyFilter,
} from "@cloudscape-design/components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuthContext } from "../../auth/useAuthContext";
import { navItems } from "../../layouts/common/menu/side-menu";
import { Controller, useForm } from "react-hook-form";
import {
  FILTERING_PROPERTIES,
  ISSUE_TYPES,
  STATUSES,
  createIssueFormValidation,
  editIssueFormValidation,
  qModules,
} from "./constants";
import { fetchAllIssues } from "./issues_actions";
import FormProvider from "../../components/forms/FormProvider";
import axiosInstance from "../../utils/axios";
import { method } from "lodash";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { getIssuesPreference } from "../common/redux/global-pref-selector";
import { fetchGlobalPref } from "../common/redux/global-pref-action";

const breadcrumbs = [{ text: "Support" }, { text: "Issues" }];

export default function IssueList() {
  const { user } = useAuthContext();
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [filteringOptions, setFilteringOptions] = useState([
    { propertyKey: "status", value: "Open" },
    { propertyKey: "status", value: "Closed" },
  ]);
  const [filteringProperties, setFilteringProperties] =
    useState(FILTERING_PROPERTIES);
  const [filteringQuery, setFilteringQuery] = useState({
    tokens: [],
    operation: "and",
  });
  const [preferences, setPreferences] = React.useState({
    pageSize: 10,
    wrapLines: true,
    stickyColumns: { first: 1, last: 0 },
    contentDisplay: [
      { id: "title", visible: true },
      { id: "status", visible: true },
      {
        id: "description",
        visible: false,
      },
      { id: "type", visible: true },
      { id: "id", visible: true },
      { id: "created at", visible: true },
      { id: "modified at", visible: true },
      { id: "created by", visible: true },
    ],
  });
  const issues = useSelector((state) => state.issues.data);
  const status = useSelector((state) => state.issues.status);

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          issues: {
            preferences: pref,
          },
        }),
      })
      .then((d) => {
        fetchGlobalPref(user.username);
      })
      .catch((e) => {});
  };


  const issuesPreference = useSelector(getIssuesPreference);

   useEffect(() => {
     if (issuesPreference !== null) {
       setPreferences(issuesPreference?.preferences);
     }
   }, [issuesPreference]);

  useEffect(() => {
    if (status === "idle") {
      fetchAllIssues();
    }
  }, [status]);



  const issueList = issues.map((issue) => ({
    title: issue.title,
    status: issue.status,
    description: issue.description,
    type: issue.type,
    id: issue.id,
    "created at": issue.created_at,
    "modified at": issue.modified_at,
    "created by": issue.created_by,
  }));

  //Form

  const createMethod = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: null,
      title: "",
      description: "",
      created_by: user?.username,
    },
    resolver: yupResolver(createIssueFormValidation),
  });

  const editMethod = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "",
      title: "",
      description: "",
      status: "",
      modified_by: user?.username,
    },
    resolver: yupResolver(editIssueFormValidation),
  });

  const { control: createIssueControl, handleSubmit, reset } = createMethod;

  const {
    control: editIssueControl,
    handleSubmit: handleEditSubmit,
    reset: editReset,
    setValue: setEditValue,
  } = editMethod;

  const createIssueFormSubmit = (data) => {
    createNewIssue({
      type: data?.type?.value,
      title: data.title,
      description: data.description,
      status: "Open",
      created_by: data.created_by,
    });
  };

  const editIssueFormSubmit = (data) => {
    editIssue({
      type: data?.type?.value,
      title: data.title,
      description: data.description,
      status: data?.status?.value,
      modified_by: data.modified_by,
    });
  };

  const createNewIssue = useCallback(
    (data) => {
      const generatedId = `I-${Math.floor(Date.now() / 1000)}`;
      axiosInstance
        .post("/issues", { ...data, id: generatedId })
        .then((d) => d.data)
        .then((d) => {
          setVisible(false);
          reset();
          fetchAllIssues();
        })
        .catch((e) => {});
    },
    [axiosInstance, fetchAllIssues, setVisible, reset]
  );

  const editIssue = useCallback(
    (data) => {
      axiosInstance
        .put(`/issues/${selectedItems[0]?.id}`, { ...data })
        .then((d) => d.data)
        .then((d) => {
          setShowEditModal(false);
          setSelectedItems([]);
          fetchAllIssues();
        })
        .catch((e) => {});
    },
    [
      axiosInstance,
      fetchAllIssues,
      setShowEditModal,
      setSelectedItems,
      selectedItems,
    ]
  );

  const deleteIssues = useCallback(
    (ids) => {
      axiosInstance
        .request({
          method: "delete",
          url: "/issues",
          data: {
            id: ids?.map((item) => item.id),
          },
        })
        .then((d) => d.data)
        .then((d) => {
          setShowDeleteModal(false);
          setSelectedItems([]);
          fetchAllIssues();
        })
        .catch((e) => {});
    },
    [axiosInstance, fetchAllIssues, setShowDeleteModal, setSelectedItems]
  );

  function DeleteModal({ instances, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = instances.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete entries" : "Delete entry"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {instances.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {instances.length} entries
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete entry
                <Box variant="span" fontWeight="bold">
                  {instances[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              Proceeding with this action will delete the
              {isMultiple
                ? " entry with all their content "
                : " entries with all its content"}
              and can affect related resources.
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <form onSubmit={handleDeleteSubmit}>
              <FormField
                label={`To confirm this deletion, type "${deleteConsentText}".`}
              >
                <ColumnLayout columns={2}>
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </ColumnLayout>
              </FormField>
            </form>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);

  useEffect(() => {
    fetchAllIssues({ query: filteringQuery.tokens });
  }, [filteringQuery]);

  const handlePropertyFilteringChange = ({ detail }) =>
    setFilteringQuery(detail);
  const columnDefinitions = [
    {
      id: "id",
      header: "ID",
      cell: (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {item.type === "Bug" && <FaBug style={{ color: "red" }} />}
          {item.type === "Feedback" && <FaLightbulb style={{ color: "yellow" }} />}
          {item.type === "New Feature" && <FaRocket style={{ color: "green" }} />}
          <Link href={`${window.location.origin}/issues/${item.id}`} className="ml-2">
            {item.id}
          </Link>
        </div>
      ),
      sortingField: "id",
    },

    {
      id: "title",
      header: "Title",
      cell: (item) => item.title,
      sortingField: "title",
    },
    {
      id: "status",
      header: "Status",
      cell: (item) => item.status,
      sortingField: "status",
    },
    // {
    //   id: "type",
    //   header: "Type",
    //   cell: (item) => item.type,
    //   sortingField: "type",
    // },

    {
      id: "created at",
      header: "Created At",
      cell: (item) => new Date(item["created at"] * 1000).toLocaleString(),
      sortingField: "created at",
    },
    {
      id: "modified at",
      header: "Modified At",
      cell: (item) => new Date(item["modified at"] * 1000).toLocaleString(),
      sortingField: "modified at",
    },
    {
      id: "created by",
      header: "Created By",
      cell: (item) => item["created by"],
      sortingField: "created by",
    },
  ];
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(issueList, {
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    contentDisplay: [
      { id: "title", label: "Title" },
      { id: "status", label: "Status" },
      {
        id: "description",
        label: "Description",
        alwaysVisible: false,
      },
      { id: "type", label: "Type" },
      { id: "id", label: "ID" },
      { id: "created at", label: "Created At" },
      { id: "modified at", label: "Modified At" },
      { id: "created by", label: "Created By" },
    ],
  });

  return (
    <>
      <Helmet>
        <title> Issue Tracker</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        content={
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Table
              {...collectionProps}
              stickyHeader={true}
              resizableColumns={true}
              wrapLines={preferences.wrapLines}
              stripedRows={preferences.stripedRows}
              contentDensity={preferences.contentDensity}
              stickyColumns={preferences.stickyColumns}
              header={
                <>
                  <Header
                    variant="h3"
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <ButtonDropdown
                          onItemClick={(e) => {
                            if (
                              e.detail.id === "rn" &&
                              selectedItems.length > 0
                            ) {
                              onDeleteInit();
                            }
                            if (
                              e.detail.id === "view" &&
                              selectedItems.length === 1
                            ) {
                              window.location =
                                "/issues/" + selectedItems[0].id;
                            }
                            if (
                              e.detail.id === "mv" &&
                              selectedItems.length === 1
                            ) {
                              setEditValue(
                                "type",
                                ISSUE_TYPES.find(
                                  (item) =>
                                    item.value === selectedItems[0]?.type
                                )
                              );
                              setEditValue("title", selectedItems[0]?.title);
                              setEditValue(
                                "description",
                                selectedItems[0]?.description
                              );
                              setEditValue(
                                "status",
                                STATUSES.find(
                                  (item) =>
                                    item.value === selectedItems[0]?.status
                                )
                              );
                              setShowEditModal(true);
                            }
                          }}
                          items={[
                            {
                              text: "View details",
                              id: "view",
                              disabled:
                                selectedItems.length === 1 ? false : true,
                            },
                            {
                              text: "Edit",
                              id: "mv",
                              disabled:
                                selectedItems.length === 1 ? false : true,
                            },
                            {
                              text: "Delete",
                              id: "rn",

                              disabled: false,
                            },
                          ]}
                        >
                          Actions
                        </ButtonDropdown>
                        <Button
                          variant="primary"
                          onClick={() => setVisible(true)}
                        >
                          Create issue
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="pr-5">Issue Tracker</span>
                      <Toggle
                        checked={isFullWidth}
                        onChange={() => setIsFullWidth(!isFullWidth)}
                      >
                        Full width
                      </Toggle>
                    </div>
                  </Header>
                </>
              }
              onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
              }
              selectedItems={selectedItems}
              variant="full-page"
              ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                  `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                  } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => item.name,
              }}
              columnDefinitions={columnDefinitions}
              columnDisplay={preferences.contentDisplay}
              items={items}
              loadingText="Loading issues"
              selectionType="multi"
              trackBy="id"
              empty={
                <Box
                  margin={{ vertical: "xs" }}
                  textAlign="center"
                  color="inherit"
                >
                  <SpaceBetween size="m">
                    <b>No Issues to display</b>
                    <Button variant="primary" onClick={() => setVisible(true)}>
                      Create issue
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              filter={
                <PropertyFilter
                  query={filteringQuery}
                  i18nStrings={{
                    filteringAriaLabel: "your choice",
                    dismissAriaLabel: "Dismiss",
                    clearAriaLabel: "Clear",

                    filteringPlaceholder:
                      "Filter issues by text, property or value",
                    groupValuesText: "Values",
                    groupPropertiesText: "Properties",
                    operatorsText: "Operators",

                    operationAndText: "and",
                    operationOrText: "or",

                    operatorLessText: "Less than",
                    operatorLessOrEqualText: "Less than or equal",
                    operatorGreaterText: "Greater than",
                    operatorGreaterOrEqualText: "Greater than or equal",
                    operatorContainsText: "Contains",
                    operatorDoesNotContainText: "Does not contain",
                    operatorEqualsText: "Equals",
                    operatorDoesNotEqualText: "Does not equal",

                    editTokenHeader: "Edit filter",
                    propertyText: "Property",
                    operatorText: "Operator",
                    valueText: "Value",
                    cancelActionText: "Cancel",
                    applyActionText: "Apply",
                    allPropertiesLabel: "All properties",

                    tokenLimitShowMore: "Show more",
                    tokenLimitShowFewer: "Show fewer",
                    clearFiltersText: "Clear filters",
                    removeTokenButtonAriaLabel: (token) =>
                      `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                    enteredTextLabel: (text) => `Use: "${text}"`,
                  }}
                  filteringProperties={filteringProperties}
                  filteringOptions={filteringOptions}
                  onChange={handlePropertyFilteringChange}
                />
              }
              pagination={
                <Pagination
                  {...paginationProps}
                  ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber) =>
                      `Page ${pageNumber} of all pages`,
                  }}
                />
              }
              preferences={
                <CollectionPreferences
                  onConfirm={({ detail }) => {
                    saveResponse(detail);
                  }}
                  title="Preferences"
                  confirmLabel="Confirm"
                  cancelLabel="Cancel"
                  preferences={preferences}
                  pageSizePreference={{
                    title: "Page size",
                    options: [
                      { value: 5, label: "5 per page" },
                      { value: 10, label: "10 per page" },
                      { value: 20, label: "20 per page" },
                      { value: 30, label: "30 per page" },
                      { value: 40, label: "40 per page" },
                      { value: 50, label: "50 per page" },
                    ],
                  }}
                  wrapLinesPreference={{
                    label: "Warp Lines",
                    description:
                      "Select to see all the text and wrap the lines",
                  }}
                  stripedRowsPreference={{
                    label: "Striped rows",
                    description: "Select to add alternating shaded rows",
                  }}
                  contentDensityPreference={{
                    label: "Compact mode",
                    description:
                      "Select to display content in a denser, more compact mode",
                  }}
                  contentDisplayPreference={{
                    options: [
                      { id: "title", label: "Title" },
                      { id: "status", label: "Status" },
                      {
                        id: "description",
                        label: "Description",
                        alwaysVisible: false,
                      },
                      { id: "type", label: "Type" },
                      { id: "id", label: "ID" },
                      { id: "created at", label: "Created At" },
                      { id: "modified at", label: "Modified At" },
                      { id: "created by", label: "Created By" },
                    ],
                  }}
                  stickyColumnsPreference={{
                    firstColumns: {
                      title: "Stick first column(s)",
                      description:
                        "Keep the first column(s) visible while horizontally scrolling the table content.",
                      options: [
                        { label: "None", value: 0 },
                        { label: "First column", value: 1 },
                        { label: "First two columns", value: 2 },
                      ],
                    },
                    lastColumns: {
                      title: "Stick last column",
                      description:
                        "Keep the last column visible while horizontally scrolling the table content.",
                      options: [
                        { label: "None", value: 0 },
                        { label: "Last column", value: 1 },
                      ],
                    },
                  }}
                />
              }
            />
            <Modal
              onDismiss={(e) =>
                e?.detail.reason === "closeButton" ? setVisible(false) : {}
              }
              visible={visible}
              header="Create Issue"
            >
              <FormProvider
                methods={createMethod}
                onSubmit={handleSubmit(createIssueFormSubmit)}
              >
                <SpaceBetween direction="vertical" size="l">
                  <Controller
                    name={"type"}
                    control={createIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Type" errorText={error?.message}>
                        <Select
                          {...field}
                          options={ISSUE_TYPES}
                          errorText={error?.message}
                          selectedOption={field.value}
                          onChange={({ detail }) =>
                            field.onChange(detail.selectedOption)
                          }
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={"title"}
                    control={createIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Title" errorText={error?.message}>
                        <Input
                          {...field}
                          value={field.value}
                          invalid={invalid}
                          onChange={({ detail }) =>
                            field.onChange(detail.value)
                          }
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={"description"}
                    control={createIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Description" errorText={error?.message}>
                        <div style={{ height: "400px", overflow: "auto" }}>
                          <ReactQuill
                            {...field}
                            value={field.value}
                            modules={qModules}
                            onChange={(t) => field.onChange(t)}
                            style={{ height: "350px" }}
                          />
                        </div>
                      </FormField>
                    )}
                  />

                  {/*   <FormField label="Status">
                  <Controller
                    name={"status"}
                    control={createIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <>
                        <Select
                          options={STATUSES}
                          selectedOption={field.value}
                          onChange={({ detail }) =>
                            field.onChange(detail.selectedOption)
                          }
                        />
                      </>
                    )}
                  />
                </FormField> */}
                  <FormField>
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          formAction="none"
                          onClick={(e) => {
                            e.preventDefault();
                            reset();
                            setVisible(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" formAction="submit">
                          Create
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </FormField>
                </SpaceBetween>
              </FormProvider>
            </Modal>
            <Modal
              onDismiss={(e) =>
                e?.detail.reason === "closeButton"
                  ? setShowEditModal(false)
                  : {}
              }
              visible={showEditModal}
              header="Edit Issue"
            >
              <FormProvider
                methods={editMethod}
                onSubmit={handleEditSubmit(editIssueFormSubmit)}
              >
                <SpaceBetween direction="vertical" size="l">
                  <Controller
                    name={"type"}
                    control={editIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Type" errorText={error?.message}>
                        <Select
                          {...field}
                          options={ISSUE_TYPES}
                          errorText={error?.message}
                          selectedOption={field.value}
                          onChange={({ detail }) =>
                            field.onChange(detail.selectedOption)
                          }
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={"title"}
                    control={editIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Title" errorText={error?.message}>
                        <Input
                          {...field}
                          value={field.value}
                          invalid={invalid}
                          onChange={({ detail }) =>
                            field.onChange(detail.value)
                          }
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={"description"}
                    control={editIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Description" errorText={error?.message}>
                        <ReactQuill
                          theme="snow"
                          {...field}
                          value={field.value}
                          spellcheck
                          rows={10}
                          modules={qModules}
                          onChange={(t) => field.onChange(t)}
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={"status"}
                    control={editIssueControl}
                    render={({
                      field,
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <FormField label="Status" errorText={error?.message}>
                        <Select
                          options={STATUSES}
                          selectedOption={field.value}
                          onChange={({ detail }) =>
                            field.onChange(detail.selectedOption)
                          }
                        />
                      </FormField>
                    )}
                  />

                  <FormField>
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          formAction="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowEditModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" formAction="submit">
                          Submit
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </FormField>
                </SpaceBetween>
              </FormProvider>
            </Modal>
            <DeleteModal
              visible={showDeleteModal}
              onDiscard={() => onDeleteDiscard()}
              onDelete={() => deleteIssues(selectedItems)}
              instances={selectedItems ?? []}
            />
          </div>
        }
      />
    </>
  );
}
