import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import { Layout } from "../Layout";
import { ChannelPage } from "./ChannelPage";

function YoutubeChannelItemPage() {
  const { id: channelId } = useParams();
  const [channelDetails, setChannelDetails] = useState(null);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/youtube/v1/channels/${channelId}`,
        );
        setChannelDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch channel details:", error);
      }
    };

    fetchChannelDetails();
  }, [channelId]);

  const breadcrumbs = [
    { text: "Media Catalog" },
    { text: "YouTube", href: "/mediacatalog/youtube" },
    { text: "Channels", href: "/mediacatalog/youtube/channels" },
    { text: channelDetails?.title },
  ];

  return (
    <Layout
      title={channelDetails?.title}
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={<ChannelPage />}
      contentType="table"
    />
  );
}

export default YoutubeChannelItemPage;
