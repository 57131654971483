import { SpaceBetween } from "@cloudscape-design/components";

const OTTProvider = ({ itemData }) => {
    return (
        (<SpaceBetween direction="horizontal" size="m">
            {itemData.data.providers.map((provider) => {
                const formattedProvider = provider
                    .replace(/\s+/g, "")
                    .replace(/\+/g, "_");
                return (
                    <div
                        title={provider}
                        key={provider}
                        style={{
                            transition: "transform 0.2s ease-in-out",
                            marginTop: "10px",
                        }}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.3)")
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                        }
                    >
                        <img
                            src={`https://images.searchpantheon.com/serviceProviderIcons/${formattedProvider}.webp`}
                            alt={`${provider} Icon`}
                            className="rounded-lg"
                            style={{ width: "60px", height: "60px" }}
                        />
                    </div>
                );
            })}
        </SpaceBetween>)
    );
}

export default OTTProvider;