import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";

export function DeleteConfirmationModal({
  instances,
  visible,
  onDiscard,
  onDelete,
  consentText,
}) {
  const [deleteInputText, setDeleteInputText] = useState("");
  useEffect(() => {
    setDeleteInputText("");
  }, [visible]);

  const handleDeleteSubmit = (event) => {
    event.preventDefault();
    if (inputMatchesConsentText) {
      onDelete();
    }
  };

  const inputMatchesConsentText = deleteInputText.toLowerCase() === consentText?.toLowerCase();

  const isMultiple = instances.length > 1;
  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={isMultiple ? "Delete Items" : `Delete ${consentText}`}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onDelete}
              disabled={!inputMatchesConsentText}
              data-testid="submit"
            >
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {instances.length > 0 && (
        <SpaceBetween size="m">
          <Box variant="span">
            Are you sure you want to delete this franchise?
          </Box>

          <Box>
            To avoid accidental deletions, we ask you to provide additional
            written consent.
          </Box>

          <form onSubmit={handleDeleteSubmit}>
            <FormField
              label={`To confirm this deletion, type "${consentText}".`}
            >
              <ColumnLayout columns={2}>
                <Input
                  placeholder={consentText}
                  onChange={(event) => setDeleteInputText(event.detail.value)}
                  value={deleteInputText}
                  ariaRequired={true}
                />
              </ColumnLayout>
            </FormField>
          </form>
        </SpaceBetween>
      )}
    </Modal>
  );
}
