import { Helmet } from "react-helmet-async";
import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TextFilter,
  ContentLayout,
  FormField,
  ButtonDropdown,
  Header,
  Table,
  Box,
  Link,
  SpaceBetween,
  HelpPanel,
  Select,
  DatePicker,
  Spinner,
  SideNavigation,
  BreadcrumbGroup,
  AppLayout,
  Button,
} from "@cloudscape-design/components";
import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import moment from "moment";
import { navItems } from "../../layouts/common/menu/side-menu";
import { getGeneralTrendsData, getWikiLeaderBoardData } from "./redux/actions";
import {
  getGeneralWikipediaTrendsData,
  getWikipediaLeaderBoardData,
} from "./redux/selectors";
import SplitPanelContainer from "../../components/SearchDemandSplitPanelContainer";
import { GetPanelContent } from "../../components/SearchDemandUtils";
import { useAuthContext } from "../../auth/useAuthContext";
import axiosInstance from "../../utils/axios";
import { genreMapping } from "../../components/GenreMapping";
import shortenInteger from "../../utils/shortenInteger";
import { CustomPagination } from "../../components/CustomPagination";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../common/redux/flash-action";
import createFlashMessage from "../../utils/createFlashMessage";
import CustomFlashBar from "../common/CustomFlashBar";
import TrendsPopover from "./TrendsPopover";

export default function WikipediaPage() {
  const { user } = useAuthContext();
  const [dateRange, setDateRange] = useState(null);
  const [generalTrendsDate, setGeneralTrendsDate] = useState("");
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [isPrefLoadCompleted, setIsPrefLoadCompleted] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const generalTrendsData = useSelector(
    (state) => state.generalWikipediaTrendsData,
  );
  const [selectedIps, setSelectedIps] = useState([]);
  const [selectedOptionSelectIPList, setSelectedOptionSelectIPList] = useState({
    label: "Pantheon",
    value: "Pantheon",
  });
  const [selectedOptionVertical, setSelectedOptionVertical] = useState(null);
  const [selectedOptionGenre, setSelectedOptionGenre] = useState(null);
  const [genreOptions, setGenreOptions] = useState([]);
  const [isGenreDisabled, setIsGenreDisabled] = useState(true);
  const generalWikipediaTrendsData = useSelector(getGeneralWikipediaTrendsData);
  const leaderboardData = useSelector(getWikipediaLeaderBoardData);
  const indexOfLastItem = currentPageIndex * 48;
  const indexOfFirstItem = indexOfLastItem - 48;
  const checkAndInsertIps = (ips) => {
    const index = selectedIps.findIndex((ip) => ip.ip_id === ips.ip_id);
    if (index === -1) {
      setSelectedIps((prevIps) => [...prevIps, ips]);
    } else {
      const newSelectedIps = [...selectedIps];
      newSelectedIps.splice(index, 1);
      setSelectedIps(newSelectedIps);
    }
  };
  const [isHelpPanelOpen, setIsHelpPanelOpen] = useState(false);
  const toggleHelpPanel = () => {
    setIsHelpPanelOpen(!isHelpPanelOpen);
  };

  useEffect(() => {
    if (selectedOptionVertical && selectedOptionVertical.value) {
      const genresForVertical = Object.entries(genreMapping)
        .filter(([_, verticals]) =>
          verticals.includes(selectedOptionVertical.value),
        )
        .map(([genre]) => ({ label: genre, value: genre }));
      setGenreOptions([{ label: "All", value: "" }, ...genresForVertical]);
      setIsGenreDisabled(false);
    } else {
      setGenreOptions([]);
      setIsGenreDisabled(true);
    }
  }, [selectedOptionVertical]);

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    getGeneralTrendsData({ date: "" });
  }, []);

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [
    dateRange,
    selectedOptionSelectIPList,
    selectedOptionVertical,
    selectedOptionGenre,
    keyword,
  ]);

  const getWikipediaLeaderBoard = useCallback(
    ({
      dateRange,
      selectedOptionSelectIPList,
      selectedOptionVertical,
      selectedOptionGenre,
      keyword,
      currentPageIndex,
    }) => {
      const indexOfLastItem = currentPageIndex * 48;
      const indexOfFirstItem = indexOfLastItem - 48;

      getWikiLeaderBoardData({
        gte: dateRange?.startDate
          ? moment(dateRange.startDate).unix()
          : moment().subtract(15, "days").unix(),
        lte: dateRange?.endDate
          ? moment(dateRange.endDate).unix()
          : moment().unix(),
        ip_list: selectedOptionSelectIPList?.value ?? "",
        vertical: selectedOptionVertical?.value ?? "",
        genre:
          selectedOptionVertical?.value && selectedOptionGenre?.value
            ? selectedOptionGenre?.value
            : "",
        keyword: keyword ?? "",
        size: 48,
        from: indexOfFirstItem,
      });
    },
    [],
  );

  useEffect(() => {
    if (isPrefLoadCompleted) {
      getWikipediaLeaderBoard({
        dateRange,
        selectedOptionSelectIPList,
        selectedOptionVertical,
        selectedOptionGenre,
        keyword,
        currentPageIndex,
      });
    }
  }, [
    dateRange,
    selectedOptionSelectIPList,
    selectedOptionVertical,
    selectedOptionGenre,
    keyword,
    currentPageIndex,
    isPrefLoadCompleted,
  ]);

  useEffect(() => {
    if (generalTrendsDate) {
      const unixTimestampToSend = moment.utc(generalTrendsDate).unix();
      getGeneralTrendsData({
        date: unixTimestampToSend,
      });
    }
  }, [generalTrendsDate]);

  const saveResponse = () => {
    let payload = {
      wikipedia: {
        leaderboard: {
          preferences: {
            unit: dateRange?.unit ?? "",
            amount: dateRange?.amount ?? "",
            dateType: dateRange?.type ?? "absolute",
            ip_list: selectedOptionSelectIPList ?? "",
            vertical: selectedOptionVertical ?? "",
            genre: selectedOptionGenre ?? "",
            keyword: keyword ?? "",
            size: 48,
            from: indexOfFirstItem,
          },
        },
      },
    };
    if (payload.wikipedia.leaderboard.preferences.dateType === "absolute") {
      payload.wikipedia.leaderboard.preferences.gte = dateRange?.startDate
        ? moment(dateRange.startDate).unix()
        : moment().subtract(15, "days").unix();
      payload.wikipedia.leaderboard.preferences.lte = dateRange?.endDate
        ? moment(dateRange.endDate).unix()
        : moment().unix();
    }

    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify(payload),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          }),
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.response?.data?.message || "Failed to save preference ",
          }),
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d?.wikipedia?.leaderboard?.preferences) {
          setKeyword(d?.wikipedia?.leaderboard?.preferences?.keyword);
          setSelectedOptionSelectIPList(
            d?.wikipedia?.leaderboard?.preferences?.ip_list,
          );
          setSelectedOptionGenre(d?.wikipedia?.leaderboard?.preferences?.genre);
          setSelectedOptionVertical(
            d?.wikipedia?.leaderboard?.preferences?.vertical,
          );
          if (d?.wikipedia?.leaderboard?.preferences?.dateType) {
            let newDateRange = {
              type:
                d?.wikipedia?.leaderboard?.preferences?.dateType ?? "absolute",
              unit: d?.wikipedia?.leaderboard?.preferences?.unit ?? "",
              amount: d?.wikipedia?.leaderboard?.preferences?.amount ?? "",
            };
            if (newDateRange.type === "absolute") {
              newDateRange.startDate = moment(
                d?.wikipedia?.leaderboard?.preferences?.gte * 1000,
              ).format("YYYY-MM-DD");
              newDateRange.endDate = moment(
                d?.wikipedia?.leaderboard?.preferences?.lte * 1000,
              ).format("YYYY-MM-DD");
            } else if (newDateRange.type === "relative") {
              newDateRange.startDate = moment()
                .subtract(
                  d?.wikipedia?.leaderboard?.preferences?.amount,
                  d?.wikipedia?.leaderboard?.preferences?.unit,
                )
                .format("YYYY-MM-DD");
              newDateRange.endDate = moment().format("YYYY-MM-DD");
            }
            setDateRange(newDateRange);
          }
          setIsPrefLoadCompleted(true);
        } else {
          getWikipediaLeaderBoard({
            dateRange,
            selectedOptionSelectIPList,
            selectedOptionVertical,
            selectedOptionGenre,
            keyword,
            currentPageIndex,
          });
        }
      })
      .catch((e) => setIsPrefLoadCompleted(true));
  };

  useEffect(() => {
    if (leaderboardData.completed && leaderboardData.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: leaderboardData.message.message,
        }),
      );
    }
  }, [leaderboardData]);

  const Content = (
    <Container
      style={{ height: "87vh" }}
      fitHeight
      header={
        <Header
          variant="h3"
          actions={
            <ButtonDropdown
              onItemClick={({ detail }) => {
                if (detail.id === "df") {
                  saveResponse();
                }
              }}
              expandToViewport
              items={[
                { text: "Export", id: "ex", disabled: true },
                { text: "Set as Default", id: "df" },
              ]}
              // variant="primary"
              // mainAction={{ text: "Set as Default", id: "df" }}
            >
              Actions
            </ButtonDropdown>
          }
        >
          Worldwide Leaderboard
        </Header>
      }
    >
      <div className="flex w-full justify-between items-center">
        <div>
          <TextFilter
            filteringPlaceholder="Filter by IP"
            filteringText={keyword}
            onChange={({ detail }) => {
              setKeyword(detail.filteringText);
            }}
          />
        </div>
        <div>
          <SpaceBetween direction="horizontal" size="xs">
            <FormField description="Date range selector">
              <RangeDateSelector
                defaults={
                  dateRange
                    ? dateRange?.type === "relative"
                      ? {
                          ...dateRange,
                          startDate: moment(dateRange.startDate),
                          endDate: moment(dateRange.endDate),
                        }
                      : dateRange
                    : {
                        type: "absolute",
                        startDate: moment()
                          .subtract(15, "days")
                          .startOf("day")
                          .format("YYYY-MM-DD"),
                        endDate: moment().format("YYYY-MM-DD"),
                      }
                }
                onChange={(e) => {
                  if (e.type === "relative") {
                    setDateRange({
                      ...e,
                      startDate: moment(e.startDate).format("YYYY-MM-DD"),
                      endDate: moment(e.endDate).format("YYYY-MM-DD"),
                    });
                  } else {
                    setDateRange(e);
                  }
                }}
              />
            </FormField>

            <FormField description="IP list">
              <Select
                selectedOption={selectedOptionSelectIPList}
                onChange={({ detail }) => {
                  setSelectedOptionSelectIPList(detail.selectedOption);
                }}
                filteringType="auto"
                expandToViewport
                controlId="select-iplist"
                options={[
                  {
                    label: "NBCUniversal",
                    value: "nbcu",
                  },
                  {
                    label: "Pantheon",
                    value: "Pantheon",
                  },
                ]}
              />
            </FormField>
            <FormField description="Category">
              <Select
                selectedOption={selectedOptionVertical}
                expandToViewport
                onChange={({ detail }) => {
                  setSelectedOptionVertical(detail.selectedOption);
                }}
                controlId="select-vertical"
                placeholder="Category"
                options={[
                  { label: "All", value: "" },
                  { label: "Movies", value: "Movies" },
                  { label: "Television", value: "Television" },
                  { label: "Games", value: "Gaming" },
                ]}
              />
            </FormField>
            <FormField description="Genre">
              <Select
                selectedOption={selectedOptionGenre}
                onChange={({ detail }) => {
                  setSelectedOptionGenre(detail.selectedOption);
                }}
                expandToViewport
                controlId="select-genre"
                placeholder="Genre"
                disabled={isGenreDisabled}
                options={genreOptions}
              />
            </FormField>
          </SpaceBetween>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center">
        {leaderboardData.loading && (
          <div className="flex flex-row justify-center items-center">
            <Spinner size="large" />
          </div>
        )}

        {(leaderboardData?.data ?? []).map((item) => {
          let imageUrl = "-";
          if (
            item.ip_id.startsWith("film-") ||
            item.ip_id.startsWith("series-")
          ) {
            imageUrl = `https://image.tmdb.org/t/p/w154/${item.image_url}`;
          } else if (item.ip_id.startsWith("game-")) {
            imageUrl = `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
          }

          return (
            <div className="inline-block relative group mt-2">
              <div
                className={`w-32 h-52 max-w-xs overflow-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out border-2 border-slate-600 m-1`}
              >
                <div className="flex justify-center items-center">
                  <Link
                    href={
                      item.ip_id
                        ? `${window.location.origin}/item/${item.ip_id}`
                        : undefined
                    }
                  >
                    <img
                      src={imageUrl}
                      alt={item.IP}
                      className={`w-36 rounded-md`}
                    />
                    <div className="absolute inset-0 rounded-md items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity border-2 border-slate-600 bg-black bg-opacity-90 p-4">
                      <span className="text-white">{item.ip}</span>
                    </div>
                  </Link>

                  <div className="absolute top-2 right-2 p-3 h-2 w-2 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-yellow-500 font-bold text-md">
                    {item.vertical.charAt(0)}
                  </div>

                  <div
                    className={`absolute bottom-2 left-2 bg-sidebarLow border-2 border-slate-600 rounded-full px-2.5 py-1`}
                  >
                    <span className="text-amber-500 font-bold flex flex-row items-center">
                      <p>#</p> {item.rank}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => checkAndInsertIps(item)}
                    className={`absolute bottom-2 right-2 rounded-full px-3 py-1.5 text-sm font-semibold border-2 border-slate-600 shadow-sm ${
                      selectedIps.filter((ip) => ip.ip_id === item.ip_id)
                        .length > 0
                        ? "bg-blue-500 text-white"
                        : "bg-sidebarLow text-white"
                    } hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                  >
                    {`${shortenInteger(item.views)}`}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <CustomPagination
        currentPage={currentPageIndex}
        totalPages={
          leaderboardData?.total ? Math.ceil(leaderboardData?.total / 48) : 1
        }
        onNext={() => setCurrentPageIndex(currentPageIndex + 1)}
        onPrevious={() => setCurrentPageIndex(currentPageIndex - 1)}
        onPageClick={(page) => setCurrentPageIndex(page)}
      />
    </Container>
  );
  const breadcrumbs = [
    {
      text: "Platforms",
      // href: "",
    },
    {
      text: "Wikipedia",
      href: "/platforms/wikipedia",
    },
  ];

  const wikiTrends = (
    <>
      <div className="flex justify-end">
        <DatePicker
          expandToViewport
          value={generalTrendsDate}
          placeholder="YYYY/MM/DD"
          onChange={({ detail }) => setGeneralTrendsDate(detail.value)}
          isDateEnabled={(date) => {
            const minDate = new Date(2023, 0, 1);
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() - 2);
            return date >= minDate && date <= maxDate;
          }}
        />
      </div>

      <div>
        <Table
          variant="borderless"
          loading={generalTrendsData?.loading}
          columnDefinitions={[
            {
              id: "rank",
              header: "#",
              cell: (item) => item.rank,
              sortingField: "rank",
            },
            {
              id: "article",
              header: "Article",
              cell: (item) =>
                item.ip_id ? (
                  <Button
                    variant="inline-link"
                    href={`${window.location.origin}/item/${item.ip_id}`}
                  >
                    {item.article.replaceAll("_", " ")}
                  </Button>
                ) : (
                  <div className="py-2">
                    {item.article.replaceAll("_", " ")}
                  </div>
                ),
              sortingField: "IP",
            },
            {
              id: "views",
              header: "Views",
              minWidth: 130,
              cell: (item) => {
                const totalViews = item.views_trend.reduce(
                  (sum, view) => sum + view.views,
                  0,
                );
                const data = item.views_trend.map((trend) => ({
                  timestamp: trend.timestamp,
                  views: trend.views,
                }));
                return <TrendsPopover data={data} totalViews={totalViews} />;
              },
              sortingField: "views",
            },
          ]}
          items={generalWikipediaTrendsData?.articles ?? []}
          loadingText=""
          sortingDisabled
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No items</b>
              </SpaceBetween>
            </Box>
          }
        />
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Wikipedia</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        splitPanelOpen={splitPanelStatus}
        contentType="dashboard"
        toolsOpen={isHelpPanelOpen}
        onToolsChange={({ detail }) => setIsHelpPanelOpen(detail.open)}
        toolsWidth={600}
        tools={
          <HelpPanel header="Wikipedia General Trends">{wikiTrends}</HelpPanel>
        }
        onSplitPanelToggle={({ detail: { open } }) => setSplitPanelStatus(open)}
        splitPanel={
          <SplitPanelContainer
            selectedItems={selectedIps}
            onTogglePanelStatus={(status) => setSplitPanelStatus(status)}
            contents={
              <SpaceBetween>
                <GetPanelContent
                  items={selectedIps}
                  setItems={(items) => setSelectedIps(items)}
                />
              </SpaceBetween>
            }
          />
        }
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  variant="h3"
                  actions={
                    <Button onClick={toggleHelpPanel}>General Trends</Button>
                  }
                >
                  Wikipedia
                </Header>
                <CustomFlashBar />
              </>
            }
          >
            {Content}
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}
