const IMDbRating = ({ percent, title, subtitle }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex space-x-2 items-center">
                <img src="/rticons/imdb_star.svg" alt="Votes" className="h-8" />
                <div className="text-xl text-slate-50 font-bold"> {percent} </div>
            </div>
            <div className="text-xs font-bold">{title}</div>
            <div className="text-xs italic text-slate-400">
                {subtitle}
            </div>
        </div>
    );
};

export default IMDbRating;