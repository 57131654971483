import { Link } from "@cloudscape-design/components";
import moment from "moment";
import React from "react";
import shortenInteger from "../../../utils/shortenInteger";
import { ResponseDataItemType } from "./api/type";

export const columnDefinitions = [
  {
    id: "image",
    width: 85,
    cell: (e: ResponseDataItemType) => {
      return (
        e.channel_id && (
          <a href={`/mediacatalog/youtube/channels/${e.channel_id}`}>
            <img
              src={`https://images.searchpantheon.com/youtubechannels/${e.channel_id}.jpeg`}
              alt={e.title}
              className="rounded"
              loading="lazy"
            />
          </a>
        )
      );
    },
  },
  {
    id: "title",
    header: "Title & Summary",
    width: 600,
    minWidth: 300,
    cell: (e: ResponseDataItemType) => {
      const truncatedSummary =
        e.description.length > 100
          ? e.description.substring(0, 100) + "..."
          : e.description;
      return (
        <div>
          <Link
            href={`/mediacatalog/youtube/channels/${e.channel_id}`}
            fontSize="heading-s"
            target="_blank"
            rel="noopener noreferrer"
            variant="secondary"
          >
            {e.title}
          </Link>
          {e.madeForKids && (
            <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
              Kids
            </span>
          )}
          <div className="text-slate-400">{truncatedSummary}</div>
        </div>
      );
    },
  },
  {
    id: "publishedAt",
    header: "Published On",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>
        {moment(new Date(e.publishedAt * 1000)).format("MMMM D, YYYY")}
      </span>
    ),
    sortingField: "publishedAt",
  },
  {
    id: "type",
    header: "Channel Type",
    width: 180,
    cell: (e: ResponseDataItemType) => <span>{e.type}</span>,
    sortingField: "type",
  },
  {
    id: "subscriberCount",
    header: "Subscribers",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.subscriberCount)}</span>
    ),
    sortingField: "subscriberCount",
  },
  {
    id: "viewCount",
    header: "Views",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.viewCount)}</span>
    ),
    sortingField: "viewCount",
  },
  {
    id: "videoCount",
    header: "Videos",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.videoCount)}</span>
    ),
    sortingField: "videoCount",
  },
  {
    id: "todaysViews",
    header: "Today's Views",
    width: 180,
    cell: (e: ResponseDataItemType) => (
      <span>{shortenInteger(e.todaysViews)}</span>
    ),
    sortingField: "todaysViews",
  }
];

export const visibleContentPreference = [
  {
    id: "image",
    label: "Image",
    visible: true,
  },
  {
    id: "title",
    label: "Title & Summary",
    visible: true,
    alwaysVisible: true,
  },
  {
    id: "publishedAt",
    label: "Published On",
    visible: true,
  },
  {
    id: "type",
    label: "Channel Type",
    visible: true,
  },
  {
    id: "subscriberCount",
    label: "Subscribers",
    visible: true,
  },
  {
    id: "viewCount",
    label: "Views",
    visible: true,
  },
  {
    id: "videoCount",
    label: "Videos",
    visible: true,
  },
  {
    id: "todaysViews",
    label: "Today's Views",
    visible: true,
  },
];
