import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../../ag-styles.css";
import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Header,
  Toggle,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Select,
  Box,
  Flashbar,
} from "@cloudscape-design/components";

import { navItems } from "../../../../layouts/common/menu/side-menu";

import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import { getTarSeriesWikiViewGenreResponse } from "./redux/actions/tar-series-wiki-actions";
import { AgChartsReact } from "ag-charts-react";
import createFlashMessage from "../../../../utils/createFlashMessage";
import numberWithCommas from "../../../../utils/ag-utils";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../../common/redux/flash-action";
import CustomFlashBar from "../../../common/CustomFlashBar";

const TarSeriesWikiViewGenrePage = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment()
      .subtract(12, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [selectedId, setSelectedId] = React.useState("seg-1");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [granularity, setGranularity] = useState({
    value: "month",
    label: "Month",
  });
  const [chartOptions, setChartOptions] = useState({});
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  // const chartOptions = getBarChartOptions(selectedRows);
  //const chartOptions1 = getLineChartOptions(selectedRows);
  const tarSeriesWikiViewGenre = useSelector(
    (state) => state.tarSeriesWikiViewGenre
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    if (rowData.length > 0) {
      setColumnDefs([
        {
          headerName: "Genre",
          field: "title",
          sortable: true,
          filter: true,
          pinned: "left",
        },
        ...rowData[0].data.map((d, i) => {
          return {
            headerName:
              granularity.value === "year"
                ? moment(d.timestamp).format("YYYY")
                : moment(d.timestamp).format("MMM YYYY"),
            field: "timestamp",
            headerClass: "centerHeader",
            suppressHeaderMenuButton: true,
            children: [
              {
                headerName: "Titles",
                field: "titleCount",
                suppressHeaderMenuButton: true,
                valueGetter: (params) => params.data.data[i].titleCount,
                valueFormatter: numberWithCommas,
              },
              {
                headerName: "Views",
                field: "pageViews",
                suppressHeaderMenuButton: true,
                valueGetter: (params) => params.data.data[i].pageViews,
                valueFormatter: numberWithCommas,
              },
            ],
          };
        }),
      ]);
    }
  }, [rowData, granularity]);

  useEffect(() => {
    if (selectedRow) {
      setChartOptions(getChartOptions([selectedRow]));
    }
  }, [selectedRow]);

  useEffect(() => {
    if (dateRange.startDate) {
      getTarSeriesWikiViewGenreResponse({
        gte: moment(dateRange.startDate).format("YYYYMMDD"),
        lte: moment(dateRange.endDate).format("YYYYMMDD"),
        interval: granularity.value,
      });
    }
  }, [dateRange, granularity]);

  useEffect(() => {
    if (tarSeriesWikiViewGenre.completed && tarSeriesWikiViewGenre.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: tarSeriesWikiViewGenre.message,
          id: "tarSeriesWikiViewGenre_server_error",
        })
      );
    }
  }, [tarSeriesWikiViewGenre]);

  const granularityItems = [
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Peacock Dashboard",href: "/peacock-dashboard" },
    { text: "Genre-based Wikipedia Insights", href: "/" },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    []
  );

  useEffect(() => {
    if (tarSeriesWikiViewGenre.data !== null) {
      setRowData(
        Object.keys(tarSeriesWikiViewGenre.data).map((value, index) => {
          return {
            title: value,
            data: tarSeriesWikiViewGenre.data[value].data,
          };
        })
      );
    }
  }, [tarSeriesWikiViewGenre]);

  useEffect(() => {
    if (rowData && rowData.length > 0) {
      setSelectedRow(rowData[0]);
    }
  }, [rowData]);

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  /***chart option */
  const getChartOptions = (rowData) => {
    const chartData = rowData.flatMap((category) =>
      category.data.map((item) => ({
        ...item,
        title: category.title,
        timestamp:
          granularity.value === "year"
            ? moment(item.timestamp).format("YYYY")
            : moment(item.timestamp).format("MMM YY"),
      }))
    );

    let minTitleCount = Math.min(...chartData.map((d) => d.titleCount));
    let maxTitleCount = Math.max(...chartData.map((d) => d.titleCount));
    let minPageViews = Math.min(...chartData.map((d) => d.pageViews));
    let maxPageViews = Math.max(...chartData.map((d) => d.pageViews));

    if (minTitleCount === maxTitleCount) {
      minTitleCount = minTitleCount - 5 >= 0 ? minTitleCount - 5 : 0;
      maxTitleCount += 5;
    }

    if (minPageViews === maxPageViews) {
      minPageViews = minPageViews - 5 >= 0 ? minPageViews - 5 : 0;
      maxPageViews += 5;
    }

    const column = {
      type: "bar",
      xKey: "timestamp",
      yKey: "titleCount",
      yName: "Titles",
      grouped: false,
      strokeWidth: 0,
      fillOpacity: 0.3,
    };
    const line = {
      type: "line",
      xKey: "timestamp",
      yKey: "pageViews",
      yName: "Wikipedia Page Views",
      strokeWidth: 3,
      marker: {
        enabled: false,
      },
    };

    return {
      autoSize: true,
      data: chartData,
      theme: {
        baseTheme: "ag-default-dark",
        palette: {
          fills: ["#7c7dec", "#ec7cb5"],
          strokes: ["#7c7dec", "#ec7cb5"],
        },
        overrides: {
          common: {
            background: { fill: "#0F1B2A" },
          },
        },
      },
      series: [
        { ...column, type: "bar" },
        { ...line, type: "line" },
      ],
      axes: [
        {
          type: "category",
          position: "bottom"
        },
        {
          // primary y axis
          type: "number",
          position: "left",
          keys: ["titleCount"],
          min: minTitleCount,
          max: maxTitleCount,
        },
        {
          // secondary y axis
          type: "number",
          position: "right",
          keys: ["pageViews"],
          min: minPageViews,
          max: maxPageViews,
          label: {
            // Add this to your axis definition
            formatter: (params) => {
              if (Math.abs(params.value) >= 1.0e9) {
                return (Math.abs(params.value) / 1.0e9).toFixed(0) + "B";
              } else if (Math.abs(params.value) >= 1.0e6) {
                return (Math.abs(params.value) / 1.0e6).toFixed(0) + "M";
              } else if (Math.abs(params.value) >= 1.0e3) {
                return (Math.abs(params.value) / 1.0e3).toFixed(0) + "K";
              }
              return params.value;
            },
          },
        },
      ],
      legend: {
        position: "top",
        item: {
          marker: {
            strokeWidth: 0,
          },
        },
      },
    };
  };

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>

      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <ContentLayout
            header={
              <>
                <Header
                  variant="h3"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Select
                        selectedOption={granularity}
                        onChange={({ detail }) =>
                          setGranularity(detail.selectedOption)
                        }
                        selectedAriaLabel="Selected"
                        options={granularityItems}
                      />
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(12, "months")
                                    .startOf("month")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(12, "months")
                                  .startOf("month")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                        
                      />
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="pr-5">Genre-based Wikipedia Insights</span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>

                <CustomFlashBar />
              </>
            }
          >
            <Container>
              <Box>
                {tarSeriesWikiViewGenre.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : rowData.length > 0 ? (
                  <SpaceBetween direction="vertical" size="xxl">
                    {rowData.length > 0 ? (
                      <>
                        <div
                          className="ag-theme-quartz-dark"
                          style={{
                            height: "330px",
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                        >
                          <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onRowSelected={(event) =>
                              setSelectedRow(event.data)
                            }
                            rowSelection="single"
                            onSelectionChanged={(event) => {
                              let selectedRows = event.api.getSelectedRows();
                              setSelectedRow(selectedRows[0]);
                            }}
                          />
                        </div>
                        <div style={{ height: "500px" }}>
                          <AgChartsReact
                            key={JSON.stringify(selectedRow)}
                            options={getChartOptions(
                              selectedRow ? [selectedRow] : []
                            )}
                          />
                        </div>
                      </>
                    ) : null}
                  </SpaceBetween>
                ) : null}
              </Box>
            </Container>
          </ContentLayout>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TarSeriesWikiViewGenrePage;
