import React, { useState, useEffect } from "react";
import ReadMore from "../../components/ReadMore";
import _ from "lodash";
import {
  Container,
  Icon,
  ColumnLayout,
  Header,
  TextContent,
  ExpandableSection,
  SpaceBetween,
  Popover,
  StatusIndicator,
  ButtonDropdown,
  Table,
  Box,
  Tabs,
  Link,
  Select,
} from "@cloudscape-design/components";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import createFlashMessage from "../../utils/createFlashMessage";
import RatingPercent from "./components/RatingPercent";
import { AgChartsReact } from "ag-charts-react";
import {
  getSteamchartsData,
  getTwitchData,
} from "./redux/actions/item-actions";
import { useSelector } from "react-redux";
import {
  baseTwitchChartOptions,
  baseSteamChartOptions,
  timelineTheme,
  timelineClasses,
} from "./chartConfig";
import { Chrono } from "react-chrono";
import { DeleteConfirmationModal } from "./modals/DeleteConfirmationModal";
import { IP_LIST, TAGS_LIST } from "../../config-global";
import { TrackUnTrackConfirmationModal } from "./modals/TrackUnTrackConfirmationModal";
import { AddToFranchiseModal } from "./modals/AddToFranchiseModal";
import { AttributesEditModal } from "./modals/AttributesEditModal";
import { useAuthContext } from "../../auth/useAuthContext";
import AuditBox from "./components/AuditBox";
import NewsBox from "./components/News";
import Genre from "./components/genre";
import FranchiseTag from "./components/FranchiseTag";
import TagsAndIDsComponent from "./components/TagsAndIDsComponent";
import SocialTrends from "./components/SocialTrends";
import moment from "moment";
import AddOnsComponent from "./components/AddOnGames";
import TrailerGrid from "./components/Trailer";
import IMDbRating from "./components/IMDbRating";
import shortenInteger from "../../utils/shortenInteger";
import { WikiScore, YoutubeScore, PantheonScore, SearchScore, EngagementScore } from "./components/GlobalScores";

const defaultDateRange = {
  type: "absolute",
  startDate: moment().subtract(6, "months").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const twitchChartModes = [
  { label: "Average", value: "average" },
  { label: "Peak", value: "peak" },
];

export default function ItemVideoGame({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
  onAddFranchise,
  onTagUpdateSubmit,
  attributeProcessing,
  setSearchDate = (e) => { },
  setFandomDate = (e) => { },
  setRedditDate = (e) => { },
  setInstagramDate = (e) => { },

  ...props
}) {
  const steamchartsData = useSelector((state) => state.steamchartsData);
  const twitchData = useSelector((state) => state.twitchData);
  const [liveDataLoaded, setLiveDataLoaded] = useState(false);
  const [isTracked, setIsTracked] = useState(false);
  const [twitchChartOptions, setTwitchChartOptions] = useState(baseTwitchChartOptions);
  const [steamChartOptions, setSteamChartOptions] = useState(baseSteamChartOptions);
  const [selectedTwitchChartMode, setSelectedTwitchChartMode] = useState(twitchChartModes[0]);
  const [gameChartSeriesKeys, setGameChartSeriesKeys] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);
  const { user } = useAuthContext();
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const [searchDateRange, setSearchDateRange] = useState(defaultDateRange);
  const [fandomDateRange, setFandomDateRange] = useState(defaultDateRange);
  const [redditDateRange, setRedditDateRange] = useState(defaultDateRange);
  const [instagramDateRange, setInstagramDateRange] = useState(defaultDateRange);

  const releaseDate = itemData?.data?.release_date;
  const year = new Date(releaseDate).getFullYear();

  const categoryNames = {
    1: "Official",
    2: "Wikia",
    3: "Wikipedia",
    4: "Facebook",
    5: "Twitter",
    6: "Twitch",
    8: "Instagram",
    9: "YouTube",
    10: "iPhone",
    11: "iPad",
    12: "Android",
    13: "Steam",
    14: "Reddit",
    15: "Itch",
    16: "Epic Games",
    17: "GOG",
    18: "Discord",
  };

  useEffect(() => {
    setSearchDate(searchDateRange);
  }, [searchDateRange]);
  useEffect(() => {
    setRedditDate(redditDateRange);
  }, [redditDateRange]);
  useEffect(() => {
    setFandomDate(fandomDateRange);
  }, [fandomDateRange]);
  useEffect(() => {
    setInstagramDate(instagramDateRange);
  }, [instagramDateRange]);

  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  useEffect(() => {
    if (!liveDataLoaded && itemData.data && itemData.data.external_games) {
      //const twitchId = itemData.data.external_games.find(
      //  (x) => x.category == "twitch"
      //)?.uid;
      const twitchGameName = decodeURIComponent(itemData.data.external_games.find(
        (x) => x.category === "twitch"
      )?.url.split("/").slice(-1)[0] || itemData.data.ip);
      const igdb_id = itemData.data.igdb_id;
      let appId = itemData.data.external_games.find(
        (x) => x.category === "steam"
      )?.uid;
      if (appId && appId.length === 0) {
        const url = itemData.data.external_games.find(
          (x) => x.category === "steam"
        )?.url;
        if (url) {
          appId = url.split("/").slice(-1)[0];
          if (appId.length === 0) {
            appId = url.split("/").slice(-2)[0];
          }
        }
      }
      let dataKeys = [];
      if (twitchGameName && igdb_id) {
        dataKeys.push("average_viewers");
        dataKeys.push("average_streamers");
        dataKeys.push("peak_viewers");
        dataKeys.push("peak_streamers");
        getTwitchData({ igdb_id: igdb_id, twitch_game_name: twitchGameName });
      }
      if (appId) {
        dataKeys.push("ccu");
        getSteamchartsData({ appid: appId });
      }
      setGameChartSeriesKeys(dataKeys);
      setLiveDataLoaded(true);
    }

    let newTimelineItems = [];
    if (itemData.data.platform_release_dates) {
      let groupedReleaseDates = {};
      itemData.data.platform_release_dates.forEach((x) => {
        if (!(x.date in groupedReleaseDates)) {
          groupedReleaseDates[x.date] = [];
        }
        groupedReleaseDates[x.date].push(x);
      });
      [...Object.keys(groupedReleaseDates)]
        .sort((a, b) => (a < b ? -1 : 1))
        .forEach((x) => {
          const releaseDates = groupedReleaseDates[x];
          const formattedDate = formatDate(new Date(x * 1000));
          newTimelineItems.push({
            title: formattedDate,
            cardTitle: `Released on ${[
              ...new Set(releaseDates.map((x) => x.platform)),
            ].join(", ")}`,
            cardDetailedText: `${[
              ...new Set(releaseDates.map((x) => regionMapping[x.region])),
            ].join(", ")}`,
          });
        });
    }
    setTimelineItems(newTimelineItems);
  }, [itemData]);

  useEffect(() => {
    const twitchTimes = twitchData.data?.map((x) => x.time)?.sort() || [];
    const dataWithValues = twitchTimes.map((x) => {
      const twitchPoint = twitchData.data.find((y) => y.time === x);
      return {
        time: x,
        average_viewers: twitchPoint?.average_viewers || 0,
        average_streamers: twitchPoint?.average_streamers || 0,
        peak_viewers: twitchPoint?.peak_viewers || 0,
        peak_streamers: twitchPoint?.peak_streamers || 0,
      };
    }).filter(point => point.average_viewers !== 0 || point.average_streamers !== 0 || point.peak_viewers !== 0 || point.peak_streamers !== 0);

    let newChartOptions = { ...baseTwitchChartOptions, data: dataWithValues };
    newChartOptions.series = newChartOptions.series.filter((x) =>
      gameChartSeriesKeys.includes(x.yKey)
    );
    newChartOptions.axes[0].crossLines[0].value = new Date(Date.parse(itemData.data.release_date)).getTime() / 1000;
    setTwitchChartOptions(newChartOptions);
  }, [twitchData]);

  useEffect(() => {
    const steamTimes = steamchartsData.data?.map((x) => x.time)?.sort() || [];
    const dataWithValues = steamTimes.map((x) => {
      const steamPoint = steamchartsData.data.find((y) => y.time === x);
      return {
        time: x,
        ccu: steamPoint?.ccu || 0,
      };
    }).filter(point => point.ccu !== 0);

    let newChartOptions = { ...baseSteamChartOptions, data: dataWithValues };
    newChartOptions.series = newChartOptions.series.filter((x) =>
      gameChartSeriesKeys.includes(x.yKey)
    );
    newChartOptions.axes[0].crossLines[0].value = new Date(Date.parse(itemData.data.release_date)).getTime() / 1000;
    setSteamChartOptions(newChartOptions);
  }, [steamchartsData]);


  const formatDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[date.getMonth()]
      } ${date.getDate()}, ${date.getFullYear()}`;
  };
  const getGamesLinksList = () => {
    const platformInfo = {
      steam: { name: "Steam", icon: "steam.svg" },
      gog: { name: "GOG", icon: "gog.svg" },
      // youtube: {name: "YouTube", icon: "youtube.svg"},
      microsoft: { name: "Microsoft Store", icon: "microsoft.svg" },
      apple: { name: "Apple", icon: "apple.svg" },
      twitch: { name: "Twitch", icon: "twitch.svg" },
      android: { name: "Google Play", icon: "android.svg" },
      // amazon_asin: "https://www.amazon.com/dp/{{id}}",
      epic_game_store: {
        name: "Epic Games Store",
        icon: "epic_game_store.svg",
      },
      oculus: { name: "Oculus", icon: "oculus.svg" },
      utomik: { name: "Utomik", icon: "utomik.svg" },
      itch_io: { name: "itch.io", icon: "itch_io.svg" },
      xbox_marketplace: { name: "Xbox Marketplace", icon: "xbox.svg" },
      playstation_store_us: {
        name: "PlayStation Store",
        icon: "playstation.svg",
      },
    };
    let trackedTypes = [];
    let links = [];

    if (!itemData || !itemData.data || !Array.isArray(itemData.data.external_games)) {
      return [];
    }

    for (let i = 0; i < itemData.data.external_games.length; i++) {
      const game = itemData.data.external_games[i];
      if (trackedTypes.includes(game.category)) continue;
      trackedTypes.push(game.category);

      if (!platformInfo[game.category]) continue;

      let gameUrl = game.url;

      if (game.category === "steam" && (!game.url || game.url.length === 0)) {
        gameUrl = `https://store.steampowered.com/app/${game.uid}/`;
      }

      links.push({
        imgSrc: require(`../../assets/images/icons/${platformInfo[game.category].icon}`),
        url: gameUrl,
        label: platformInfo[game.category].name,
      });
    }

    return links;
  };
  const regionMapping = {
    europe: "Europe",
    north_america: "North America",
    australia: "Australia",
    new_zealand: "New Zealand",
    japan: "Japan",
    china: "China",
    asia: "Asia",
    worldwide: "Worldwide",
    korea: "Korea",
    brazil: "Brazil",
  };
  const getGamesPlatformsList = (releaseDates) => {
    let trackedTypes = {};
    for (let i = 0; i < releaseDates.length; i++) {
      const releaseDate = releaseDates[i];
      if (!(releaseDate.platform in trackedTypes)) {
        trackedTypes[releaseDate.platform] = [];
      }
      trackedTypes[releaseDate.platform].push({
        name: regionMapping[releaseDate.region],
        date: formatDate(new Date(releaseDate.date * 1000)),
      });
    }
    let items = [];
    for (const key in trackedTypes) {
      const item = trackedTypes[key];
      items.push(
        <li
          title={item
            .map((region) => `${region.date} (${region.name})`)
            .join("\n")}
        >
          {key}
        </li>
      );
    }
    return items;
  };

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";
  useEffect(() => {

    onShowAttrClose();

  }, [itemData]);

  const wikiTotalViews = shortenInteger(itemData?.data?.global_rank?.wiki?.total_views);
  const youtubeTotalViews = shortenInteger(itemData?.data?.global_rank?.youtube?.views);

  return (<>
    {!itemData.loading && itemData.data && itemData.data.type ? (
      <>
        <Container
          header={
            <Header
              actions={
                <ButtonDropdown
                  expandToViewport
                  variant="primary"
                  onItemClick={({ detail }) => {
                    if (detail.id === "tt") {
                      setAddTrack(true);
                      onTrackUnTrackInit();
                    }
                    if (detail.id === "ut") {
                      setAddTrack(false);
                      onTrackUnTrackInit();
                    }
                    if (detail.id === "dt") {
                      onDeleteModalInit();
                    }

                    if (detail.id === "af") {
                      onAddFranchiseModalInit();
                    }
                    if (detail.id === "et") {
                      onShowAttrInit();
                    }
                  }}
                  items={[
                    {
                      text: "Track title",
                      id: "tt",
                      disabled: !isAdmin
                        ? true
                        : (checkIpList(itemData?.data?.ip_list) || itemData?.data?.tracked),
                    },
                    {
                      text: "Untrack title",
                      id: "ut",
                      disabled: !isAdmin ? true : !itemData?.data?.tracked,
                    },
                    { text: "Edit tags", id: "et", disabled: !isAdmin },
                    { text: "Compare", id: "cm", disabled: true },
                    {
                      text: "Add to franchise",
                      id: "af",
                      disabled: !isAdmin,
                    },
                    {
                      text: "Delete title",
                      id: "dt",
                      disabled: !isAdmin,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              }
            >
              <div>
                <div className="flex items-center">
                  <div className="text-xl text-blue-400 font-bold inline-block">
                    {itemData.data.name}
                  </div>
                  <div className="inline-block whitespace-nowrap ml-4 ">
                    {isTracked ? (
                      <div className="flex space-x-2">
                        {itemData.data?.ip_list?.length > 0 ? (
                          itemData.data.ip_list.map((ip) => (
                            <span
                              key={ip}
                              className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                            >
                              <svg
                                className="h-1.5 w-1.5 fill-green-400"
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              {ip}
                            </span>
                          ))
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                            Tracked
                          </span>
                        )}
                      </div>
                    ) : (
                      <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                          <span className="text-slate-400 text-sm">
                            Not Tracked
                          </span>
                        }
                      >
                        <StatusIndicator type="stopped" />
                      </Popover>
                    )}
                  </div>
                </div>
                <div className="text-slate-400 text-xs">
                  Video Game {" • "}
                  {itemData.data.release_date &&
                    formatDate(
                      new Date(Date.parse(itemData.data.release_date))
                    )}
                </div>
              </div>
            </Header>
          }
        >
          <div className="space-y-6">
            <div className="flex space-x-6">
              <img
                src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${itemData.data.image_url}.png`}
                className="w-64 drop-shadow-lg h-min rounded-md"
              />
              <div className="space-y-2">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Genre: </span>
                  <Genre item={itemData.data.genre} />
                </div>
                <div>
                  {itemData.data.franchise &&
                    itemData.data.franchise.length > 0 && (
                      <FranchiseTag itemData={itemData} />
                    )}
                  {itemData.data.involved_companies &&
                    itemData.data.involved_companies.filter(
                      (x) => x.publisher === true
                    ).length > 0 && (
                      <div>
                        Publishers:
                        {itemData.data.involved_companies
                          .filter((x) => x.publisher === true)
                          .map((c, index) => (
                            <React.Fragment key={c.company.id}>
                              {/* <Link href={`/item/${c?.company.id}`} variant="primary"> */}
                              <button
                                type="button"
                                className="ml-2 mt-2 rounded-md bg-red-400/10 px-2.5 py-1 text-xs  text-red-400 shadow-sm hover:bg-red-500  hover:text-white ring-1 ring-inset ring-red-400/50"
                              >
                                {c.company.name}
                              </button>
                              {/* </Link> */}
                            </React.Fragment>
                          ))}
                      </div>
                    )}
                  <div className="m-2"></div>
                  {itemData.data.involved_companies &&
                    itemData.data.involved_companies.filter(
                      (x) => x.developer === true
                    ).length > 0 && (
                      <div>
                        Developers:
                        {itemData.data.involved_companies
                          .filter((x) => x.developer === true)
                          .map((c, index) => (
                            <React.Fragment key={c.company.id}>
                              {/* <Link href={`/item/${c?.company.id}`} variant="primary"> */}
                              <button
                                type="button"
                                className="ml-2 rounded-md bg-indigo-400/10 px-2.5 py-1 text-xs  text-indigo-400 shadow-sm hover:bg-indigo-500  hover:text-white ring-1 ring-inset ring-indigo-400/50"
                              >
                                {c.company.name}
                              </button>
                              {/* </Link> */}
                            </React.Fragment>
                          ))}
                      </div>
                    )}
                </div>
                {itemData.data.summary && (
                  <div> Overview: <ReadMore> {itemData.data.summary} </ReadMore> </div>
                )}
                <div className="space-y-2">
                  {itemData.data.platform_release_dates && (
                    <div className="flex space-x-4 grow">
                      {[
                        ...new Set(
                          itemData.data.platform_release_dates.map(
                            (x) => x.platform
                          )
                        ),
                      ].map((platform) => {
                        const formattedPlatform = platform.replace(
                          /[\/:*?" <>|]/g,
                          "_"
                        );
                        return (
                          <div
                            title={platform}
                            key={platform}
                            style={{
                              transition: "transform 0.2s ease-in-out",
                              marginTop: "10px",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.3)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            <img
                              src={`https://images.searchpantheon.com/gamePlatformIcons/${formattedPlatform}.jpg`}
                              alt={`${platform}`}
                              className="rounded-lg"
                              style={{ width: "60px", height: "60px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="mt-4">
                    <SpaceBetween direction="horizontal" size="xl">
                    {!!itemData.data.steam_rating_description && (
                      <div>
                        <div className="font-bold">Steam Reviews</div>
                        <div className="flex">
                          <span className="flex">
                            <Icon name="thumbs-up" variant="success" />
                            <span className="ml-1">
                              {itemData.data.steam_rating_positive.toLocaleString()}
                            </span>
                          </span>
                          <span className="ml-2" />
                          <span className="flex">
                            <Icon name="thumbs-down" variant="error" />
                            <span className="ml-1">
                              {itemData.data.steam_rating_negative.toLocaleString()}
                            </span>
                          </span>
                        </div>
                        <div className="italic text-slate-400 text-xs">
                          {itemData.data.steam_rating_description}
                        </div>
                      </div>
                    )}
                    {!!itemData.data.metacritic_rating && (
                      <div className={"flex space-x-2 items-center"}>
                        <div
                          className="rounded-lg w-12 h-12 flex items-center"
                          style={{ backgroundColor: itemData.data.metacritic_rating >= 75 ? "green" : itemData.data.metacritic_rating >= 50 && itemData.data.metacritic_rating < 75 ? "#fc3" : "red", }}
                        >
                          <div
                            className="m-auto text-2xl font-bold"
                            style={{ color: "white" }}
                          >
                            {itemData.data.metacritic_rating}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="whitespace-pre-line font-bold text-left">
                            <div>Metacritic<br />Score</div>
                          </div>
                          <div className="whitespace-pre-line italic text-slate-400 text-xs">
                            {`${(
                              itemData?.data?.metacritic_vote_count ?? 0
                            ).toLocaleString()} reviews`}
                          </div>
                        </div>
                      </div>
                    )}
                    {!!itemData?.data?.metacritic_user_rating && (
                      <RatingPercent
                        percent={itemData.data.metacritic_user_rating * 10}
                        title={<div>Metacritic<br />User Score</div>}
                        subtitle={`${itemData.data.metacritic_user_vote_count?.toLocaleString() ??
                          "N/A"
                          } reviews`}
                      />
                    )}
                    {!!itemData.data.imdb_vote_average && (<IMDbRating percent={itemData.data.imdb_vote_average} title={"IMDb SCORE"} subtitle={`${itemData.data.imdb_vote_count.toLocaleString()} votes`} />)}
                    {!!itemData.data?.global_rank?.wiki && itemData.data.global_rank.wiki.rank !== null && itemData.data.global_rank.wiki.total_views !== null && (
                      <WikiScore
                        rank={itemData.data.global_rank.wiki.rank}
                        title={"Wikipedia Rank"}
                        subtitle={`Article views: ${wikiTotalViews}`}
                      />
                    )}
                    {itemData.data?.global_rank?.youtube && itemData.data.global_rank.youtube.rank !== null && itemData.data.global_rank.youtube.views !== null && (
                      <YoutubeScore
                        rank={itemData.data.global_rank.youtube.rank}
                        title={"YouTube Rank"}
                        subtitle={`Trailer views: ${youtubeTotalViews}`}
                      />
                    )}
                    {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_pan_rank !== null && itemData.data.global_rank?.pantheon?.pantheon_rank !== null && (
                      <PantheonScore
                        rank={itemData.data.global_rank?.pantheon?.at_pan_rank}
                        title={"Pantheon Rank"}
                        subtitle={`Weekly rank: ${itemData.data.global_rank?.pantheon?.pantheon_rank}`}
                      />
                    )}
                    {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_src_rank !== null && itemData.data.global_rank?.pantheon?.search_rank !== null && (
                      <SearchScore
                        rank={itemData.data.global_rank?.pantheon?.at_src_rank}
                        title={"Search Rank"}
                        subtitle={`Weekly rank: ${itemData.data.global_rank?.pantheon?.search_rank}`}
                      />
                    )}
                    {itemData.data?.global_rank?.pantheon && itemData.data.global_rank?.pantheon?.at_eng_rank !== null && itemData.data.global_rank?.pantheon?.engagement_rank !== null && (
                      <EngagementScore
                        rank={itemData.data.global_rank?.pantheon?.at_eng_rank}
                        title={"Engagement Rank"}
                        subtitle={`Weekly rank: ${itemData.data.global_rank?.pantheon?.engagement_rank}`}
                      />
                    )}
                    </SpaceBetween>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Container>
        <div className="flex space-x-6 mt-6">
          <div className="w-full">
            <Tabs
              tabs={[
                {
                  label: "Trends",
                  id: "trends",
                  content: (
                    <SpaceBetween direction="vertical" size="l">

                      {itemData?.data?.tracked && (
                        <ExpandableSection
                          defaultExpanded
                          headingTagOverride="h4"
                          variant="container"
                          headerText="Social Trends"
                        >
                          <SocialTrends itemData={itemData} instagramHashtagsData={props.instagramHashtagsData} />
                          {
                            itemData?.data?.hasOwnProperty("tracked") &&
                            itemData?.data?.tracked && itemData?.data?.instagram && (
                              props.trackedInstagramTrendChartOption && (
                                <div className="h-60 mt-6">

                                  <AgChartsReact
                                    options={
                                      props.trackedInstagramTrendChartOption
                                    }
                                  />
                                </div>
                              )
                            )
                          }
                        </ExpandableSection>
                      )}

                      {twitchChartOptions && twitchChartOptions.data.length > 0 && (
                        <ExpandableSection
                          headingTagOverride="h4"
                          variant="container"
                          defaultExpanded
                          headerText="Twitch Trends"
                          headerActions={
                            <Select
                              options={twitchChartModes}
                              selectedOption={selectedTwitchChartMode}
                              onChange={({ detail }) => setSelectedTwitchChartMode(detail.selectedOption)}
                            />
                          }
                        >
                          <div className="h-60">
                            
                              <AgChartsReact options={{ ...twitchChartOptions, series: twitchChartOptions.series.filter(series => series.yKey == `${selectedTwitchChartMode.value}_viewers` || series.yKey == `${selectedTwitchChartMode.value}_streamers`) }} />
                            
                          </div>
                        </ExpandableSection>
                      )}

                      {steamChartOptions && steamChartOptions.data.length > 0 && (
                        <ExpandableSection
                          headingTagOverride="h4"
                          variant="container"
                          defaultExpanded
                          headerText="Steam Trends"
                        >
                          <div className="h-60">
                            <AgChartsReact options={steamChartOptions} />
                          </div>
                        </ExpandableSection>
                      )}

                      {itemData?.data?.hasOwnProperty("tracked") &&
                        !itemData?.data?.tracked && (
                          <>
                            <ExpandableSection
                              headingTagOverride="h4"
                              variant="container"
                              defaultExpanded
                              headerText="Search Trends"
                            >

                              {props.wikiOptions && (
                                <div className="h-60">
                                  <AgChartsReact options={props.wikiOptions} />
                                </div>
                              )}
                              <br />
                              {props.gtrendsOptions && (
                                <div className="h-60">
                                  <AgChartsReact options={props.gtrendsOptions} />
                                </div>
                              )}
                            </ExpandableSection>
                          </>
                        )}

                      {itemData?.data?.hasOwnProperty("tracked") &&
                        itemData?.data?.tracked && (
                          <ExpandableSection
                            headerActions={
                              <RangeDateSelector
                                defaults={searchDateRange}
                                onChange={(e) => {
                                  const formattedStartDate = moment(
                                    e.startDate
                                  ).format("YYYY-MM-DD");
                                  const formattedEndDate = moment(
                                    e.endDate
                                  ).format("YYYY-MM-DD");
                                  setSearchDateRange({
                                    ...e,
                                    startDate: formattedStartDate,
                                    endDate: formattedEndDate,
                                  });
                                }}
                              />
                            }
                            variant="container"
                            defaultExpanded
                            headerText="Search Trends"
                          >
                            {props.trackedWikiTrendChartOption && (
                              <div className="h-60">
                                <AgChartsReact
                                  options={props.trackedWikiTrendChartOption}
                                />
                              </div>
                            )}
                            {props.trackedGoogleTrendsChartOption && (
                              <div className="h-60">
                                <AgChartsReact
                                  options={props.trackedGoogleTrendsChartOption}
                                />
                              </div>
                            )}

                          </ExpandableSection>
                        )}
                      {/* {itemData?.data?.hasOwnProperty("tracked") &&
                        itemData?.data?.tracked && itemData?.data?.fandom && (
                          <ExpandableSection
                            headerActions={
                              <RangeDateSelector
                                defaults={fandomDateRange}
                                onChange={(e) => {
                                  const formattedStartDate = moment(
                                    e.startDate
                                  ).format("YYYY-MM-DD");
                                  const formattedEndDate = moment(
                                    e.endDate
                                  ).format("YYYY-MM-DD");
                                  setFandomDateRange({
                                    ...e,
                                    startDate: formattedStartDate,
                                    endDate: formattedEndDate,
                                  });
                                }}
                              />
                            }
                            variant="container"
                            defaultExpanded
                            headerText="Fandom Trends"
                          >
                            {props.trackedFandomTrendChartOption && (
                              <div className="h-60">
                                <AgChartsReact
                                  options={
                                    props.trackedFandomTrendChartOption
                                  }
                                />
                              </div>
                            )}
                          </ExpandableSection>
                        )} */}

                      {itemData?.data?.hasOwnProperty("tracked") &&
                        itemData?.data?.tracked && itemData?.data?.subreddit && (
                          <ExpandableSection
                            headerActions={
                              <RangeDateSelector
                                defaults={redditDateRange}
                                onChange={(e) => {
                                  const formattedStartDate = moment(
                                    e.startDate
                                  ).format("YYYY-MM-DD");
                                  const formattedEndDate = moment(
                                    e.endDate
                                  ).format("YYYY-MM-DD");
                                  setRedditDateRange({
                                    ...e,
                                    startDate: formattedStartDate,
                                    endDate: formattedEndDate,
                                  });
                                }}
                              />
                            }
                            variant="container"
                            defaultExpanded
                            headerText="Reddit Trends"
                          >
                            {props.trackedRedditTrendChartOption && (
                              <div className="h-60">
                                <AgChartsReact
                                  options={
                                    props.trackedRedditTrendChartOption
                                  }
                                />
                              </div>
                            )}
                          </ExpandableSection>
                        )}
                    </SpaceBetween>
                  ),
                },
                ...(
                  (itemData.data.dlcs && itemData.data.dlcs.length > 0) ||
                    (itemData.data.bundles && itemData.data.bundles.length > 0) ||
                    (itemData.data.expansions && itemData.data.expansions.length > 0) ||
                    (itemData.data.remakes && itemData.data.remakes.length > 0) ||
                    (itemData.data.remasters && itemData.data.remasters.length > 0)
                    ? [{
                      label: "Add-ons",
                      id: "addons",
                      content: (
                        <AddOnsComponent
                          dlcs={itemData.data.dlcs}
                          bundles={itemData.data.bundles}
                          expansions={itemData.data.expansions}
                          remakes={itemData.data.remakes}
                          remasters={itemData.data.remasters}
                        />
                      ),
                    }]
                    : []
                ),
                {
                  label: "Tags & IDs",
                  id: "tags",
                  content: (
                    <SpaceBetween direction="vertical" size="m">
                      <ExpandableSection
                        defaultExpanded
                        headingTagOverride="h4"
                        variant="container"
                        headerText="Metadata"
                      >
                        <ColumnLayout columns={4} minColumnWidth={150} variant="text-grid">

                          {itemData.data?.game_modes && (
                            <div>
                              <TextContent><h4>Game Modes</h4></TextContent>
                              {itemData.data.game_modes.map((x, index) => (
                                <div key={index}>{x}</div>
                              ))}
                            </div>
                          )}
                          <div>
                            {itemData.data?.themes && (
                              <div>
                                <TextContent><h4>Themes</h4></TextContent>
                                {itemData.data.themes.map((x) => (
                                  <div>{x}</div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div>
                            {itemData.data?.player_perspectives && (
                              <div>
                                <TextContent><h4>Player Perspectives</h4></TextContent>
                                {itemData.data.player_perspectives.map((x) => (
                                  <div>{x.name}</div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div>
                            {itemData.data?.game_engine && (
                              <div>
                                <TextContent><h4>Game Engines</h4></TextContent>
                                {itemData.data.game_engine.map((x) => (
                                  <div>{x.name}</div>
                                ))}
                              </div>
                            )}
                          </div>
                        </ColumnLayout>
                      </ExpandableSection>
                      <ExpandableSection
                        defaultExpanded
                        headingTagOverride="h4"
                        variant="container"
                        headerText="Tags & IDs"
                      >
                        <TagsAndIDsComponent itemData={itemData.data} />
                      </ExpandableSection>
                    </SpaceBetween>
                  ),
                },
                {
                  label: "Links",
                  id: "links",
                  content: (
                    <SpaceBetween direction="vertical" size="m">
                      <ExpandableSection
                        defaultExpanded
                        headingTagOverride="h4"
                        variant="container"
                        headerText="External Links"
                      >
                        <div className="flex flex-col space-y-2">
                          {getGamesLinksList().map((link, index) => (
                            <a key={index} href={link.url} target="_blank" rel="noreferrer" className="flex items-center gap-2" >
                              <img src={link.imgSrc} alt={link.label} className="w-6 h-6 filter-white-icon" />
                              <span className="font-bold">{link.label}</span>
                            </a>
                          ))}
                        </div>

                      </ExpandableSection>
                      <ExpandableSection
                        defaultExpanded
                        headingTagOverride="h4"
                        variant="container"
                        headerText="Official Links"
                      >
                        <Table
                          columnDefinitions={[
                            {
                              id: "category",
                              header: "Category Name",
                              cell: item => item.categoryName || "-",
                              isRowHeader: true,
                            },
                            {
                              id: "link",
                              header: "Link",
                              cell: item => (
                                <Link href={item.url} target="_blank" rel="noreferrer">{item.url}</Link>
                              ),
                            }
                          ]}
                          enableKeyboardNavigation
                          items={itemData.data.websites?.map(link => ({
                            categoryName: categoryNames[link.category], // Assuming `categoryNames` is your mapping object
                            url: link.url,
                          }))}
                          loadingText="Loading links..."
                          variant="embedded"
                          empty={
                            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
                              <SpaceBetween size="m">
                                <b>No official links available</b>
                              </SpaceBetween>
                            </Box>
                          }
                        />
                      </ExpandableSection>

                    </SpaceBetween>
                  ),
                },
                {
                  label: "Trailers",
                  id: "Trailers",
                  content: (
                    <TrailerGrid id={itemData.data.ip_id} />
                  ),
                },
                {
                  label: "News",
                  id: "news",
                  content: (
                    <NewsBox id={`${itemData?.data?.ip}+${year}`} />
                  ),
                },
                {
                  label: "Timeline",
                  id: "timeline",
                  content: (
                    <Container>
                      <Chrono
                        items={timelineItems}
                        mode="VERTICAL_ALTERNATING"
                        allowDynamicUpdate={true}
                        showAllCardsHorizontal={true}
                        theme={timelineTheme}
                        classNames={timelineClasses}
                        scrollable={{ scrollbar: true }}
                        cardHeight={50}
                      />
                    </Container>
                  ),
                },
                {
                  label: "Audit Events",
                  id: "AU",
                  content: <AuditBox ip_id={itemData?.data?.ip_id} />,
                },
              ]} />
          </div>
        </div>
      </>
    ) : null}
    <TrackUnTrackConfirmationModal
      visible={showTrackUnTrackModal}
      ipLists={IP_LIST}
      track={!addTrack}
      itemData={itemData?.data}
      ip_id={itemData?.data?.ip_id}
      defaultSelectedIps={itemData?.data?.ip_list}
      onDiscard={onTrackUnTrackClose}
      consentText={itemData?.data?.name}
      onConfirm={(item) => {
        if (item.track) {
          onUntrackItem(item);
          onTrackUnTrackClose();
        } else {
          onTrackItem(item);
          onTrackUnTrackClose();
        }
      }}
    />
    <DeleteConfirmationModal
      instances={[itemData?.data]}
      visible={showDeleteModal}
      onDiscard={onDeleteModalClose}
      onDelete={() => onDelete(itemData?.data?.ip_id)}
      consentText={itemData?.data?.name}
    />
    <AddToFranchiseModal
      visible={showAddFranchiseModal}
      onSubmit={(selectedOption) => {
        onAddFranchise({
          id: selectedOption.value,
          name: selectedOption.label,
          removed: [],
          added: [itemData?.data?.ip_id],
        });

        onAddFranchiseModalClose();
      }}
      onDiscard={onAddFranchiseModalClose}
    />
    <AttributesEditModal
      loading={attributeProcessing}
      visible={showAttrModal}
      tags={_.map(_.pick(itemData?.data, TAGS_LIST), (value, key) => ({
        key,
        value,
        /*  existing: !!value, */
      }))}
      onDiscard={onShowAttrClose}
      onSubmit={(data) => {
        onTagUpdateSubmit(data);
      }}
    />
  </>);
}
