import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import renderTrend from "../../../../utils/trendIcon";

export const useGridConfig = () => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "rank_change",
        headerName: "Daily Rank Change",
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        headerName: "Rank",
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "daily_rank",
            "rank_change",
            "rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        cellRenderer: (params) => {
          const releaseYear = new Date(params.data.release_date*1000).getFullYear();
          const ipId = params.data.ip_id;
          const destination = `/item/${ipId}`;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.tracked != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link
                color="inverted"
                variant="secondary"
                href={destination}
                onClick={(e) => !ipId && e.preventDefault()}
              >
                {params.value}
              </Link>
              <span className="text-xs text-slate-500 mt-1">
                ({releaseYear})
              </span>
            </div>
          );
        },
      },
      {
        field: "weekly_rank",
        headerName: "Weekly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "weekly_rank",
            "weekly_rank_change",
            "weekly_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.weekly_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "monthly_rank",
        headerName: "Monthly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "monthly_rank",
            "monthly_rank_change",
            "monthly_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.monthly_rank),
        valueFormatter: (params) => `${params.value}`,
      },
      {
        field: "grade",
        headerName: "Pantheon Score",
        headerTooltip: "Pantheon Score Calculated by taking Daily, weekly and monthly scores into account",
        cellDataType: "number",
        maxWidth: 100,
        pinned: "left",
        valueFormatter: (params) => params.value.toFixed(0),
      },
      {
        field: "peak_broadcasters",
        headerName: "Peak Broadcasters",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "peak_viewers",
        headerName: "Peak Viewers",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "max_followers",
        headerName: "Followers",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "average_broadcasters",
        headerName: "Average Broadcasters",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "average_viewers",
        headerName: "Average Viewers",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "new_followers",
        headerName: "New Followers",
        cellDataType: "number",
        valueFormatter: (params) => shortenInteger(params.value),
      },
      {
        field: "weekly_sum",
        headerName: "Last 7 days Score",
        cellDataType: "number",
        valueFormatter: (params) => params.value.toFixed(0),
      },
      {
        field: "monthly_sum",
        headerName: "Last 30 days Score",
        cellDataType: "number",
        valueFormatter: (params) => params.value.toFixed(0),
      },
      {
        field: "monthly_grade",
        headerName: "30-day Trend",
        maxWidth: 600,
        minWidth: 200,
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: "area",
            marker: {
              size: 1,
              shape: "circle",
              fill: "white",
            },
            fill: "#0972d3",
            highlightStyle: {
              fill: "#033160",
            },
          },
        },
        filter: false,
      },
    ];

    return allColumns;
  }, []);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  return {
    defaultColDef,
    columnDefs,
    onGridReady,
  };
};
