import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {
  Cards, SpaceBetween,
  Box,Container,
  ExpandableSection, Link
} from "@cloudscape-design/components";

export default function ItemCompany({ itemData, ...props }) {
  const [isTracked, setIsTracked] = useState(false);
 
  useEffect(() => {
  
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const videoGames = itemData?.data?.["Video Game"] || [];
  const movies = itemData?.data?.["Film"] || [];
  const series = itemData?.data?.["Television Series"] || [];
  const noImage = require("../../assets/images/icons/no-image_342.jpg");

  return (
    <>
      {!itemData.loading && itemData.data && itemData.data.type ? (
        <Container variant="borderless">
          <div className="space-y-6">
            <div className="flex space-x-6">
              <img
                src={`https://www.themoviedb.org/t/p/h50_filter(negate,000,666)/${itemData.data.poster_path}`}
                alt="..."
                className="w-48 xl:w-64 drop-shadow-lg h-min rounded-md"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noImage;
                }}
              />

              <div className="flex-1 flex-col">
                <div className="text-3xl font-bold">{itemData.data.name}</div>

                <div className="text-slate-400">{itemData.data.type.name}</div>

                {itemData.data.description && (
                  <>
                    <div className="text-lg xl:text-xl font-bold mt-6">
                      Overview
                    </div>
                    {itemData.data.description}
                  </>
                )}
              </div>

            </div>
            <div className="flex space-x-2 ">
              <div className="w-full ">
                <SpaceBetween direction="vertical" size="l">

                  {itemData?.data?.["Video Game"]?.length > 0 && (
                    <ExpandableSection
                      headingTagOverride="h2"
                      variant="footer"
                      defaultExpanded
                      headerText="Video Games"
                    >
                      <Cards
                        cardDefinition={{
                          header: (game) => (
                            <div className="flex justify-center items-center p-2">
                              <Link
                                href={`${window.location.origin}/item/${game.ip_id}`}
                                fontSize="heading-s"
                              >
                                {game.name}
                              </Link>
                            </div>
                          ),

                          sections: [
                            {
                              id: "image-summary",
                              content: (game) => (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "150px",
                                      height: "200px",
                                      borderRadius: "20px",
                                      marginRight: "20px",
                                    }}
                                    src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${game.image_url}.png`}
                                    alt={game.name}
                                    
                                  />
                                  <div>
                                    {game.summary && game.summary.length > 150
                                      ? `${game.summary.substring(0, 150)}...`
                                      : game.summary}
                                  </div>
                                </div>
                              ),
                            }

                          ],
                        }}
                        cardsPerRow={[
                          { cards: 1 },
                          { minWidth: 800, cards: 2 },
                          { minWidth: 1000, cards: 3 },
                        ]}
                        items={videoGames}
                        loadingText="Loading..."
                      />
                    </ExpandableSection>
                  )}
                  {itemData?.data?.["Film"]?.length > 0 && (
                    <ExpandableSection
                      headingTagOverride="h2"
                      variant="footer"
                      defaultExpanded
                      headerText="Movies"
                    >
                      <Cards
                        cardDefinition={{
                          header: (movie) => (
                            <div className="flex justify-center items-center p-2">
                              <Link
                                href={`${window.location.origin}/item/${movie.ip_id}`}
                                fontSize="heading-s"
                              >
                                {movie.name}
                              </Link>
                            </div>
                          ),

                          sections: [
                            {
                              id: "image-summary",
                              content: (movie) => (
                                <div
                                  style={{
                                    display: "flex",
                                    // alignItems: "center",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "150px",
                                      height: "200px",
                                      borderRadius: "10%",
                                      marginRight: "20px",
                                    }}
                                    src={`https://image.tmdb.org/t/p/w780${movie.image_url}`}
                                    alt={movie.name}
                                    
                                  />
                                  <div>
                                    {movie.summary && movie.summary.length > 150
                                      ? `${movie.summary.substring(0, 150)}...`
                                      : movie.summary}
                                  </div>

                                </div>
                              ),
                            },
                          ],
                        }}
                        cardsPerRow={[
                          { cards: 1 },
                          { minWidth: 800, cards: 2 },
                          { minWidth: 1000, cards: 3 },
                        ]}
                        items={movies}
                        loadingText="Loading..."
                      />
                    </ExpandableSection>
                  )}
                  {itemData?.data?.["Television Series"]?.length > 0 && (
                    <ExpandableSection
                      headingTagOverride="h2"
                      variant="footer"
                      defaultExpanded
                      headerText="Series"
                    >
                      <Cards
                        cardDefinition={{
                          header: (series) => (
                            <div className="flex justify-center items-center p-2">
                              <Link
                                href={`${window.location.origin}/item/${series.ip_id}`}
                                fontSize="heading-s"
                              >
                                {series.name}
                              </Link>
                            </div>
                          ),

                          sections: [
                            {
                              id: "image-summary",
                              content: (series) => (
                                <div
                                  style={{
                                    display: "flex",
                                    
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "150px",
                                      height: "200px",
                                      borderRadius: "20px",
                                      marginRight: "20px",
                                    }}
                                    src={`https://image.tmdb.org/t/p/w780${series.image_url}`}
                                    alt={series.name}
                                  />
                                  <div>
                                    {series.summary && series.summary.length > 150
                                      ? `${series.summary.substring(0, 150)}...`
                                      : series.summary}
                                  </div>

                                </div>
                              ),
                            },
                          ],
                        }}
                        cardsPerRow={[
                          { cards: 1 },
                          { minWidth: 800, cards: 2 },
                          { minWidth: 1000, cards: 3 },
                        ]}
                        items={series}
                        loadingText="Loading..."
                      />
                    </ExpandableSection>
                  )}
                </SpaceBetween>
              </div>
            </div>
          </div>
        </Container>
      ) : null}
    </>
  );
}
