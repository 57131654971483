import React, { useEffect, useMemo, useState, useRef } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../ag-styles.css";
import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Header,
  Toggle,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Box,
  Flashbar,
} from "@cloudscape-design/components";

import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { AgChartsReact } from "ag-charts-react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { getTarSeriesWikiViewGenrePlatformMarketshareResponse } from "./redux/actions/tar-series-wiki-genre-platform-marketshare-actions";
import _ from "lodash";
import createFlashMessage from "../../../utils/createFlashMessage";
import numberWithCommas from "../../../utils/ag-utils";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const TarSeriesWikiViewGenrePlatformMarketsharePage = () => {
  const [dateRange, setDateRange] = useState({
    startDate: moment()
      .subtract(12, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const chartRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [, setChartData] = useState([]);

  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [excludeOTT, setExcludeOTT] = React.useState(false);

  const tarSeriesWikiViewGenrePlatformMarketshare = useSelector(
    (state) => state.tarSeriesWikiViewGenrePlatformMarketshare
  );

  useEffect(() => {
    if (rowData.length > 0) {
      setColumnDefs([
        {
          headerName: "Genre",
          field: "title",
          sortable: true,
          filter: true,
          rowGroup: true,
        },
        {
          headerName: "Platform",
          field: "platform",
        },
        {
          headerName: "Views",
          field: "pageViews",
          valueFormatter: (params) =>
            params.value ? numberWithCommas(params) : "",
        },
        {
          headerName: "Title Count",
          field: "titleCount",
        },
        {
          headerName: "Platform Share(%)",
          field: "perc",
        },
      ]);
    }
  }, [rowData]);
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  useEffect(() => {
    if (dateRange.startDate) {
      getTarSeriesWikiViewGenrePlatformMarketshareResponse({
        gte: moment(dateRange.startDate).format("YYYYMMDD"),
        lte: moment(dateRange.endDate).format("YYYYMMDD"),
        excludeOTT,
      });
    }
  }, [dateRange, excludeOTT]);

  useEffect(() => {
    if (
      tarSeriesWikiViewGenrePlatformMarketshare.completed &&
      tarSeriesWikiViewGenrePlatformMarketshare.hasError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: tarSeriesWikiViewGenrePlatformMarketshare.message,
        })
      );
    }
  }, [tarSeriesWikiViewGenrePlatformMarketshare]);

  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Peacock Dashboard", href: "/peacock-dashboard" },
    { text: "Genre-Based Wiki Marketshare", href: "/" },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    []
  );

  const colors = [
    "#FF0000", // Red
    "#FF4500", // Orange Red
    "#FF8C00", // Dark Orange
    "#FFA500", // Orange
    "#FFD700", // Gold
    "#FFFF00", // Yellow
    "#ADFF2F", // Green Yellow
    "#7CFC00", // Lawn Green
    "#00FF00", // Lime
    "#00FF7F", // Spring Green
    "#00FFFF", // Cyan
    "#1E90FF", // Dodger Blue
    "#0000FF", // Blue
    "#4B0082", // Indigo
    "#8A2BE2", // Blue Violet
    "#9932CC", // Dark Orchid
    "#8B008B", // Dark Magenta
    "#FF00FF", // Magenta
    "#FF69B4", // Hot Pink
    "#FF1493", // Deep Pink
  ];

  useEffect(() => {
    if (tarSeriesWikiViewGenrePlatformMarketshare.data !== null) {
      setRowData(
        Object.entries(tarSeriesWikiViewGenrePlatformMarketshare.data)
          .map(([title, { titleCount, data }]) => {
            return {
              title: title,
              titleCount: titleCount,
              data: data,
            };
          })
          .flatMap(({ title, data }) =>
            data.map((item) => ({
              ...item,
              title: title,
            }))
          )
      );
    }
  }, [tarSeriesWikiViewGenrePlatformMarketshare]);

  const allTitle = _.uniq(_.compact(_.map(rowData, "title")));

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>

      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(12, "months")
                                    .startOf("month")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(12, "months")
                                  .startOf("month")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD"
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                      />
                      <Toggle
                        checked={excludeOTT}
                        onChange={() => setExcludeOTT(!excludeOTT)}
                      >
                        Exclude OTT Originals
                      </Toggle>
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="pr-5">Platform Ratio by Genre</span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>

                <CustomFlashBar />
              </>
            }
          >
            <Container>
              <Box>
                {tarSeriesWikiViewGenrePlatformMarketshare.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : rowData.length > 0 ? (
                  <SpaceBetween direction="vertical" size="xxl">
                    <>
                      <div
                        className="ag-theme-quartz-dark"
                        style={{
                          height: "330px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <AgGridReact
                          rowData={rowData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowSelection="single"
                        />
                      </div>
                      <div style={{ height: "900px" }}>
                        <AgChartsReact
                          ref={chartRef}
                          options={{
                            autoSize: true,
                            theme: {
                              baseTheme: "ag-default-dark",
                              palette: {
                                fills: [
                                  "rgba(230, 25, 75, .5)", // #e6194b
                                  "rgba(60, 180, 75, .5)", // #3cb44b
                                  "rgba(255, 225, 25, .5)", // #ffe119
                                  "rgba(0, 130, 200, .5)", // #0082c8
                                  "rgba(245, 130, 49, .5)", // #f58231
                                  "rgba(145, 30, 180, .5)", // #911eb4
                                  "rgba(70, 240, 240, .5)", // #46f0f0
                                  "rgba(240, 50, 230, .5)", // #f032e6
                                  "rgba(210, 245, 60, .5)", // #d2f53c
                                  "rgba(250, 190, 190, .5)", // #fabebe
                                ],
                                strokes: ["#0F1B2A"],
                              },
                              overrides: {
                                common: {
                                  background: { fill: "#0F1B2A" },
                                  title: {
                                    fontSize: 22,
                                    fontFamily: "Arial, sans-serif",
                                  },
                                  axes: {
                                    number: { tick: { width: 0 } },
                                    category: { tick: { width: 0 } },
                                  },
                                },
                              },
                            },
                            legend: {
                              position: "bottom",
                            },
                            series: allTitle.map((d) => {
                              return {
                                type: "bubble",
                                title: d,
                                data: rowData.filter((f) => f.title === d),
                                xKey: "platform",
                                xName: d,
                                yKey: "pageViews",
                                yName: "Wikipedia page views",
                                sizeKey: "perc",
                                sizeName: "% Share",
                                labelKey: "platform",
                                labelName: "Platform",
                                tooltipRenderer: function (params) {
                                  return `${params.datum.platform} - ${params.datum.title}`;
                                },
                                marker: {
                                  shape: "circle",
                                  size: 2,
                                  maxSize: 40,
                                },
                                label: {
                                  enabled: false,
                                },
                                // highlightStyle: {
                                //   item: {
                                //     fill: "red",
                                //     stroke: "maroon",
                                //     strokeWidth: 4,
                                //   },
                                //   series: {
                                //     dimOpacity: 0.01,
                                //     strokeWidth: 2,
                                //   },
                                // },
                              };
                            }),

                            axes: [
                              {
                                type: "category",
                                position: "bottom",
                                label: {
                                  rotation: 315,
                                  formatter: (params) => params.value,
                                },
                              },
                              {
                                type: "number",
                                position: "left",
                                title: {
                                  text: "Wikipedia Page Views",
                                },
                                label: {
                                  // Add this to your axis definition
                                  formatter: (params) => {
                                    if (Math.abs(params.value) >= 1.0e9) {
                                      return (Math.abs(params.value) / 1.0e9).toFixed(0) + "B";
                                    } else if (Math.abs(params.value) >= 1.0e6) {
                                      return (Math.abs(params.value) / 1.0e6).toFixed(0) + "M";
                                    } else if (Math.abs(params.value) >= 1.0e3) {
                                      return (Math.abs(params.value) / 1.0e3).toFixed(0) + "K";
                                    }
                                    return params.value;
                                  },
                                },
                              },
                            ],
                          }}
                        />
                      </div>
                    </>
                  </SpaceBetween>
                ) : null}
              </Box>
            </Container>
          </ContentLayout>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TarSeriesWikiViewGenrePlatformMarketsharePage;
