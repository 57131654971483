import {
  Container,
  FormField,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import "ag-charts-enterprise";
import React, { useEffect, useMemo, useState } from "react";
import { MultiRangeSlider } from "../../../components/MultiRangeSlider/MultiRangeSlider";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import { isNone } from "../../../utils/sugarUtils";
import { useGetIMDbData } from "../api/hooks/useGetIMDbData";
import { ImdbResponseItem } from "../api/request";
import { ChartContainer } from "./ChartContainer";

export const IMDbRatingChart = () => {
  const { isLoading, data, error } = useGetIMDbData();

  const [selectedImdbRating, setSelectedImdbRating] = useState<{
    min: number;
    max: number;
  }>({
    min: 0,
    max: 10,
  });

  const [selectedGenre, setSelectedGenre] = useState<OptionDefinition>();

  const [selectedDistributor, setSelectedDistributor] =
    useState<OptionDefinition>();

  const [selectedOTT, setSelectedOTT] = useState<OptionDefinition>();

  const [selectedProduction, setSelectedProduction] =
    useState<OptionDefinition>();

  const [selectedYear, setSelectedYear] = useState<OptionDefinition>();

  const dropdownElements = [
    {
      key: "year",
      label: "Year",
      placeholder: "Year",
      option: selectedYear,
      setOption: setSelectedYear,
    },
    {
      key: "genre",
      label: "Genre",
      placeholder: "Genre",
      option: selectedGenre,
      setOption: setSelectedGenre,
    },
    {
      key: "distributors",
      label: "Distributor",
      placeholder: "Distributor",
      option: selectedDistributor,
      setOption: setSelectedDistributor,
    },
    {
      key: "ott",
      label: "OTT",
      placeholder: "OTT",
      option: selectedOTT,
      setOption: setSelectedOTT,
    },
    {
      key: "production",
      label: "Production",
      placeholder: "Production",
      option: selectedProduction,
      setOption: setSelectedProduction,
    },
  ];

  const [filteredData, setFilteredData] = useState();

  const getOptions = (key: string) => {
    if (!data[key]) return [];

    const allOption = { label: "All", value: "All" };
    const options = data[key].map((item: ImdbResponseItem) => ({
      label: item.toString(),
      value: item.toString(),
    }));
    options.unshift(allOption);

    return options;
  };

  const isValidOption = (
    item: ImdbResponseItem,
    key: string,
    value: string,
  ) => {
    if (isNone(value) || value === "All") return true;

    if (key === "release_date") {
      return new Date(item[key]).getFullYear() === Number(value);
    }

    return item[key]?.includes(value);
  };

  useEffect(() => {
    const newData = data?.data?.filter((item: ImdbResponseItem) => {
      return (
        item["imdb_rating"] >= selectedImdbRating.min &&
        item["imdb_rating"] <= selectedImdbRating.max &&
        isValidOption(item, "release_date", selectedYear?.value) &&
        isValidOption(item, "genre", selectedGenre?.value) &&
        isValidOption(item, "distributors", selectedDistributor?.value) &&
        isValidOption(item, "ott", selectedOTT?.value) &&
        isValidOption(item, "production", selectedProduction?.value)
      );
    });

    setFilteredData(newData);
  }, [
    data,
    selectedYear?.value,
    selectedGenre,
    selectedDistributor?.value,
    selectedOTT?.value,
    selectedProduction?.value,
    setFilteredData,
    selectedImdbRating.min,
    selectedImdbRating.max,
  ]);

  const handleSliderChange = useMemo(
    () =>
      ({ min, max }) =>
        setSelectedImdbRating({ min, max }),
    [],
  );

  if (isLoading) {
    return (
      <Container>
        <div className="flex flex-row justify-center h-200">
          <CustomLoadingOverlay />
        </div>
      </Container>
    );
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Container>
        <SpaceBetween direction="horizontal" size="l">
          <FormField label="IMDB Rating">
            <SpaceBetween direction="horizontal" size="xs">
              <MultiRangeSlider
                min={0}
                max={10}
                onChange={handleSliderChange}
              />
            </SpaceBetween>
          </FormField>
          {dropdownElements.map(
            ({ key, label, placeholder, option, setOption }) => (
              <FormField label={label} key={label}>
                <Select
                  placeholder={placeholder}
                  selectedOption={option}
                  onChange={({ detail }) => setOption(detail.selectedOption)}
                  options={getOptions(key)}
                  filteringType="auto"
                />
              </FormField>
            ),
          )}
        </SpaceBetween>
      </Container>
      <div className="h-200">
        <ChartContainer data={filteredData} />
      </div>
    </SpaceBetween>
  );
};
