import React, { useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Header,
  Toggle,
  SideNavigation,
  Box,
  Icon,
  Table,
  Popover,
  StatusIndicator,
  Input,
  Hotspot,
  Link,
  Flashbar,
  Pagination,
  ButtonDropdown,
  SpaceBetween,
  Modal,
  Button,
} from "@cloudscape-design/components";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import {
  getFilteredFranchiseData,
  createFranchiseAction,
} from "./redux/franchise_action";
import Preferences from "../components/Preferences";
import Filters from "../components/Filters";
import { FranchiseCreateModal } from "./modals/CreateModal";
import { set } from "lodash";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };
const breadcrumbs = [{ text: "Media Catalog" }, { text: "Explore", href: "/explore" }, { text: "Franchise" }];

const TableColumns = [
  // {
  //   id: "poster",

  //   cell: (e) => {
  //     return (
  //       <img
  //         src={`https://images.searchpantheon.com/collections/${e.ip_id}_684.jpg`}
  //         alt={e.name}
  //         className="rounded-full w-20 h-20  object-cover"
  //       />
  //     );
  //   },
  // },
  {
    id: "name",
    header: "Title & Summary",
    width: 450,
    minWidth: 300,
    cell: (e) => {
      const summary = e.summary || "N/A";
      const typeMapping = {
        "Video Game": "Game",
        "Television Series": "Series",
        Film: "Movies",
      };

      const firstDate = new Date(e.first_date);
      const lastDate = new Date(e.last_date);

      // Calculate difference in years and months
      const diffYears = lastDate.getFullYear() - firstDate.getFullYear();
      const diffMonths =
        (lastDate.getFullYear() - firstDate.getFullYear()) * 12 +
        (lastDate.getMonth() - firstDate.getMonth());

      let timeDisplay;
      if (diffMonths < 12) {
        timeDisplay = `${diffMonths} ${diffMonths > 1 ? "months" : "month"}`;
      } else {
        timeDisplay = `${diffYears} ${diffYears > 1 ? "years" : "year"}`;
      }

      return (
        <div className="flex items-start">
          <img
            src={`https://images.searchpantheon.com/collections/${e.ip_id}_684.jpg`}
            alt={e.name}
            className="rounded-lg w-20 h-20  object-cover"
            loading="lazy"
          />
          <div className="ml-4">
            <div className=" flex  gap-2 align-top items-center">
              <Link
                href={`${window.location.origin}/item/${e.ip_id}`}
                fontSize="heading-m"
              >
                <span className=" font-bold">{e.name}</span>
              </Link>
              <Popover
                dismissButton={false}
                size="large"
                content={<div>{summary}</div>}
              >
                <Icon variant="link" name="status-info" />
              </Popover>
            </div>

            <div className="flex flex-wrap gap-1 mt-1">
              {e.content_types?.length > 0 ? (
                e.content_types.map((type, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center rounded-md bg-purple-400/10 px-2 py-1 text-xs font-medium text-purple-400 ring-1 ring-inset ring-purple-400/30"
                  >
                    {typeMapping[type] || type}
                  </span>
                ))
              ) : (
                <div className="text-slate-400">N/A</div>
              )}
            </div>
            <div className="flex flex-row mt-1 gap-1 items-center">
              <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                <span>
                  {firstDate.getFullYear()} - {lastDate.getFullYear()}
                </span>
              </span>
              <span className="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                <span>{timeDisplay}</span>
              </span>
            </div>
          </div>
        </div>
      );
    },
    sortingField: "name",
  },
  {
    id: "total_ips",
    header: "Total",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="font-bold text-base">{e.total_ips}</span>;
    },
    sortingField: "total_ips",
  },
  {
    id: "film_count",
    header: "Movies",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="font-bold text-base">{e.film_count}</span>;
    },
    sortingField: "film_count",
  },
  {
    id: "series_count",
    header: "Series",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="font-bold text-base">{e.series_count}</span>;
    },
    sortingField: "series_count",
  },
  {
    id: "game_count",
    header: "Games",
    minWidth: 100,
    maxWidth: 100,
    cell: (e) => {
      return <span className="font-bold text-base">{e.game_count}</span>;
    },
    sortingField: "game_count",
  },
];

const idMap = new Map([
  ["name", "name"],
  ["tracked", "tracked"],
  ["total_ips", "total_ips"],
  ["film_count", "film_count"],
  ["series_count", "series_count"],
  ["game_count", "game_count"],
]);

export default function ExploreFranchise({
  itemData,
  onFranchiseCreateSubmit,
  ...props
}) {
  const { user } = useAuthContext();
  const { data, total, status, error } = useSelector(
    (state) => state.franchiseData
  );
  const [preferences, setPreferences] = useState({
    wrapLines: true,
    stripedRows: true,
    contentDensity: "comfortable",
    pageSize: 50,
    visibleContent: [
      "name",
      "poster",
      "content_type",
      "total_ips",
      "film_count",
      "series_count",
      "game_count",
    ],
  });
  const [sortingColumn, setSortingColumn] = useState({ id: "total_ips" });
  const [sortingDescending, setSortingDescending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const onCreateInit = () => {
    setShowCreateModal(true);
  };
  const onCreateDiscard = () => {
    setShowCreateModal(false);
  };
  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          explore: {
            franchise: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          })
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            content: e.response?.data?.message || "Failed to save preference ",
          })
        );
      });
  };

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((d) => d.data)
      .then((d) => {
        if (d.explore?.franchise?.preferences) {
          setPreferences(d.explore?.franchise?.preferences);
        }
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  const formatFilter = (token, operator) => {
    if (!token.propertyKey) {
      return {
        match_phrase: {
          name: `*${token.value}*`,
        },
      };
    }

    switch (token.propertyKey) {
      case "name":
        return operator === "contain"
          ? {
              query_string: {
                default_field: "ip",
                query: `*${token.value}*`,
              },
            }
          : {
              match: {
                ip: {
                  query: token.value,
                  auto_generate_synonyms_phrase_query: false,
                },
              },
            };
      case "tracked":
        return {
          query_string: {
            default_field: "tracked",
            query: token.value,
          },
        };
      case "status":
        return {
          match: { status: token.value },
        };

      default:
        return null;
    }
  };

  useEffect(() => {
    const from = (currentPage - 1) * preferences.pageSize;

    const sort = sortingColumn
      ? [
          {
            [`${idMap.get(sortingColumn.id)}`]: sortingDescending
              ? "desc"
              : "asc",
          },
        ]
      : [];
    const include = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "=") {
          return formatFilter(t);
        }
        if (t.operator === ":") {
          return formatFilter(t, "contain");
        }
        return null;
      })
      .filter((e) => e);
    const exclude = filteringQuery.tokens
      .map((t) => {
        if (t.operator === "!=") {
          return formatFilter(t);
        }
        return null;
      })
      .filter((e) => e);
    const body = {
      sort,
      include,
      exclude,
      include_condition: filteringQuery.operation,
    };
    const range = () => {
      if (!filteringQuery.tokens.map((e) => e.propertyKey)) {
        return null;
      }
    };

    getFilteredFranchiseData(from, preferences.pageSize, range(), body);
  }, [
    currentPage,
    preferences.pageSize,
    sortingColumn,
    sortingDescending,
    filteringQuery,
  ]);

  useEffect(() => {
    getPref();
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (status === "failed" && error) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error,
        })
      );
    }
  }, [status, error]);

  const createFranchise = (data) => {
    createFranchiseAction(data.name, data.summary, data.added)
      .then((res) => res.data)
      .then((response) => {
        window.location = "/item/" + response?.ip_id;
        onCreateDiscard();
      })
      .catch((error) => {
        console.log("Error creating franchise:", error);
        onCreateDiscard();
      });
  };
  return (
    <>
      <Helmet>
        <title>Explore Franchise</title>
      </Helmet>

      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
            <Table
              visibleColumns={preferences.visibleContent}
              columnDefinitions={TableColumns}
              sortingColumn={sortingColumn}
              sortingDescending={sortingDescending}
              onSortingChange={(event) => {
                setSortingDescending(event.detail.isDescending);
                setSortingColumn(event.detail.sortingColumn);
              }}
              loading={status === "loading"}
              items={data}
              loadingText="Loading resources"
              wrapLines
              stickyHeader
              resizableColumns={true}
              variant="full-page"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No items</b>
                  <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                    No items to display.
                  </Box>
                </Box>
              }
              header={
                <>
                  <CustomFlashBar />
                  <Header
                    variant="h3"
                    counter={ <span>({total.toLocaleString()})</span> }
                    actions={
                      <Button
                        variant="primary"
                        onClick={() => setShowCreateModal(true)}
                      >
                        Create Franchise
                      </Button>
                    }
                  >
                    Franchises
                  </Header>
                </>
              }
              preferences={
                <Preferences
                  preferences={preferences}
                  handleChange={handlePreferencesChange}
                />
              }
              pagination={
                <Pagination
                  currentPageIndex={currentPage}
                  pagesCount={Math.ceil(
                    (total > 10000 ? 10000 : total) / preferences.pageSize
                  )}
                  onChange={(page) => {
                    setCurrentPage(page.detail.currentPageIndex);
                  }}
                />
              }
              filter={
                <Filters
                  data={data}
                  preferences={preferences}
                  query={filteringQuery}
                  handleChange={(detail) => {
                    setFilteringQuery(detail);
                    setCurrentPage(1);
                  }}
                />
              }
            />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
      <FranchiseCreateModal
        visible={showCreateModal}
        onDiscard={onCreateDiscard}
        onSubmit={(e) => {
          createFranchise(e);
        }}
        title={itemData?.data?.name || ""}
        description={itemData?.data?.summary || ""}
      />
    </>
  );
}
