import {
  AppLayout,
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
  SplitPanel,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import Board from "@cloudscape-design/board-components/board";
import { EmptyState } from "./components/EmptyState";
import { boardI18nStrings } from "./utils/i18n-strings";

import _ from "lodash";
import Palette from "./components/Palette";
import { useItemsLayout } from "./utils/use-items-layout";
import { ResetButton } from "./components/reset-button";
import {
  exportLayout,
  getBoardWidgets,
  getPaletteWidgets,
} from "./utils/widgets";
import { ConfigurableWidget } from "./components/configurable-widget";
import { useSelector } from "react-redux";
import { getViewersPulsePreferences } from "../../common/redux/global-pref-selector";

const splitPanelMaxSize = 360;

const DashboardWizard = () => {
  const appLayoutRef = useRef();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelSize, setSplitPanelSize] = useState(360);
  const [boards, setBoard] = useState([]);
  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Viewer's Pulse", href: "/"},
    { text: "Personal Dashboard" },
  ];

  const viewersPulsePreferences = useSelector(getViewersPulsePreferences);

  const [ref, layout, setLayout, resetLayout] = useItemsLayout({
    viewersPulsePreferences,
  });

  const handleNavigationChange = ({ detail: { open } }) => {
    setNavigationOpen(open);
  };

  return (
    <>
      <Helmet>
        <title>Personal Dashboard</title>
      </Helmet>
      <AppLayout
        ref={appLayoutRef}
        toolsHide
        navigationOpen={navigationOpen}
        onNavigationChange={handleNavigationChange}
        contentType="cards"
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        splitPanel={
          <SplitPanel
            header="Add widgets"
            closeBehavior="hide"
            hidePreferencesButton={true}
          >
            <Palette items={getPaletteWidgets(layout)} />
          </SplitPanel>
        }
        splitPanelPreferences={{ position: "side" }}
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={({ detail }) => setSplitPanelOpen(detail.open)}
        splitPanelSize={splitPanelSize}
        onSplitPanelResize={(event) =>
          setSplitPanelSize(Math.min(event.detail.size, splitPanelMaxSize))
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  variant="h2"
                  description="A personal dashboard with customizable widgets."
                  actions={
                    <SpaceBetween size="xs" direction="horizontal">
                      {layout?.length > 0 && (
                        <ResetButton onReset={resetLayout}>
                          Reset to default layout
                        </ResetButton>
                      )}
                      <Button
                        iconName="add-plus"
                        onClick={() => setSplitPanelOpen(true)}
                      >
                        Add widget
                      </Button>
                    </SpaceBetween>
                  }
                >
                  Personal Dashboard
                </Header>
              </>
            }
          >
            <div ref={ref}>
              <Board
                empty={
                  <EmptyState
                    title="No widgets"
                    description="There are no widgets on the dashboard."
                    verticalCenter={true}
                    action={
                      <Button
                        iconName="add-plus"
                        onClick={() => setSplitPanelOpen(true)}
                      >
                        Add Widget
                      </Button>
                    }
                  />
                }
                i18nStrings={boardI18nStrings}
                items={getBoardWidgets(layout)}
                onItemsChange={({ detail: { items } }) => {
                  setLayout(exportLayout(items));
                }}
                renderItem={(item, actions) => {
                  const Wrapper = item?.data?.provider ?? React.Fragment;
                  return (
                    <Wrapper>
                      <ConfigurableWidget
                        config={item?.data}
                        onRemove={actions?.removeItem}
                      />
                    </Wrapper>
                  );
                }}
              />
            </div>
          </ContentLayout>
        }
      />
    </>
  );
};

export default DashboardWizard;
