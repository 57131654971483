export const getSentimentCategory = (score: number) => {
  const sentimentScores = {
    Overwhelmingly_Positive: [0.8, 1.0],
    Very_Positive: [0.6, 0.8],
    Positive: [0.4, 0.6],
    Mostly_Positive: [0.2, 0.4],
    Mixed: [-0.2, 0.2],
    Mostly_Negative: [-0.4, -0.2],
    Negative: [-0.6, -0.4],
    Very_Negative: [-0.8, -0.6],
    Overwhelmingly_Negative: [-1.0, -0.8],
  };

  for (const [sentiment, [min, max]] of Object.entries(sentimentScores)) {
    if (score >= min && score <= max) {
      return sentiment.replace(/_/g, " ");
    }
  }

  return " ";
};

export const getSentimentColor = (score: number) => {
  if (score > 0.2) {
    return "text-status-success";
  } else if (score >= -0.2 && score <= 0.2) {
    return "text-status-info";
  } else {
    return "text-status-error";
  }
};