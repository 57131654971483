import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTarSeriesWikiViewGenrePlatform = createAsyncThunk(
  "tarSeriesWikiViewGenrePlatform/fetchTarSeriesWikiViewGenrePlatform",
  async ({ gte, lte, interval, excludeOTT }) => {
    const params = excludeOTT
      ? { gte, lte, interval, exclude: "ott" }
      : { gte, lte, interval };
    const response = await axiosInstance.request({
      url: `/tar/wiki/views/genre/platform`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

const tarSeriesWikiViewGenrePlatformResposeSlice = createSlice({
  name: "tarSeriesWikiViewGenrePlatform",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTarSeriesWikiViewGenrePlatform.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchTarSeriesWikiViewGenrePlatform.fulfilled,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        }
      )
      .addCase(
        fetchTarSeriesWikiViewGenrePlatform.rejected,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        }
      );
  },
});

const TarSeriesWikiViewGenrePlatformReducer =
  tarSeriesWikiViewGenrePlatformResposeSlice.reducer;

export default TarSeriesWikiViewGenrePlatformReducer;

export const tarSeriesWikiViewGenrePlatformResposeAction =
  tarSeriesWikiViewGenrePlatformResposeSlice.actions;
