import React from 'react';
import {
    ColumnLayout,
    Box,
    Badge,
    SpaceBetween,
    Link,
    TextContent,
    Container,
    Header,
} from '@cloudscape-design/components';

const ProdnDistrib = ({ distributors }) => {
    // Group distributors by format
    const distributorsByFormat = distributors.reduce((acc, distributor) => {
        distributor.formats.forEach(format => {
            if (!acc[format]) acc[format] = [];
            acc[format].push(distributor);
        });
        return acc;
    }, {});

    // Sort groups by priority
    const formatPriority = ['Theatrical', 'DVD', 'Blu-ray', 'TV'];
    const sortedFormats = Object.keys(distributorsByFormat).sort(
        (a, b) => formatPriority.indexOf(a) - formatPriority.indexOf(b)
    );

    return (
        <SpaceBetween size="l" direction="vertical">
            {sortedFormats.map((format) => (
                    <Container key={format} header={
                    <Header variant="h4">
                            {format.toUpperCase()}
                        </Header>
                    }>
                        <ColumnLayout columns={3} borders="vertical" variant="text-grid">
                            {distributorsByFormat[format].sort((a, b) => a.startYear - b.startYear).map((distributor, index) => (
                                <Box key={index} padding="s">
                                    <Link href="#">{distributor.name}</Link>
                                    <TextContent >{`${distributor.startYear} - ${distributor.endYear}`}</TextContent>
                                </Box>
                            ))}
                        </ColumnLayout>
                    </Container>
            ))}
        </SpaceBetween>
    );
};

export default ProdnDistrib;
