import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../../../components/forms/TextField";
import FormProvider from "../../../../components/forms/FormProvider";
import SelectBox from "../../../../components/forms/SelectBox";
import { useSelector } from "react-redux";
import {
  clearUserEditFn, editUserFn
} from "../redux/actions/user-actions";
import { Alert } from "@cloudscape-design/components";
export default function EditUserForm({

  setCancel,
  setCompleted,
  defaults,
  ...others
}) {

  const editUser = useSelector((state) => state.editUser);
  useEffect(() => {
    if (!editUser.isLoading && editUser.isCompleted && !editUser.isError) {
      setCompleted();
      setCancel(true);
    }
    return () => {
      clearUserEditFn();
    };
  }, [editUser]);

  const onSubmit = async (data) => {
    editUserFn({ ...data, userName: defaults.Username });
  };

  const createUserDefaultValue = {
    fullname: defaults ? defaults.name : "",
    username: defaults ? defaults.userName : "",
    role:
      defaults && defaults.role
        ? others.rolesList.find((r) => r.label === defaults.role)
        : "",
    org:
      defaults && defaults.organization
        ? others.orgs.find((r) => r.label === defaults.organization)
        : "",
    email: defaults ? defaults.email : "",
  };
  const createUserSchema = Yup.object().shape({
    fullname: Yup.string().required("Fullname is required"),
    role: Yup.object().required("Role is required"),
    org: Yup.object().required("Team is required"),
  });

  const methods = useForm({
    defaultValues: createUserDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserSchema),
  });

  const {
    setValue,
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (defaults) {
      setValue("fullname", defaults.name);
      setValue(
        "role",
        others.rolesList.find((r) => r.label === defaults.role)
      );
      setValue(
        "org",
        others.orgs.find((r) => r.label === defaults.organization)
      );
    }
  }, [defaults]);

 

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      id="edit_create_form"
    >
      <div className="py-5">
        {!editUser.isLoading && editUser.isCompleted && editUser.isError && (
          <Alert type="error" dismissible={true}>
            {editUser.message}
          </Alert>
        )}
        {!editUser.isLoading && editUser.isCompleted && !editUser.isError && (
          <Alert type="success" dismissible={true}>
            {editUser.message}
          </Alert>
        )}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
          <span>
            <TextField
              name={"fullname"}
              label="Full Name"
              placeholder={"Full Name"}
              type={"text"}
            />
          </span>
          <span>
            <SelectBox
              name={"role"}
              label="Roles"
              placeholder={"Roles"}
              items={others.rolesList}
            />
          </span>
          <span>
            <SelectBox
              name={"org"}
              label="Team"
              placeholder={"Team"}
              items={others.orgs}
            />
          </span>

          <span></span>
        </div>
      
      </div>
    </FormProvider>
  );
}
