import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getIMDbTrendsData } from "../request";

export const useGetIMDbTrendsData = (vertical: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_IMDB_TRENDS_DATA,vertical],
    () => getIMDbTrendsData(vertical),
  );

  return {
    data,
    isLoading,
    error,
  };
};
