import { dispatch } from "../../../../app/store";
import { fetchTelevisionData, fetchFilteredTelevisionData } from "./television_slice";

export async function getTelevisionData(from, size, range) {
  await dispatch(fetchTelevisionData({ from, size, range }));
}

export async function getFilteredTelevisionData(from, size, range, body) {
  await dispatch(fetchFilteredTelevisionData({ from, size, range, body }));
}
